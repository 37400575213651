<template>
    <div class="createproject">
        <div class="container">
            <h1>{{ title }}</h1>
            <el-form :model="createform" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="项目名称" prop="title">
                    <el-input type="text" placeholder="请输入项目名称" @input="change($event)" v-model="createform.title"
                        maxlength="20" show-word-limit>
                    </el-input>
                </el-form-item>
                <el-form-item label="阶段数目" prop="stagenum" class="stagenum">
                    <el-input type="number" placeholder="请输入项目名称" v-model="createform.stagenum" maxlength="20"
                        show-word-limit @blur="onblur" @input="onInput">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="stageform" ref="ruleForm" label-width="100px" class="demo-ruleForm" id="stageform">
                <el-row v-for="(item, index) in this.stageform.process" :key="index">
                    <el-col class="list">
                        <p>p{{ index + 1 }}阶段</p>
                        <img src="@/assets/images/userprofile/delproject.png" class="del"
                            @click.stop="dellist(item, index)" alt="">
                        <el-col>
                            <el-col>
                                <el-form-item label="阶段名称" :prop="'process.' + index + '.title'"
                                    :rules="stagerules.title">
                                    <el-input v-model="item.title" @input="change($event)" placeholder="请输入阶段名称">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col>
                                <el-form-item label="计划交付日期" :prop="'process.' + index + '.pay_date'"
                                    :rules="stagerules.pay_date">
                                    <el-date-picker v-model="item.pay_date" placeholder="选择日期" @change="dateplackchange"
                                        type="daterange" :range-separator="rangeSeparator" value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col>
                                <el-form-item label="计划金额" :prop="'process.' + index + '.money'"
                                    :rules="stagerules.money">
                                    <el-input v-model="item.money" @input="change($event)" type="number"
                                        placeholder="请输入计划金额">
                                        <i slot="suffix">元</i>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col>
                                <el-form-item label="交付说明" :prop="'process.' + index + '.introduce'"
                                    :rules="stagerules.introduce">
                                    <el-input type="textarea" @input="change($event)" v-model="item.introduce"
                                        placeholder="请输入交付说明" maxlength="200" show-word-limit>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-col>
                    </el-col>
                </el-row>
                <el-form-item class="ceratebtn">
                    <el-button @click="submitForm('ruleForm')">确定</el-button>
                    <el-button @click="resetForm('ruleForm')">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { addProject, prodetail, editProject,Resubmit } from "@/api/api"
export default {
    data() {
        return {
            status: "",
            title: "创建项目",
            statusbar: '',//判断是否是编辑项目
            detailid: "",
            rangeSeparator: "",
            createform: {
                stagenum: 2,
            },
            stageform: {
                process: [
                    {
                        title: "",
                        pay_date: "",
                        money: "",
                        introduce: ""
                    },
                    {
                        title: "",
                        pay_date: "",
                        money: "",
                        introduce: ""
                    }
                ]
            },
            rules: {
                title: [
                    { required: true, message: '请输入项目名称', trigger: 'blur' },
                ],
                stagenum: [
                    { required: true, message: '请输入阶段数目', trigger: 'blur' },

                ]
            },
            stagerules: {
                title: [
                    {
                        required: true, message: '请输入阶段名称', trigger: 'blur'
                    }
                ],
                pay_date: [
                    {
                        required: true, message: "请选择计划交付日期", trigger: 'blur'
                    }
                ],
                money: [
                    {
                        required: true, message: "请输入计划金额", trigger: 'blur'
                    }
                ],
                introduce: [
                    {
                        required: true, message: "请输入计划说明", trigger: 'blur'
                    }
                ],
            }
        }
    },
    mounted() {
        if (this.$route.query.id) {
            this.title = "编辑项目"
            this.detailid = this.$route.query.id
            this.statusbar = this.$route.query.statusbar
            this.getProDetail(this.detailid)
            if (this.$route.query.status) {
                this.status = this.$route.query.status
            } else {
                this.status = ""
            }
        } else {
            this.title = "创建项目"
        }
    },
    methods: {
        change() {
            this.$forceUpdate();
        },
        // 获取编辑详情
        getProDetail(id) {
            prodetail(id)
                .then(res => {
                    if (res.code == 10000) {
                        this.createform.title = res.data.title
                        res.data.processList.map(e => {
                            e.pay_date = e.pay_date.split(",")
                        })
                        console.log(res.data.processList)
                        this.stageform.process = res.data.processList
                        this.rangeSeparator = "-"
                    }
                })
        },
        // 创建几个阶段数目
        onblur() {
            let list = []
            if (this.createform.stagenum == 0 || this.createform.stagenum == 1) {
                this.createform.stagenum = 2
                this.$message.error("不能少于两个阶段！！")
                var num = parseInt(this.createform.stagenum)
                for (var i = 0; i < num; i++) {
                    list.push({
                        title: "",
                        pay_date: "",
                        money: "",
                        introduce: ""
                    })

                }
                this.stageform.process = list
                return
            } else {
                var num = parseInt(this.createform.stagenum)

                for (var i = 0; i < num; i++) {
                    list.push({
                        title: "",
                        pay_date: "",
                        money: "",
                        introduce: ""
                    })

                }

                this.$set(this.stageform, "process", list)
                this.stageform.process = list
                console.log(this.stageform.stagelist)
            }

        },
        // 删除阶段数目
        dellist(e, id) {
            if (this.stageform.process.length <= 2) {
                this.$message.error("阶段数目不能少于两个！！")
            } else {
                this.stageform.process.splice(this.stageform.stagelist.indexOf(id), 1);
            }
        },
        onInput(e) {

        },
        // 日期选中触发
        dateplackchange(e) {
            this.rangeSeparator = "-"
            console.log(e)
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var process = []
                    this.stageform.process.map((e, index) => {
                        // e.pay_date = e.pay_date.toString()
                        process.push({
                            title: e.title,
                            pay_date: e.pay_date.toString(),
                            money: e.money,
                            introduce: e.introduce,
                            stage: Number(index + 1)
                        })
                    })
                    this.stageform.process = process
                    console.log(this.stageform.process)
                    var st = []
                    this.stageform.process.map((e, index) => {
                        st.push(index)
                    })
                    let data = {
                        title: this.createform.title,
                        number: 0,
                        need_id: 0,
                        total_money: 0,
                        process: this.stageform.process
                    }

                    if (this.$route.query.statusbar) {
                        // 编辑项目
                        editProject(this.detailid, data)
                            .then(res => {
                                if (res.code == 10000) {
                                    this.$message.success("编辑成功！")
                                }
                            })
                    } else if (this.$route.query.status) {
                        data.id = this.detailid
                        Resubmit(data).then(res=>{
                            if(res.code==10000){
                               this.$message.success('操作成功！')
                            }
                        })
                        console.log("充好高兴哦你们")
                    } else {
                        console.log("sdsd")
                        // 创建项目
                        addProject(data)
                            .then(res => {
                                if (res.code == 10000) {
                                    this.$message.success("创建成功！")
                                    this.$refs[formName].resetFields();
                                    this.createform.title = ""
                                    this.createform.stagenum = 2
                                }
                            })
                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.$router.go(-1)
        }
    }
}
</script>
<style lang="scss" >
.createproject {
    width: 100%;
    height: auto;
    overflow: hidden;

    .container {
        height: 100%;
        margin: 0 auto;
        padding: 2.5rem 3.125rem;
        max-width: 86.25rem;

        h1 {
            font-size: 2.875rem;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #240F45;
            line-height: 3.375rem;
        }

        .el-form {
            .el-form-item {
                margin: 2rem 0;
                display: flex;
                align-items: center;

                .el-form-item__label {
                    position: relative;
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #111029;
                    line-height: 1.1875rem;

                    &::before {
                        position: absolute;
                        right: -0.1875rem;
                        top: -0.3125rem;
                    }
                }

                .el-form-item__content {
                    margin-left: 1rem !important;

                    .el-input {
                        width: 35.125rem;
                        height: 2.625rem;
                        background: #FFFFFF;
                        box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
                        border-radius: 10px 10px 10px 10px;

                        .el-input__inner {
                            height: 100%;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #111029;
                            line-height: 1.1875rem;

                            &::placeholder {
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &::-webkit-input-placeholder {
                                /* WebKit browsers 适配谷歌 */
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &:-moz-placeholder {
                                /* Mozilla Firefox 4 to 18 适配火狐 */
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &::-moz-placeholder {
                                /* Mozilla Firefox 19+ 适配火狐 */
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &:-ms-input-placeholder {
                                /* Internet Explorer 10+  适配ie*/
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }
                        }
                    }



                }

            }

            .stagenum {
                width: 100%;
                height: 106px;
                border-radius: 10px 10px 0px 0px;
                background: url('../../../../assets/images/userprofile/createbg.png') center no-repeat;
                background-size: 100% 100%;
                margin-bottom: 0rem;
            }
        }

        #stageform {
            width: 100%;
            background: #F7F7FC;

            .el-row {
                .list {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #DCDDEB;
                    position: relative;

                    .del {
                        cursor: pointer;
                        display: block;
                        width: 1.25rem;
                        height: 1.25rem;
                        position: absolute;
                        top: .625rem;
                        right: .625rem;
                    }

                    p {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        font-size: 1rem;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #2F2C42;
                        line-height: 1.1875rem;
                        width: 11.25rem;
                        height: 22.875rem;
                        border-right: 1px solid #DCDDEB;
                    }

                    .el-col {
                        .el-form-item {
                            margin-left: 2rem;
                            margin-top: 0;
                            margin-bottom: 2rem;

                            .el-form-item__label {
                                white-space: nowrap;
                            }

                            .el-form-item__content {
                                .el-date-editor {
                                    position: relative;

                                    .el-input__icon {
                                        position: absolute;
                                        right: 1rem;
                                    }
                                }

                                .el-input__suffix {
                                    margin-right: 1rem;
                                }

                                .el-textarea {
                                    width: 65.375rem;
                                    min-height: 5rem;
                                    background: #FFFFFF;
                                    box-shadow: 0px .25rem .625rem .0625rem rgba(58, 114, 255, 0.06);
                                    border-radius: .625rem .625rem .625rem .625rem;
                                    border: .0625rem solid #EFF0F6;

                                    .el-textarea__inner {
                                        height: 5rem;
                                        font-size: 1rem;
                                        font-family: PingFang SC-Regular, PingFang SC;
                                        font-weight: 100;
                                        color: #111029;
                                        line-height: 1.1875rem;

                                        &::placeholder {
                                            font-size: 1rem;
                                            font-family: PingFang SC-Regular, PingFang SC;
                                            font-weight: 100;
                                            color: #A0A3BD;
                                            line-height: 1.1875rem;
                                        }

                                        &::-webkit-input-placeholder {
                                            /* WebKit browsers 适配谷歌 */
                                            font-size: 1rem;
                                            font-family: PingFang SC-Regular, PingFang SC;
                                            font-weight: 100;
                                            color: #A0A3BD;
                                            line-height: 1.1875rem;
                                        }

                                        &:-moz-placeholder {
                                            /* Mozilla Firefox 4 to 18 适配火狐 */
                                            font-size: 1rem;
                                            font-family: PingFang SC-Regular, PingFang SC;
                                            font-weight: 100;
                                            color: #A0A3BD;
                                            line-height: 1.1875rem;
                                        }

                                        &::-moz-placeholder {
                                            /* Mozilla Firefox 19+ 适配火狐 */
                                            font-size: 1rem;
                                            font-family: PingFang SC-Regular, PingFang SC;
                                            font-weight: 100;
                                            color: #A0A3BD;
                                            line-height: 1.1875rem;
                                        }

                                        &:-ms-input-placeholder {
                                            /* Internet Explorer 10+  适配ie*/
                                            font-size: 1rem;
                                            font-family: PingFang SC-Regular, PingFang SC;
                                            font-weight: 100;
                                            color: #A0A3BD;
                                            line-height: 1.1875rem;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }


            }

            .ceratebtn {
                width: 100%;
                margin-top: 0;
                background: #FFFFFF;
                padding: 3.125rem 0;

                .el-form-item__content {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .el-button {
                        margin-top: 2rem;
                        width: 5rem;
                        height: 3.625rem;
                        font-size: 1rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #FFFFFF;
                        line-height: 1.1875rem;
                        background: #111029;
                        border-radius: 3.25rem 3.25rem 3.25rem 3.25rem;

                        &:nth-child(2) {
                            margin-left: 2rem;
                            background: #FFFFFF;
                            color: #111029;
                        }
                    }
                }
            }
        }
    }
}
</style>