<template>
    <div id="security">
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="手机号" v-if="form.mobile != ''">
                <el-input class="input" v-model="form.mobile" disabled></el-input>
                <!-- <div class="input">{{ form.ipone }}</div> -->
                <p @click="Edit(1)">修改手机号</p>
            </el-form-item>
            <el-form-item label="邮箱" v-if="form.mail != ''">
                <el-input class="input" v-model="form.mail" disabled></el-input>
                <!-- <div class="input">{{ form.ipone }}</div> -->
                <p @click="Edit(1, form)">修改邮箱</p>
            </el-form-item>
            <el-form-item label="密码">
                <el-input class="input" v-model="form.password" type="password" disabled></el-input>
                <!-- <div class="input">{{ form.pwd }}</div> -->
                <p @click="Edit(2, form)">修改密码</p>
            </el-form-item>
            <el-form-item label="支付宝账号">
                <el-input class="input" v-model="form.alipay_account" disabled></el-input>
                <!-- <div class="input">{{ form.zfb }}</div> -->
                <p @click="Edit(3, form)">{{ form.alipay_account == '' ? '添加支付宝' : "换绑支付宝" }}</p>
            </el-form-item>
        </el-form>
        <!-- 修改弹窗 -->
        <dialogs :show.sync="dialogVisible" width="690px" :center="true" :title="edittitle">
            <el-form :model="checkpwdform" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                class="demo-ruleForm" v-if="editindex == 2">
                <el-form-item prop="old_password">
                    <el-input type="password" v-model.number="checkpwdform.old_password" placeholder="旧密码">
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input type="password" v-model="checkpwdform.password" placeholder="新密码">
                    </el-input>
                </el-form-item>
                <el-form-item prop="repassword">
                    <el-input type="password" v-model="checkpwdform.repassword" placeholder="确认密码">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-form :model="ruleForm2" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                class="demo-ruleForm2" v-if="editindex == 1 || editindex == 3">
                 <el-form-item prop="old" v-if="editindex == 3">
                    <el-input v-model="ruleForm2.real_name" type="text" @input="onInput" placeholder="真实姓名"></el-input>
                </el-form-item>
                <el-form-item prop="old">
                    <el-input v-model="ruleForm2.old" type="text" @input="onInput" :placeholder=placeholder></el-input>
                </el-form-item>
                <p style="text-align: center;" v-if="editindex == 3">注：请务必确认填写的信息真实有效，否则会导致提现失败！</p>
                <el-form-item v-if="editindex != 3">
                    <div class="verification">
                        <el-input placeholder="验证码" v-model="ruleForm2.code" clearable></el-input>
                        <span class="code_btn verification-span text-align-center font-size-16 font-weight-400"
                            :class="codeText1 !== '获取验证码' ? 'gray-color' : ''"
                            @click="codeText1 === '获取验证码' ? send() : null">{{ codeText1 }}</span>
                    </div>
                </el-form-item>
                <el-form-item id="editbtn">

                </el-form-item>
            </el-form>
            <template slot="footer-slot">
                <el-button @click="resetForm('ruleForm')">取消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
            </template>
        </dialogs>
    </div>
</template>
<script>
import { changePwd, changeBinds, editzfb } from "@/api/api"
import { sindmsg, mailSind } from "@/api/javaApi"
import dialogs from '../../../../components/common/dialog-cpn.vue'
export default {
    props: {
        security: {
            type: Object,
            default: {}
        }
    },
    components: {
        dialogs
    },
    data() {
        var checkAge = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请输入旧密码'));
            }
            return
            setTimeout(() => {
                if (!Number.isInteger(value)) {
                    callback(new Error('请输入数字值'));
                } else {
                    if (value < 18) {
                        callback(new Error('必须年满18岁'));
                    } else {
                        callback();
                    }
                }
            }, 1000);
        };
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.checkpwdform.repassword !== '') {
                    this.$refs.ruleForm.validateField('repassword');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.checkpwdform.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            dialogVisible: false,
            edittitle: "修改手机号/邮箱",
            form: {
                ipone: "",
                pwd: '',
                zfb: ""
            },
            placeholder: "新手机号/邮箱",
            editindex: null,
            // 修改表单
            codeText1: "获取验证码",
            checkpwdform: {
            },
            ruleForm2: {},
            rules: {
                password: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                repassword: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
                old_password: [
                    { validator: checkAge, trigger: 'blur' }
                ]
            }
        }
    },
    watch: {
        security: {
            handler(data) {
                if (data) {
                    this.form = data
                    this.form.password = "11111111"
                }
            },
            deep:true,
            immediate:true
        }
    },
    methods: {
        onInput() {
            this.$forceUpdate();
        },
        Edit(e) {
            this.editindex = e
            this.dialogVisible = true
            if (e == 1) {
                if (this.form.mobile != '') {
                    this.edittitle = "修改手机号"
                    this.placeholder = "新手机号"
                } else {
                    this.edittitle = "修改邮箱"
                    this.placeholder = "新邮箱"
                }

            } else if (e == 2) {
                this.edittitle = "修改密码"

            } else {
                if (this.form.alipay_account == '') {
                    this.edittitle = "添加支付宝账号"
                } else {
                    this.edittitle = "修改支付宝账号"

                }
                this.placeholder = "新账号"
            }
        },
        submitForm(formName) {
            if (this.editindex == 2) {
                // 修改密码
                changePwd(this.checkpwdform)
                    .then(res => {
                        if (res.code == 10000) {
                            this.$message.success(res.message)
                            this.dialogVisible = false
                            this.$emit("upload")
                            setTimeout(() => {
                                this.$store.commit("REMOVE_INFO")
                                this.propDailog = false
                                let promise = this.$tim.logout()
                                promise
                                    .then(function (imResponse) {
                                        console.log(imResponse.data) // 登出成功
                                    })
                                    .catch(function (imError) {
                                        console.warn("logout error:", imError)
                                    })

                                this.$router.push("/login")
                            }, 1000)

                        }
                    })
            } else if (this.editindex == 1) {
                if (this.form.mobile != "") {
                    // 修改手机号
                    var obj = {
                        type: 'mobile',
                        mobile: this.ruleForm2.old,
                        code: this.ruleForm2.code
                    }

                } else {
                    // 修改邮箱
                    var obj = {
                        type: 'mail',
                        mail: this.ruleForm2.old,
                        code: this.ruleForm2.code
                    }
                }
                changeBinds(obj)
                    .then(res => {
                        if (res.code == 10000) {
                            this.$message.success(res.message)
                            this.$emit('upload')
                            this.dialogVisible = false
                            setTimeout(() => {
                                this.$store.commit("REMOVE_INFO")
                                this.propDailog = false
                                let promise = this.$tim.logout()
                                promise
                                    .then(function (imResponse) {
                                        console.log(imResponse.data) // 登出成功
                                    })
                                    .catch(function (imError) {
                                        console.warn("logout error:", imError)
                                    })

                                this.$router.push("/login")
                            }, 1000)
                        }
                    })
            } else {
                let obj={
                    real_name:this.ruleForm2.real_name,
                    alipay_account:this.ruleForm2.old
                }
                editzfb(obj).then(res => {
                    if (res.code == 10000) {
                        this.$message.success(res.message)
                        this.$emit('upload')
                        this.dialogVisible = false
                        this.ruleForm2={}
                    }
                })
            }
            return
            this.$refs[formName].validate((valid) => {
                if (valid) {

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {

            this.dialogVisible = false
            this.$refs[formName].resetFields();
        },
        send() {
            if (!this.ruleForm2.old) {
                this.$message.error('手机号不能为空')
            } else {
                if (this.form.mobile != "") {
                    // 发送验证码
                    sindmsg({ mobile: this.ruleForm2.old })
                        .then(res => {
                            if (res.code == 10000) {
                                this.codeText1 = '验证码已发送'
                                let num = 60
                                let timer = setInterval(res => {
                                    num = num - 1
                                    this.codeText1 = `${num}s后重新获取`
                                    if (num === 0) {
                                        clearInterval(timer)
                                        this.codeText1 = '获取验证码'
                                    }
                                }, 1000)
                            }
                        })
                } else {
                    mailSind({ mail: this.ruleForm2.old })
                        .then(res => {
                            if (res.code == 10000) {
                                this.codeText1 = '验证码已发送'
                                let num = 60
                                let timer = setInterval(res => {
                                    num = num - 1
                                    this.codeText1 = `${num}s后重新获取`
                                    if (num === 0) {
                                        clearInterval(timer)
                                        this.codeText1 = '获取验证码'
                                    }
                                }, 1000)
                            }
                        })
                }



                // let phoneNumber = this.ruleForm2.old
            }
        },
    }
}
</script>
<style lang="scss" >
#security {
    .el-form {
        margin-top: 1.5625rem;

        .el-form-item {

            .el-form-item__label {
                white-space: nowrap;
                 color: #111029;
                font-weight: 100;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
            }

            .el-form-item__content {
                display: flex;
                align-items: center;

                p {
                    cursor: pointer;
                    margin-left: 2rem;
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    color: #3A71FF;
                    line-height: 1.1875rem;
                }

                .input {
                    display: flex;
                    align-items: center;
                    width: 21.5rem;
                    padding-left: 1rem;
                    height: 2.625rem;
                    // background: #FFFFFF;
                    // // box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
                    // // border-radius: .625rem .625rem .625rem .625rem;
                    // margin-left: 2rem;
                    // font-size: 1rem !important;
                    // font-family: PingFang SC-Regular, PingFang SC;
                    // color: #A0A3BD !important;
                    // line-height: 1.1875rem;

                }
            }
        }

    }

    .el-dialog {
        width: 34.875rem;
        background: #FFFFFF;
        border-radius: 10px 10px 10px 10px;
        opacity: 1;

        .el-dialog__close {
            font-size: 1.875rem;
            font-weight: bold;
        }

        .el-form {
            width: 100%;
            margin-top: 0rem;

            .el-form-item {
                display: flex;
                justify-content: center;

                .el-form-item__content {
                    margin-left: 0rem !important;
                }

                .el-input {
                    width: 23.75rem;
                    height: 2.625rem;
                    background: #FFFFFF;
                    box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
                    border-radius: 10px 10px 10px 10px;

                    .el-input__inner {
                        height: 100%;
                        font-size: .875rem;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 300;
                        color: #57596C;
                        line-height: 1rem;

                        &::placeholder {
                            font-size: 14px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #A0A3BD
                        }

                        &::-webkit-input-placeholder {

                            /* Chrome/Opera/Safari */
                            font-size: 14px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #A0A3BD
                        }

                        &:-moz-placeholder {
                            /* Mozilla Firefox 4 to 18 适配火狐 */
                            font-size: 14px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #A0A3BD;
                            /* 设置opacity  解决firefox不生效问题 */
                            opacity: 1;
                        }

                        &::-moz-placeholder {
                            /* Mozilla Firefox 19+ 适配火狐 */
                            font-size: 14px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #A0A3BD;
                            opacity: 1;
                        }

                        &:-ms-input-placeholder {
                            /* Internet Explorer 10+  适配ie*/
                            font-size: 14px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #A0A3BD
                        }
                    }
                }
            }

            #editbtn {
                .el-form-item__content {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .el-button {
                        width: 4.25rem;
                        height: 2.375rem;
                        background: #FFFFFF;
                        border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                        opacity: 1;
                        border: 1px solid #111029;

                        &:nth-child(2) {
                            background: #111029;
                        }
                    }
                }
            }
        }

        .demo-ruleForm2 {
            .verification {
                .el-input {
                    width: 15.75rem;
                    height: 2.625rem;
                    background: #FFFFFF;
                    box-shadow: 0px .25rem .625remx .0625rem rgba(58, 114, 255, 0.06);
                    border-radius: .625rem .625rem .625rem .625rem;
                }

                .verification-span {
                    cursor: pointer;
                    margin-left: 1rem;
                    display: inline-block;
                    width: 7rem;
                    height: 2.625rem;
                    background: #FFFFFF;
                    box-shadow: 0px .25rem .625rem .0625rem rgba(58, 114, 255, 0.06);
                    border-radius: .625rem .625rem .625rem .625rem;
                    border: 1px solid #EFF0F6;
                }
            }

            #editbtn {
                margin-top: 2rem;
            }
        }

        .el-dialog__footer {
            .dialog-footer {
                display: flex;
                align-content: center;
                justify-content: center;

                .el-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    width: 4.25rem;
                    height: 2.375rem;
                    background: #FFFFFF;
                    border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;

                    border: 1px solid #111029;

                    &:nth-child(2) {
                        background: #111029;
                    }
                }
            }
        }
    }
}
</style>