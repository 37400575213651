<!--
* @description 爆款案例推荐页面
* @fileName bkaltj.vue
* @author liulian
* @date 2022/06/08 18:31:53
-->
<template>
  <div class="bk_container">
    <div class="con">
      <div class="card" v-for="(item, index) in cards" :key="index">
        <coverCard :item="item"></coverCard>
      </div>
    </div>
  </div>
</template>

<script>
import coverCard from "@/components/common/cover-card.vue"
import { recommendedListApi } from "@/api/api.js"
export default {
  name: "bkaltj",
  components: {
    coverCard,
  },
  data() {
    return {
      cards: [],
    }
  },
  computed: {},
  created() {
    this.getInfo()
  },
  mounted() {},
  methods: {
    getInfo() {
      recommendedListApi().then((res) => {
        if (res.code === 10000) {
          this.cards = res.data.length > 0 && res.data.slice(0, 10)
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.bk_container {
  width: 89.125rem;
  margin: auto;
  .con {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // justify-content: space-between;

    .card {
      width: 15.625rem;
      height: 24rem;
      background: #ffffff;
      box-shadow: 0rem 0.125rem 0.75rem 0.0625rem rgba(20, 20, 43, 0.08);
      border-radius: 2rem;
      opacity: 1;
      border: 0.0625rem solid #eff0f6;
      margin-bottom: 1.75rem;
      margin-left: 1rem;
      margin-right: 1rem;
      .cover_img {
        width: 100%;
        height: 13.75rem;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
      }
      .center {
        width: 13.625rem;
        margin: 0.75rem auto;
        .aratar_box {
          width: 2.5rem;
          height: 2.5rem;
          position: relative;
          margin-right: 0.625rem;
          .avatar_img {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100%;
          }
          .flag_icon {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 0.875rem;
            height: 0.875rem;
          }
        }
        .right {
          .name {
            font-family: PingFang SC-Medium, PingFang SC;
            color: #240f45;
          }
          .item1 {
            width: 2.625rem;
            height: 1.0625rem;
            background: #ffeff0;
            border-radius: 1.5625rem;
            opacity: 1;
            display: inline-block;
            color: #dc2b2b;
            margin-right: 0.375rem;
            img {
              width: 0.4938rem;
              height: 0.5313rem;
            }
          }
          .item2 {
            display: inline-block;
            width: 2.625rem;
            height: 1.0625rem;
            opacity: 1;
            color: #11845b;
            background: #def2e6;
            border-radius: 1.5625rem;
          }
        }
        .center_bottom {
          color: #5c6466;
          margin-top: 0.875rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          word-break: break-all;
          overflow: hidden;
        }
      }
      .card_bottom {
        width: 13.625rem;
        margin: 1rem auto;
        border-top: 0.0625rem solid #f4f4f4;
        display: flex;
        align-items: center;
        .card_bottom_left {
          margin-top: 0.75rem;
          span {
            color: #a0a3bd;
          }
          .icon {
            width: 1.1875rem;
            height: 1.1875rem;
            margin-right: 0.375rem;
          }
        }
        .card_bottom_right {
          margin-top: 0.75rem;
          color: #2f2c42;
        }
      }
    }
  }
}
</style>
