<!--
* @description 登录页面
* @fileName login.vue
* @author liulian
* @date 2022/06/10 09:53:33
-->
<template>
  <div class="login">
    <div class="login_box">
      <div class="con_box">
        <!-- tab切换个人注册，官方注册 -->
        <div class="menu flex flex-row-center font-size-20 font-weight-400">
          <span class="cursor-pointer isAceive">忘记密码</span>
        </div>
        <!-- 官方注册 -->
        <el-form class="form_box form_box2" :model="form" status-icon :rules="rules" ref="form">
          <el-form-item>
            <el-input placeholder="手机号/邮箱" v-model="phoneOrMail" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="输入6-10位新密码" v-model="form.password" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="确认密码" v-model="form.repassword" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <div class="code_box flex">
              <el-input placeholder="输入4位验证码" v-model="form.code" clearable></el-input>
              <span class="code_btn text-align-center font-size-16 font-weight-400" :class="codeText !== '获取验证码' ? 'gray-color' : ''" @click="codeText === '获取验证码' ? sendCode() : null">{{ codeText }}</span>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="tips flex-row-between">
              <div class="link font-size-16 font-weight-400">返回<span class="font-color-blue cursor-pointer" @click="loginHandle">登录</span></div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="submitForm('form')">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 信息 -->
    <p class="bottom-tip font-size-12 font-weight-400F text-align-center">©1999-2022北京创新乐知网络技术有限公司</p>
  </div>
</template>

<script>
import { isPhone, isEmail, isPassword } from "@/utils/validate.js"
import { smsresetApi, mailresetApi } from "@/api/javaApi.js"
import { passwordResetApi } from "@/api/api.js"
export default {
  name: "login",
  components: {},
  data() {
    return {
      tabValue: 1,
      form: {
        mobile: null,
        mail: null,
        password: "",
        repassword: "",
        code: "",
      }, // 个人注册表单
      rules: {}, // 官方注册
      phoneOrMail: "",
      codeText: "获取验证码",
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /*
     * @Description: 登录
     * @MethodAuthor:  liulian
     * @Date: 2022-06-28 10:55:03
     * @param: ''
     */
    submitForm() {
      let c = this.checkData()
      if (!c) {
        passwordResetApi(this.form).then((res) => {
          if (res.code === 10000) {
            this.$message.success("重置密码成功")
            this.$router.push("/login")
          }
          //  else {
          //   this.$message.error(res.message)
          // }
        })
      }
    },
    /*
     * @Description: 发送验证码
     * @MethodAuthor:  liulian
     * @Date: 2022-06-28 10:55:08
     * @param: ''
     */
    sendCode() {
      if (!this.phoneOrMail) {
        this.$message.error("手机号或邮箱不能为空")
        return
      } else {
        // 校验是手机号，还是验证码
        let c = this.checkPhoneOrEmail(this.phoneOrMail, 2)
        if (c) {
          this.codeText = "验证码已发送"
          let num = 60
          let timer = setInterval((res) => {
            num = num - 1
            this.codeText = `${num}s后重新获取`
            if (num === 0) {
              clearInterval(timer)
              this.codeText = "获取验证码"
            }
          }, 1000)
        }
        if (this.form.mobile) {
          smsresetApi({ mobile: this.form.mobile }).then((res) => {
            if (!res.code === 10000) {
              // this.$message.error(res.message)
            }
          })
        } else if (this.form.mail) {
          mailresetApi({ mail: this.form.mail }).then((res) => {
            if (!res.code === 10000) {
              // this.$message.error(res.message)
            }
          })
        }
      }
    },
    checkPhoneOrEmail(str, key) {
      this.form.mobile = null
      this.form.mail = null

      let phone = isPhone(str)
      let mail = isEmail(str)
      if (key === 1) {
        if (phone) {
          this.form.mobile = str
        }
        if (mail) {
          this.form.mail = str
        }
      } else {
        if (phone) {
          this.form.mobile = str
        }
        if (mail) {
          this.form.mail = str
        }
      }
      if (phone || mail) {
        return true
      } else {
        this.$message.error("手机或邮箱格式不正确")
      }
    },
    loginHandle() {
      this.$router.push("/login")
    },
    checkData() {
      if (!this.phoneOrMail) {
        this.$message.error("手机或邮箱不能为空")
        return true
      }
      if (this.phoneOrMail) {
        this.form.mobile = null
        this.form.mail = null
        let phone = isPhone(this.phoneOrMail)
        let mail = isEmail(this.phoneOrMail)
        if (phone) {
          this.form.mobile = this.phoneOrMail
        } else if (mail) {
          this.form.mail = this.phoneOrMail
        } else {
          this.$message.error("手机或邮箱格式不正确")
          return true
        }
      }
      if (!this.form.password) {
        this.$message.error("密码不能为空")
        return true
      }
      if (this.form.password) {
        let check = isPassword(this.form.password)
        if (!check) {
          this.$message.error("密码大于6个字符，包含字母加数字")
          return true
        }
      }
      if (!this.form.repassword) {
        this.$message.error("请确认密码")
        return true
      }
      if (this.form.repassword) {
        if (this.form.repassword !== this.form.password) {
          this.$message.error("两次输入密码不一致")
          return true
        }
      }
      if (!this.form.code) {
        this.$message.error("验证码不能为空")
        return true
      }
    },
  },
}
</script>

<style scoped lang="scss">
.login {
  width: 100%;
  background-image: url("../../assets/images/login/login_bg@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 70vh;
  .login_box {
    margin-top: 4.6rem;
    margin-right: 6.25rem;
    width: 31.75rem;
    height: 30.5rem;
    float: right;
    background-color: #fff;
    box-shadow: 0px 0px 30px 1px rgba(237, 235, 245, 0.7);
    border-radius: 0.625rem;
    opacity: 1;
    border: 1px solid #f4f4f4;
    margin-bottom: 1rem;
    ::v-deep .el-select {
      width: 100%;
    }
    .con_box {
      width: 23.75rem;
      margin: auto;
      .menu {
        margin-top: 1.5rem;
        margin-bottom: 2.375rem;
        span {
          color: #57596c;
        }
        .l_line {
          height: 10px;
          width: 0px;
          background: #57596c;
          opacity: 1;
          border: 1px solid #240f45;
          margin-left: 2rem;
          margin-right: 2rem;
        }
        .isAceive {
          font-weight: 500;
          color: #111029;
        }
      }
      .form_box {
        ::v-deep .el-input__inner {
          box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
          border-radius: 10px 10px 10px 10px;
          height: 42px;
        }
        .code_box {
          .code_btn {
            height: 2.625rem;
            background: #ffffff;
            box-shadow: 0rem 0.25rem 0.625rem 0.0625rem rgba(58, 114, 255, 0.06);
            border-radius: 0.625rem;
            opacity: 1;
            border: 1px solid #eff0f6;
            white-space: nowrap;
            padding-left: 1rem;
            padding-right: 1rem;
            cursor: pointer;
          }
          .gray-color {
            color: #a0a3bd;
          }
          ::v-deep .el-input__inner {
            width: 252px;
            height: 42px;
            background: #ffffff;
            box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            border: 1px solid #eff0f6;
          }
          ::v-deep span {
            right: 1.25rem;
          }
        }
        .link {
          height: 1.375rem;
          color: #240f45;
          margin-top: 0.375rem;
        }
        ::v-deep.el-form-item {
          margin-top: -0.375rem;

          height: 2.625rem;
        }
        ::v-deep .el-button--primary {
          width: 100%;
          border-radius: 0.625rem;
          font-size: 0.875rem;
          font-weight: 500;
          height: 2.375rem;
          margin-top: 1.5625rem;
        }
      }
    }
  }

  .bottom-tip {
    color: #969696;
    clear: both;
    position: absolute;
    bottom: 9.3125rem;
    left: 40%;
  }

  .form_box2 {
    .upload_item {
      margin-bottom: 3rem;
    }
    .link {
      height: 1.375rem;
      color: #240f45;
    }
  }
}
</style>
