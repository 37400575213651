<!--
* @description 成为营销师
* @fileName index.vue
* @author liulian
* @date 2022/06/08 09:43:31
-->
<template>
  <div class="cwyxs screenWidth">
    <!-- 提交页面 -->
    <template>
      <div class="title font-weight-800" v-if="$store.state.userInfo && $store.state.userInfo.is_official_admin === 1">成为信用认证机构</div>
      <div class="title font-weight-800" v-else>成为社媒顾问</div>

      <!-- 为什么成为营销师-->
      <div class="img_box">
        <img src="@/assets/images/cwyxs@2x.png" />
        <!-- 是机构 -->
        <div class="con_box"  v-if="$store.state.userInfo && $store.state.userInfo.is_official_admin === 1">
          <div class="con_box_title">加入Keyy社媒营销工厂成为信用机构</div>
          <div class="bottom">
            <p class="font-size-18 font-weight-400">-加入Keyy社媒营销工厂成为信用机构</p>
            <p class="font-size-18 font-weight-400">-机构Social顾问可借鉴成品案例，使用编辑器快速生成方案发给客户</p>
            <p class="font-size-18 font-weight-400">-接单，帮助品牌Social传播赚取佣金</p>
            <p class="font-size-18 font-weight-400">-发布机构自己的的优质案例吸引客户/吸引优质社媒顾问加入</p>
            <p class="font-size-18 font-weight-400">-提升机构在品牌方知名度</p>
          </div>
        </div>
          <div class="con_box" v-else>
          <div class="con_box_title">为什么成为Social社媒顾问？</div>
          <div class="bottom" >
            <p class="font-size-18 font-weight-400">-借鉴案例，使用编辑器快速生成自己的方案</p>
            <p class="font-size-18 font-weight-400">-接单，帮助品牌Social传播赚取佣金</p>
            <p class="font-size-18 font-weight-400">-发布自己的优质案例赚取借鉴费</p>
            <p class="font-size-18 font-weight-400">-提升等级，用认知提升职业高度</p>
          </div>
        </div>
      </div>

      <!-- 内容区 -->
      <div class="tags">
        <template v-for="(item, index) in tags">
          <!-- 提示内容区域 -->
          <div v-if="item.name === '擅长领域'" class="tip_item">step1-选择擅长领域(提示:擅长领域只能在内容、执行、流量里选择一个方向)</div>
          <div v-if="item.name === '社交网络'" class="tip_item">step2-选择擅长的社交网络社交网络</div>
          <div v-if="item.name === '擅长行业'" class="tip_item">step3-选择擅长行业领域</div>

          <div class="tags_item" :class="item.name === '擅长行业' ? 'tags_item2' : item.name === '自我介绍' ? 'tags_item3' : ''">
            <div class="tags_item_type">
              <!-- <span class="font-size-16 font-weight-400">{{ item.name }}</span> -->
              <!-- 文字修改 -->
              <span class="font-size-16 font-weight-400">
                <template v-if="item.name === '擅长行业'">行业领域</template>
                <template v-if="item.name === '擅长领域'">传播内容</template>
                <template v-if="item.name !== '擅长行业' && item.name !== '擅长领域'"> {{ item.name }}</template>
              </span>

              <img src="@/assets/images/bt@2x.png" alt="必填icon" />
            </div>
            <!-- 擅长领域 -->
            <template v-if="item.type === '擅长领域'">
              <div class="tags_con">
                <div class="tags_row" v-for="(ly, index1) in item.children" :key="index1">
                  <div class="tag_type">
                    <span class="font-size-16 font-weight-500">{{ ly.type }}</span>
                  </div>
                  <div class="tag_list">
                    <span class="cursor-pointer tag_span font-weight-400 font-size-16" :class="i.isActive ? 'is-active' : ''" v-for="(i, key) in ly.children" :key="key" @click="clickItem(i, item, ly)">{{ i.name }}</span>
                    <span v-if="!ly.showInput" class="cursor-pointer font-weight-400 font-size-16" @click="addTag(ly, item.children)">自定义<i class="el-icon-plus"></i></span>
                    <span v-else class="add_input">
                      <el-input :maxlength="maxlength" v-model="ly.inputValue" @change="change" @blur="change" @input="inputHanlde"></el-input>
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <!-- 擅长行业 -->
            <!-- 社交网络 -->
            <template v-if="item.type === '社交网络' || item.type === '擅长行业'">
              <div class="tags_con">
                <div class="tag_list">
                  <span class="cursor-pointer tag_span font-weight-400 font-size-16" :class="i.isActive ? 'is-active' : ''" v-for="(i, key) in item.children" :key="key" @click="clickItem(i, item)">{{ i.name }}</span>
                  <span v-if="!item.showInput" class="cursor-pointer font-weight-400 font-size-16" @click="addTag(item, tags)">自定义<i class="el-icon-plus"></i></span>
                  <span v-else class="add_input">
                    <el-input :maxlength="maxlength" v-model="item.inputValue" @change="change" @blur="change" @input="inputHanlde"></el-input>
                  </span>
                </div>
              </div>
            </template>
            <!-- 角色 -->
            <template v-if="item.type === '角色'">
              <div class="tags_con">
                <div class="tag_list">
                  <el-select v-model="item.inputValue" placeholder="请选择">
                    <el-option v-for="item in item.children" :key="item.value" :label="item.name" :value="item.value"> </el-option>
                  </el-select>
                </div>
              </div>
            </template>
            <!-- 自我介绍 -->
            <template v-if="item.type === '自我介绍'">
              <div class="textarea_con">
                <el-input type="textarea" resize="none" placeholder="请尽量详细介绍你的优势" v-model="item.content" maxlength="200" show-word-limit />
              </div>
            </template>
          </div>
        </template>
      </div>

      <!-- 联系人电话 -->
      <div class="mobile tags" v-if="$store.state.userInfo && $store.state.userInfo.is_official_admin === 1">
        <div class="tip_item">step4-机构负责人</div>
        <div class="tags_item flex-column">
          <div class="con_item flex phone">
            <div class="tags_item_type flex">
              <span class="font-size-16 font-weight-400 type_span"> 联系人姓名 </span>
              <img src="@/assets/images/bt@2x.png" alt="必填icon" />
            </div>
            <el-input placeholder="联系人姓名" v-model="organization_username"></el-input>
          </div>
          <div class="con_item flex phone">
            <div class="tags_item_type">
              <span class="font-size-16 font-weight-400 type_span"> 联系人电话 </span>
              <img src="@/assets/images/bt@2x.png" alt="必填icon" />
            </div>
            <el-input placeholder="联系人电话" v-model="organization_mobile"></el-input>
          </div>
        </div>
      </div>
      <!-- 按钮区 -->
      <div class="btn_box" v-if="tags.length > 0">
        <el-button class="btn" @click="submit">提交</el-button>
        <el-button plain @click="$router.push('/home')">取消</el-button>
      </div>
    </template>
    <!-- <template v-else>
      <successPage></successPage>
    </template> -->
  </div>
</template>

<script>
import successPage from "@/views/cwyxs/children/cg.vue"
import { getRoleListApi, getTagOperateApi, becomeMarketerApi, getUserApi } from "@/api/api.js"
import { isPhone } from "@/utils/validate.js"
export default {
  name: "index",
  components: { successPage },
  data() {
    return {
      tags: [],
      textareaValue: "",
      success: false, // 判断显示
      maxlength: 8, // 最大输入值
      operates: [], // 标签原始数组
      organization_mobile: null, // 联系人电话
      organization_username: null, // 联系人姓名
      role: null,
    }
  },
  computed: {},
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    inputHanlde(e) {
      if (!(e.length < 8)) {
        this.$message.error("最多只能输入八个字符")
      }
    },
    /*
     * @Description: 点击确定按钮
     * @MethodAuthor:  liulian
     * @Date: 2022-06-14 18:00:03
     * @param: ''
     */
    submit() {
      let obj = {
        common_tag_arr: [], // 普通标签数组
        custom_tag_arr: [], // 自定义标签二维数组
        introduction: "", // 个人介绍，
        role: "", // 角色
      }
      let flag = false
      let falg1 = false

      this.tags.map((item) => {
        if (item.name === "擅长行业" || item.name === "社交网络") {
          item.selects.length === 0 && (flag = true)
        }

        // 擅長領域
        if (item.bind === "area") {
          item.children.length > 0 &&
            item.children.map((i) => {
              i.selects.length !== 0 && (falg1 = true)
            })
        }
        if (item.selects && item.selects.length > 0) {
          item.selects.map((item) => {
            if (item.parent_bind) {
              let newObj = {
                name: item.name,
                parent_bind: item.parent_bind,
              }
              obj.custom_tag_arr.push(newObj)
            } else {
              obj.common_tag_arr.push(item.id)
            }
          })
        }
        if (item.bind === "area") {
          item.children.length > 0 &&
            item.children.map((i) => {
              i.selects.length > 0 &&
                i.selects.map((o) => {
                  obj.common_tag_arr.push(o.id)
                })
            })
        }

        if (item.name === "角色") {
          if (item.inputValue) {
            obj.role = item.inputValue
          }
        }
        if (item.name === "自我介绍") {
          if (item.content) {
            obj.introduction = item.content
          }
        }
      })
      if (flag) {
        this.$message.error(`请确保每个分类下至少有一个标签`)
        return
      }
      if (!falg1) {
        this.$message.error(`请确保每个分类下至少有一个标签`)
        return
      }
      // if (!obj.role) {
      //   this.$message.error("请选择角色类型")
      //   return
      // }
      if (!obj.introduction) {
        this.$message.error("请填写自我介绍")
        return
      }

      // 判断是机构并校验联系电话和联系人
      if (this.$store.state.userInfo && this.$store.state.userInfo.is_official_admin === 1) {
        if (!this.organization_username) {
          this.$message.error("请填写联系人姓名")
          return
        }

        if (!this.organization_mobile) {
          this.$message.error("请填写联系人电话")
          return
        } else {
          let check = isPhone(this.organization_mobile)
          if (!check) {
            this.$message.error("联系电话格式不正确")
            return
          }
        }
      }
      if (this.organization_mobile || this.organization_username) {
        obj.organization_username = this.organization_username
        obj.organization_mobile = this.organization_mobile
      }

      becomeMarketerApi(obj).then((res) => {
        if (res.code === 10000) {
          this.$message.success("提交成功")
          this.getUserInfo()
        }
      })
    },
    /*
     * @Description: 获取用户信息
     * @MethodAuthor:  liulian
     * @Date: 2022-06-28 11:12:10
     * @param: ''
     */
    getUserInfo() {
      getUserApi().then((res) => {
        if (res.code === 10000) {
          this.$store.commit("SET_USERINFO", res.data)
          // this.success = true
          this.$router.push('/cwyxsCg')
        }
      })
    },
    /*
     * @Description: 输入框值改变
     * @MethodAuthor:  liulian
     * @Date: 2022-06-14 17:00:55
     * @param: ''
     */
    change() {
      this.tags.map((item) => {
        if (item.type == "擅长领域") {
          if (item.children.length > 0) {
            item.children.map((i, index) => {
              if (i.showInput) {
                if (i.children.length > 0) {
                  // 定义唯一的key值
                  if (i.inputValue) {
                    let obj = {
                      key: new Date().getTime(),
                      name: i.inputValue,
                      isActive: i.selects.length < 5,
                      parent_bind: i.bind,
                    }
                    i.children.push(obj)
                  }
                }
                this.$set(i, "showInput", false)
                this.$set(i, "inputValue", "")
              } else {
                i.children.length > 0 &&
                  i.children.map((o) => {
                    this.$set(o, "isActive", false)
                  })
              }
            })
          }
        } else {
          if (item.showInput) {
            if (item.children.length > 0) {
              if (item.inputValue) {
                let obj = {
                  key: new Date().getTime(),
                  name: item.inputValue,
                  parent_bind: item.bind,
                  isActive: item.selects.length < 5,
                }
                item.children.push(obj)
              }
            }
            this.$set(item, "showInput", false)
            this.$set(item, "inputValue", "")
          }
        }
      })
      this.checkselectLen()
    },
    /*
     * @Description: 添加标签
     * @MethodAuthor:  liulian
     * @Date: 2022-06-14 16:21:13
     * @param: ''
     */
    addTag(item, arr) {
      arr.map((i) => {
        this.$set(i, "showInput", i.type === item.type)
      })
    },
    /*
     * @Description: 点击标签
     * @MethodAuthor:  liulian
     * @Date: 2022-06-14 16:15:48
     * @param: ''
     */
    clickItem(item, parentObj, ly) {
      // 擅长领域
      if (parentObj.bind === "area") {
        if (ly.selects.length === 5 && !item.isActive) {
          this.$message.error("最多只能选择五个")
        }
      } else {
        if (parentObj.selects.length === 5 && !item.isActive) {
          this.$message.error("最多只能选择五个")
        }
      }
      this.tags.map((it) => {
        if (it.name === parentObj.name) {
          if (it.bind === "area") {
            it.children.length > 0 &&
              it.children.map((o) => {
                // 判断偏内容、片流量、偏执行、只能选择一类
                if (it.bind === "area") {
                  it.children.length > 0 &&
                    it.children.map((i) => {
                      if (i.bind === ly.bind) {
                        if (ly.selects.length !== 5) {
                          this.$set(item, "isActive", !item.isActive)
                        } else {
                          this.$set(item, "isActive", false)
                        }
                      } else {
                        // 取消其他类型所有被选中的项
                        i.children.length > 0 &&
                          i.children.map((j) => {
                            this.$set(j, "isActive", false)
                          })
                      }
                    })
                } else {
                  this.$set(item, "isActive", !item.isActive)
                }
              })
          } else {
            if (it.selects.length !== 5) {
              this.$set(item, "isActive", !item.isActive)
            } else {
              this.$set(item, "isActive", false)
            }
          }
        }
      })
      this.checkselectLen()
    },
    /*
     * @Description: 判断选择的长度
     * @MethodAuthor:  liulian
     * @Date: 2022-06-30 11:40:08
     * @param: ''
     */
    checkselectLen() {
      this.tags.map((item) => {
        // 擅长领域
        if (item.name === "擅长行业" || item.name === "社交网络") {
          item.selects = []
          if (item.children.length > 0) {
            item.children.map((it) => {
              // 属于擅长领域
              if (it.children) {
                it.children.map((i) => {
                  if (i.isActive) {
                    item.selects.push(i)
                  }
                })
              } else {
                if (it.isActive) {
                  item.selects.push(it)
                }
              }
            })
          }
        }
        // 擅长领域
        if (item.bind === "area") {
          if (item.children.length > 0) {
            item.children.map((it) => {
              it.selects = []
              // 属于擅长领域
              if (it.children) {
                it.children.map((i) => {
                  if (i.isActive) {
                    it.selects.push(i)
                  }
                })
              } else {
                if (it.isActive) {
                  it.selects.push(it)
                }
              }
            })
          }
        }
      })
    },
    async getList() {
      let data = await this.getTagOperateList()
      this.tags = await this.getRoleList(data)
      this.tagStyleInit()
    },
    /*
     * @Description: 获取标签列表
     * @MethodAuthor:  liulian
     * @Date: 2022-06-30 10:31:31
     * @param: ''
     */
    getTagOperateList() {
      return new Promise((resolve, reject) => {
        getTagOperateApi().then((res) => {
          if (res.code === 10000) {
            // 字段名称改变
            res.data.length > 0 &&
              res.data.map((item) => {
                if (item.name === "传播内容") {
                  this.$set(item, "name", "擅长领域")
                  // item.name === "擅长领域"
                }
                if (item.name === "行业领域") {
                  // item.name === "擅长行业"
                  this.$set(item, "name", "擅长行业")
                }
              })
            this.operates = res.data
            let arr = JSON.parse(JSON.stringify(this.operates))
            arr.length > 0 &&
              arr.map((item) => {
                item.type = item.name
                if (item.name === "擅长领域") {
                  item.children.length > 0 &&
                    item.children.map((it) => {
                      it.type = it.name
                    })
                }
              })
            let newarr = [
              // {
              //   type: "角色",
              //   name: "角色",
              // },
              {
                type: "自我介绍",
                name: "自我介绍",
                content: "",
              },
            ]
            resolve(arr.concat(newarr))
          }
        })
      })
    },
    /*
     * @Description: 获取角色列表
     * @MethodAuthor:  liulian
     * @Date: 2022-06-30 10:23:38
     * @param: ''
     */
    getRoleList(data) {
      return new Promise((resolve, reject) => {
        getRoleListApi().then((res) => {
          if (res.code === 10000) {
            res.data.length > 0 &&
              data.map((item) => {
                if (item.type === "角色") {
                  let arr = JSON.parse(JSON.stringify(res.data))
                  arr.map((i) => (i.value = i.id))
                  item.children = arr
                  this.$set(item, "children", arr)
                }
              })
            resolve(data)
          }
        })
      })
    },
    /*
     * @Description: 标签样式初始化
     * @MethodAuthor:  liulian
     * @Date: 2022-06-14 16:13:02
     * @param: ''
     */
    tagStyleInit() {
      this.tags.map((item, index) => {
        if (item.name === "擅长行业" || item.name === "社交网络") {
          this.$set(item, "selects", [])
        }
        // 擅长领域
        if (item.bind === "area") {
          item.children.length > 0 &&
            item.children.map((i) => {
              this.$set(i, "selects", [])
            })
        }
        item.children &&
          item.children.map((i, index1) => {
            if (i.children) {
              i.children.map((j, index2) => {
                this.$set(j, "isActive", false)
                this.$set(i, "inputValue", "")
              })
            } else {
              this.$set(i, "isActive", false)
              this.$set(item, "inputValue", "")
            }
          })
      })
    },
  },
}
</script>

<style scoped lang="scss">
.cwyxs {
  margin: auto;
  padding-bottom: 3.0625rem;
  .title {
    color: #240f45;
    margin-top: 2.5rem;
    font-size: 2.875rem;
  }
  .img_box {
    width: 100%;
    min-height: 278px;
    margin-top: 1.875rem;
    margin-bottom: 4rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .con_box {
      position: absolute;
      top: 1.5rem;
      left: 3.375rem;
      .con_box_title {
        font-size: 24px;
        font-weight: bold;
        color: #57596c;
        margin-bottom: 32px;
      }
      .bottom {
        margin-left: 7.5625rem;
        p {
          margin-bottom: 16px;
          font-size: 18px;
          font-weight: 400;
          color: #57596c;
        }
      }
    }
  }

  .tags {
    .tip_item {
      font-size: 1.375rem;
      font-weight: bold;
      color: #111029;
      margin-bottom: 2rem;
    }
    .tags_item {
      width: 100%;
      display: flex;
      margin-top: 2rem;
      background: #f7f7fc;
      border-radius: 1.25rem;
      padding-top: 42px;
      padding-bottom: 2rem;
      margin-bottom: 3rem;

      .tags_item_type {
        margin-right: 1rem;
        white-space: nowrap;
        display: flex;
        margin-left: 2rem;
        img {
          width: 0.5rem;
          height: 0.5rem;
        }
        span {
          color: #111029;
          text-align: justify;
          text-align-last: justify;
          text-justify: inter-ideograph;
          white-space: nowrap;
        }
      }
      .phone {
        margin-bottom: 1rem;
        align-items: flex-start;
        ::v-deep .el-input__inner {
          width: 330px;
        }
      }
      .tags_con {
        .tags_row {
          .tag_type {
            margin-bottom: 1rem;
            span {
              color: #57596c;
              width: 5rem;
              height: 2.625rem;
              background: #eff0f6;
              border-radius: 0.25rem;
              display: block;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        .tag_list {
          span {
            color: #57596c;
            background: #ffffff;
            border-radius: 2rem;
            opacity: 1;
            border: 0.0625rem solid #dcddeb;
            padding: 0.625rem 1rem;
            margin-right: 0.5rem;
            margin-bottom: 1rem;
            display: inline-block;
          }
          .tag_span:hover {
            color: #2f2c42;
            border: 0.0625rem solid #2f2c42;
          }
          .is-active {
            color: #2f2c42;
            border: 0.0625rem solid #2f2c42;
          }
          .add_input {
            width: 6.875rem;
            // height: 2.625rem;
            display: inline-block;
            height: 1.375rem;
            // padding: 0.1px;
            justify-content: center;
            align-items: center;
            ::v-deep .el-input__inner {
              border: none;
              margin-top: -0.1875rem;
              height: 1.875rem;
            }
            ::v-deep .el-input {
              width: 87%;
              margin: 0rem auto;
              margin-left: 0.25rem;
            }
          }
        }
      }
    }
    .con_item {
      .con_item_type {
        white-space: nowrap;
      }
    }

    .tags_item2 {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .tags_item3 {
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding-top: 1rem;
    }
  }
  .textarea_con {
    ::v-deep .el-textarea {
      width: 100%;
      height: 11.8125rem;
      background: #ffffff;
      border-radius: 0.625rem;
      opacity: 1;
      box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
    }
    ::v-deep .el-textarea__inner {
      width: 76.625rem;
      height: 11.8125rem;
      background: #ffffff;
      border-radius: 0.625rem;
      opacity: 1;
      border: 1px solid #eff0f6;
    }
  }
  .btn_box {
    margin-top: 3.375rem;
    text-align: center;

    ::v-deep .el-button {
      width: 5rem;
      height: 3.625rem;
      border-radius: 3.25rem;
      opacity: 1;
      color: #111029;
    }
    .btn {
      background: #111029;
      color: #ffffff;
    }

    ::v-deep .el-button:nth-child(2) {
      margin-left: 4rem;
      border: 1px solid #111029;
    }
    ::v-deep .el-button:nth-child(2):hover {
      span {
        color: #111029;
      }
    }
  }
  ::v-deep .el-empty__description {
    p {
      font-size: 1rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #57596c;
    }
  }
}
</style>
