<template>
    <div class="circle">
        <tabs></tabs>
        <circle-list :dataList="dataList"></circle-list>
        <!-- 空状态 -->
        <div class="empty" v-show="emptyShow">
            <img src="@/assets/images/empty.png" alt="">
            <p>哎呀，这里什么也没有~快试试其他搜索词吧！</p>
        </div>
    </div>
</template>
<script>
import { wechart } from "@/api/api"
import tabs from '../../../components/tabs/tabs.vue'
import circleList from './list/list.vue';
export default {
    components: {
        tabs,
        circleList
    },
    data() {
        return {
            emptyShow: false,
            dataList: [],
            query: {
                page: 1,
                limit: 10,
                type: 1
            },
            total: ""
        }
    },
    mounted() {
        this.getWeChart()
        this.$nextTick(() => {
            this.scroll.sctollBot("", (data) => {
                if (data == true) {
                    if (this.dataList.length < this.total) {
                        this.query.page += 1
                        wechart(this.query).then(res => {
                            if (res.code == 10000) {
                                if (res.data.length == 0) {
                                    this.emptyShow = true
                                } else {
                                    this.emptyShow = false
                                }
                                res.data.map(e => {
                                    this.dataList.push(e)
                                })
                                this.total = res.count
                            }
                        })
                    }
                }
            })
        })
    },
    methods: {
        getWeChart() {
            wechart(this.query)
                .then(res => {
                    if (res.code == 10000) {
                        if (res.data.length == 0) {
                            this.emptyShow = true
                        } else {
                            this.emptyShow = false
                        }
                        this.dataList = res.data
                        this.total = res.count
                    }
                })
        }
    }
}
</script>
<style  lang="scss" scoped>
.circle {
    padding: 1.5625rem 2rem;

    .empty {
        margin-top: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            width: 18.75rem;
            height: 12.5rem;
        }

        p {
            margin-top: 2rem;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #A0A3BD;
            line-height: 1.1875rem;
        }
    }
}
</style>