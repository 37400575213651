<!--
* @description 案例页面
* @fileName anli.vue
* @author liulian
* @date 2022/06/15 16:53:04
-->
<template>
  <div class="anli">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="案例" name="1"></el-tab-pane>
    </el-tabs>
    <!-- 案例列表 -->
    <template v-if="activeName === '1'">
      <div class="title font-size-16 font-weight-400">共{{ total }}份案例</div>
      <loading-more v-if="lists.length > 0" class="loadmore" :completed="completed" @changeData="loadMore" :loadingIcon="loadingIcon">
        <div class="con">
          <div class="card" v-for="(item, index) in lists" :key="index">
            <coverCard :item="item"></coverCard>
          </div>
        </div>
      </loading-more>
    </template>
  </div>
</template>

<script>
import coverCard from "@/components/common/cover-card.vue"
import { userPageCaseListApi } from "@/api/api.js"
import loadingMore from "@/components/common/loadingMore.vue"
export default {
  name: "anli",
  components: { coverCard, loadingMore },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      activeName: "1",
      lists: [],
      caseQuery: {
        user_id: this.id, // 用户id
        page: 1, //
        limit: 10, //
      },
      completed: false, // 加载
      loadingIcon: true, // 加载
      total: null, // 加载
    }
  },
  computed: {},
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    /*
     * @Description: 页面加载
     * @MethodAuthor:  liulian
     * @Date: 2022-07-14 18:28:08
     * @param: ''
     */
    loadMore(e) {
      if (this.total > 0) {
        let check = e > Math.ceil(this.total / this.caseQuery.limit)
        if (check) {
          this.completed = true
          this.loadingIcon = false
          return
        }
      }
      setTimeout((res) => {
        this.caseQuery.page = e
        this.getLoadList() // 重新请求数据
      }, 2000)
    },
    async getLoadList() {
      this.completed = false
      this.loadingIcon = true
      let data = await this.listApi()
      this.lists = this.lists.concat(data)
    },

    handleClick(tab, event) {},

    async getList() {
      this.lists = await this.listApi()
    },
    /*
     * @Description: 接口请求
     * @MethodAuthor:  liulian
     * @Date: 2022-07-14 18:26:46
     * @param: ''
     */
    listApi() {
      return new Promise((resolve, rejects) => {
        userPageCaseListApi(this.caseQuery).then((res) => {
          if (res.code === 10000) {
            this.total = res.count
             if (!(res.count > this.caseQuery.limit)) {
              this.completed = true
              this.loadingIcon = false
            }
            resolve(res.data)
          }
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
.anli {
  .loadmore {
    display: flex;
  }
  ::v-deep .vue-data-loading {
    width: 100%;
  }
  .el-tabs {
    margin-top: 1.25rem;
    ::v-deep .el-tabs__nav-wrap::after {
      background-color: #eff0f6;
    }
    ::v-deep .el-tabs__item {
      font-size: 1.375rem;
      font-weight: bold;
    }
  }
  .title {
    margin-bottom: 1rem;
  }
  .con {
    display: flex;
    flex-wrap: wrap;

    .card {
      margin-right: 0.625rem;
      margin-left: 0.625rem;
      display: flex;
    }
  }
}
</style>
