<template>
    <div id="creator">
        <div class="datalist">
            <ul>
                <li v-for="(item, index) in datalist" :key="item.id" @click="ondetail(item)">
                    <div class="tle_price">
                        <p>
                            {{ item.title }}
                        </p>
                        <p>￥{{ item.total_money }}</p>
                        <div class="userinfo-right" v-if="item.status == 0 && tabsindex==1 ">
                            <div class="edit" @click.stop="edits(index)">
                                <img src="@/assets/images/userprofile/edit.png" alt="">
                                <div class="edit-box" v-if="editshow == true && editIndex == index">
                                    <p @click="onEdit(item)">编辑</p>
                                    <p @click="onDelete(item)">删除</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="project">
                        <p>需求方：{{ item.needInfo == null || item.needInfo == "" ? '' : item.needInfo.username }}</p>
                        <p>项目编号：{{ item.number }}</p>
                    </div>
                    <div class="copy-icon">
                        <div class="copy" @click.stop="copyLink(item)" v-if="tabsindex == 1">复制链接</div>
                        <div class="icon iconone" v-if="item.status == 0">
                            <img src="@/assets/images/userprofile/daiqueren.png" alt="">
                            <span>待确认</span>
                        </div>
                        <div class="icon icontwo" v-if="item.status == 1">
                            <img src="@/assets/images/userprofile/zhixingzhong.png" alt="">
                            <span>执行中</span>
                        </div>
                        <div class="icon iconthree" v-if="item.status == 2">
                            <img src="@/assets/images/userprofile/end.png" alt="">
                            <span>已结束</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 删除确认弹窗 -->
        <dialogs :show.sync="showDialog" width="690px" :center="true" title="删除">
            <p>是否删除id为 {{ deleteId }} 的这条需求？</p>
            <template slot="footer-slot">
                <el-button class="cancle" @click="cancle">
                    取消
                </el-button>
                <el-button class="determine" @click="determine">
                    确认
                </el-button>
            </template>
        </dialogs>
    </div>
</template>
<script>
import { deleteProject } from "@/api/api"
import dialogs from "@/components/common/dialog-cpn.vue"
import { json } from "body-parser"
export default {
    components: {
        dialogs
    },
    props: {
        creator: {
            type: Array / String,
            default: ""
        },
        tabsindex: {
            type:String,
            default: ""
        }
    },
    data() {
        return {

            showDialog: false,
            editshow: false,
            pathactive: 1,
            datalist: [

            ],
            deleteId: '',
            tablist: [
                {
                    id: 1,
                    name: "全部"
                }, {
                    id: 2,
                    name: "待确认"
                }, {
                    id: 3,
                    name: "执行中"
                }, {
                    id: 4,
                    name: "已结束"
                }
            ]

        }
    },
    watch: {
        creator: {
            handler(data) {
                if (data) {
                    console.log(data)
                    this.datalist = data
                }
            },
        }
    },
    methods: {
        // 复制链接
        copyLink(val) { // 复制链接  val 就是你需要复制的链接
        console.log(val)
            this.$copyText(val.copyurl).then(e => {
                this.$message.success('复制成功！')
                localStorage.setItem('projectLink',JSON.stringify(val))
            }, e => {
                this.$message.error('复制失败！')
            })
        },
        edits(index) {
            console.log(index)
            this.editIndex = index
            if (this.editshow == false) {
                this.editshow = true
            } else {
                this.editshow = false
            }
        },
        // 编辑项目
        onEdit(item) {

            this.$router.push({ path: "/createproject", query: { id: item.id, statusbar: 0 } })


        },
        // 删除项目
        onDelete(item) {
            console.log(item)
            this.deleteId = item.id
            this.showDialog = true
        },
        cancle() {
            this.showDialog = false
        },
        determine() {
            deleteProject(this.deleteId)
                .then(res => {
                    if (res.code == 10000) {
                        this.$message.success('删除成功！')
                        this.$emit("uploadata")
                    }
                })
            this.showDialog = false
        },
        // 进入详情
        ondetail(item) {
            console.log(item)
            if (this.tabsindex == 1 || this.tabsindex == 3) {
                this.$router.push({ path: "/grzx/projectdetail", query: { id: item.id } })
            } else {
                this.$router.push({ path: "/grzx/demangdetail", query: { id: item.id } })
            }
            return
            // 待审核
            this.$router.push("/grzx/projectdetail")
            // 执行中
            //  this.$router.push("/grzx/projectimplement")

        }
    }
}
</script>
<style lang="scss" scoped>
#creator {
    .el-dialog {
        .el-dialog__body {
            p {
                text-align: center;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 300;
                color: #5C6466;
                line-height: 1.1875rem;
            }

        }

        .el-dialog__footer {
            .dialog-footer {

                .cancle {
                    width: 4.25rem;
                    height: 2.375rem;
                    font-size: .875rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1rem;
                    background: #FFFFFF;
                    border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                    border: 1px solid #111029;
                }

                .determine {
                    margin-left: 11.375rem;
                    width: 4.25rem;
                    height: 2.375rem;
                    font-size: .875rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 1rem;
                    background: #111029;
                    border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                    border: 1px solid #111029;
                }
            }
        }
    }

    .tablist {
        ul {
            display: flex;
            align-items: center;

            li {
                cursor: pointer;
                display: flex;
                margin: 0 .25rem;
                justify-content: center;
                align-items: center;
                padding: .25rem .625rem;
                background: #EFF0F6;
                border-radius: 1.1875rem 1.1875rem 1.1875rem 1.1875rem;
            }

            .active {
                border: 1px solid #111029;
                background: #FFFFFF;
                color: #240F45;
            }

            .font {
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 100;
                color: #5C6466;
                line-height: 1rem;
            }
        }
    }

    .datalist {
        margin-top: 1rem;

        ul {
            li {
                cursor: pointer;
                padding: 2rem 0;
                border-top: 1px solid #EFF0F6;

                .tle_price {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: .5rem;

                    :nth-child(1) {
                        font-size: 1rem;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #111029;
                        line-height: 1.1875rem;
                    }

                    :nth-child(2) {
                        margin-left: auto;
                        font-size: 1.25rem;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 800;
                        color: #FF6868 !important;
                        line-height: 1.4375rem;
                    }

                    .userinfo-right {
                        display: flex;

                        align-items: center;

                        .errorexamine,
                        .successexamine,
                        .review {
                            width: 6.875rem;
                            height: 2.5rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            line-height: 1.1875rem;
                            border-radius: 2.1875rem 2.1875rem 2.1875rem 2.1875rem;

                            img {
                                display: block;
                                width: 1.375rem;
                                height: 1.375rem;
                                margin-right: .25rem;
                            }
                        }

                        .errorexamine {
                            color: #DC2B2B;
                            background: #FFEFF0;
                        }

                        .successexamine {
                            color: #11845B;
                            background: #DEF2E6;
                        }

                        .review {
                            color: #086CD9;
                            background: #EAF4FF;
                        }

                        .edit {
                            position: relative;
                            margin-left: 2rem;

                            img {
                                display: block;
                                width: 1.25rem;
                                height: 1.25rem;
                            }

                            .edit-box {

                                position: absolute;
                                padding: .625rem 1rem;
                                left: -3.125rem;
                                background: #FFFFFF;
                                box-shadow: 0px 2px 6px 1px rgba(20, 20, 43, 0.0600);
                                border-radius: .625rem;
                                opacity: 1;
                                border: 1px solid #EFF0F6;

                                p {
                                    padding: .375rem 0;
                                    font-size: .875rem;
                                    font-family: PingFang SC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #57596C !important;
                                    line-height: 1rem;
                                }
                            }
                        }


                    }

                }

                .project {
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #57596C;
                    line-height: 1.1875rem;
                    margin-bottom: 2.75rem;

                    p {
                        margin: .25rem 0;
                    }
                }

                .copy-icon {

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .copy {
                        cursor: pointer;
                        font-size: .875rem;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #3A71FF;
                        line-height: 1rem;
                    }

                    ::v-deep .icon {
                        width: auto;
                        margin-left: auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background: #EAF4FF;
                        border-radius: 2.1875rem 2.1875rem 2.1875rem 2.1875rem;
                        padding: .625rem;
                        font-size: 1rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        line-height: 1.1875rem;

                        span {
                            display: block;
                            margin-left: .3125rem;
                        }
                    }

                    .iconone {
                        color: #086CD9;
                        background: #EAF4FF;

                    }

                    .icontwo {
                        background: #DEF2E6;
                        color: #11845B;
                    }

                    .iconthree {
                        background: #F7F7FC;
                        color: #A0A3BD;
                    }
                }
            }
        }
    }
}
</style>