<template>
    <div id="productdetail">
        <div class="container">
            <p class="tle">
                {{ detail.title }}
            </p>
            <div class="project-no">
                <div class="num">项目编号：{{ detail.number }}</div>
                <div class="price">
                    <img src="@/assets/images/price.png" alt="">
                    ￥{{ detail.total_money }}
                </div>
                <div class="data">
                    <img src="@/assets/images/date.png" alt=""/>
                    {{ detail.days }}天
                </div>
                <!-- <div class="share">
                    <share @onMove="onMoves()" :QRCodeMsg="qrurl"></share>
                </div> -->

            </div>
            <div class="notice" v-if="detail.status == -1">
                <img src="@/assets/images/userprofile/notice.png" alt="">
                <p>需求方未同意您的项目划分，请修改重新发送给需求方</p>
            </div>
            <div class="content">
                <!-- 步骤条 -->
                <steps :stepsLabel="stepsLabel" :totalSteps="totalSteps" :currentStep="currentStep"></steps>
                <!-- 阶段列表 -->
                <div class="stage" v-for="(item, index) in detail.processList" :key="index">
                    <div class="list">
                        <div class="list-tle">
                            <div class="tle">
                                <p>P{{ index + 1 }}阶段</p>
                                <span> |</span>
                                <p>{{ item.title }}</p>
                            </div>
                            <div class="iconbox iconone" v-if="item.status == 1 || item.status == 2">
                                <img src="@/assets/images/userprofile/daiqueren.png" alt="">
                                执行中
                            </div>
                            <div class="iconbox icontwo" v-if="detail.status == 0 || detail.status == -1">
                                <img src="@/assets/images/userprofile/zhixingzhong.png" alt="">
                                待确认
                            </div>
                            <div class="iconbox icontw" v-if="detail.status == 1 && item.status == 0">
                                <img src="@/assets/images/userprofile/zhixingzhong.png" alt="">
                                未启动
                            </div>
                            <div class="iconbox iconthree" v-if="item.status == 3">
                                <img src="@/assets/images/userprofile/end.png" alt="">
                                已结束
                            </div>
                        </div>
                        <div class="list-content">
                            <div class="deliverdata">
                                计划交付日期：<span>{{ item.pay_date }}</span>
                            </div>
                            <div class="price">
                                计划金额
                                <span>￥{{ item.money }}</span>
                            </div>
                            <div class="explain">
                                交付说明
                                <span>{{ item.introduce }}</span>
                            </div>
                            <div class="file" v-if="item.status == 3">
                                交付文档
                                <div class="file-box">
                                    <img src="@/assets/images/xqdt/txt@2x.png" alt="">
                                    {{ item.filename }}
                                    <p @click.stop="dowlond(item.files)"> 下载</p>
                                </div>
                            </div>
                        </div>
                        <div class="btn" v-if="item.status == 3">
                            <p>需求方通过了您的「P{{ index + 1 }}验收」申请，阶段已验收</p>
                        </div>
                        <!-- 拒绝验收 -->
                        <div class="btn" v-if="item.status == -1">
                            <p v-if="item.status == -1">需求方拒绝了您的 「P{{ index + 1 }}验收」申请，拒绝原因是{{ item.reason
                            }}。请与需求方私聊沟通，重新提交验收
                            </p>
                            <el-button style="margin-left:auto;" @click="ondialog(item)">提交验收</el-button>
                        </div>
                        <!--  -->
                        <div class="btn" v-if="item.status == 1">
                            <el-button style="margin-left:auto;" @click="ondialog(item)">提交验收</el-button>
                        </div>

                    </div>
                </div>
                <!-- -->
                <div class="score" v-if="detail.status == 2 && detail.score != ''">
                    <p>评分</p>
                    <el-rate v-model="detail.score" disabled show-score text-color="#ff9900" score-template="{value}">
                    </el-rate>
                </div>
                <div class="edit" @click="reEdit(detail)" v-if="detail.status == -1">
                    <p> 重新编辑</p>
                </div>
            </div>

        </div>
        <!-- 弹窗组件 -->
        <dailogspop :show.sync="showpop" width="690px" :center="true" :showFooter=false title="提交交付物">
            <template>
                <div class="upload" v-loading="loading">
                    <el-upload class="upload-demo" drag :action="upload" :show-file-list="false"
                        :data="datas" multiple :before-upload="beforeUpload" :headers="importHeaders" :on-success="onSuccess">
                        <div v-if="upurl == ''">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em style="color: #3A71FF;">点击上传</em></div>
                        </div>
                        <div v-else>
                            {{ filename }}
                        </div>
                    </el-upload>
                </div>
            </template>
            <div class="dialogbtn">
                <el-button @click="cancle">取消</el-button>
                <el-button class="sub" @click="submit">确认</el-button>

            </div>
        </dailogspop>
    </div>
</template>
<script>
var mytoken = localStorage.getItem('token')
import { prodetail, subdeliverables, Resubmit } from "@/api/api"
import {getuploadOss} from "@/api/javaApi"
import dailogspop from "@/components/common/dialog-cpn.vue"
import steps from "@/components/steps/step.vue"
import share from "@/components/share/share.vue"
export default {
    components: {
        steps,
        dailogspop,
        share
    },
    data() {
        return {
            loading: false,
            value: 1,
            deliverables: "",
            filename: "",
            upurl: "",
            qrurl: "",
            detailid: "",
            detail: {},
            upload: '',
            importHeaders: { Authorization: mytoken },
            showpop: false,
            stepsLabel: ["创建项目", "待需求方确认", "执行中", "已结束"],//步骤条数组
            totalSteps: 4,
            currentStep: 0, //步骤条执行到第几步,
            datas:{},
            dir:"",
            host:"",
        }
    },
    mounted() {
        this.detailid = this.$route.query.id
        this.getDetail()
    },
    methods: {
        // 获取详情
        getDetail() {
            prodetail(this.detailid)
                .then(res => {
                    if (res.code == 10000) {
                        res.data.processList.map(e => {
                            e.pay_date = e.pay_date.replace(",", "-")
                        })
                        this.detail = res.data
                        this.currentStep = Number(res.data.status + 2)
                    }
                })
        },
        // 重新编辑
        reEdit(detail) {
            console.log(detail)
            // Resubmit({id:detail.id}).then(res=>{
            //     if(res.code==10000){
            //        this.$message.success('操作成功！')
            //     }
            // })
            this.$router.push({ path: "/createproject", query: { id: detail.id, status: 0 } })
        },
        // 鼠标移入
        onMoves() {
            // console.log(item)

        },
        dowlond(e) {
            window.open(e)
        },
        ondialog(item) {
            console.log(item)
            this.deliverables = item
            this.showpop = true
        },
        // 获取oss
       getuplaod() {
            return new Promise((resolve, rejects) => {
                getuploadOss()
                    .then(res => {
                        resolve(res.data)
                    })
            })

        },
        async beforeUpload(file) {
             var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const extension = testmsg === "ppt" ||  testmsg === "pptx" || testmsg === "pdf" || testmsg === "xls" ||testmsg === "xlsx" || testmsg=== "doc" || testmsg === "docx";
              let imgSize = Number(file.size / 1024 / 1024);
            this.loading = true
           if (!extension) {
                this.$message({
                    message: "上传文件只能是ppt,pptx,pdf,word,excel类型!",
                    type: "warning",
                });
                  this.loading = false
               return false
            }
            // if (imgSize > 5) {
            //    this.$message.warning('文件大小不能超过5M ！请重新上传')
            //       this.loading = false
               
            //     return false;
            // }
             var data = await this.getuplaod()
            this.filename = file.name
            this.dir = data.dir;
            this.datas.policy = data.policy;
            this.host = data.host;
            this.upload=data.host
            this.datas.OSSAccessKeyId = data.accessid;
            this.datas.callback=data.callback
            this.datas.signature = data.signature;
            let files = file.name;
            var fileExtension = files.substring(files.lastIndexOf("."));
            var key = this.dir + Number(Math.random().toString().substr(3, 6) + Date.now()).toString(36)  + fileExtension;
            this.datas.key =key
        },
        onSuccess(res) {
            if (res.code == 10000) {
                this.upurl =this.host +'/'+ this.datas.key
                console.log(this.upurl)
                this.loading = false

            }
            //  else {
            //     this.$message.error(res.message)
            // }
        },
        // 取消
        cancle() {
            this.showpop = false
            this.filename = ""
            this.upurl = ""
        },
        submit() {
            if (!this.upurl) return
            this.showpop = false
            let obj = {
                id: this.deliverables.id,
                files: this.upurl,
                filename: this.filename
            }
            subdeliverables(obj)
                .then(res => {
                    if (res.code == 10000) {
                        this.getDetail()
                        this.$message.success("操作成功！")
                        this.filename = ""
                        this.upurl = ""
                    }
                })

        }
    }

}
</script>
<style lang="scss" scoped>
#productdetail {
    width: 100%;
    height: auto;
    overflow: hidden;

    .container {
        margin: 0 auto;
        max-width: 86.25rem;
        padding: 2.5rem 3.125rem;
        height: 100%;

        .tle {
            font-size: 1.25rem;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #111029;
            line-height: 1.4375rem;
        }

        .project-no {
            margin-top: 2rem;
            font-size: 1.125rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 100;
            color: #2F2C42;
            line-height: 1.3125rem;
            display: flex;
            align-items: center;

            .price {
                margin: 0 3.5rem 0 4.0625rem;
            }

            .price,
            .data {
                display: flex;
                align-items: center;
                color: #57596C;
                font-size: 1rem;

                img {
                    display: block;
                    margin-right: .5rem;
                }
            }

            .share {
                margin-left: auto;
            }

        }

        .notice {
            margin-top: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: auto;
            background: #FFEFF0;
            border-radius: .625rem .625rem .625rem .625rem;

            img {
                display: block;
                width: 2rem;
                height: 2rem;
            }

            p {
                padding: 1.3125rem 0;
                font-size: 1rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #DC2B2B;
            }
        }

        .content {
            padding: 4rem 2rem;
            margin-top: .5rem;
            background: #F7F7FC;
            border-radius: .625rem .625rem .625rem .625rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .stage {
                background: #FFFFFF;
                padding: 1rem 2rem;
                height: auto;
                border-radius: .625rem .625rem 0px 0px;
                margin-top: 2.375rem;

                .list {
                    display: flex;
                    flex-direction: column;

                    .list-tle {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .tle {
                            display: flex;
                            align-items: center;

                            :nth-child(1) {
                                font-size: 1.125rem;
                                font-family: PingFang SC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #111029;
                                line-height: 1.3125rem;
                            }

                            :nth-child(3) {
                                font-size: 1.125rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #2F2C42;
                                line-height: 1.3125rem;
                            }

                            span {
                                display: inline-block;
                                margin: 0 2rem;
                            }
                        }

                        .iconbox {
                            display: flex;
                            align-items: center;
                            background: #DEF2E6;
                            border-radius: 2.1875rem 2.1875rem 2.1875rem 2.1875rem;
                            padding: .625rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #11845B;
                            line-height: 1.1875rem;

                            img {
                                display: block;
                                margin-right: .25rem;

                            }
                        }

                        .iconone {
                            color: #086CD9;
                            background: #EAF4FF;

                        }

                        .icontwo {
                            background: #DEF2E6;
                            color: #11845B;
                        }

                        .icontw {
                            color: #D5691B;
                            background: #FFF3E4;
                        }

                        .iconthree {
                            background: #F7F7FC;
                            color: #A0A3BD;
                        }
                    }

                    .list-content {
                        margin-top: 1rem;
                        padding: 2rem 0;
                        border-top: 1px solid #DCDDEB;
                        border-bottom: 1px solid #DCDDEB;

                        .deliverdata {
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #2F2C42;
                            line-height: 1.1875rem;

                            span {
                                display: inline-block;
                                margin-left: 1rem;
                                color: #57596C;
                            }
                        }

                        .price {
                            margin: 1.0625rem 0 1.125rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #111029;
                            line-height: 1.1875rem;

                            span {
                                display: inline-block;
                                margin-left: 1rem;
                                color: #FF6868;
                                font-weight: bold;
                            }
                        }

                        .explain {
                            display: flex;
                            align-items: flex-start;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #2F2C42;
                            line-height: 1.1875rem;
                            white-space: nowrap;

                            span {
                                white-space: pre-wrap;
                                display: inline-block;
                                margin-left: 1rem;
                                color: #57596C;
                            }
                        }

                        .file {
                            display: flex;
                            margin-top: 2rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #2F2C42;
                            line-height: 1.1875rem;

                            .file-box {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #57596C;
                                line-height: 1.1875rem;
                                margin-left: 1rem;
                                width: 15.0625rem;
                                height: 3.75rem;
                                background: #F7F7FC;
                                border-radius: .625rem .625rem .625rem .625rem;

                                img {
                                    margin-right: .5rem;
                                    display: block;
                                    width: 2.25rem;
                                    height: 2.25rem;
                                }

                                p {
                                    cursor: pointer;
                                    margin-left: 1rem;
                                    font-size: .875rem;
                                    font-family: PingFang SC-Regular, PingFang SC;
                                    font-weight: 100;
                                    color: #3A71FF;
                                    line-height: 1rem;
                                }
                            }
                        }

                    }

                    .btn {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        // margin-left: auto;
                        margin-top: 2rem;

                        p {
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #57596C;
                            line-height: 1.1875rem;
                        }

                        .el-button {
                            width: 7rem;
                            height: 3.625rem;
                            background: #111029;
                            border-radius: 3.25rem 3.25rem 3.25rem 3.25rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #FFFFFF;
                            line-height: 1.1875rem;

                        }
                    }
                }
            }

            .score {
                margin-left: auto;
                margin-top: 2rem;
                display: flex;

                p {
                    margin-right: 1rem;
                    font-size: 1.25rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2F2C42;
                    line-height: 1.4375rem;
                }

            }

            .edit {
                width: auto;
                margin: 3.75rem auto 0;

                p {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 7rem;
                    height: 3.625rem;
                    background: #FFFFFF;
                    border-radius: 3.25rem 3.25rem 3.25rem 3.25rem;
                    border: 1px solid #111029;
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #111029;
                    transition: all .3s ease;

                    &:hover {
                        background: #111029;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }

    .upload {

        display: flex;
        justify-content: center;

        .el-upload__text {
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 100;
            line-height: 1.1875rem;

        }



    }

    .dialogbtn {
        margin: 2rem auto 0;
        width: 45%;
        display: flex;
        justify-content: space-between;

        .el-button {
            width: 4.25rem;
            height: 2.375rem;
            background: #FFFFFF;
            border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
            opacity: 1;
            border: 1px solid #111029;
            font-size: .875rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #111029;
            line-height: 1rem;
        }

        .sub {
            background: #111029;
            color: #FFFFFF;
        }



    }

}

::v-deep .el-upload-dragger {
    width: 31.25rem;
    height: 7.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .el-icon-upload {
        margin-top: 0;
    }
}
</style>