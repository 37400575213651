<!--
* @description 筛选下拉框组件
* @fileName filter-select.vue
* @author liulian
* @date 2022/06/08 11:58:08
-->
<template>
  <div class="select_container">
    <div class="top_select" ref="selctId">
      <div class="select_box" v-for="(item, index) in filters" :key="index">
        <div class="box_header cursor-pointer" @click.stop="clickItem(item, index)">
          <img :src="item.icon" />

          <!-- <span class="font-size-20 font-weight-400">{{ item.name }}</span> -->

          <!-- 擅长行业改成、擅长领域改成传播内容 -->
          <span class="font-size-20 font-weight-400" v-if="item.name === '擅长行业'">行业领域</span>
          <span class="font-size-20 font-weight-400" v-if="item.name === '擅长领域'">传播内容</span>
          <span class="font-size-20 font-weight-400" v-if="item.name !== '擅长行业' && item.name !== '擅长领域'">{{ item.name }}</span>

          <img v-if="(item.selectList && item.selectList.length > 0) || (showCloseIcon && item.name === '擅长领域') || ((item.input1 || item.input2) && item.name === '项目案值')" class="clear_img" src="@/assets/images/qingchu@2x.png" @click.stop="clearHandle(item)" />
          <img v-else class="clear_img" src="@/assets/images/jiantou@2x.png" />
        </div>
        <!-- 底部区域 -->
        <div class="select_body" v-if="item.isOpen">
          <div class="con">
            <slot :name="item.slot">
              <!-- 社交网络 -->
              <template v-if="item.name === '社交网络' || item.name === '擅长行业'">
                <div class="box">
                  <el-checkbox-group v-model="item.selectList" :max="checkMax">
                    <el-checkbox v-for="(it, index) in item.list.slice(0, item.maxLen)" :key="index" :label="it.value" :value="it.value">{{ it.name }}</el-checkbox>
                  </el-checkbox-group>

                     <!-- <div v-if="item.maxLen < item.list.length" class="more text-align-center" @click="showMoreHandle(it, sclys)">更多</div> -->
                </div>
              </template>
              <!-- 擅长领域 -->
              <template v-if="item.name === '擅长领域'">
                <div class="box scly">
                  <template v-for="(it, key) in sclys">
                    <div class="type_box">
                      <div class="title font-size-14 font-weight-500">{{ it.type }}</div>
                      <div class="con cons">
                        <el-checkbox-group v-model="it.selectList" :max="checkMax">
                          <el-checkbox v-for="(i, index1) in it.list.slice(0, it.maxLen)" :key="index1" :label="i.value" :value="i.value" @change="changeHandle(it.selectList)">{{ i.name }}</el-checkbox>
                        </el-checkbox-group>
                      </div>
                      <div v-if="it.maxLen < it.list.length" class="more text-align-center" @click="showMoreHandle1(it, sclys)">更多</div>
                    </div>
                    <div :class="key !== sclys.length - 1 ? 'right_line' : ''"></div>
                  </template>
                </div>
              </template>

              <!--项目案值  -->
              <template v-if="item.name === '项目案值'">
                <div class="box xmaz flex">
                  <el-input :min="0" v-model="item.input1" placeholder="最低"></el-input>
                  <span>至</span>
                  <el-input :min="0" v-model="item.input2" placeholder="最高"></el-input>
                </div>
              </template>
              <div v-if="item.maxLen < (item.list && item.list.length) && item.name !== '擅长领域'" class="more text-align-center" @click="showMoreHandle(item, item.list)">更多</div>
            </slot>
          </div>

          <div class="btn">
            <el-button @click.stop="isOk(item, index)">确认</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 已选中的数据 -->
    <div class="tags_list" v-if="selectTags.length > 0">
      <div class="tag" v-for="(item, index) in selectTags" :key="index">
        <span class="span font-weight-400 font-size-16">{{ item.name }}</span>
        <img class="icon cursor-pointer" src="@/assets/images/clo@2x.png" @click.stop="closeTag(item)" />
      </div>
    </div>
  </div>
</template>

<script>
import { getTagListApi } from "@/api/api"
export default {
  name: "filter-select",
  components: {},
  props: {
    filters: {
      type: Array,
      default: () => {
        return [
          {
            icon: require("@/assets/images/sjwl@2x.png"),
            name: "社交网络",
            isOpen: false,
            slot: "select1",
            selectList: [],
            list: [
              {
                name: "明星直播",
                value: "明星直播",
              },
              {
                name: "主播直播",
                value: "主播直播",
              },
              {
                name: "功能场景直播",
                value: "功能场景直播",
              },
              {
                name: "官店代播",
                value: "官店代播",
              },
              {
                name: "TVC/品牌故事",
                value: "TVC/品牌故事",
              },
              {
                name: "vlog植入",
                value: "vlog植入",
              },
            ],
          },
        ]
      },
    },
  },
  data() {
    return {
      // 最终被选择的数据
      emitValue: [
        {
          name: "社交网络",
          selects: [],
        },
        {
          name: "擅长行业",
          selects: [],
        },
        {
          name: "擅长领域",
          selects: [],
        },
        {
          name: "项目案值",
          selects: [],
        },
      ],
      sclys: [],
      checkMax: 5,
      showCloseIcon: false, // 判断是否显示清除icon
      lists: [], // 标签列表
      selectTags: [], // 已被选择的标签
      cloneFilter: [], // 克隆已选数据
    }
  },
  watch: {},
  computed: {},
  created() {
    this.getTags()
  },
  mounted() {
    this.checkClick()
  },
  methods: {
    /*
     * @Description: 点击弹窗之外的元素
     * @MethodAuthor:  liulian
     * @Date: 2022-06-27 09:51:48
     * @param: ''
     */
    checkClick() {
      document.addEventListener("click", (e) => {
        // 获取弹框对象
        if (this.$refs.selctId) {
          let isSelf = this.$refs.selctId.contains(e.target) // 该点击对象包含组件元素在内
          if (!isSelf) {
            this.filters.map((item) => {
              this.$set(item, "isOpen", false)
            })
            this.clearSelect()
          }
        }
      })
    },
    /*
     * @Description: 没有点击确认按钮的时候清除已选参数
     * @MethodAuthor:  liulian
     * @Date: 2022-08-03 17:16:23
     * @param: ''
     */
    clearSelect() {
      this.filters.map((item) => {
        this.cloneFilter.map((it) => {
          if ((it.name === item.name && item.name === "社交网络") || (it.name === item.name && item.name === "擅长行业")) {
            this.$set(item, "selectList", it.selectList)
          }
          if (it.name === item.name && item.name === "擅长领域") {
            let showIcon = false
            item.list.length > 0 &&
              item.list.map((f) => {
                it.list.length > 0 &&
                  it.list.map((c) => {
                    if (f.name === c.name) {
                      this.$set(f, "selectList", c.selectList)
                      if (f.selectList.length > 0) {
                        showIcon = true
                      }
                    }
                  })
              })

            this.showCloseIcon = showIcon
          }
          if (it.name === item.name && item.name === "项目案值") {
            this.$set(item, "input1", it.input1)
            this.$set(item, "input2", it.input2)
          }
        })
      })
    },

    /*
     * @Description: 关闭已选的标签
     * @MethodAuthor:  liulian
     * @Date: 2022-07-11 11:46:03
     * @param: ''
     */
    closeTag(obj) {
      this.filters.map((item) => {
        if (item.name === "社交网络" || item.name === "擅长行业") {
          if (item.selectList.length > 0) {
            item.selectList.map((i, index) => {
              if (i === obj.id) {
                item.selectList.splice(index, 1)
              }
            })
          }
        } else if (item.name === "擅长领域") {
          this.sclys.map((it) => {
            it.selectList.length > 0 &&
              it.selectList.map((i, index) => {
                if (i === obj.id) {
                  it.selectList.splice(index, 1)
                }
              })
          })
          this.sclys.map((i) => {
            this.$set(i, "flag", i.selectList.length > 0)
          })
          let check = this.sclys.some((k) => k.flag)
          this.showCloseIcon = check
        }
      })

      // 去掉emitValue中相同的id
      this.emitValue.length > 0 &&
        this.emitValue.map((item) => {
          if (item.name == "社交网络" || item.name === "擅长行业") {
            item.selects.length &&
              item.selects.map((it, index) => {
                if (it === obj.id) {
                  item.selects.splice(index, 1)
                }
              })
          }
          if (item.name === "擅长领域") {
            item.selects.length &&
              item.selects.map((it) => {
                it.value.length > 0 &&
                  it.value.map((i, index) => {
                    if (i === obj.id) {
                      item.selects.splice(index, 1)
                    }
                  })
              })
          }
        })
      this.checkTags()
      this.sendPramsParent()

      // 再次克隆当前数据
      this.cloneFilter = JSON.parse(JSON.stringify(this.filters))
    },

    /*
     * @Description: 点击清空图标
     * @MethodAuthor:  liulian
     * @Date: 2022-07-08 10:57:30
     * @param: ''
     */
    clearHandle(item) {
      if (item.name === "社交网络" || item.name === "擅长行业") {
        this.$set(item, "selectList", [])
      } else if (item.name === "擅长领域") {
        if (item.list.length > 0) {
          item.list.map((it) => {
            this.$set(it, "selectList", [])
            this.showCloseIcon = false
          })
        }
      } else if (item.name === "项目案值") {
        this.$set(item, "input1", null)
        this.$set(item, "input2", null)
      }
      this.delTag(item)
    },
    /*
     * @Description: 删除id相同的标签
     * @MethodAuthor:  liulian
     * @Date: 2022-07-11 15:45:48
     * @param: ''
     */
    delTag(item) {
      this.emitValue.length > 0 &&
        this.emitValue.map((i) => {
          if (item.name === i.name) {
            this.$set(i, "selects", [])
          }
        })
      this.checkTags()
      this.sendPramsParent()

      // 再次克隆当前数据
      this.cloneFilter = JSON.parse(JSON.stringify(this.filters))
    },
    /*
     * @Description: 获取标签列表
     * @MethodAuthor:  liulian
     * @Date: 2022-06-28 12:17:15
     * @param: ''
     */
    getTags() {
      getTagListApi().then((res) => {
        if (res.code === 10000) {
        // 字段名称改变
          res.data.length > 0 &&
            res.data.map((item) => {
              if (item.name === "传播内容") {
              this.$set(item,'name','擅长领域')
                // item.name === "擅长领域"
              }
              if (item.name === "行业领域") {
                // item.name === "擅长行业"
                   this.$set(item,'name','擅长行业')
              }
            })
          this.lists = res.data
          this.getList()
        }
      })
    },

    /*
     * @Description: 社交领域被选中
     * @MethodAuthor:  liulian
     * @Date: 2022-06-17 11:23:13
     *
     * @param: ''
     */
    changeHandle(list) {
      this.showCloseIcon = !(list.length === 0)
    },
    /*
     * @Description: 点击展示更多
     * @MethodAuthor:  liulian
     * @Date: 2022-06-16 18:01:47
     * @param: ''
     */
    showMoreHandle(item, arr) {
      event.stopPropagation()
      if (item.maxLen < arr.length) {
        this.$set(item, "maxLen", arr.length)
      }
    },
    /*
     * @Description: 擅长领域显示更多
     * @MethodAuthor:  liulian
     * @Date: 2022-06-17 09:32:16
     * @param: ''
     */
    showMoreHandle1(obj) {
      event.stopPropagation()
      this.$set(obj, "maxLen", obj.list.length)
    },
    getList() {
      this.filters.length > 0 &&
        this.filters.map((item) => {
          this.$set(item, "selectList", []) // 初始化绑定的值
          this.$set(item, "maxLen", 6) // 最多展示几条
          if (item.name === "社交网络") {
            item.list = this.getSjwl()
          } else if (item.name === "擅长行业") {
            item.list = this.getSchy()
          } else if (item.name === "擅长领域") {
            item.list = this.getScly()
          } else if (item.name === "项目案值") {
            this.$set(item, "input1", null)
            this.$set(item, "input2", null)
          }
        })

      // 初始化克隆数据
      this.cloneFilter = JSON.parse(JSON.stringify(this.filters))
    },
    /*
     * @Description: 获取社交网络的数据
     * @MethodAuthor:  liulian
     * @Date: 2022-06-16 17:52:29
     * @param: ''
     */
    getSjwl() {
      let list = []
      this.lists.map((item) => {
        if (item.name === "社交网络") {
          if (item.children.length > 0) {
            item.children.map((i) => {
              let obj = {
                name: i.name,
                value: i.id,
                parent_id: i.parent_id,
                sort: i.sort,
                top_parent_id: i.top_parent_id,
                type: i.type,
              }
              list.push(obj)
            })
          }
        }
      })
      return list
    },
    /*
     * @Description: 获取擅长行业的数据
     * @MethodAuthor:  liulian
     * @Date: 2022-06-16 17:52:56
     * @param: ''
     */
    getSchy() {
      let list = []
      this.lists.map((item) => {
        if (item.name === "擅长行业") {
          if (item.children.length > 0) {
            item.children.map((i) => {
              let obj = {
                name: i.name,
                value: i.id,
                parent_id: i.parent_id,
                sort: i.sort,
                top_parent_id: i.top_parent_id,
                type: i.type,
              }
              list.push(obj)
            })
          }
        }
      })
      return list
    },
    /*
     * @Description: 获取擅长领域的数据
     * @MethodAuthor:  liulian
     * @Date: 2022-06-16 17:53:14
     * @param: ''
     */
    getScly() {
      this.lists.map((item) => {
        if (item.name === "擅长领域") {
          if (item.children.length > 0) {
            item.children.map((it) => {
              if (it.children.length > 0) {
                it.children.map((j) => {
                  j.value = j.id
                })
              }
              let obj = {
                type: it.name,
                name: it.name,
                value: it.id,
                parent_id: it.parent_id,
                sort: it.sort,
                top_parent_id: it.top_parent_id,
                list: it.children,
              }
              this.sclys.push(obj)
            })
          }
        }
      })
      this.sclys.length > 0 &&
        this.sclys.map((item) => {
          if (item.list.length > 0) {
            this.$set(item, "maxLen", 6)
            this.$set(item, "selectList", [])
          }
        })

      return this.sclys
    },

    /*
     * @Description: 点击菜单
     * @MethodAuthor:  liulian
     * @Date: 2022-06-16 17:51:41
     * @param: ''
     */
    clickItem(item, key) {
      if (item.isOpen) {
        this.clearSelect()
      }
      // 最近关闭的一个菜单
      this.filters.map((i, index) => {
        if (item.name === i.name) {
          this.$set(i, "isOpen", !item.isOpen)
        } else {
          this.$set(i, "isOpen", false)
        }
        // 初始状态展示六个数据
        if (i.maxLen) {
          this.$set(i, "maxLen", 6)
        }
        if (i.name === "擅长领域") {
          i.list.length > 0 &&
            i.list.map((j) => {
              if (j.maxLen) {
                this.$set(j, "maxLen", 6)
              }
            })
        }
      })
      // if(item.isOpen) {
      //      this.cloneFilter = JSON.parse(JSON.stringify(this.filters))
      // }
      // 克隆当前数据
    },
    /*
     * @Description: 点击确定
     * @MethodAuthor:  liulian
     * @Date: 2022-06-16 17:51:58
     * @param: ''
     */
    isOk(obj, key) {
      this.filters.map((i, index) => {
        this.$set(i, "isOpen", false)
      })

      //  被点击后将选取的值放到数组中
      if (obj.name === "社交网络" || obj.name === "擅长行业") {
        this.emitValue.map((item) => {
          if (item.name === obj.name) {
            item.selects = obj.selectList
          }
        })
      }
      if (obj.name === "擅长领域") {
        this.emitValue.map((item) => {
          if (item.name === obj.name) {
            item.selects = []
            this.sclys.map((i) => {
              item.selects.push({
                name: i.type,
                value: i.selectList,
              })
            })
          }
        })
      }
      if (obj.name === "项目案值") {
        this.emitValue.map((item) => {
          if (item.name === obj.name) {
            item.selects = [obj.input1, obj.input2]
          }
        })
      }

      this.checkTags()
      this.sendPramsParent()

      // 克隆一份已选数据
      this.cloneFilter = JSON.parse(JSON.stringify(this.filters))
    },
    /*
     * @Description: 确定后展示已经被选中的标签
     * @MethodAuthor:  liulian
     * @Date: 2022-07-11 11:07:37
     * @param: ''
     */
    checkTags() {
      this.selectTags = []
      this.filters.map((item) => {
        if (item.name === "社交网络" || item.name === "擅长行业") {
          if (item.selectList.length > 0) {
            item.selectList.map((it) => {
              item.list.map((i) => {
                if (it === i.value) {
                  let obj = { name: "", id: null }
                  obj.name = i.name
                  obj.id = i.value
                  this.selectTags.push(obj)
                }
              })
            })
          }
        } else if (item.name === "擅长领域") {
          item.list.length > 0 &&
            item.list.map((it) => {
              if (it.selectList.length > 0) {
                it.selectList.map((i) => {
                  it.list.map((j) => {
                    if (i === j.value) {
                      let obj = { name: "", id: null }
                      obj.name = j.name
                      obj.id = j.value
                      this.selectTags.push(obj)
                    }
                  })
                })
              }
            })
        }
      })
    },
    /*
     * @Description: 将选中的参数传递给父组件
     * @MethodAuthor:  liulian
     * @Date: 2022-07-11 10:39:34
     * @param: ''
     */
    sendPramsParent() {
      this.$emit("vals", this.emitValue)
    },
  },
}
</script>

<style scoped lang="scss">
.select_container {
  // z-index: 9999;
  z-index: 2000;
  .top_select {
    display: flex;
    align-items: center;
  }
  .select_box {
    margin-right: 1rem;
    .box_header {
      width: 10rem;
      height: 3.25rem;
      background: #ffffff;
      border-radius: 1rem;
      opacity: 1;
      border: 0.0625rem solid #eff0f6;
      display: flex;
      align-items: center;
      img {
        width: 1.75rem;
        height: 1.75rem;
        margin-left: 0.5rem;
      }
      span {
        height: 1.75rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 100;
        color: #111029;
        margin-left: 0.5rem;
      }
      .clear_img {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    .select_body {
      background: #ffffff;
      box-shadow: 0rem 0.5rem 1.75rem 0.0625rem rgba(20, 20, 43, 0.1);
      border-radius: 1rem;
      opacity: 1;
      border: 0.0625rem solid #eff0f6;
      margin-top: 1rem;
      position: absolute;
      z-index: 999;
      .con {
        // margin: 1.25rem;
        margin-top: 1.25rem;
        margin-left: 1.5rem;
        margin-right: 1.25rem;
        ::v-deep .el-checkbox-group {
          display: flex;
          flex-wrap: wrap;
        }
        ::v-deep .el-checkbox {
          flex: 0 0 35%;
        }
        .box {
          width: 14.125rem;
          ::v-deep .el-checkbox-group {
            padding-bottom: 0;
          }
          ::v-deep .el-checkbox {
            margin-bottom: 1.125rem;
          }
        }
        .scly {
          display: flex;
          width: 55.625rem;
          .type_box {
            flex: 0 0 33%;
            // border-right: 0.0625rem solid #eff0f6;
            .title {
              white-space: nowrap;
            }
          }
          .right_line {
            height: 9.4375rem;
            width: 0;
            border-right: 1px solid #eff0f6;
            margin-right: 1.5rem;
          }
          .line {
            height: 9.4375rem;
            opacity: 1;
          }
        }
        // 项目案值
        .xmaz {
          margin-bottom: 1rem;
          span {
            margin-left: 0.875rem;
            margin-right: 0.875rem;
          }
          ::v-deep .el-input__inner:focus {
            border-color: #eff0f6;
          }
          ::v-deep .el-input__inner:hover {
            border-color: #eff0f6;
          }
        }
      }
      .cons {
        margin-left: 0;
        margin-right: 0;
      }
      .more {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 100;
        color: #a0a3bd;
        cursor: pointer;
        margin-bottom: 1rem;
        margin-right: 1.25rem;
      }
      .btn {
        margin-bottom: 0.625rem;
        height: 3.9375rem;
        line-height: 3.9375rem;
        border-top: 0.0625rem solid #eff0f6;
        text-align: center;
        ::v-deep .el-button {
          border: none;
          position: static;
          width: 2.75rem;
          height: 1.4375rem;
          background: #111029;
          border-radius: 0.25rem;
          opacity: 1;
          color: #f7f7fc;
          padding: 0;
          font-size: 0.75rem;
          font-weight: 400;
        }
      }
    }
  }
  .tags_list {
    margin-top: 1.625rem;
    display: flex;
    flex-wrap: wrap;
    .tag {
      height: 2.625rem;
      background: #eff0f6;
      border-radius: 2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;
      span {
        color: #57596c;
      }
      .icon {
        width: 1.375rem;
        height: 1.375rem;
        margin-left: 0.5rem;
      }
    }
  }
}
</style>
