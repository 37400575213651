<template>
    <div class="mechanism">
        <tabs :tabs="tabslist" @handleClick="handleClick" :switchshow="activeindex"></tabs>
        <listdata :active="activeindex" :datas="dataList" @uplodata="uplodata"></listdata>
        <div class="empty" v-show="emptyShow">
            <img src="@/assets/images/empty.png" alt="">
            <p>哎呀，这里什么也没有~快试试其他搜索词吧！</p>
        </div>
    </div>
</template>
<script>
import { ExaimList, CreatorList } from "@/api/api"
// tabs
import tabs from "../../../components/tabs/tabs.vue"
// 列表
import listdata from "./listdata/listdata.vue"
export default {
    components: {
        tabs,
        listdata
    },
    data() {
        return {
            emptyShow: false,
            switchshow: true,
            activeindex: 1,
            tabslist: [
                {
                    id: "1",
                    name: "创作者",
                },
                {
                    id: "2",
                    name: "待审核",
                }
            ],
            query: {
                page: 1,
                limit: 10
            },
            total: 10,
            dataList: []
        }
    },
    mounted() {
        this.getCreator()
    },
    methods: {
        uplodata() {
            if (this.activeindex == 1) {
                this.getCreator()
            } else {
                this.getExaimList()
            }
        },
        // 获取创作者列表
        getCreator() {
            CreatorList(this.query)
                .then(res => {
                    if (res.code == 10000) {
                        if (res.data.length > 0) {
                            this.emptyShow = false
                        } else {
                            this.emptyShow = true
                        }
                        this.total = res.count
                        this.dataList = res.data
                    }
                })
        },
        // 待审核列表
        getExaimList() {
            ExaimList(this.query)
                .then(res => {
                    if (res.code == 10000) {
                        if (res.data.length > 0) {
                            this.emptyShow = false
                        } else {
                            this.emptyShow = true
                        }
                        this.total = res.count
                        this.dataList = res.data
                    }
                })
        },
        handleClick(e) {
            this.activeindex = e
            this.query.page = 1
            if (e == 1) {
                this.getCreator()
            } else {
                this.getExaimList()
            }
        }
    }
}
</script>
<style  lang="scss" scoped>
.mechanism {
    padding: 1.5625rem 2rem;

    .empty {
        margin-top: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            width: 18.75rem;
            height: 12.5rem;
        }

        p {
            margin-top: 2rem;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #A0A3BD;
            line-height: 1.1875rem;
        }
    }
}
</style>