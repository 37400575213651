<template>
  <div class="follow">
    <div class="partake-top">
      <p>私信</p>
      <!-- <p @click="allMark">全部标为已读</p> -->
    </div>
    <div class="partake-list">
      <ul v-if="!(list.length === 0)">
        <li v-for="item in list" :key="item.id">
          <div class="img">
            <img :src="item.userProfile.avatar" class="avatar" alt="" />
            <!-- <img src="@/assets/images/userprofile/isvip.png" class="isvip" alt=""> -->
          </div>
          <div class="content">
            <div class="tle-date">
              <p>{{ item.userProfile.nick }}</p>
              <el-badge :is-dot="item.unreadCount > 0 ? true : false" class="item">与您的私信</el-badge>
              <p>
                {{ getNowTime == item.time.substring(0, 10).replace(/-/g, "") ?
                    item.time.substring(10, item.time.length - 3) : '' || getNowTime ==
                      Number(item.time.substring(0, 10).replace(/-/g, "") + 1) ? '昨天' : item.time.substring(5,
                        10).replace(/-/g,
                          "/")
                }}
              </p>
            </div>
            <div class="con" v-if="item.lastMessage.payload != ''">
              <p>{{ item.lastMessage.payload.text }}</p>
              <p @click="onDetail(item)" style="margin-left: auto">查看</p>
            </div>
          </div>
        </li>
      </ul>
      <!-- 空状态 -->
      <emptyPageVue v-else :config="config"></emptyPageVue>
    </div>
  </div>
</template>
<script>
import TIM from "tim-js-sdk"
import emptyPageVue from "@/components/common/empty-page.vue"
export default {
  components: {
    emptyPageVue,
  },
  data() {
    return {
      list: [],

      config: {
        emptyImage: require("@/assets/images/home/empty@2x.png"),
        description: "",
      },
    }
  },
  computed: {
    // 获取当前日期（年份，月份，时间）：
    getNowTime() {
      var nowtime = ""
      let dt = new Date()
      var y = dt.getFullYear()
      var mt = (dt.getMonth() + 1).toString().padStart(2, '0')
      var day = dt.getDate().toString().padStart(2, '0')
      var h = dt.getHours().toString().padStart(2, '0')
      var m = dt.getMinutes().toString().padStart(2, '0')
      nowtime = (y + "-" + mt + "-" + day).replace(/-/g, "")
      // nowtime = y + "-" + mt + "-" + day + " " + h + ":" + m
      return nowtime
      console.log('此刻时间', this.nowtime)
    }
  },
  mounted() {
    this.getConverList()
  },
  methods: {
    // 全部标为已读
    allMark() {
      let promise = this.$tim.setAllMessageRead(); // 等同于 tim.setAllMessageRead({scope: TIM.TYPES.READ_ALL_MSG})
      promise.then(function (imResponse) {
        console.log('操作成功')
        // 已读上报成功，所有会话的 unreadCount 属性值被置为0
      }).catch(function (imError) {
        // 已读上报失败
        console.warn('setAllMessageRead error:', imError);
      });
    },
    getConverList() {
      var that_ = this
      let promise = this.$tim.getConversationList()
      promise
        .then(function (imResponse) {
          console.log(imResponse)
          imResponse.data.conversationList.map(e => {
            e.time = that_.timestampToTime(e.lastMessage.lastTime)
          })
          that_.list = imResponse.data.conversationList // 会话列表，用该列表覆盖原有的会话列表
          //会话列表信息解析处理，请求后台接口获取对应聊天人信息
          console.log(that_.list)
        })
        .catch(function (imError) {
          console.warn("getConversationList error:", imError) // 获取会话列表失败的相关信息
        })
    },
    // 时间戳转换
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = date.getDate() + ' ';
      var h = date.getHours() + ':';
      var m = date.getMinutes() + ':';
      var s = date.getSeconds();
      return Y + M + D + h + m + s;
    },
    onDetail(item) {
      let isLogin = this.$store.state.token
      if(!isLogin){
        this.$messageBox.alert("未登录，请重新登录", {
          type: "warning",
        })
          .then(() => {
            this.$store.commit("REMOVE_INFO")
            this.$router.push("/login")
            this.$messageBox.close()
          })
          .catch(() => {
            this.$messageBox.close()
          })

          return
      }
      this.$router.push({ path: "/chartroom", query: { id: item.userProfile.userID } })
    },
  },
}
</script>
<style lang="scss" scoped>
.follow {
  padding: 1rem 2rem;

  .partake-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eff0f6;

    p {
      font-size: 1rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 300;
      color: #111029;
      line-height: 1.1875rem;
      padding-bottom: 1rem;

      &:nth-child(2) {
        cursor: pointer;
      }
    }
  }

  .partake-list {
    ul {
      display: flex;
      width: 100%;
      flex-direction: column;

      li {
        width: 100%;
        display: flex;
        padding: 2rem 0;
        border-bottom: 1px solid #eff0f6;

        .img {
          display: block;
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          position: relative;

          .avatar {
            display: block;
            width: 100%;
            height: 100%;
          }

          .isvip {
            position: absolute;
            right: 0.125rem;
            bottom: 0.125rem;
            display: block;
            width: 1.1875rem;
            height: 1.1875rem;
          }
        }

        .content {
          margin-left: 2rem;
          flex: 1;

          .tle-date {
            display: flex;
            align-items: flex-end;
            padding-bottom: 1rem;

            p {
              font-size: 1.125rem;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #111029;
              line-height: 1.3125rem;

              &:nth-child(3) {
                margin-left: 1rem;
                font-size: 0.875rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #a0a3bd;
                line-height: 1rem;
              }
            }

            .el-badge {
              margin: 0 1rem;
              font-size: 1.125rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 100;
              color: #111029;
              line-height: 1.3125rem;

              ::v-deep .el-badge__content {
                background: #dc2b2b;
              }
            }
          }

          .con {
            display: flex;
            align-items: center;

            p {
              font-size: 0.875rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 100;
              color: #57596c;
              line-height: 1rem;

              &:nth-child(2) {
                margin-left: auto;
                cursor: pointer;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #3a71ff;
                line-height: 1.1875rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
