<!--
* @description 提示组件
* @fileName tip-item.vue
* @author liulian
* @date 2022/08/10 16:04:28
-->
<template>
    <!-- 筛选项提示 -->
    <div class="filter_tip">
      <img class="icon" src="@/assets/images/tip@2x.png" />
      <span class="text font-weight-400 font-size-16">提示：{{ tipText }}</span>
    </div>
</template>

<script>
export default {
  name: "tip-item",
  components: {},
  props: {
    // 提示的文字
    tipText: {
      type: String,
      default: "按擅长Social平台/行业领域/传播内容 筛选创作者",
    },
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.filter_tip {
  height: 2.875rem;
  background: #eff0f6;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  line-height: 2.875rem;
  display: inline-block;
  margin-bottom: 1rem;
  .icon {
    margin-left: 0.75rem;
    width: 1.375rem;
    height: 1.375rem;
    margin-top: 0.75rem;
  }
  .text {
    margin-right: 0.75rem;
    color: #57596c;
    display: block;
    float: right;
  }
}
</style>
