<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 * 
 * 
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * 
 *            佛祖保佑     永不宕机     永无BUG
 * 
 *        佛曰:  
 *                写字楼里写字间，写字间里程序员；  
 *                程序人员写程序，又拿程序换酒钱。  
 *                酒醒只在网上坐，酒醉还来网下眠；  
 *                酒醉酒醒日复日，网上网下年复年。  
 *                但愿老死电脑间，不愿鞠躬老板前；  
 *                奔驰宝马贵者趣，公交自行程序员。  
 *                别人笑我忒疯癫，我笑自己命太贱；  
 *                不见满街漂亮妹，哪个归得程序员？
 -->

<!--
 * @Author: 任重浩
 * @Date: 2022-08-05 12:13:11
 * @LastEditors: 任重浩
 * @LastEditTime: 2022-08-11 15:36:35
 * @Description: 私信聊天页面
 * @FilePath: /social_contact_pc/src/views/xiaoxi/chart/chartroom/chartroom.vue
-->

<template>
    <div class="chartsroom">
        <div class="box">
            <div class="box-left">
                <div class="userlist">
                    <div class="list" v-for="(item, index) in userlist" :key="index"
                        @click.stop="checkActive(item, index)"
                        :class="pathActive == item.userProfile.userID ? 'isActive' : ''">
                        <img :src="item.userProfile.avatar" alt="">
                        <div class="userinfor">
                            <div class="name">{{ item.userProfile.nick }}
                                <i v-if="item.unreadCount > 0"></i>
                            </div>
                            <p>{{ item.lastMessage.payload.text }}</p>
                        </div>
                        <div class="time">
                            {{ getNowTime == item.time.substring(0, 10).replace(/-/g, "") ?
                                    item.time.substring(10, item.time.length - 3) : '' || getNowTime ==
                                        Number(item.time.substring(0, 10).replace(/-/g, "") + 1) ?
                                        '昨天' : item.time.substring(5, 10).replace(/-/g, "/")
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-right">
                <div class="user-tle">{{ username }}</div>
                <div class="chart-box" ref='scrollview'>
                    <div class="chart" v-for="(item, index) in chartList" :key="index"
                        :style="{ minHeight: item.payload.data == 'image' ? '22rem' : '' || item.payload.data == 'link' ? '15rem' : '5rem' }">
                        <!-- 接收方 -->
                        <div class="in" v-if="item.flow == 'in'">
                            <!-- 头像 -->
                            <div class="infor">
                                <img :src="item.avatar" alt="">
                            </div>
                            <div class="content">
                                <div class="coin" v-if="item.payload.data != 'image'"> {{ item.payload.text }} </div>
                                <div class="img" v-if="item.payload.data == 'image'">
                                    <img :src="item.payload.description" alt="">
                                </div>
                                <div class="projectlink" v-if="item.payload.data == 'link'">
                                    <p>请核对项目信息</p>
                                    <div class="link">
                                        <p><span>项目名称</span>{{ item.payload.extension.text }}</p>
                                        <p> <span>项目金额</span>{{ item.payload.extension.total }}</p>
                                        <p><span>项目周期</span>{{ item.payload.extension.days }}</p>
                                        <div class="btn" @click.stop="viewdetails(item)">查看详情</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="out" v-if="item.flow == 'out'">
                            <div class="infor">
                                <img :src="item.avatar" alt="">
                            </div>
                            <div class="content">
                                <div class="coin" v-if="item.payload.data != 'image'"> {{ item.payload.text }} </div>
                                <div class="img" v-if="item.payload.data == 'image'">
                                    <img :src="item.payload.description" alt="">
                                </div>
                                <div class="projectlink" v-if="item.payload.data == 'link'">
                                    <p>请核对项目信息</p>
                                    <div class="link">
                                        <p><span>项目名称</span>{{ item.payload.extension.text }}</p>
                                        <p> <span>项目金额</span>{{ item.payload.extension.total }}</p>
                                        <p><span>项目周期</span>{{ item.payload.extension.days }}</p>
                                        <div class="btn" @click.stop="viewdetails(item)">查看详情</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="send">
                    <div class="tools">
                        <div class="new" @click="sendNews">
                            <img src="@/assets/images/ch1.png" alt="">
                        </div>
                        <div class="new" style="margin-left:3rem">
                            <el-upload class="avatar-uploader" :action="uploadurl" :show-file-list="false"
                                :on-success="handleAvatarSuccess" :headers="importHeaders"
                                :before-upload="beforeAvatarUpload">
                                <img src="@/assets/images/ch2.png" alt="">
                                <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                            </el-upload>
                        </div>
                    </div>
                    <div class="inp">
                        <el-input placeholder="请输入内容" type="textarea" v-model="input">
                        </el-input>
                    </div>
                    <div class="btn" @click="send">
                        <p>发送</p>
                    </div>
                </div>
                <ul class="news" ref="news" v-if="isshow">
                    <li class="emojiList" v-for="(item, index) in emojis" :key="index" @click="handleEmoji(item)">
                        {{ item.text }}
                    </li>
                </ul>

            </div>
        </div>
    </div>
</template>
<script>
import { getUig } from "@/api/api"
var mytoken = localStorage.getItem('token')
import TIM from 'tim-js-sdk';
export default {
    data() {
        return {
            username: "",
            pathActive: null,
            imageUrl: "",
            uploadurl: "/ja/upload/oss/img",
            importHeaders: { Authorization: mytoken },
            nextReqMessageID: '',// 用于续拉，分页续拉时需传入该字段
            isCompleted: "",//判断是否继续加载数据
            isshow: false,
            chartid: "",
            chartList: [],
            userlist: [],//会话列表
            input: "",
            emojis: [],
            projectLink: "",
        }

    },
    computed: {
        // 获取当前日期（年份，月份，时间）：
        getNowTime() {
            var nowtime = ""
            let dt = new Date()
            var y = dt.getFullYear()
            var mt = (dt.getMonth() + 1).toString().padStart(2, '0')
            var day = dt.getDate().toString().padStart(2, '0')
            var h = dt.getHours().toString().padStart(2, '0')
            var m = dt.getMinutes().toString().padStart(2, '0')
            nowtime = (y + "-" + mt + "-" + day).replace(/-/g, "")
            // nowtime = y + "-" + mt + "-" + day + " " + h + ":" + m
            return nowtime
        }
    },
    updated() {
        this.$nextTick(() => {
            // this.$refs.scrollview.scrollTop = this.$refs.scrollview.scrollHeight
        })
    },
    mounted() {
        this.$nextTick(() => {
            // 获取指定元素
            const scrollview = this.$refs['scrollview'];
            // 添加滚动监听，该滚动监听了拖拽滚动条
            // 尾部的 true 最好加上，我这边测试没加 true ，拖拽滚动条无法监听到滚动，加上则可以监听到拖拽滚动条滚动回调
            scrollview.addEventListener('scroll', this.scrollChange, true);


        })
        this.projectLink = JSON.parse(localStorage.getItem("projectLink"))
        const emojis = [
            '😀', '😄', '😅', '🤣', '😂', '😉', '😊', '😍', '😘', '😜',
            '😝', '😏', '😒', '🙄', '😔', '😴', '😷', '🤮', '🥵', '😎',
            '😮', '😰', '😭', '😱', '😩', '😡', '💀', '👽', '🤓', '🥳',
            '😺', '😹', '😻', '🤚', '💩', '👍', '👎', '👏', '🙏', '💪'
        ]
        this.emojis = emojis.map(emoji => ({ text: emoji }))
        if (this.$route.query.id) {
            this.chartid = this.$route.query.id
            this.username = this.$route.query.username
            this.pathActive = this.$route.query.id
        }
        this.scroll()
        this.getConverList()
        this.getmsgDetail(this.$route.query.id)
        this.$tim.on(this.$TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage);
    },
    methods: {
        onReceiveMessage({ data: messageList }) {
            this.$nextTick(() => {
                this.$refs.scrollview.scrollTop = this.$refs.scrollview.scrollHeight
            })
            this.chartList.push(messageList[0])
        },
        // 查看详情
        viewdetails(item) {

            window.open(item.payload.description)
        },
        // 切换不同用户聊天
        checkActive(item, index) {
            this.username = item.userProfile.nick
            this.pathActive = item.userProfile.userID
            this.getmsgDetail(item.userProfile.userID)
            this.chartid = item.userProfile.userID
        },
        // 滚动监听
        scrollChange(e) {
            if (this.$refs.scrollview.scrollTop === Number(0)) {
                // 执行加载缓存数据
                // 下拉查看更多消息
                if (this.isCompleted == false) {
                    let promise = this.$tim.getMessageList({ conversationID: 'C2C' + this.chartid, nextReqMessageID: this.nextReqMessageID, count: 15 });
                    promise.then(res => {
                        this.isCompleted = res.data.isCompleted
                        res.data.messageList.map(e => {
                            if (e.payload.data == 'link') {
                                if (typeof e.payload.extension === 'string') {
                                    try {
                                        e.payload.extension = JSON.parse(e.payload.extension)
                                        return true;
                                    } catch (e) {
                                        return false;
                                    }
                                }
                            }
                            this.chartList.unshift(e)
                        })
                        this.nextReqMessageID = res.data.nextReqMessageID
                        // const messageList = imResponse.data.messageList; // 消息列表。
                        // const nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
                        // const isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。isCompleted 为 true 时，nextReqMessageID 为 ""。
                    });
                }

            }
        },
        sendNews() {
            if (this.isshow == false) {
                this.isshow = true
            } else {
                this.isshow = false
            }
            // this.$refs.news.style.visibility="visible"  
        },
        handleEmoji(item) {
            this.input = item.text
            this.isshow = false
        },
        scroll() {
            this.$nextTick(() => {
                let msg = document.getElementsByClassName('chart-box') // 获取对象
                let distanceFromBottom = msg.scrollHeight - msg.scrollTop - msg.clientHeight
                // 滚动高度
            })
        },
        // 获取会话列表
        getConverList() {
            var that_ = this
            let promise = this.$tim.getConversationList();
            promise.then(function (imResponse) {
                imResponse.data.conversationList.map(e => {
                    e.time = that_.timestampToTime(e.lastMessage.lastTime)
                })
                that_.userlist = imResponse.data.conversationList; // 会话列表，用该列表覆盖原有的会话列表
                //会话列表信息解析处理，请求后台接口获取对应聊天人信息

            }).catch(function (imError) {
            });
        },
        // 时间戳转换
        timestampToTime(timestamp) {
            var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = date.getDate() + ' ';
            var h = date.getHours() + ':';
            var m = date.getMinutes() + ':';
            var s = date.getSeconds();
            return Y + M + D + h + m + s;
        },
        // 获取会话详情
        getmsgDetail(id) {
            var that_ = this

            var promise = that_.$tim.getMessageList({
                conversationID: 'C2C' + id,   //id为对应聊天人的userID
                count: 15  //一次拉取的聊天条数
            });
            promise.then(res => {
                if (res.code == 0) {
                    var list = []
                    that_.isCompleted = res.data.isCompleted
                    that_.nextReqMessageID = res.data.nextReqMessageID
                    res.data.messageList.map(e => {
                        if (e.flow == 'in') {
                            that_.username = e.nick
                        }
                        if (e.payload.data == 'link') {
                            if (typeof e.payload.extension === 'string') {
                                try {
                                    e.payload.extension = JSON.parse(e.payload.extension)
                                    return true;
                                } catch (e) {
                                    return false;
                                }
                            }
                        }

                    })
                    that_.$nextTick(() => {
                        that_.$refs.scrollview.scrollTop = that_.$refs.scrollview.scrollHeight
                    })
                    that_.chartList = res.data.messageList
                    // 消息已读
                    // 将某会话下所有未读消息已读上报
                    let promise = that_.$tim.setMessageRead({ conversationID: 'C2C' + this.chartid });
                    promise.then(function (imResponse) {
                        // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
                    }).catch(function (imError) {
                        // 已读上报失败
                    });

                }
            })
        },
        handleAvatarSuccess(res) {
            var that_ = this
            if (res.code == 10000) {
                // this.input = res.data
                var message = that_.$tim.createCustomMessage({
                    to: that_.chartid,
                    conversationType: TIM.TYPES.CONV_C2C,
                    payload: {
                        data: 'image', // 用于标识该消息是图片
                        description: res.data, // 图片地址
                        extension: JSON.stringify({
                            text: '新的图片',
                            url: res.data,
                        })
                    }
                });
                that_.isSend(message)
            }
        },
        beforeAvatarUpload() {

        },
        send() {
            var that_ = this
            if (!that_.input) return
            if (that_.input.substr(0, 30) == 'https://web.social.lyunweb.com') {
                var message = that_.$tim.createCustomMessage({
                    to: that_.chartid,
                    conversationType: TIM.TYPES.CONV_C2C,
                    payload: {
                        data: 'link', // 用于标识该消息是图片
                        description: that_.input, // 图片地址
                        extension: JSON.stringify({
                            text: that_.projectLink.title,
                            total: that_.projectLink.total_money,
                            days: that_.projectLink.days,
                        })
                    }
                });
                that_.isSend(message)
            } else {
                var message = that_.$tim.createTextMessage({
                    to: that_.chartid,
                    conversationType: TIM.TYPES.CONV_C2C,
                    payload: {
                        text: that_.input,

                    },
                    needReadReceipt: true
                });
                that_.isSend(message)
            }

            // 2. 发送消息


        },
        isSend(message) {
            var that_ = this
            let promise = that_.$tim.sendMessage(message);
            promise.then(function (imResponse) {
                if (imResponse.code == 0) {
                    that_.input = ""
                    var promise = that_.$tim.getMessageList({
                        conversationID: 'C2C' + that_.chartid,   //id为对应聊天人的userID
                        count: 15  //一次拉取的聊天条数
                    });
                    promise.then(res => {
                        if (res.code == 0) {
                            if (res.data.messageList[res.data.messageList.length - 1].payload.data == 'link') {
                                res.data.messageList[res.data.messageList.length - 1].payload.extension = JSON.parse(res.data.messageList[res.data.messageList.length - 1].payload.extension)
                            }
                            that_.chartList.push(res.data.messageList[res.data.messageList.length - 1])
                            that_.$nextTick(() => {
                                that_.$refs.scrollview.scrollTop = that_.$refs.scrollview.scrollHeight
                            })
                        }
                    })
                }
            }).catch(function (imError) {
                // 发送失败
            });
        }
    },
}
</script>
<style lang="scss">
.chartsroom {
    margin: 50px auto 0;
    max-width: 86.25rem;
    height: 46.125rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 30px 1px rgba(237, 235, 245, 0.7000);
    border-radius: .625rem;
    border: 1px solid #F4F4F4;

    .box {
        display: flex;

        .box-left {
            flex-shrink: 1;
            width: 14.1875rem;

            .userlist {

                .list {
                    padding: 1rem;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    border-bottom: 1px solid rgb(230, 229, 240);

                    img {
                        display: block;
                        flex-shrink: 1;
                        width: 3rem;
                        height: 3rem;
                        border-radius: .25rem
                    }

                    .userinfor {
                        margin-left: .625rem;
                        /* 文本不换行显示   nowrap  */

                        .name {
                            display: flex;
                            font-size: .875rem;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #240F45;
                            line-height: 1rem;
                            padding-bottom: .25rem;

                            i {
                                display: block;
                                width: .5rem;
                                height: .5rem;
                                background: #FF3D54;
                                border-radius: 50%;
                            }
                        }

                        p {
                            font-size: .75rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #5C6466;
                            line-height: .875rem;
                            width: 100px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            -o-text-overflow: ellipsis;
                        }
                    }

                    .time {
                        margin-left: auto;
                        font-size: .75rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #C5C5C5;
                        line-height: .875rem;
                    }
                }

                .isActive {
                    background: #F4F4F4;
                }
            }
        }

        .box-right {
            flex: 1;
            position: relative;

            .user-tle {
                padding: 2.375rem 2rem;
                background: #F7F7FC;
                border-bottom: 1px solid rgb(230, 229, 240);
            }

            .chart-box {
                height: 386px;
                overflow-y: scroll;
                background: #F7F7FC;
                position: relative;
                padding-bottom: 2rem;
                border-bottom: 1px solid rgb(230, 229, 240);

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .chart {
                min-height: 6.25rem;

                .in {
                    display: flex;
                    padding: 2rem 2rem 0;
                    position: absolute;
                    left: 0;

                    .infor {
                        img {
                            display: block;
                            width: 3rem;
                            height: 3rem;
                            border-radius: .25rem
                        }
                    }

                    .content {
                        display: flex;
                        align-items: center;
                        margin-left: 1rem;
                        min-width: 7.125rem;
                        padding: .625rem 1.25rem;
                        background: #FFFFFF;
                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            width: 0px;
                            left: -0.75rem;
                            top: .9375rem;
                            height: 0px;

                            border: .375rem solid #000;

                            border-top-color: transparent;

                            border-bottom-color: transparent;

                            border-left-color: transparent;
                            border-right-color: #ffffff;
                        }

                        .coin {
                            font-size: .875rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #111029;
                        }

                        .img {
                            height: 18.75rem;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .projectlink {
                            padding: .625rem 0;
                            width: 13.125rem;

                            p {
                                font-size: .875rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #111029;
                                line-height: 1rem;
                            }

                            .link {
                                padding: .6875rem 1rem;
                                background: #ffffff;
                                margin-top: .9375rem;

                                p {
                                    display: flex;
                                    align-items: center;
                                    margin: .25rem 0;

                                    span {
                                        margin-right: .5rem;
                                        display: flex;
                                        font-size: .875rem;
                                        font-family: PingFang SC-Regular, PingFang SC;
                                        font-weight: 100;
                                        color: #57596C;
                                        ;
                                        line-height: 1rem;
                                    }
                                }

                                .btn {
                                    cursor: pointer;
                                    text-align: center;
                                    color: #3A71FF;
                                    font-size: .875rem;
                                    font-family: PingFang SC-Regular, PingFang SC;
                                    font-weight: 100;
                                    line-height: 1rem;
                                    margin: .75rem auto 0;
                                }
                            }
                        }
                    }
                }

                .out {
                    display: flex;
                    padding: 2rem 2rem 0;
                    position: absolute;
                    flex-flow: row-reverse;
                    right: 0;

                    .infor {
                        img {
                            display: block;
                            width: 3rem;
                            height: 3rem;
                            border-radius: .25rem
                        }
                    }

                    .content {

                        display: flex;
                        align-items: center;
                        margin-right: 1rem;
                        min-width: 7.125rem;
                        padding: .625rem 1.25rem;
                        background: #DCDDEB;
                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            width: 0px;
                            right: -0.75rem;
                            top: .9375rem;
                            height: 0px;

                            border: .375rem solid #000;

                            border-top-color: transparent;

                            border-bottom-color: transparent;

                            border-left-color: #DCDDEB;
                            border-right-color: transparent;
                        }

                        .coin {
                            font-size: .875rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #111029;
                        }

                        .img {
                            height: 18.75rem;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .projectlink {
                            padding: .625rem 0;
                            width: 13.125rem;

                            p {
                                font-size: .875rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #111029;
                                line-height: 1rem;
                            }

                            .link {
                                padding: .6875rem 1rem;
                                background: #ffffff;
                                margin-top: .9375rem;

                                p {
                                    display: flex;
                                    align-items: center;
                                    margin: .25rem 0;

                                    span {
                                        margin-right: .5rem;
                                        display: flex;
                                        font-size: .875rem;
                                        font-family: PingFang SC-Regular, PingFang SC;
                                        font-weight: 100;
                                        color: #57596C;
                                        ;
                                        line-height: 1rem;
                                    }
                                }

                                .btn {
                                    cursor: pointer;
                                    text-align: center;
                                    color: #3A71FF;
                                    font-size: .875rem;
                                    font-family: PingFang SC-Regular, PingFang SC;
                                    font-weight: 100;
                                    line-height: 1rem;
                                    margin: .75rem auto 0;
                                }
                            }
                        }
                    }
                }
            }

            .send {
                padding: 0 2rem;
                position: relative;
                background: #F7F7FC;

                .tools {
                    padding: 1.0625rem 0 2rem;
                    display: flex;
                    align-items: center;

                    .new {
                        cursor: pointer;
                    }
                }

                .inp {
                    min-height: 8.6875rem;
                    overflow-y: scroll;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .el-textarea {
                        min-height: 8.6875rem;
                        background: #F7F7FC;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .el-textarea__inner {
                            background: #F7F7FC;
                            padding: 0;
                            min-height: 6.875rem !important;
                            border: none;

                            &::-webkit-scrollbar {
                                display: none;
                            }
                        }
                    }
                }

                .btn {
                    position: absolute;
                    bottom: 1.75rem;
                    right: 1.25rem;
                    cursor: pointer;

                    p {
                        display: flex;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 4.25rem;
                        height: 2.375rem;
                        background: #111029;
                        border-radius: 2.9375rem;
                        font-size: .875rem;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 1rem;
                    }
                }
            }

            .news {
                position: absolute;
                bottom: 16.25rem;
                left: 1.1875rem;
                width: 18.75rem;
                display: flex;
                flex-wrap: wrap;
                padding: .625rem;
                background: #ffffff;
                transition: all .5s ease;

                li {
                    cursor: pointer;
                    margin: .625rem;


                }
            }
        }
    }
}
</style>