import  request from "@/utils/request.js";

// 发送邮箱验证
export function mailCodeApi(params){
    return request({
        url:'/ja/send/mail/register',
        method: 'post',
        params
    })
}
// 短信发送-注册
export function smsCodeApi(params){
    return request({
        url:'/ja/send/sms/register',
        method: 'post',
        params
    })
}
// 短信发送-登录
export function smsLoginCodeApi(params){
    return request({
        url:'/ja/send/sms/login',
        method: 'post',
        params
    })
}
// 邮件发送-登录
export function mailLoginCodeApi(params){
    return request({
        url:'/ja/send/mail/login',
        method: 'post',
        params
    })
}
// 短信发送-忘记密码
export function smsresetApi(params){
    return request({
        url:'/ja/send/sms/reset',
        method: 'post',
        params
    })
}
// 邮件发送-忘记密码
export function mailresetApi(params){
    return request({
        url:'/ja/send/mail/reset',
        method: 'post',
        params
    })
}
// 上传图片
export function uploadFiles(data){
    return request({
        url:'/ja/upload/oss/img',
        method: 'post',
        data
    })
}
// 文件上传
export function uploadfile(data){
    return request({
        url:"/ja//upload/oss/doc",
        method:"post",
        data
    })
}
// 短信发送换绑账号
export function sindmsg(params){
    return request({
        url:"/ja/send/sms/rebind",
        method:"post",
        params
    })
}
// 邮箱发送换绑
export function mailSind(params){
    return request({
        url:"/ja/send/mail/rebind",
        method:"post",
        params
    })
}
// 文件上传获取oss
export function getuploadOss(){
    return request ({
      url:"/ja/upload/oss/policy",
      method:"get"
    })
  }
