<!--
* @description 左侧有图片item
* @fileName left-image-card.vue
* @author liulian
* @date 2022/06/13 18:23:49
-->
<template>
  <div class="left-img">
    <div class="cardlist">
      <div class="card flex ">
        <div class="left">
          <img class="avatar" :src="item.avatar" alt="头像" />
          <span class="font-size-16 font-weight-500">{{ item.username }}</span>
        </div>
        <div class="line"></div>
        <div class="center flex-column">
          <div class="title font-size-16 font-weight-500">{{ item.demand_name }}</div>
          <div class="tip flex">
            <img v-if="item.recruit_role === 1" src="@/assets/images/home/geren@2x.png" alt="icon" />
            <!-- <img v-if="item.flag === 1" src="@/assets/images/home/pinpai@2x.png" alt="icon" /> -->
            <img v-if="item.recruit_role === 2" src="@/assets/images/home/jigou@2x.png" alt="icon" />
            <span class="pinpai font-size-16 font-weight-400">{{ item.recruit_role === 1 ? "个人" : "机构" }}</span>
            <span class="tip_line">|</span>
            <img class="rili" src="@/assets/images/home/rili@2x.png" alt="icon" />
            <span class="number font-size-16 font-weight-400">{{ item.delivery_time ? item.delivery_time : 0 }}天</span>
          </div>
          <span class="baoming font-size-14 font-weight-500 font-color-yellow">{{ item.apply_num ? item.apply_num : 0 }}人报名</span>
          <span class="time font-size-14 font-weight-500">{{ item.created_at }}</span>
        </div>
        <div class="right-price flex-column-between">
          <span class="font-size-20 font-weight-800 price_text">{{ item.budget ? "￥" + item.budget : "" }}</span>
          <div class="detail_btn text-align-center cursor-pointer"  @click="goDetail(item)">查看详情</div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "left-image-card",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          avatar: "https://s3.bmp.ovh/imgs/2022/06/09/fedd208b167ccf86.jpg",
          username: "名字名字名字名字名字",
          demand_name: "需求名称需求名称需求名称需求名称需求名称需求名称需求名称需求名称需求名称需求名称需求名称需求名称需求称需求名称需求名称需求名称需求名称需求称需求名称",
          number: 30,
          baoming: 1200,
          created_at: "2022年5月20日",
          recruit_role: 1,
          budget: "3000",
        }
      },
    },
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /*
     * @Description: 跳转需求大厅详情页面
     * @MethodAuthor:  liulian
     * @Date: 2022-06-14 10:16:48
     * @param: ''
     */
    goDetail(item) {
      this.$router.push(`/xqdtDetail?id=${item.id}`)
    },
  },
}
</script>

<style scoped lang="scss">
.left-img {
  .cardlist {
    margin: 2rem auto;
    flex-wrap: wrap;
    .card {
      width: 100%;
      min-height: 13.75rem;
      border-radius: 1.5rem;
      border: 1px solid #eff0f6;
      margin-bottom: 2rem;
      background-color: #fff;
      .left {
        display: flex;
        flex-direction: column;
        margin-left: 3.25rem;
        align-items: center;
        .avatar {
          width: 7.5rem;
          height: 7.5rem;
          border-radius: 199%;
        }
        span {
          margin-top: 0.875rem;
          color: #111029;
        }
      }
      .line {
        margin-left: 2rem;
        height: 6.75rem;
        width: 0px;
        opacity: 1;
        border: 1px solid #f4f4f4;
      }
      .center {
        margin-left: 2rem;
        margin-right: 26.75rem;
        .title {
          color: #111029;
          width: 36rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          word-break: break-all;
          overflow: hidden;
        }
        .tip {
          margin-top: 1rem;
          color: #57596c;
          img {
            width: 1.375rem;
            height: 1.375rem;
          }
          .rili {
            margin-left: 0.5rem;
          }
          .tip_line {
            color: #a0a3bd;
          }
          .pinpai {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }
          .number {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }
        }
        .baoming {
          margin-top: 1rem;
        }
        .time {
          margin-top: 1rem;
          color: #a0a3bd;
        }
      }
      .right-price {
        // flex: 1;
        display: block;
        margin-right: 2rem;
        text-align: right;
        height:200px;
        padding-top: 2rem;
        .price_text {
          width: 100px;
          color: #ff6868;
        }
        .detail_btn {
          width: 6.5rem;
          height: 3rem;
          line-height: 3rem;
          background: #ffffff;
          border-radius: 47px;
          opacity: 1;
          border: 1px solid #2f2c42;
          font-size: 1rem;
          font-weight: 400;
          color: #2f2c42;
          margin-top: 5rem;

        }
      }
    }
  }
}
</style>
