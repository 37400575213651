<!--
* @description 缺省页面
* @fileName empty-page.vue
* @author liulian
* @date 2022/06/13 17:49:41
-->
<template>
  <div class="empty">
    <el-empty :description="config.description" :image="config.emptyImage"></el-empty>
  </div>
</template>

<script>
export default {
  name: "empty-page",
  components: {},
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          emptyImage: require("../../assets/images/home/empty@2x.png"),
          description: "哎呀，这里什么也没有~快试试其他搜索词吧！",
        }
      },
    },
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.empty {
  margin: auto;
  ::v-deep .el-empty {
    margin-top: 8.5625rem;
  }
  ::v-deep .el-empty__image {
    width: 31.0625rem;
    height: 20.8125rem;
    img {
      width: 31.0625rem;
      height: 20.8125rem;
    }
  }
  ::v-deep .el-empty__description {
    margin-top: 2rem;
    font-size: 1rem;
    font-weight: 100;
    color: #a0a3bd;
  }
}
</style>
