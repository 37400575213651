<template>
    <!-- 执行中 -->
    <div id="productdetail">
        <div class="container">
            <p class="tle">
                {{ detail.title }}
            </p>
            <div class="project-no">
                <div class="num">项目编号：{{ detail.number }}</div>
                <div class="price">
                    <img src="@/assets/images/price.png" alt="">
                    ￥{{ detail.total_money }}
                </div>
                <div class="data">
                    <img src="@/assets/images/date.png" alt="">
                    {{ detail.days }}天
                </div>
                <div class="share" style="margin-left:auto;">
                    <!-- <share @onMove="onMoves"></share> -->

                    <!-- <share @onMove="onMoves(item)" :QRCodeMsg="qrurl"></share> -->
                </div>
            </div>
            <div class="userinfor" v-show="false">
                <div class="user">
                    <img src="" alt="">
                    <p>姓名姓名姓名</p>
                </div>
                <div class="information">
                    <p>
                        <img src="@/assets/images/userprofile/user.png" alt="">
                        <span>周华</span>
                    </p>
                    <span>/</span>
                    <p>
                        <img src="@/assets/images/userprofile/mobel.png" alt="">
                        <span>17079100088</span>
                    </p>
                    <span>/</span>
                    <p>
                        <img src="@/assets/images/userprofile/email.png" alt="">
                        <span>kpzssens@qq.com</span>
                    </p>
                    <el-button>私信</el-button>
                </div>

            </div>
            <!-- <div class="notice" >
                <img src="@/assets/images/userprofile/notice.png" alt="">
                <p>需求方未同意您的项目划分，请修改重新发送给需求方</p>
            </div> -->
            <div class="content">
                <!-- 步骤条 -->
                <steps :stepsLabel="stepsLabel" :totalSteps="totalSteps" :currentStep="currentStep"></steps>
                <!-- 阶段列表 -->
                <div class="stage" v-for="(item, index) in  detail.processList" :key="index">
                    <div class="list">
                        <div class="list-tle">
                            <div class="tle">
                                <p>P{{ index + 1 }}阶段</p>
                                <span> |</span>
                                <p>{{ item.title }}</p>
                            </div>
                            <div class="iconbox iconone" v-if="item.status == 1 || item.status == 2">
                                <img src="@/assets/images/userprofile/daiqueren.png" alt="">
                                执行中
                            </div>
                           <div class="iconbox icontwo" v-if="detail.status == 0 || detail.status == -1">
                                <img src="@/assets/images/userprofile/zhixingzhong.png" alt="">
                                待确认
                            </div>
                             <div class="iconbox icontw" v-if="detail.status == 1 && item.status == 0">
                                <img src="@/assets/images/userprofile/zhixingzhong.png" alt="">
                                未启动
                            </div>
                            <div class="iconbox iconthree" v-if="item.status == 3">
                                <img src="@/assets/images/userprofile/end.png" alt="">
                                已结束
                            </div>
                        </div>
                        <div class="list-content">
                            <div class="deliverdata">
                                <p>计划交付日期：<span>{{ item.pay_date }}</span></p>
                                <p v-if="item.status == 3">实际交付日期<span>{{item.updated_at}}</span></p>
                            </div>
                            <div class="price">
                                <p>
                                    计划金额
                                    <span>￥{{ item.money }}</span>
                                </p>
                                <p v-if="item.status == 3">
                                    实际金额
                                    <span>￥12000</span>
                                </p>
                            </div>
                            <div class="explain">
                                交付说明
                                <span>{{ item.introduce }}</span>
                            </div>
                            <div class="file" v-if="item.status == 3">
                                交付文档
                                <div class="file-box">
                                    <img src="@/assets/images/xqdt/txt@2x.png" alt="">
                                    {{item.filename}}
                                    <p @click.stop="dowlond(item.files)">下载</p>
                                </div>
                            </div>

                        </div>

                        <div class="btn" v-if="item.status == 3">
                            <p>您通过了创作者的「P{{index + 1}} 阶段的」申请，阶段已验收</p>
                        </div>
                        <div class="demandbtn" v-if="item.status == 2">
                            <el-button @click="stageonRefuse(item)">拒绝</el-button>
                            <el-button @click.stop="stageSub(item)">通过</el-button>
                        </div>

                    </div>
                </div>
                <div class="score" v-if="detail.status == 2 && detail.score !=''">
                    <p>评分</p>
                    <el-rate v-model="detail.score" disabled show-score text-color="#ff9900" score-template="{value}">
                    </el-rate>
                </div>
                <div class="edit" v-if="detail.status == 2 && detail.score==''" :style="{marginTop:detail.score==''?'1.4375rem':''}">
                    <el-button @click="onScore">去评分</el-button>
                </div>
                <div class="agree-reject" v-if="detail.status == 0" :style="{ marginTop: detail.status == 0 ? '3.375rem' : '' }">
                    <el-button @click="agree">同意</el-button>
                    <el-button @click="refuse">拒绝</el-button>
                </div>
            </div>
        </div>
        <div class="refuse">
            <!-- 拒绝弹窗 -->
            <dialo-refuse :show.sync="refusepop" width="690px" :center="true" :showFooter=false title="拒绝原因">
                <el-input type="textarea" placeholder="请描述您的拒绝原因" v-model="refuseval" maxlength="50" show-word-limit>
                </el-input>
                <div class="refusebtn">
                    <el-button @click="cancle">取消</el-button>
                    <el-button class="sub" @click="submit">确认</el-button>
                </div>

            </dialo-refuse>
            <!-- 评分弹窗 -->
            <dialo-refuse :show.sync="onScorepop" width="690px" :center="true" :showFooter=false title="评分">
                <div class="score-box">
                    <p>请您评分</p>
                    <el-rate v-model="scroceval" show-score text-color="#ff9900" score-template="{value}">
                        分
                    </el-rate>
                </div>
                <div class="refusebtn" style="justify-content: center" >
                    <el-button class="sub" @click="submitScore">提交</el-button>
                </div>

            </dialo-refuse>
        </div>
    </div>
</template>
<script>
import { prodetail, projectStatus, editstagestatus ,projectscroll} from "@/api/api"
import dialoRefuse from '@/components/common/dialog-cpn.vue';
import steps from "@/components/steps/step.vue"
import share from "@/components/share/share.vue"
export default {
    components: {
        steps,
        dialoRefuse,
        share
    },
    data() {
        return {
            refuseIrtem:"",
            detail: {},
            qrurl: "",
            scroceval: 0,
            onScorepop: false,
            refusepop: false,
            refuseval: "",
            value: 0,
            stepsLabel: ["创建项目", "待需求方确认", "执行中", "已结束"],//步骤条数组
            totalSteps: 4,
            currentStep: 1, //步骤条执行到第几步
        }
    },
    mounted() {
        this.detailid = this.$route.query.id
        this.getDetail()
    },
    methods: {
        // 项目详情
        getDetail() {
            prodetail( this.detailid)
                .then(res => {
                    if (res.code == 10000) {
                        res.data.processList.map(e => {
                            e.pay_date = e.pay_date.replace(",", "-")
                        })
                        this.detail = res.data
                        this.currentStep = Number(res.data.status + 2)
                    }
                })
        },
        // 下载文件
        dowlond(e){
            window.open(e)
        },
        // 需求方同意
        agree() {
            let obj = {
                status: 1,
                id:this.detailid
            }
            projectStatus(obj)
                .then(res => {
                    if (res.code == 10000) {
                        this.getDetail()
                        this.$message.success("操作成功")
                    }
                })
        },
        // 需求方拒绝
        refuse() {
            let obj = {
                status: -1,
                id: this.detailid
            }
            projectStatus(obj)
                .then(res => {
                    if (res.code == 10000) {
                        this.getDetail()
                        this.$message.success("操作成功")
                    }
                })
        },
        // 鼠标移入
        onMoves() {
            // console.log(item)
            this.qrurl = "http:://www.huawei.com"
        },
        // 拒绝弹窗
        stageonRefuse(item) {
            this.refuseIrtem=item
            this.refusepop = true
        },
        // 阶段通过
        stageSub(item) {
            console.log(item)
            let obj = {
                id: item.id,
                status: 3,
                test: 0.1
            }

            editstagestatus(obj)
                .then(res => {
                    if (res.code == 10000) {
                        const div = document.createElement('div')
                        div.innerHTML = res.data
                        document.body.appendChild(div)
                        document.forms[0].submit() //重要，这个才是点击跳页面的核心
                        this.getDetail()
                    }
                })
        },
        submit() {
            this.refusepop = false
            let obj ={
                 id:this.refuseIrtem.id,
                status: -1,
                reason:this.refuseval
            }
            editstagestatus(obj)
            .then(res=>{
                if(res.code==10000){
                    this.$message.success('操作成功')
                }
            })
        },
        cancle() {
            this.refusepop = false
        },
        onScore() {
            this.onScorepop = true
        },
        // 评分提交事件
        submitScore() {
            let obj={
                project_id:this.detailid,
                score:this.scroceval
            }
            projectscroll(obj).then(res=>{
                if(res.code==10000){
                    this.$message.success('评分成功！')
                    this.getDetail()
                }
            })
            
            this.onScorepop = false
        }
    }

}
</script>
<style lang="scss"  scoped>
#productdetail {
    width: 100%;
    height: auto;
    overflow: hidden;

    .container {
        margin: 0 auto;
        max-width: 86.25rem;
        padding: 2.5rem 3.125rem;
        height: 100%;

        .tle {
            font-size: 1.25rem;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #111029;
            line-height: 1.4375rem;
        }

        .project-no {
            margin-top: 2rem;
            font-size: 1.125rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 100;
            color: #2F2C42;
            line-height: 1.3125rem;
            display: flex;
            align-items: center;

            .price {
                margin: 0 3.5rem 0 4.0625rem;
            }

            .price,
            .data {
                display: flex;
                align-items: center;
                color: #57596C;
                font-size: 1rem;

                img {
                    display: block;
                    margin-right: .5rem;
                }
            }

        }

        .userinfor {
            margin-top: 4rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .user {
                display: flex;
                align-items: center;

                img {
                    display: block;
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                }

                p {
                    margin-left: 1rem;
                    font-size: 1.25rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1.4375rem;
                }
            }

            .information {
                display: flex;
                align-items: center;

                p {
                    margin: 0 2rem;
                    display: flex;
                    align-content: center;

                    img {
                        display: block;
                        width: 1.25rem;
                        height: 1.25rem;
                        margin-right: .5rem;
                    }
                }

                .el-button {
                    width: 4.25rem;
                    height: 2.375rem;
                    background: #111029;
                    border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                    font-size: .875rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                }
            }
        }

        .notice {
            margin-top: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: auto;
            background: #FFEFF0;
            border-radius: .625rem .625rem .625rem .625rem;

            img {
                display: block;
                width: 2rem;
                height: 2rem;
            }

            p {
                padding: 1.3125rem 0;
                font-size: 1rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #DC2B2B;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            padding: 4rem 2rem;
            margin-top: 2rem;
            background: #F7F7FC;
            border-radius: .625rem .625rem .625rem .625rem;

            .stage {
                background: #FFFFFF;
                padding: 1rem 2rem;
                height: auto;
                border-radius: .625rem .625rem 0px 0px;
                margin-top: 2.375rem;

                .list {
                    display: flex;
                    flex-direction: column;

                    .list-tle {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .tle {
                            display: flex;
                            align-items: center;

                            :nth-child(1) {
                                font-size: 1.125rem;
                                font-family: PingFang SC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #111029;
                                line-height: 1.3125rem;
                            }

                            :nth-child(3) {
                                font-size: 1.125rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #2F2C42;
                                line-height: 1.3125rem;
                            }

                            span {
                                display: inline-block;
                                margin: 0 2rem;
                            }
                        }

                        .iconbox {

                            display: flex;
                            align-items: center;
                            background: #DEF2E6;
                            border-radius: 2.1875rem 2.1875rem 2.1875rem 2.1875rem;
                            padding: .625rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #11845B;
                            line-height: 1.1875rem;

                            img {
                                display: block;
                                margin-right: .25rem;

                            }
                        }

                        .iconone {
                            color: #086CD9;
                            background: #EAF4FF;

                        }

                        .icontwo {
                            background: #DEF2E6;
                            color: #11845B;
                        }
                         .icontw {
                            color: #D5691B;
                            background: #FFF3E4 ;
                        }

                        .iconthree {
                            background: #F7F7FC;
                            color: #A0A3BD;
                        }
                    }

                    .list-content {
                        margin-top: 1rem;
                        padding: 2rem 0;
                        border-top: 1px solid #DCDDEB;
                        border-bottom: 1px solid #DCDDEB;

                        .deliverdata {
                            display: flex;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #2F2C42;
                            line-height: 1.1875rem;

                            p {
                                &:nth-child(2) {
                                    margin-left: 2.7rem;
                                }
                            }

                            span {
                                display: inline-block;
                                margin-left: 1rem;
                                color: #57596C;
                            }
                        }

                        .price {
                            display: flex;
                            margin: 1.0625rem 0 1.125rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #111029;
                            line-height: 1.1875rem;

                            p {
                                &:nth-child(2) {
                                    margin-left: 7.5625rem;
                                }
                            }

                            span {
                                display: inline-block;
                                margin-left: 1rem;
                                color: #FF6868;
                                font-weight: bold;
                            }
                        }

                        .explain {
                            display: flex;
                            align-items: flex-start;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #2F2C42;
                            line-height: 1.1875rem;
                            white-space: nowrap;

                            span {
                                white-space: pre-wrap;
                                display: inline-block;
                                margin-left: 1rem;
                                color: #57596C;
                            }
                        }

                        .file {
                            display: flex;
                            margin-top: 2rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #2F2C42;
                            line-height: 1.1875rem;

                            .file-box {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #57596C;
                                line-height: 1.1875rem;
                                margin-left: 1rem;
                                width: 15.0625rem;
                                height: 3.75rem;
                                background: #F7F7FC;
                                border-radius: .625rem .625rem .625rem .625rem;

                                img {
                                    margin-right: .5rem;
                                    display: block;
                                    width: 2.25rem;
                                    height: 2.25rem;
                                }

                                p {
                                    cursor: pointer;
                                    margin-left: 1rem;
                                    font-size: .875rem;
                                    font-family: PingFang SC-Regular, PingFang SC;
                                    font-weight: 100;
                                    color: #3A71FF;
                                    line-height: 1rem;
                                }
                            }
                        }

                    }

                    .btn {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        // margin-left: auto;
                        margin-top: 2rem;

                        p {
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #57596C;
                            line-height: 1.1875rem;
                        }

                        .el-button {
                            margin-left: auto;
                            width: 7rem;
                            height: 3.625rem;
                            background: #111029;
                            border-radius: 3.25rem 3.25rem 3.25rem 3.25rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #FFFFFF;
                            line-height: 1.1875rem;

                        }
                    }

                    .demandbtn {
                        margin-left: auto;
                        margin-top: 1rem;

                        .el-button {
                            width: 5rem;
                            height: 3.625rem;
                            background: #FFFFFF;
                            border-radius: 3.25rem 3.25rem 3.25rem 3.25rem;
                            border: 1px solid #111029;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #111029;
                            line-height: 1.1875rem;

                            &:nth-child(2) {
                                margin-left: 2rem;
                                background: #111029;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }

            .score {
                margin-left: auto;
                margin-top: 2rem;
                display: flex;

                p {
                    margin-right: 1rem;
                    font-size: 1.25rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2F2C42;
                    line-height: 1.4375rem;
                }

            }

            .edit {
                margin: 0 auto;

                .el-button {
                    width: 7rem;
                    height: 3.625rem;
                    background: #FFFFFF;
                    border-radius: 3.25rem 3.25rem 3.25rem 3.25rem;
                    border: 1px solid #111029;
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #111029;
                    line-height: 1.1875rem;
                    transition: all .5s ease;
                    &:hover{
                        background: #111029;
                        color: #FFFFFF;

                    }
                }
            }

            .agree-reject {
                margin: 0 auto;

                .el-button {
                    width: 5rem;
                    height: 3.625rem;
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #FFFFFF;
                    line-height: 1.1875rem;
                    background: #111029;
                    border-radius: 3.25rem 3.25rem 3.25rem 3.25rem;

                    &:nth-child(2) {
                        margin-left: 4rem;
                        background: #FFFFFF;
                        color: #111029;
                    }
                }
            }
        }

    }

    .refuse {
        .el-textarea {
            width: 39.125rem;
            min-height: 6.25rem;
            background: #EFF0F6;
            border-radius: .25rem .25rem .25rem .25rem;

            ::v-deep.el-textarea__inner {
                height: 6.25rem;
                background: #EFF0F6;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #111029;
                line-height: 1.1875rem;

                &::placeholder {
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #A0A3BD;
                    line-height: 1.1875rem;
                }

                &::-webkit-input-placeholder {
                    /* WebKit browsers 适配谷歌 */
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #A0A3BD;
                    line-height: 1.1875rem;
                }

                &:-moz-placeholder {
                    /* Mozilla Firefox 4 to 18 适配火狐 */
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #A0A3BD;
                    line-height: 1.1875rem;
                }

                &::-moz-placeholder {
                    /* Mozilla Firefox 19+ 适配火狐 */
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #A0A3BD;
                    line-height: 1.1875rem;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10+  适配ie*/
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #A0A3BD;
                    line-height: 1.1875rem;
                }
            }

            .el-input__count {
                background: #EFF0F6;
            }

        }

        .score-box {
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                font-size: 1.25rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #2F2C42;
                line-height: 1.4375rem;
            }
        }

        .refusebtn {
            margin: 2rem auto 0;
            width: 45%;
            display: flex;
            justify-content: space-between;

            .el-button {
                width: 4.25rem;
                height: 2.375rem;
                background: #FFFFFF;
                border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                opacity: 1;
                border: 1px solid #111029;
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #111029;
            }

            .sub {
                background: #111029;
                color: #FFFFFF;
            }



        }


    }

}

::v-deep .el-dialog__headerbtn {
    font-size: 1.875rem;
    font-weight: bold;
}

::v-deep .el-input__count {
    background: transparent;
}
</style>