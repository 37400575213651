<!--
* @description 测试测试
* @fileName liuliantest.vue
* @author liulian
* @date 2022/08/16 17:25:24
-->
<template>
  <div>
    <div id="container"></div>
  </div>
</template>

<script>
import { getWebOfficeURLApi, refreshWebOfficeTokenApi, navigationInfoApi, immSaveCallBackApi } from "@/api/api.js"

export default {
  name: "liuliantest",
  components: {},
  data() {
    return {
      isCan: false, // 是否可以请求
      timer: null, // 定时对象
      tokenInfo: {},
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      let _this = this
      let tokenInfo = await this.getToken()

      // this.tokenInfo = data
      if (tokenInfo) {
          // 缓存上次的tokenInfo，用于刷新token。
          let lastTokenInfo = tokenInfo
        //  刷新token
        const refreshToken = function () {
          let obj = {
            accessToken: lastTokenInfo.accessToken,
            refreshToken: lastTokenInfo.refreshToken,
          }
          return new Promise(function (resolve) {
            refreshWebOfficeTokenApi(obj).then(function (tokenInfo) {
              console.log("res???? ", tokenInfo)
              if (tokenInfo.code === 10000) {
                lastTokenInfo = tokenInfo.data
                resolve({
                  token: tokenInfo.data.accessToken, //必须设置。
                  timeout: 30 * 60 * 1000, //必须设置。token超时时间，单位为ms。可配合refreshToken配置函数使用，在超时前调用refreshToken重新刷新token。
                })
              }
            })
          })
        }
        // const freshToken = await _this.freshToken(data)
        let instance = aliyun.config({
          mount: document.querySelector("#container"),
          url: tokenInfo.webofficeURL, //即步骤2示例中的文档协作URL地址（tokenInfo.WebofficeURL）。
          mode: "normal",
          refreshToken,
          commandBars: [
            // 可以配置多个组件
            {
              cmbId: "WPPPcCommentButton", // 品论
              attributes: {
                visible: false, // 隐藏组件
                enable: false, // 禁用组件， 组件显示但不响应点击事件
              },
            },
            {
              cmbId: "HistoryVersion", // 历史版本
              attributes: {
                visible: false, // 隐藏组件
                enable: false, // 禁用组件， 组件显示但不响应点击事件
              },
            },

            {
              cmbId: "HeaderRight", // 历史记录
              attributes: {
                visible: false, // 隐藏组件
                enable: false, // 禁用组件， 组件显示但不响应点击事件
              },
            },
            {
              cmbId: "FloatQuickHelp", // 历史记录
              attributes: {
                visible: false, // 隐藏组件
                enable: false, // 禁用组件， 组件显示但不响应点击事件
              },
            },
            {
              cmbId: "WPPPcCommentButton", // 历史记录
              attributes: {
                visible: false, // 隐藏组件
                enable: false, // 禁用组件， 组件显示但不响应点击事件
              },
            },
            {
              cmbId: "HistoryRecord", // 历史记录
              attributes: {
                visible: false, // 隐藏组件
                enable: false, // 禁用组件， 组件显示但不响应点击事件
              },
            },
            {
              cmbId: "HeaderHistoryMenuBtn", // 历史记录
              attributes: {
                visible: false, // 隐藏组件
                enable: false, // 禁用组件， 组件显示但不响应点击事件
              },
            },
            {
              cmbId: "PreviewDownload", // 历史记录
              attributes: {
                visible: false, // 隐藏组件
                enable: false, // 禁用组件， 组件显示但不响应点击事件
              },
            },
            {
              cmbId: "Comment", // 历史记录
              attributes: {
                visible: false, // 隐藏组件
                enable: false, // 禁用组件， 组件显示但不响应点击事件
              },
            },
          ],
          commonOptions: {
            showHistoryLinkInSavingToast: false,
            isBrowserViewFullscreen: true,
            isIframeViewFullscreen: true,
          },
        })

        instance.setToken({
          token: tokenInfo.accessToken,
          timeout: 300 * 60 * 1000, //必须设置。token的超时时间，单位为ms。以10分钟示例说明。注意：JS-SDK会提前5分钟调用刷新token方法，所以设置的 timeout 应在10分钟以上（10 * 60 * 1000 以上，单位为毫秒），避免刷新过快
        })

        let num = 0 
        // 事件监听
        //文件打开成功或者失败时的事件回调
        instance.on("fileStatus", async (data) => {
          if (data.status === 1 || data.status === 7) {
            // example()
            if (num === 0) {
              num = num + 1
            } else {
              num = 0
            }

            if (num === 0) {
              // 判断文件大小是否超过200M

              //获取当前页码

              await instance.ready()
              const app = instance.Application
              const curryPage = await app.ActivePresentation.SlideShowWindow.View.Slide.SlideIndex

              if (data.status === 7) {
                let file = await instance.save()
                let size = 200 * 1024 * 1024
                if (!(file.size < size)) {
                  this.$message.error("文件大小超过200M，操作失败")
                }

                // 手动保存或者自动保存 7 ,1, 手动保存
                let obj = {
                  id: this.$route.query.id,
                  version: file.version,
                  // save_type:data.status === 7?1:2,
                  size: file.size,
                  page: curryPage,
                }

                // 对第一页做防抖
                if (curryPage === 1) {
                  if (!this.isCan) {
                    this.isCan = true
                    this.timer = setTimeout((res) => {
                      this.immSaveCallBack(obj)
                    }, 30000)
                  }
                } else {
                  this.immSaveCallBack(obj)
                }
                // if (!this.isCan) {
                //   this.isCan = true
                //   setTimeout((res) => {
                //     this.immSaveCallBack(obj)
                //   }, 30000)
                // }
                // this.immSaveCallBack(obj)
              }
            }
          }
        })
        // 文件打开回调函数返回文件信息
        // instance.on("fileOpen", (data) => {
        // })
        // pdf转换为图片
        async function example() {
          await instance.ready()
          const app = instance.Application

          //导出文件为图片
          // const pdfUrl = await app.ActivePresentation.ExportAsFixedFormat({
          //   FixedFormatType: app.Enum.PpFixedFormatType.ppFixedFormatTypeIMG,
          //   ImgFormat: app.Enum.PpExportImgFormatType.ppImgTypePNG,
          //   // RangeType:app.Enum.PpPrintRangeType.ppPrintSlideRange,
          // })

          //获取当前页码
          const curryPage = await app.ActivePresentation.SlideShowWindow.View.Slide.SlideIndex
        }
      
        

      }
    },

    /*
     * @Description: 文件文件
     * @MethodAuthor:  liulian
     * @Date: 2022-09-09 09:30:18
     * @param: ''
     */
    immSaveCallBack(obj) {
      immSaveCallBackApi(obj).then((res) => {
        if (res.code === 10000) {
          this.isCan = false
        }
      })
    },

    getData() {
      navigationInfoApi().then((res) => {
        if (res.code === 10000) {
        }
      })
    },
    // 刷新token
    freshToken(data) {
      let obj = {
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      }
      return new Promise((resolve, rejects) => {
        refreshWebOfficeTokenApi(obj).then((res) => {
          if (res.code === 10000) {
            resolve({
              token: res.data.AccessToken, //必须设置。
              timeout: 10 * 60 * 1000, //必须设置。token超时时间，单位为ms。可配合refreshToken配置函数使用，在超时前调用refreshToken重新刷新token。
            })
          }
        })
      })
    },

    // 获取token
    getToken() {
      return new Promise((resolve, rejects) => {
        getWebOfficeURLApi(this.$route.query.id).then((res) => {
          if (res.code === 10000) {
            resolve(res.data)
          }
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
#container {
  width: 100vw;
  height: 100vh;
}
</style>
