<template>
    <div class="project">
        <tabs :tabs="tabslist" @handleClick="handleClick"></tabs>
        <div class="tablist">
            <ul>
                <li v-for="item in tablist" :key="item.id" class="font" @click="onTablist(item)"
                    :class="pathactive == item.id ? 'active' : ''">
                    {{ item.name }}
                </li>
            </ul>
        </div>
        <creator :creator="projectlist" :tabsindex="tabsindex" @uploadata="uploadata"></creator>
        <!-- <demand v-if="tabsindex == 2"  :demand="projectlist"></demand> -->
        <div class="empty" v-show="emptyShow">
            <img src="@/assets/images/empty.png" alt="">
            <p>哎呀，这里什么也没有~快试试其他搜索词吧！</p>
        </div>
    </div>
</template>
<script>
import { ProjectList, creatorProject } from "@/api/api"
import creator from "./creator/creator.vue"
import tabs from "../../../components/tabs/tabs.vue"
// 我是需求方
import demand from "./demand/demand.vue"
export default {
    components: {
        tabs,
        creator,
        demand
    },
    data() {
        return {
            baseUrl: "https://web.social.lyunweb.com/grzx/demangdetail?id=",
            emptyShow: false,
            projectlist: [],
            tabsindex: 1,
            pathactive: -1,
            tablist: [
                {
                    id: -1,
                    name: "全部"
                }, {
                    id: 0,
                    name: "待确认"
                }, {
                    id: 1,
                    name: "执行中"
                }, {
                    id: 2,
                    name: "已结束"
                }
            ],
            tabslist: [
                {
                    id: "1",
                    name: "我是创作者"
                },
                {
                    id: "2",
                    name: "我是需求方"
                },

            ],
            query: {
                page: 1,
                limit: 10,
                type: 2, //1是需求方，2 代表我的创作者
            },
            total: ""
        }
    },
    mounted() {
        console.log(this.$store.state.userHomPage)
        if (this.$store.state.userHomPage.infor.is_official_admin == 1 && this.$store.state.userHomPage.infor.official_type == 1) {
            this.tabslist.push({
                id: "3",
                name: "机构创作者项目"
            })
        }
        var that_ = this
        this.getProjectList()
        this.$nextTick(() => {
            that_.scroll.sctollBot("", e => {
                if (e == true) {
                    console.log(this.projectlist.length, this.total)
                    if (this.projectlist.length < this.total) {
                        this.query.page += 1
                        ProjectList(this.query).then(res => {
                            if (res.code == 10000) {
                                if (res.data.length > 0) {
                                    this.emptyShow = false
                                } else {
                                    this.emptyShow = true
                                }
                                res.data.map(e => {
                                    // e.needName=e.needInfo.username
                                    e.copyurl = this.baseUrl + e.id
                                    that_.projectlist.push(e)
                                })
                            }


                        })
                    }
                }
            })
        })
    },
    methods: {
        uploadata() {
            this.getProjectList()
        },
        // 项目列表
        getProjectList() {
            var that_ = this
            ProjectList(this.query)
                .then(res => {
                    if (res.code == 10000) {
                        if (res.data.length > 0) {
                            this.emptyShow = false
                        } else {
                            this.emptyShow = true
                        }
                        var list = []
                        that_.total = res.count
                        res.data.map(e => {
                            // e.needName=e.needInfo.username
                            e.copyurl = this.baseUrl + e.id
                            console.log(e)
                            list.push(e)
                        })
                        that_.projectlist = list
                    }
                })
        },
        // 机构创作者项目
        getcreator() {
            // let obj={
            //     page:this.query.page,
            //     limit:this.query.limit
            // }
            creatorProject(this.query).then(res => {
                if (res.code == 10000) {
                    if (res.data.length > 0) {
                        this.emptyShow = false
                    } else {
                        this.emptyShow = true
                    }
                    this.total = res.count
                    this.projectlist = res.data
                }
            })
        },
        onTablist(e) {
            this.pathactive = e.id
            if (e.id === -1) {
                delete this.query.status
            } else {
                this.query.status = e.id
            }
            if (this.tabsindex == 1 || this.tabsindex == 2) {
                this.getProjectList()
            } else {
                this.getcreator()
            }

        },
        handleClick(e) {
            this.query.page=1
            this.tabsindex = e
            if (e == 1) {
                this.query.type = 2
                this.getProjectList()

            } else if (e == 2) {
                this.query.type = 1
                this.getProjectList()
            } else {
                this.getcreator()
            }

        }
    }
}
</script>
<style  lang="scss" scoped>
.project {
    padding: 1.5625rem 2rem;

    .empty {
        margin-top: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            width: 18.75rem;
            height: 12.5rem;
        }

        p {
            margin-top: 2rem;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #A0A3BD;
            line-height: 1.1875rem;
        }
    }

    .tablist {
        ul {
            display: flex;
            align-items: center;

            li {
                cursor: pointer;
                display: flex;
                margin: 0 .25rem;
                justify-content: center;
                align-items: center;
                padding: .25rem .625rem;
                background: #EFF0F6;
                border-radius: 1.1875rem 1.1875rem 1.1875rem 1.1875rem;
            }

            .active {
                border: 1px solid #111029;
                background: #FFFFFF;
                color: #240F45;
            }

            .font {
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 100;
                color: #5C6466;
                line-height: 1rem;
            }
        }
    }

}
</style>