<template>
    <div class="case">
        <div class="tab">
            <!-- 导航栏 -->
            <tabs :tabs="tabslist" @handleClick="handleClick"></tabs>
        </div>
        <div class="screen" v-if="activeIndex == 2">
            <ul>
                <li v-for="item in screen" :key="item.id" :class="screenindex == item.id ? 'activeli' : ''"
                    @click="onscreen(item)">{{ item.name }}</li>
            </ul>
        </div>
        <!--我的案例 -->
        <data-list :case="caseList" v-if="activeIndex == 1" @uploadCase="uploadCase"> </data-list>
        <!-- 审核记录 -->
        <record-list :record="recordList" v-else></record-list>
        <!-- 空状态 -->
        <div class="empty" v-show="emptyShow">
            <img src="@/assets/images/empty.png" alt="">
            <p>哎呀，这里什么也没有~快试试其他搜索词吧！</p>
        </div>
    </div>
</template>
<script>
import { caseList, exaimCseList } from "@/api/api"
import tabs from "../../../components/tabs/tabs.vue"
import dataList from './datalist.vue'
import recordList from "./recordlist.vue"
export default {
    components: {
        dataList,
        tabs,
        recordList

    },
    data() {
        return {
            scrollLoading: false,
            emptyShow: false,//空状态
            tabslist: [
                {
                    id: '1',
                    name: "我的案例",
                },
                {
                    id: '2',
                    name: "审核记录",
                },
            ],
            caseList: [],//审核成功
            recordList: [],//审核记录列表
            activeIndex: "1",
            screenindex: -1,
            screen: [
                {
                    id: -1,
                    name: "全部"
                },
                {
                    id: 0,
                    name: "审核中"
                },
                {
                    id: 1,
                    name: "审核通过"
                },
                {
                    id: 2,
                    name: "审核驳回"
                },

            ],
            query: {
                page:1,
                limit: 10
            },
            total: 0
        }
    },
    mounted() {
        this.getCase()
        let that_ = this
        that_.$nextTick(() => {
            that_.scroll.sctollBot("", (data) => {
                console.log(data)
                if (data == true) {
                    if (that_.caseList.length > 0 && that_.caseList.length < that_.total && that_.activeIndex == 1) {
                        that_.query.page += 1
                        caseList(this.query)
                            .then(res => {
                                if (res.code == 10000) {
                                    if (res.data.length > 0) {
                                        this.emptyShow = false
                                        res.data.map(e => {
                                            this.caseList.push(e)
                                        })
                                    } else {
                                        this.emptyShow = true

                                    }
                                    this.total = res.count
                                }
                            })
                    } else if ( that_.recordList.length < that_.total && that_.activeIndex == 2) {
                        that_.query.page += 1
                        let audit_status = ""
                        if (this.screenindex == -1) {
                            audit_status = ""
                        } else {
                            audit_status = this.screenindex
                        }
                        let query = {
                            page: this.query.page,
                            limit: this.query.limit,
                            audit_status: audit_status
                        }
                        exaimCseList(query)
                            .then(res => {
                                if (res.code == 10000) {
                                    if (res.data.length > 0) {
                                        this.emptyShow = false
                                        res.data.map(e => {
                                            this.recordList.push(e)
                                        })
                                    } else {
                                        this.emptyShow = true
                                    }

                                    this.total = res.count
                                }
                            })
                    }
                }
            })
        })

    },
    methods: {
        uploadCase() {
            this.getCase()
        },
        // 获取审核成功案例列表
        getCase() {
            caseList(this.query)
                .then(res => {
                    if (res.code == 10000) {
                        if (res.data.length > 0) {
                            this.emptyShow = false
                            this.scrollLoading = true
                        } else {
                            this.emptyShow = true
                            this.scrollLoading = false
                        }
                        this.total = res.count
                        this.caseList = res.data
                        console.log(this.caseList)
                    }
                })
        },
        // 获取案例记录列表
        getRecord() {
            let audit_status = ""
            if (this.screenindex == -1) {
                audit_status = ""
            } else {
                audit_status = this.screenindex
            }
            let query = {
                page: this.query.page,
                limit: this.query.limit,
                audit_status: audit_status
            }
            exaimCseList(query)
                .then(res => {
                    if (res.code == 10000) {
                        if (res.data.length > 0) {
                            this.emptyShow = false
                            this.scrollLoading = true
                        } else {
                            this.emptyShow = true
                            this.scrollLoading = false
                        }
                        this.recordList = res.data
                        this.total = res.count
                    }
                })
        },
        onscreen(item) {
            this.query.page = 1
            this.screenindex = item.id
            this.getRecord()
        },
        handleClick(e) {
            this.query.page = 1
            this.activeIndex = e
            if (e == 1) {
                this.getCase()
            } else {
                this.getRecord()
            }
        },
        // 当前菜单激活
        menuselect(e) {

        }
    }
}
</script>
<style  lang="scss" scoped>
.case {
    padding: 1.5625rem;

    ::v-deep .tab {
        margin-bottom: 1rem;
    }

    .empty {
        margin-top: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            width: 18.75rem;
            height: 12.5rem;
        }

        p {
            margin-top: 2rem;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #A0A3BD;
            line-height: 1.1875rem;
        }
    }

    .screen {
        margin-top: 1rem;
        margin-bottom: 1rem;

        ul {
            display: flex;
            align-items: center;

            li {
                cursor: pointer;

                margin: 0 .25rem;
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 100;
                color: #5C6466;
                line-height: 1rem;
                padding: .25rem .625rem;
                background: #EFF0F6;
                border-radius: 1.1875rem 1.1875rem 1.1875rem 1.1875rem;

                &:nth-child(1) {
                    margin-left: 0rem;
                }
            }

            .activeli {
                background: #FFFFFF;
                border: 1px solid #111029;
                transition: all .5s ease;
            }
        }
    }

}
</style>