<template>
    <div>
        <div class="datalist" v-for="item in list" :key="item.id" @click="onDetail(item)">
            <div class="userinfo">
                <div class="userinfo-left">
                    <div class="img-box">
                        <img class="avatar"
                            :src="item.module == 'case' ? item.caseInfo.creatorInfo.avatar : '' || item.module == 'article' ? item.articleInfo.increatorInfo.avatar : ''"
                            alt="">
                        <img src="@/assets/images/b.png" alt="" class="icon" v-if="item.creatorInfo.official_type == 1">
                        <img src="@/assets/images/y.png" alt="" class="icon" v-if="item.creatorInfo.official_type == 2">
                    </div>
                    <div class="lei">
                        <p>{{ item.module == 'case' ? item.caseInfo.creatorInfo.username : '' ||
                                item.module == 'article' ? item.articleInfo.increatorInfo.username : ''
                        }}</p>
                        <div class="lei_one">
                            <img src="@/assets/images/userprofile/V.png" alt="" class="leiimg">
                            <span>{{ item.module == 'case' ?
                                    item.caseInfo.creatorInfo.level_context : item.articleInfo.increatorInfo.level_context
                            }}</span>
                        </div>
                        <div class="lei_two">
                            {{ item.module == 'case' ?
                                    item.caseInfo.creatorInfo.marketer_role_context :
                                    item.articleInfo.increatorInfo.marketer_role_context
                            }}
                        </div>
                    </div>
                </div>
                <div class="userinfo-right" @click.stop="Cancle(item)">
                    <img src="../../../../assets/images/userprofile/fabulous.png" alt="">
                </div>
            </div>
            <div class="content">
                <div class="content-main">
                    <img :src="item.module == 'case' ? item.caseInfo.cover : ''" alt="" v-if="tabindex == 1">
                    <div :class="tabindex == 2 ? 'contenttwo' : 'content-tle'">
                        <p>{{ item.module == "article" ? item.articleInfo.title : item.caseInfo.name }}</p>
                        <span v-if="tabindex == 1">{{ item.module == 'case' ? item.caseInfo.introduction : '' }}</span>
                        <span v-else v-html="item.moduleInfo.content"></span>
                    </div>
                </div>
                <div class="content-bot">
                    <p>{{ item.created_at }}</p>
                    <div class="forward">
                        <div class="comment">
                            <!-- <img src="@/assets/images/userprofile/forward.png" alt=""> -->
                            <share @onMove="onMoves(item)" :QRCodeMsg="qrurl"></share>
                        </div>
                        <div class="comment">
                            <img src="@/assets/images/userprofile/comment.png" alt="">
                            <span>{{ item.module ==
                                    'case' ? item.caseInfo.commentTotal : item.articleInfo.commentTotal
                            }}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { cancleCollection, iscollect } from "@/api/api"
import share from "@/components/share/share.vue"
export default {
    components: {
        share
    },
    props: {
        tabindex: {
            type: Number / String,
            default: 0
        },
        datalist: {
            type: Array / String,
            default: []
        }
    },
    data() {
        return {
            qrurl: "",
            editlist: [
                {
                    name: "编辑",
                    value: 1
                },
                {
                    name: "删除",
                    value: 2
                }
            ],
            list: []
        }
    },
    watch: {
        datalist: {
            handler(data) {
                if (data) {
                    console.log(data)
                    this.list = data
                    console.log(this.list)
                }
            }

        }
    },
    methods: {
        onMoves(item) {
            console.log(item)

        },
        // 取消收藏
        Cancle(item) {
            // 
            console.log(item)
            let obj = {
                module: "",
                module_id: ""
            }
            if (item.module == 'case') {
                obj.module = 'case'
                obj.module_id = item.caseInfo.id
            } else {
                obj.module = 'article'
                obj.module_id = item.articleInfo.id
            }
            iscollect(obj).then(res => {
                if (res.code == 10000) {
                    this.$message.success("取消收藏！")
                    this.$emit("upadte")
                }
            })
            return
            cancleCollection(item.id)
                .then(res => {
                    if (res.code == 10000) {
                        this.$message.success("取消收藏！")
                        this.$emit("upadte")
                    }
                })
        },
        onDetail(item) {
            console.log(item)
            if (item.module == 'article') {
                this.$router.push({ path: '/wdwz/articledetail', query: { id: item.moduleInfo.id, status: 0 } })
            } else {
                this.$router.push({ path: '/zbkalDetail', query: { id: item.caseInfo.id } })
            }
        },

    },
}
</script>
<style lang="scss" scoped>
.datalist {
    cursor: pointer;
    margin-top: 1rem;
    padding: 2rem 0;
    border-top: 1px solid #EFF0F6;

    .userinfo {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .userinfo-left {
            display: flex;
            align-items: center;

            .img-box {
                position: relative;

                .avatar {
                    border-radius: 50%;
                    display: block;
                    width: 2.5rem;
                    height: 2.5rem;
                    opacity: 1;
                }

                .icon {
                    display: block;
                    width: .8125rem;
                    height: .8125rem;
                    position: absolute;
                    bottom: .125rem;
                    right: 0;
                }
            }

            .lei {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                margin: .5rem 0;

                p {
                    font-size: 1.125rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1.3125rem;
                    margin: 0 .5rem;
                }

                .lei_one {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 2.625rem;
                    height: 1.0625rem;
                    background: #FFEFF0;
                    border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                    opacity: 1;

                    .leiimg {
                        display: block;
                        width: .5rem !important;
                        height: .5625rem !important;
                    }

                    span {
                        font-size: .75rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #DC2B2B;
                        line-height: .875rem;
                    }
                }

                .lei_two {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: .5625rem;
                    width: auto;
                    padding: 0 .3125rem;
                    height: 1.0625rem;
                    background: #DEF2E6;
                    border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                    opacity: 1;
                    font-size: .75rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #11845B;
                    line-height: .875rem;

                }
            }
        }


        .userinfo-right {
            display: flex;
            align-items: center;

            .el-dropdown {
                cursor: pointer;

            }


        }

    }

    .content {
        margin-top: 1.4375rem;
        padding-left: 3.125rem;

        .content-main {
            display: flex;
            align-items: center;

            img {
                display: block;
                flex-shrink: 0;
                width: 8.125rem;
                height: 8.125rem;
                border-radius: .625rem .625rem .625rem .625rem;
            }

            .content-tle {
                margin-left: 1rem;
                flex: 1;

                p {
                    font-size: 1rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1.1875rem;
                    margin: 1rem 0;
                }

                span {
                    font-size: .875rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #57596C;
                    line-height: 1rem;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                }

            }

            .contenttwo {
                margin-left: 0rem;

                span {
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }


        }

        .content-bot {
            margin-top: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #A0A3BD;
                line-height: 1rem;
            }

            .forward {
                display: flex;
                align-items: center;

                .comment {
                    display: flex;
                    align-content: center;

                    span {
                        display: inline-block;
                        margin-left: .3125rem;
                        font-size: .875rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #A0A3BD;
                        line-height: 1rem;
                    }

                    &:nth-child(1) {
                        margin-right: 4.5625rem;
                    }

                    ::v-deep.share-box {
                        right: 0;
                    }
                }
            }
        }
    }
}

.el-dropdown-menu {

    padding: 1rem 0;
    background: #FFFFFF;
    box-shadow: 0px .125rem .375rem .0625rem rgba(20, 20, 43, 0.06);
    border-radius: .625rem .625rem .625rem .625rem;

    .el-dropdown-menu__item {
        font-size: .875rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 100;
        color: #57596C;
        line-height: 1rem;
        margin: .375rem 0;
    }
}
</style>