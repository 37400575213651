<template>
  <div id="viewFile" ref="viewFiles"></div>
</template>
<script>
import axios from "axios"
export default {
  props: ["wpsUrl", "token"],

  data() {
    return {
      // 是否开启简易模式
      simpleMode: false,
      app: null,
    }
  },
  mounted() {
    this.openWps(this.wpsUrl, this.token)
  },

  methods: {
    async openWps(url, token) {
      let _this = this
      const wps = _this.wps.config({
        mode: _this.simpleMode ? "simple" : "normal",
        mount: document.querySelector("#app"),
        wpsUrl: url,
        commandBars: [
          // 可以配置多个组件
          {
            cmbId: "WPPPcCommentButton", // 品论
            attributes: {
              visible: false, // 隐藏组件
              enable: false, // 禁用组件， 组件显示但不响应点击事件
            },
          },
          {
            cmbId: "HistoryVersion", // 历史版本
            attributes: {
              visible: false, // 隐藏组件
              enable: false, // 禁用组件， 组件显示但不响应点击事件
            },
          },

          {
            cmbId: "HeaderRight", // 历史记录
            attributes: {
              visible: false, // 隐藏组件
              enable: false, // 禁用组件， 组件显示但不响应点击事件
            },
          },
          {
            cmbId: "FloatQuickHelp", // 历史记录
            attributes: {
              visible: false, // 隐藏组件
              enable: false, // 禁用组件， 组件显示但不响应点击事件
            },
          },
          {
            cmbId: "WPPPcCommentButton", // 历史记录
            attributes: {
              visible: false, // 隐藏组件
              enable: false, // 禁用组件， 组件显示但不响应点击事件
            },
          },
          {
            cmbId: "HistoryRecord", // 历史记录
            attributes: {
              visible: false, // 隐藏组件
              enable: false, // 禁用组件， 组件显示但不响应点击事件
            },
          },
          {
            cmbId: "HeaderHistoryMenuBtn", // 历史记录
            attributes: {
              visible: false, // 隐藏组件
              enable: false, // 禁用组件， 组件显示但不响应点击事件
            },
          },
          {
            cmbId: "PreviewDownload", // 历史记录
            attributes: {
              visible: false, // 隐藏组件
              enable: false, // 禁用组件， 组件显示但不响应点击事件
            },
          },
          {
            cmbId: "Comment", // 历史记录
            attributes: {
              visible: false, // 隐藏组件
              enable: false, // 禁用组件， 组件显示但不响应点击事件
            },
          },
        ],
      })
      wps.setToken({ token })

      await wps.ready()
      // let app = wps.Application
      _this.app = wps.Application

      // 定制元素对象【开始 Tab】
      const controls = await _this.app.CommandBars("InsertTab").Controls

      // 添加 1 个定制元素：按钮，并设置标题
      // const controlButton = await controls.Add(1)
      // controlButton.Caption = "按钮"
      // controlButton.Picture = "https://files.catbox.moe/iwiysw.jpg"
      // controlButton.TooltipText = "立即保存"
      // controlButton.OnAction = () => {
      //   async function example() {
      //     // 保存文件的改动
      //     console.log(_this.app);
      //   // const save = await _this.app.ActiveDocument.Save()
      //   console.log('%c [ save ]-114', 'font-size:13px; background:pink; color:#bf2c9f;', save)
      //   }
      //   example()
      // }

      // 添加 1 个定制元素：下拉框，并设置标题
    },
  },
  saveHandle() {
    let _this = this
    // const save = await _this.app.ActiveDocument.Save()
  },
}
</script>
