<!--
* @description 444
* @fileName swiper.vue
* @author liulian
* @date 2022/06/10 18:22:29
-->
<template>
  <div class="czz">
    <div class="title screenWidth font-size-20 font-weight-500">该机构下的创作者</div>
    <div class="swiper-container screenWidth">
      <div class="swiper-wrapper" ref="swiper">
        <div class="swiper-slide swiper-slide1" :class="index === listData.length - 1 ? 'swiper-no-swiping' : ''" v-for="(item, index) in listData" :key="index">
          <avatarCard :item="item" @click.native="getDetal(item)"></avatarCard>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper" // 注意引入的是Swiper
import "swiper/css/swiper.min.css" // 注意这里的引入
import avatarCard from "@/components/common/avatar-card.vue"
export default {
  name: "CarouselShow",
  components: {
    avatarCard,
  },
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    let _this = this
    new Swiper(".swiper-container", {
      slidesPerView: 5.6,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      calculateHeight: true,
      cssWidthAndHeight: true,
    })
  },
  data() {
    return {
      lists: [],
    }
  },
  created() {},
  methods: {
    getDetal(item) {
      this.$emit("val", item)
    },
  },
}
</script>

<style scoped lang="scss">
.czz {
  // height: 29.125rem;
  background: #111029;
  background-image: url("../../../assets/images/yxyxs/heisebg@2x.png");
  padding-top: 2rem;
  .title {
    color: #ffffff;
    margin: auto;
    margin-bottom: 2rem;
    padding-left: 1rem;
  }
}
.swiper-container {
  margin: auto;
  ::v-deep .swiper-slide {
    // height: 22.8125rem;
    // margin-right: 0.8rem;
    // margin-left: 1rem;
    border-radius: 0.625rem;
    width: 17.375rem !important;
    // img {
    //   width: 100%;
    //   height: 100%;
    //   border-radius: 0.625rem;
    // }
  }
  ::v-deep .swiper-button-prev {
    background-image: url("../../../assets/images/bkal/left@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 2.375rem !important;
    height: 2.375rem !important;
  }
  ::v-deep .swiper-button-prev::after {
    display: none;
    width: 2.375rem;
    height: 2.375rem;
  }
  ::v-deep .swiper-button-next {
    background-image: url("../../../assets/images/bkal/right@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 2.375rem !important;
    height: 2.375rem !important;
  }
  ::v-deep .swiper-button-next::after {
    display: none;
    width: 2.375rem;
    height: 2.375rem;
  }

  .swiper_btn {
    width: 20px;
    height: 20px;
    background-size: contain;
  }
  ::v-deep .card {
    width: 15.625rem;
    // height: 22.4375rem;
  }
}
</style>
