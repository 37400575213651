<template>
    <div>
        <div class="datalist" v-for="item in datalist" :key="item.id" @click="onDetail(item)">
            <div class="userinfo">
                <div class="userinfo-left">
                    <img class="avatar" :src="item.avatar" alt="">
                    <div class="lei">
                        <p>{{ item.username }}</p>
                        <div class="lei_one">
                            <img src="@/assets/images/userprofile/V.png" alt="" class="leiimg">
                            <span>{{item.level_context}}</span>
                        </div>
                        <div class="lei_two">
                            {{item.marketer_role_context}}
                        </div>
                    </div>
                </div>
                <div class="userinfo-right" @click.stop="cancleSgn(item)">
                    <el-button class="calcle-sign" >取消报名</el-button>
                </div>
            </div>
            <div class="content">
                <div class="content-main">
               
                    <div class="content-tle">
                        <p>{{item.demand_name}}</p>
                        <span>{{item.demand_introduction}}</span>
                    </div>
                </div>
                <div class="content-bot">
                    <p>{{item.created_at}}</p>
                    <div class="forward">
                        <div class="comment">
                            <!-- <img src="@/assets/images/userprofile/forward.png" alt=""> -->
                            <share @onMove="onMoves(item)" :QRCodeMsg="qrurl"></share>
                        </div>
                        <div class="comment">
                            <img src="@/assets/images/userprofile/comment.png" alt="">
                            <span>12</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
         <dialogs :show.sync="showDialog" width="690px" :center="true" title="取消报名">
            <p>是否取消报名？</p>
            <template slot="footer-slot">
                <el-button class="cancle"  @click="cancle">
                    取消
                </el-button>
                <el-button class="determine" @click="determine" >
                    确认
                </el-button>
            </template>
        </dialogs>
    </div>
</template>
<script>
import {cancle} from "@/api/api"
import share from "@/components/share/share.vue"
import dialogs from "@/components/common/dialog-cpn.vue"

export default {
    components:{
        share,
        dialogs
    },
    props:{
        sign:{
            type:Array/String,
            default:"",
        },
    },
    data() {
        return {
            cancleId:"",
             showDialog:false,
            qrurl:"",
            editlist: [
                {
                    name: "编辑",
                    value: 1
                },
                {
                    name: "删除",
                    value: 2
                }
            ],
            datalist: []
        }
    },
    watch:{
        sign:{
            handler(data){
               if(data){
                this.datalist=data
               }
            }
        }
    },
    methods:{
        // 报名详情
        onDetail(item){
            this.$router.push({path:"/xqdtDetail",query:{id:item.demand_id}})
        },
        // 取消报名
        cancleSgn(item) {
            this.showDialog=true
            this.cancleId=item.join_id
        },
        cancle(){
            this.showDialog=false
        },
        determine(){
            cancle(this.cancleId)
            .then(res=>{
                if(res.code==10000){
                    this.$message.success(res.message)
                    this.showDialog=false
                    this.$emit("refreshSign")


                }
            })
           
        },
        onMoves(item) {
            console.log(item)
            this.qrurl = item.codeurl
        },
    },
}
</script>
<style lang="scss" scoped>
    ::v-deep .el-dialog {
        .el-dialog__body {
            p {
                text-align: center;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 300;
                color: #5C6466;
                line-height: 1.1875rem;
            }

        }
        .el-dialog__footer {
           .dialog-footer {
                .cancle {
                    width: 4.25rem;
                    height: 2.375rem;
                    font-size: .875rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1rem;
                    background: #FFFFFF;
                    border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                    border: 1px solid #111029;
                }
                .determine {
                    margin-left: 11.375rem;
                     width: 4.25rem;
                    height: 2.375rem;
                    font-size: .875rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                   color: #FFFFFF;
                    line-height: 1rem;
                    background: #111029;
                    border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                    border: 1px solid #111029;
                }
            }
        }
    }
.datalist {
    padding: 2rem 0;
    border-top: 1px solid #EFF0F6;
    cursor: pointer;
    .userinfo {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .userinfo-left {
            display: flex;
            align-items: center;

            .avatar {
                border-radius: 50%;
                display: block;
                width: 2.5rem;
                height: 2.5rem;
                opacity: 1;
            }

            .lei {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                margin: .5rem 0;

                p {
                    font-size: 1.125rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1.3125rem;
                    margin: 0 .5rem;
                }

                .lei_one {
                    display: flex;
                    align-items: center;
                    width: 2.625rem;
                    height: 1.0625rem;
                    background: #FFEFF0;
                    border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                    opacity: 1;

                    .leiimg {
                        display: block;
                        width: .5rem !important;
                        height: .5625rem !important;
                    }

                    span {
                        font-size: .75rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #DC2B2B;
                        line-height: .875rem;
                    }
                }

                .lei_two {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: .5625rem;
                    width: auto;
                    padding: 0 .1875rem;
                    height: 1.0625rem;
                    background: #DEF2E6;
                    border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                    opacity: 1;
                    font-size: .75rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #11845B;
                    line-height: .875rem;
                }
            }
        }


        .userinfo-right {
            display: flex;
            align-items: center;

            .cancleSgn {
                cursor: pointer;
                width: 6rem;
                height: 2.375rem;
                background: #FFFFFF;
                border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                border: 1px solid #2F2C42;
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #2F2C42;
                line-height: 1rem;
            }

            .el-dropdown {
                cursor: pointer;

            }


        }

    }

    .content {
        margin-top: 1.4375rem;
        padding-left: 3.125rem;

        .content-main {
            display: flex;
            align-items: center;

            img {
                display: block;
                flex: 1;
                width: 8.125rem;
                height: 8.125rem;
                border-radius: .625rem .625rem .625rem .625rem;
            }

            .content-tle {
            
                p {
                    font-size: 1rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1.1875rem;
                    margin: 1rem 0;
                }

                span {
                    font-size: .875rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #57596C;
                    line-height: 1rem;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

            }


        }

        .content-bot {
            margin-top: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #A0A3BD;
                line-height: 1rem;
            }

            .forward {
                display: flex;
                align-items: center;

                .comment {
                    display: flex;
                    align-content: center;

                    span {
                        display: inline-block;
                        margin-left: .3125rem;
                        font-size: .875rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #A0A3BD;
                        line-height: 1rem;
                    }

                    &:nth-child(1) {
                        margin-right: 4.5625rem;
                    }
                     ::v-deep.share-box {
                        right: 0;
                    }
                }
            }
        }
    }
}

.el-dropdown-menu {

    padding: 1rem 0;
    background: #FFFFFF;
    box-shadow: 0px .125rem .375rem .0625rem rgba(20, 20, 43, 0.06);
    border-radius: .625rem .625rem .625rem .625rem;

    .el-dropdown-menu__item {
        font-size: .875rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 100;
        color: #57596C;
        line-height: 1rem;
        margin: .375rem 0;
    }
}
</style>