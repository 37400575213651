<!--
* @description 其他通知
* @fileName other.vue
* @author liulian
* @date 2022/09/09 14:26:28
-->
<template>
    <div class="partake">
      <div class="partake-top">
        <p>其他通知</p>
        <!-- <p>全部标为已读</p> -->
      </div>
      <div class="partake-list">
        <ul v-if="!showPage">
          <loadingMoreVue :completed="completed" :loadingIcon="loadingIcon" @changeData="loadMore">
            <template v-for="item in list">
              <li v-if="item.module === 'demand'">
                <img src="@/assets/images/userprofile/notify.png" alt="" />
                <div class="content">
                  <div class="tle-date">
                    <el-badge is-dot :hidden="item.isRead">
                      <p>系统消息</p>
                    </el-badge>
                    <p>{{ item.created_at }}</p>
                  </div>
                  <div class="con">
                    <p>您发布的需求【{{ item.name }}】收到一个新的报名申请：【{{ item.username }}】，报名角色 -【{{ item.recruit_role === 1 ? "个人" : "机构" }}】。</p>
                    <p v-if="item.flag == false" @click="onDetail(1)">查看报名信息</p>
                    <p v-else @click="goDetail(item)">查看</p>
                  </div>
                  <div class="con">
                    <p class="description">
                      <span>报名信息：</span>
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </li>
              <!-- 人才推荐 -->
              <li v-if="item.module === 'demand_recommend'">
                <img src="@/assets/images/userprofile/notify.png" alt="" />
                <div class="content">
                  <div class="tle-date">
                    <el-badge is-dot :hidden="item.isRead">
                      <p>系统消息</p>
                    </el-badge>
                    <p>{{ item.created_at }}</p>
                  </div>
                  <div class="con">
                    <p>您好，平台为您推荐的需求【{{ item.demand_name }}】推荐了匹配人才</p>
                    <p @click="talentRe(item)">查看</p>
                  </div>
                </div>
              </li>
  
              <!-- 注册 -->
              <li v-if="item.module === 'register'">
                <img src="@/assets/images/userprofile/notify.png" alt="" />
                <div class="content">
                  <div class="tle-date">
                    <el-badge is-dot :hidden="item.isRead">
                      <p>系统消息</p>
                    </el-badge>
                    <p>{{ item.created_at }}</p>
                  </div>
                  <div class="con">
                    <p>{{ item.message }}</p>
                  </div>
                </div>
              </li>
  
              <!-- 审核通过 -->
              <li v-if="item.module === 'registerPass'">
                <img src="@/assets/images/userprofile/notify.png" alt="" />
                <div class="content">
                  <div class="tle-date">
                    <el-badge is-dot :hidden="item.isRead">
                      <p>系统消息</p>
                    </el-badge>
                    <p>{{ item.created_at }}</p>
                  </div>
                  <div class="con">
                    <p>{{ item.message }}</p>
                    <!-- <p @click="goDetail(item)">查看</p> -->
                  </div>
                </div>
              </li>
              <!-- 未通过 -->
              <li v-if="item.module === 'demand_refuse'">
                <img src="@/assets/images/userprofile/notify.png" alt="" />
                <div class="content">
                  <div class="tle-date">
                    <el-badge is-dot :hidden="item.isRead">
                      <p>系统消息</p>
                    </el-badge>
                    <p>{{ item.created_at }}</p>
                  </div>
                  <div class="con">
                    <p>{{ item.message }}</p>
                    <!-- <p @click="goDetail(item)">查看</p> -->
                  </div>
                </div>
              </li>
            </template>
          </loadingMoreVue>
        </ul>
        <!-- 空状态 -->
        <emptyPageVue v-else :config="config"></emptyPageVue>
      </div>
    </div>
  </template>
  <script>
  import emptyPageVue from "@/components/common/empty-page.vue"
  import { messageApi, clearCountApi } from "@/api/api.js"
  import loadingMoreVue from "@/components/common/loadingMore.vue"
  export default {
    components: {
      emptyPageVue,
      loadingMoreVue,
    },
    data() {
      return {
        list: [],
        config: {
          emptyImage: require("@/assets/images/home/empty@2x.png"),
          description: "",
        },
        queryInfo: {
          type: "other",
          page: 1,
          limit: 10,
        },
        total: 0,
        completed: false,
        loadingIcon: true,
        showPage: false,
      }
    },
    created() {
      this.getList()
      this.clearMsg()

    },
    mounted() {
    },
    methods: {
      /*
       * @Description: 清空消息
       * @MethodAuthor:  liulian
       * @Date: 2022-08-18 11:21:13
       * @param: ''
       */
      clearMsg() {
        clearCountApi({ module: "other" }).then((res) => {
          if (res.code === 10000) {
            this.$store.dispatch("setMsgCount", "other")
          }
        })
      },
  
      talentRe(item) {
        if (item.demandTag && item.demandTag.length === 0) {
          this.$message.warning("没有可推荐的人才")
          return
        }
        sessionStorage.setItem("demandTag", JSON.stringify(item.demandTag))
        this.$router.push("/personnel")
      },
      /*
       * @Description: 加载更多
       * @MethodAuthor:  liulian
       * @Date: 2022-07-26 15:18:31
       * @param: ''
       */
      async loadMore(e) {
        let page = Math.ceil(this.total / this.queryInfo.limit)
        if (e > page) {
          this.completed = true
          this.loadingIcon = false
          return
        }
        this.queryInfo.page = e
        let data = await this.listAPi()
        setTimeout((res) => {
          this.list = this.list.concat(data)
        }, 2000)
      },
      async getList() {
        this.list = await this.listAPi()
      },
      listAPi() {
        return new Promise((resolve, reject) => {
          messageApi(this.queryInfo).then((res) => {
            if (res.code === 10000) {
              this.total = res.count
              this.showPage = res.data && res.data.length === 0
              if (res.count < this.queryInfo.limit) {
                this.completed = true
                this.loadingIcon = false
              }
              res.data.length > 0 &&
                res.data.map((item) => {
                  if (item.module === "demand") {
                    item.created_at = item.created_at
                    item.name = item.demandJoinInfo.demandInfo && item.demandJoinInfo.demandInfo.name
                    item.username = item.fromInfo.username
                    item.marketer_role_context = item.fromInfo.marketer_role_context
                    item.description = item.demandJoinInfo.description
                    item.recruit_role = item.demandJoinInfo.demandInfo && item.demandJoinInfo.demandInfo.recruit_role
                  }
                  // 需求推荐人才
                  // if (item.module === "demand_recommend") {
                  //   item.created_at = item.created_at
                  //   item.name = item.demandJoinInfo.demandInfo && item.demandJoinInfo.demandInfo.name
                  //   item.username = item.fromInfo.username
                  //   item.marketer_role_context = item.fromInfo.marketer_role_context
                  //   item.description = item.demandJoinInfo.description
                  //   item.recruit_role = item.demandJoinInfo.demandInfo && item.demandJoinInfo.demandInfo.recruit_role
                  // }
                })
              resolve(res.data)
            }
          })
        })
      },
      /*
       * @Description: 跳转需求详情
       * @MethodAuthor:  liulian
       * @Date: 2022-07-21 11:14:35
       * @param: ''
       */
      goDetail(item) {
        if (!(item.demandJoinInfo.demandInfo && item.demandJoinInfo.demandInfo.id)) {
          // 该需求不存在
          this.$message.warning("该需求不存在或已被销毁")
          return
        }
        this.$router.push(`/xqdtDetail?id=${item.demandJoinInfo.demandInfo.id}`)
      },
      onDetail() {
        this.$router.push("/personnel")
      },
    },
  }
  </script>
  <style lang="scss" scoped>
  .partake {
    padding: 1rem 2rem;
  
    .partake-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eff0f6;
  
      p {
        font-size: 1rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #111029;
        line-height: 1.1875rem;
        padding-bottom: 1rem;
  
        &:nth-child(2) {
          cursor: pointer;
        }
      }
    }
  
    .partake-list {
      ul {
        display: flex;
        width: 100%;
        flex-direction: column;
        li {
          width: 100%;
          display: flex;
          padding: 2rem 0;
          border-bottom: 1px solid #eff0f6;
          img {
            display: block;
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
          }
  
          .content {
            margin-left: 2rem;
            flex: 1;
            .tle-date {
              display: flex;
              align-items: flex-end;
              padding-bottom: 1rem;
  
              p {
                font-size: 1.125rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #111029;
                line-height: 1.3125rem;
  
                &:nth-child(2) {
                  margin-left: 1rem;
                  font-size: 0.875rem;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 100;
                  color: #a0a3bd;
                  line-height: 1rem;
                }
              }
            }
  
            .con {
              display: flex;
              align-items: center;
  
              p {
                font-size: 0.875rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #57596c;
                line-height: 1rem;
  
                &:nth-child(2) {
                  margin-left: auto;
                  cursor: pointer;
                  font-size: 1rem;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 100;
                  color: #3a71ff;
                  line-height: 1.1875rem;
                }
              }
              .description {
                margin-top: 0.625rem;
              }
            }
          }
        }
      }
    }
  }
  </style>
  
