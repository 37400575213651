<template>
    <div class="demand">
        <tabs :tabs="tabslist" @handleClick="handleClick"
            :style="{ paddingBottom: tabindex == 1 || tabindex == 2 ? '1.5625rem' : '' }"></tabs>
        <div class="screen" v-if="tabindex == 3">
            <ul>
                <li v-for="item in screen" :key="item.id" :class="screenindex == item.id ? 'activeli' : ''"
                    @click="onscreen(item)">{{ item.name }}</li>
            </ul>
        </div>
        <div v-if="tabindex == 1">
            <to-examine :listdata="datalist" @UploadList="UploadList" :tabid="tabindex"></to-examine>
        </div>
        <div v-if="tabindex == 3">
            <demanglist :demandlist="underdatalist" :tabid="tabindex"></demanglist>
        </div>
        <div v-if="tabindex == 2">
            <sign :sign="signList" @refreshSign="refreshSign"></sign>
        </div>
        <!-- 空状态 -->
        <div class="empty" v-show="emptyShow">
            <img src="@/assets/images/empty.png" alt="">
            <p>哎呀，这里什么也没有~快试试其他搜索词吧！</p>
        </div>
        <!-- loading加载 -->
        <div v-loading="loading"></div>
    </div>

</template>
<script>
import { UnderDemandList, myDemanList, signList } from "@/api/api"
import tabs from "../../../components/tabs/tabs.vue"
import toExamine from "./list/list.vue"
// 我的发布
import demanglist from './relepase/relepase.vue'
// 我的报名
import sign from "./wdbm/wdbm.vue"
export default {
    components: {
        tabs,
        toExamine,
        demanglist,
        sign,
        // 
    },
    data() {
        return {
            loading: false,
            emptyShow: false,
            datalist: [],//审核成功列表
            underdatalist: [],//审核记录列表
            signList: [],//我的报名列表
            tabindex: "1",
            tabslist: [
                {
                    id: "1",
                    name: "我的需求"
                },
                {
                    id: "2",
                    name: "我的报名"
                },
                {
                    id: "3",
                    name: "审核记录"
                }
            ],
            screenindex: -1,
            screen: [
                {
                    id: -1,
                    name: "全部"
                },
                {
                    id: 0,
                    name: "审核中"
                },
                {
                    id: 1,
                    name: "审核通过"
                },
                {
                    id: 2,
                    name: "审核驳回"
                },

            ],
            query: {
                page: 1,
                limit: 10,
            },
            total: ""//总数
        }
    },
    mounted() {
        this.getDemandList()
        this.$nextTick(() => {
            this.scroll.sctollBot("", (data) => {
                if (data == true) {
                    if (this.datalist.length < this.total && this.tabindex == 1) {
                        this.query.page += 1
                        myDemanList(this.query)
                            .then(res => {
                                if (res.code == 10000) {
                                    if (res.data.length > 0) {
                                        this.emptyShow = false
                                        res.data.map(e => {
                                            this.datalist.push(e)
                                        })
                                    } else {
                                        this.emptyShow = true
                                    }
                                    this.total = res.count
                                    this.loading = false
                                }
                            })
                    } else if (this.underdatalist.length < this.total && this.tabindex == 3) {
                        this.query.page += 1
                        let audit_status = ""
                        if (this.screenindex == -1) {
                            audit_status = ""
                        } else {
                            audit_status = this.screenindex
                        }
                        let query = {
                            page: this.query.page,
                            limit: this.query.limit,
                            audit_status: audit_status
                        }
                        UnderDemandList(query)
                            .then(res => {
                                if (res.code == 10000) {
                                    if (res.data.length > 0) {
                                        this.emptyShow = false
                                        res.data.map(e => {
                                            this.underdatalist.push(e)
                                        })
                                    } else {
                                        this.emptyShow = true
                                    }
                                    this.total = res.count
                                    this.loading = false
                                }
                            })
                    } else if (this.signList.length < this.total && this.tabindex == 2) {
                        this.query.page += 1
                        signList(this.query)
                            .then(res => {
                                if (res.code == 10000) {
                                    if (res.data.length > 0) {
                                        this.emptyShow = false
                                        res.data.map(e => {
                                            this.signList.push(e)
                                        })
                                    } else {
                                        this.emptyShow = true
                                    }
                                    this.total = res.count
                                    this.loading = false
                                }
                            })
                    }
                }
            })
        })

    },
    methods: {
        UploadList() {
            this.getDemandList()
        },
        refreshSign() {
            this.getSignUpList()
        },
        // 获取我的需求列表（审核成功）
        getDemandList() {
            myDemanList(this.query)
                .then(res => {
                    if (res.code == 10000) {
                        if (res.data.length > 0) {
                            this.emptyShow = false
                        } else {
                            this.emptyShow = true
                        }
                        this.datalist = res.data
                        console.log(this.datalist)
                        this.total = res.count
                        this.loading = false
                    }
                })
        },
        // 获取审核中的列表
        getUndeReview() {
            let audit_status = ""
            if (this.screenindex == -1) {
                audit_status = ""
            } else {
                audit_status = this.screenindex
            }
            let query = {
                page: this.query.page,
                limit: this.query.limit,
                audit_status: audit_status
            }
            UnderDemandList(query)
                .then(res => {
                    if (res.code == 10000) {
                        if (res.data.length > 0) {
                            this.emptyShow = false
                        } else {
                            this.emptyShow = true
                        }
                        this.underdatalist = res.data
                        console.log(this.datalist)
                        this.total = res.count
                        this.loading = false
                    }
                })
        },
        // 我的报名列表
        getSignUpList() {
            signList(this.query)
                .then(res => {
                    if (res.code == 10000) {
                        if (res.data.length > 0) {
                            this.emptyShow = false
                        } else {
                            this.emptyShow = true
                        }
                        this.signList = res.data
                        this.total = res.count
                        this.loading = false
                    }
                })

        },
        onscreen(item) {
            this.query.page = 1
            this.screenindex = item.id
            this.getUndeReview()
        },
        // 切换tab栏
        handleClick(e) {
            console.log(e)
            this.tabindex = e
            this.query.page = 1
            if (e == 1) {
                this.getDemandList()
            } else if (e == 3) {
                this.getUndeReview()
            } else {
                this.getSignUpList()
            }
        }
    }
}
</script>
<style  lang="scss" scoped>
.demand {
    padding: 1.5625rem 3rem;

    .screen {
        margin-top: 1rem;
        margin-bottom: 1rem;

        ul {
            display: flex;
            align-items: center;

            li {
                cursor: pointer;

                margin: 0 .25rem;
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 100;
                color: #5C6466;
                line-height: 1rem;
                padding: .25rem .625rem;
                background: #EFF0F6;
                border-radius: 1.1875rem 1.1875rem 1.1875rem 1.1875rem;

                &:nth-child(1) {
                    margin-left: 0rem;
                }
            }

            .activeli {
                background: #FFFFFF;
                border: 1px solid #111029;
                transition: all .5s ease;
            }
        }
    }

    .empty {
        margin-top: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            width: 18.75rem;
            height: 12.5rem;
        }

        p {
            margin-top: 2rem;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #A0A3BD;
            line-height: 1.1875rem;
        }
    }
}
</style>