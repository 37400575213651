<!--
* @description 节流防抖测试
* @fileName jieliufangdou.vue
* @author liulian
* @date 2022/09/13 10:45:31
-->
<template>
  <div>
    <el-button @click="clickHandle">点击</el-button>
  </div>
</template>

<script>
import { getUserApi } from "@/api/api"
export default {
  name: "jieliufangdou",
  components: {},
  data() {
    return {
      isCan: false, // 是否可以请求
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    clickHandle() {
      this.getInfo()
    },
    getInfo() {
      if (!this.isCan) {
        this.isCan = true
        setTimeout((res) => {
          getUserApi().then((res) => {
            if (res.code === 10000) {
              this.$message.success("请求成功")
              this.isCan = false
            }
          })
        }, 3000)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
