<!--
* @description 成为营销师成功页面
* @fileName cg.vue
* @author liulian
* @date 2022/06/14 17:44:58
-->
<template>
  <div class="cg">
    <emptyPage :config="config"></emptyPage>
    <!-- 描述区域 -->
    <div class="ms">
      <!-- <p class="font-size-16 font-weight-400">
        了解keyy接单流程，成为专业 社媒营销⼤师了解keyy接单流程，成为专业 社媒营销⼤师了解keyy接单流程，成为专业 社媒营销⼤师了解keyy接单流程，成为专业 社媒营销⼤师了解keyy接单流程，成为专业 社媒营销⼤师 了解keyy接单流程，成为专业 社媒营销⼤师了解keyy接单流程，成为专业 社媒营销⼤师了解keyy接单流程，成为专业 社媒营销⼤师了解keyy接单流程，成为专业 社媒营销⼤师了解keyy接单流程，成为专业 社媒营销⼤师 了解keyy接单流程，成为专业 社媒营销⼤师了解keyy接单流程，成为专业 社媒营销⼤师
      </p> -->
      <div class="tip font-size-16 font-weight-400 text-align-center">快去上传案例吧~</div>
      <div class="btn_bar text-align-center">
        <el-button plain @click="goBack">稍后上传</el-button>
        <el-button @click="goUp">现在上传</el-button>
      </div>
    </div>

    <!-- 右面提示框 -->
    <div class="right_tip">
      <div class="top">
        <img class="top_icon" src="../../../assets/images/yxyxs/tip-icon@2x.png" alt="" />
        <span class="top_span font-size-16 font-weight-500">新手入门贴士~如何提升成交额？</span>
      </div>
      <div class="con_box">
        <div class="box">
          <p>1、您可通过上传优质案例，增加曝光，促成与品牌的合作</p>
        </div>
        <div class="box">
          <p>2、也可通过借鉴出去的案例赚取借鉴费</p>
          <span class="text_white">（ 借鉴费会随着等级提升而升高）</span>
        </div>
        <div class="box">
          <p>3、等级越高，行业知名度越高，曝光关注越多</p>
          <span class="text_white">（角色等级规则查看「创作者社区」）</span>
        </div>
        <div class="box">
          <p>4、浏览「需求大厅」品牌需求，报名锁定商机</p>
        </div>
      </div>
      <div class="bottom_box">
        <span class="text">Keyy会一路陪伴您升级成长，成就理想职业</span>
      </div>
    </div>
  </div>
</template>

<script>
import emptyPage from "@/components/common/empty-page.vue"
export default {
  name: "cg",
  components: { emptyPage },
  data() {
    return {
      config: {
        emptyImage: require("@/assets/images/cg@2x.png"),
        description: "恭喜您成为xxx角色，您的等级将根据在平台的成交额提升！",
      },
    }
  },
  computed: {},
  created() {
    this.config.description = `恭喜您成为${this.$store.state.userInfo.marketer_role_context}，您的等级将根据在平台的成交额提升！`
  },
  mounted() {},
  methods: {
    /*
     * @Description: 稍后上传按钮
     * @MethodAuthor:  liulian
     * @Date: 2022-06-30 15:32:36
     * @param: ''
     */
    goBack() {
      let check = this.$store.state.userInfo
      if (check && check.official_type === 2) {
        console.log("---到案例库-")
      } else {
        // 个人或者机构跳转到需求大厅
        this.$router.push("/xqdt")
      }
    },
    /*
     * @Description: 上传案例
     * @MethodAuthor:  liulian
     * @Date: 2022-06-30 15:33:16
     * @param: ''
     */
    goUp() {
      this.$router.push("/uploadcase")
    },
  },
}
</script>

<style scoped lang="scss">
.cg {
  margin: auto;
  .ms {
    p {
      color: #57596c;
    }
    .tip {
      margin-top: 6.25rem;
      color: #2f2c42;
    }
    .btn_bar {
      background: #ffffff;
      border-radius: 3.25rem;
      margin-top: 2rem;
      opacity: 1;
      ::v-deep .el-button {
        width: 7rem;
        height: 3.625rem;
        border-radius: 2rem;
        border: 1px solid #111029;
        font-size: 16px;
        font-weight: 400;
        color: #111029;
      }
      ::v-deep .el-button:hover {
        border: 1px solid #111029;
        color: #111029;
      }
      ::v-deep .el-button:nth-child(2) {
        margin-left: 4rem;
        border: none;
        background: #111029;
        color: #ffffff;
      }
    }
  }
  ::v-deep .empty .el-empty__image img {
    height: 12.5rem;
  }
  ::v-deep .empty .el-empty__image {
    height: 12.5rem;
    padding-top: 23px;
  }
  .right_tip {
    position: absolute;
    top: 9.125rem;
    right: 6.125rem;
    width: 320px;
    height: 415px;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    opacity: 1;
    .top {
      display: flex;
      align-items: center;
      height: 4.75rem;

      border-bottom: 1px solid #eff0f6;
      .top_icon {
        width: 28px;
        height: 28px;
        margin-left: 26px;
        margin-right: .375rem;
      }
      .top_span {
        color: #2f2c42;
      }
    }
    .con_box {
      width: 256px;
      margin: 24px auto;
      .box {
        margin-bottom: 16px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #57596c;
        height: 9px;
        background-image: url("../../../assets/images/yxyxs/text-bg@2x.png");
        display: inline;
      }
      .text_white {
        font-size: 14px;
        background: linear-gradient(to right, #c0b7ed, #393cd3);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }
    .bottom_box {
      width: 320px;
      height: 59px;
      line-height: 59px;
      background: linear-gradient(101deg, #83d1e1 0%, #49e9ff 39%, #ceb5f3 72%, #f8bee4 100%);
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      text-align: center;
      .text {
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        margin: 23px auto;
      }
    }
  }
}
</style>
