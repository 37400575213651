<!--
* @description 二维码生成组件
* @fileName qrcode_cpn.vue
* @author liulian
* @date 2022/08/04 09:12:07
-->
<template>
  <div>
    <div class="qrcode" ref="qrcodeUrl" :key="new Date().getTime()"></div>
  </div>
</template>

<script>
import QRCodeJs from "qrcodejs2"
export default {
  name: "qrcode_cpn",
  props: {
  // 需要转为二维码的链接
    url: {
      type: String,
      default: "https://web.social.lyunweb.com/",
    },
  },
  data() {
    return {
      qrcode: "",
    }
  },
  mounted() {
    this.createQrcode()
  },
  methods: {
    createQrcode() {
      this.qrcode = new QRCodeJs(this.$refs.qrcodeUrl, {
        text: this.url,
        width: 140,
        height: 140,
        colorDFarl: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCodeJs.CorrectLevel.H,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.qrcode {
  display: inline-block;
  img {
    width: 8.75rem;
    height: 8.75rem;
    background-color: #fff;
    padding: 0.375rem;
    box-sizing: border-box;
  }
}
</style>
