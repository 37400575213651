<template>
    <div id="tab">
        <div class="tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane :label="item.name" :name=item.id v-for="item in tabslist" :key="item.id"></el-tab-pane>
            </el-tabs>
            <div class="tabs-right">
                <div class="switch" v-if="switchshow == 1">
                    <el-switch v-model="value" @change="SwitchChange" :active-value="activeVal"
                        :inactive-value="inactiveVal" active-color="#2F2C42" inactive-color="#F5F5F5">
                    </el-switch>
                    <p>是否在个人主页展示机构创作者</p>
                </div>
                <div class="addbox" ref="addbox" v-if="isadd == true">
                    <p @click.stop="visibleChange">新建
                        <img src="@/assets/images/userprofile/Vector.png" alt="" class="add" ref="addimg">
                    </p>
                    <ul ref="addlist">
                        <li v-for="item in addlist" :key="item.id" @click="onAdd(item)">{{ item.name }}</li>
                    </ul>
                </div>

            </div>

        </div>
        <!-- 发布文章弹窗 -->
        <dialogs :show.sync="showDialog" width="86.25rem" :center="true" :title="title" :showFooter="false">
            <!-- 富文本 -->
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="文章标题" prop="title">
                    <el-input type="text" placeholder="请输入文章标题" v-model="ruleForm.title" maxlength="20" show-word-limit
                        @input="change($event)">
                    </el-input>
                </el-form-item>
                <el-form-item label="案例描述" style="align-items: flex-start;" id="content">
                    <el-upload class="avatar-uploader" :headers="importHeaders" :action="uploadurl"
                        :show-file-list="false" :on-success="uploadSuccess" :before-upload="beforeUpload">
                    </el-upload>
                    <quill-editor v-model="content" :content="content" :options="editorOption"
                        @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @ready="onEditorReady($event)"
                        @change="onEditorChange($event)" ref="QuillEditor">
                    </quill-editor>

                    <p class="tip" v-show="isTip">请输入案例描述</p>
                </el-form-item>
                <el-form-item class="btns">
                    <el-button @click="submitForm('ruleForm')">{{ subtitle }}</el-button>
                    <el-button @click="resetForm('ruleForm')">取消</el-button>
                </el-form-item>
            </el-form>

        </dialogs>
    </div>
</template>
<script>

var mytoken = localStorage.getItem('token')
import { addArticle, articleDetail, editArticle, isShowCreator } from "@/api/api.js"
import dialogs from "../../components/common/dialog-cpn.vue"
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['image'],
]
export default {
    components: {
        dialogs,
    },
    props: {
        tabs: {
            type: Array,
            default: null
        },
        switchshow: {
            type: Number,
            default: 0
        },
        isadd: {
            type: Boolean,
            default: true
        }

    },
    data() {
        return {
            subtitle: "发布",
            title: "发布文章",
            editid: "",
            editFalg: 1,// 判断发布编辑按钮 1为发布文章 ，2为编辑文章
            uploadurl: "/ja/upload/oss/img",
            importHeaders: { Authorization: mytoken },
            isTip: false,
            isShow: false,
            showDialog: false,
            show: false,
            value: "",
            activeVal: 1,
            inactiveVal: 0,
            activeName: "1",
            tabslist: [],
            flag: false,
            addlist: [
                {
                    id: 1,
                    name: "发布需求",
                },
                {
                    id: 2,
                    name: "上传案例",
                },
                {
                    id: 3,
                    name: "发布文章",
                },
                {
                    id: 4,
                    name: "创建项目",
                }
            ],
            // 富文本编辑器默认内容
            content: '<p2>说点什么吧~</p2>',
            //富文本编辑器配置
            editorOption: {
                theme: 'snow',
                modules: {
                    toolbar: {
                        container: toolbarOptions,
                        handlers: {
                            image(value) {
                                if (value) {
                                    document.querySelector('.avatar-uploader input').click()
                                } else {
                                    _this.quill.format('image', false)
                                }
                            },
                        },
                    },
                },
            },
            ruleForm: {
                content: ""
            },
            rules: {
                title: [
                    { required: true, message: '请输入文章标题', trigger: 'blur' },
                ],
                content: [
                    { required: true, message: '请输入案例描述', trigger: 'blur' },
                ]
            },
        }
    },
    mounted() {
        this.value = JSON.parse(localStorage.getItem('userinfor')).is_show_user
        document.addEventListener("click", this.handleClose);
    },
    watch: {
        tabs: {
            handler(e) {
                if (e) {
                    this.tabslist = e
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        // 点击区域外
        handleClose() {
           
            if (this.isShow == true) {
                this.isShow = false
                this.$refs.addbox.style.height = "3rem";
                this.$refs.addbox.style.borderRadius = '3.125rem';
                this.$refs.addlist.style.visibility = "hidden"
                this.$refs.addimg.style.transform = "rotate(0deg)"
            }
        },
        SwitchChange(e) {
            isShowCreator()
                .then(res => {
                    if (res.code == 10000) {
                        this.value = res.data.is_show_user
                        this.$message.success(res.message)
                    }
                })
        },
        change() {
            this.$forceUpdate();
        },
        onTabs(data) {
            if (data) {
                console.log("获取到了")
                this.title = "编辑文章"
                this.subtitle = "提交"
                this.editid = data.id
                this.getartDetail(data.id)
                this.editFalg = 2
            } else {
                this.subtitle = "发布"
            }
        },
        // 获取文章详情
        getartDetail(id) {
            articleDetail(id)
                .then(res => {
                    if (res.code == 10000) {
                        this.showDialog = true
                        this.ruleForm.title = res.data.title
                        this.content = res.data.content
                    }
                })
        },
        // 切换tab栏
        handleClick(e) {
            this.$emit("handleClick", this.activeName)
        },
        visibleChange(e) {
            // this.flag = e
            if (this.isShow == false) {
                this.isShow = true
                this.$refs.addbox.style.height = '13.75rem';
                this.$refs.addbox.style.borderRadius = '1rem';
                this.$refs.addlist.style.visibility = "visible"
                this.$refs.addimg.style.transform = "rotate(-45deg)"

            } else {
                this.isShow = false
                this.$refs.addbox.style.height = "3rem";
                this.$refs.addbox.style.borderRadius = '3.125rem';
                this.$refs.addlist.style.visibility = "hidden"
                this.$refs.addimg.style.transform = "rotate(0deg)"
            }
        },
        // 选择新建
        onAdd(e) {
            if (e.id == 1) {
                this.$router.push("/release")
            } else if (e.id == 4) {
                this.$router.push("/createproject")
            } else if (e.id == 2) {
                this.$router.push("/uploadcase")
            } else {
                this.title = "发布文章"
                this.showDialog = true
                this.editFalg = 1
                this.isShow = false
                this.$refs.addbox.style.height = "3rem";
                this.$refs.addbox.style.borderRadius = '3.125rem';
                this.$refs.addlist.style.visibility = "hidden"
                this.$refs.addimg.style.transform = "rotate(0deg)"
                this.ruleForm = {}
                this.content = `<p2>说点什么吧~</p2>`
            }
        },
        //失去焦点事件
        onEditorBlur(quill) {
            if (this.content.length == 0) {
                this.isTip = true
            } else {
                this.isTip = false
            }
        },
        //获得焦点事件
        onEditorFocus(quill) {
            console.log('editor focus!', quill)
        },
        // 准备富文本编辑器
        onEditorReady(quill) {
            console.log('editor ready!', quill)
        },
        //内容改变事件
        onEditorChange({ quill, html, text }) {
        },
        // 图片上传成功
        uploadSuccess(res) {
            // 获取富文本组件实例
            let quill = this.$refs.QuillEditor.quill
            // 如果上传成功
            if (res.code === 10000) {
                // 获取光标所在位置
                let length = quill.getSelection().index;
                // 插入图片，res为服务器返回的图片链接地址
                quill.insertEmbed(length, 'image', res.data)
                // 调整光标到最后
                quill.setSelection(length + 1)
            } else {
                // 提示信息，需引入Message
                this.$message.error('图片插入失败！')
            }
        },

        // 图片上传之前
        beforeUpload() { },
        // 发布文章
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.content.length == 0 || this.content == `<p2>说点什么吧~</p2>`) {
                        this.isTip = true
                        return false
                    }
                    this.ruleForm.content = this.content
                    if (this.editFalg == 1) {
                        addArticle(this.ruleForm)
                            .then(res => {
                                if (res.code == 10000) {
                                    this.$message.success("发布成功！")
                                    this.$refs[formName].resetFields();
                                    this.showDialog = false
                                    this.$emit("UploadList")
                                }
                            })
                    } else {
                        editArticle(this.editid, this.ruleForm)
                            .then(res => {
                                if (res.code == 10000) {
                                    this.$refs[formName].resetFields();
                                    this.showDialog = false
                                    this.$router.go(-1)
                                    this.$emit("UploadList")
                                    this.$message.success("编辑成功！")
                                }
                            })
                    }

                } else {
                    if (this.content.length == 0 || this.content == `<p2>说点什么吧~</p2>`) {
                        this.isTip = true
                        return
                    }
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.showDialog = false
        }


    }
}
</script>
<style lang="scss" >
#tab {
    .el-dialog__wrapper {
        .el-dialog {
            .el-dialog__body {
                .el-form {
                    .el-form-item {
                        display: flex;
                        align-items: center;

                        .el-form-item__label {
                            white-space: nowrap;
                            position: relative;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #111029;
                            line-height: 1.1875rem;

                            &::before {
                                position: absolute;
                                right: -0.1875rem;
                                top: -0.3125rem;
                            }
                        }

                        .el-form-item__content {
                            margin-left: 1rem !important;
                            width: 100%;

                            .el-input {
                                width: 41.125rem;
                                height: 2.625rem;
                                background: #FFFFFF;
                                box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
                                border-radius: 10px 10px 10px 10px;
                                border: 1px solid #EFF0F6;

                                .el-input__inner {
                                    height: 2.625rem;
                                    font-size: 1rem;
                                    font-family: PingFang SC-Regular, PingFang SC;
                                    font-weight: 100;
                                    color: #57596C;
                                    line-height: 1.1875rem;

                                    &::placeholder {
                                        font-size: 1rem;
                                        font-family: PingFang SC-Regular, PingFang SC;
                                        font-weight: 100;
                                        color: #A0A3BD;
                                        line-height: 1.1875rem;
                                    }

                                    &::-webkit-input-placeholder {
                                        /* WebKit browsers 适配谷歌 */
                                        font-size: 1rem;
                                        font-family: PingFang SC-Regular, PingFang SC;
                                        font-weight: 100;
                                        color: #A0A3BD;
                                        line-height: 1.1875rem;
                                    }

                                    &:-moz-placeholder {
                                        /* Mozilla Firefox 4 to 18 适配火狐 */
                                        font-size: 1rem;
                                        font-family: PingFang SC-Regular, PingFang SC;
                                        font-weight: 100;
                                        color: #A0A3BD;
                                        line-height: 1.1875rem;
                                    }

                                    &::-moz-placeholder {
                                        /* Mozilla Firefox 19+ 适配火狐 */
                                        font-size: 1rem;
                                        font-family: PingFang SC-Regular, PingFang SC;
                                        font-weight: 100;
                                        color: #A0A3BD;
                                        line-height: 1.1875rem;
                                    }

                                    &:-ms-input-placeholder {
                                        /* Internet Explorer 10+  适配ie*/
                                        font-size: 1rem;
                                        font-family: PingFang SC-Regular, PingFang SC;
                                        font-weight: 100;
                                        color: #A0A3BD;
                                        line-height: 1.1875rem;
                                    }
                                }
                            }

                            .ql-editor {
                                height: 21.0625rem;
                            }
                        }

                        .tip {
                            color: #F56C6C;
                            font-size: 12px;
                            line-height: 1;
                            padding-top: 4px;
                        }

                        .quill-editor {
                            width: 100%;
                            margin-top: -40px;
                        }
                    }

                    .btns {
                        width: 100%;
                        display: flex;
                        justify-content: center;

                        .el-form-item__content {
                            width: auto;
                        }

                        .el-button {
                            margin-top: 2rem;
                            width: 5rem;
                            height: 3.625rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #FFFFFF;
                            line-height: 1.1875rem;
                            background: #111029;
                            border-radius: 3.25rem 3.25rem 3.25rem 3.25rem;

                            &:nth-child(2) {
                                margin-left: 2rem;
                                background: #FFFFFF;
                                color: #111029;
                            }
                        }
                    }

                    #content {
                        .el-form-item__label {
                            position: relative;

                            &::after {
                                content: "*";
                                color: #F56C6C;
                                position: absolute;
                                top: -0.3125rem;
                                right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .el-tabs {
        .el-tabs__nav-wrap {
            &::after {
                display: none;
            }
        }

        .el-tabs__active-bar {
            height: .25rem;
            background: #2F2C42;
            border-radius: .75rem .75rem .75rem .75rem;
            opacity: 1;
        }

        .el-tabs__item {
            height: auto;
            font-size: 1rem;
            font-family: PingFang SC-Medium, PingFang SC;
            color: #57596C;
            line-height: 1.1875rem;
            padding-bottom: .5rem;
        }

        .is-active {
            color: #111029;
            font-weight: 500;
        }
    }

    .tabs-right {
        display: flex;
        align-items: center;

        .switch {
            display: flex;
            align-items: center;
            margin-right: 10rem;

            p {
                margin-left: .625rem;
                font-size: .875rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #111029;
                line-height: 1rem;
            }
        }
    }

    .addbox {
        z-index: 100;
        position: absolute;
        right: 0;
        top: 0;
        width: 6.5rem;
        height: 3rem;
        background: rgb(47, 44, 65);
        border-radius: 3.125rem;
        transition: all .5s ease;
        cursor: pointer;

        p {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;

            line-height: 3rem;

            img {
                display: block;
                margin-left: 1rem;
                transition: all .5s ease;
            }
        }

        ul {
            visibility: hidden;
            z-index: 100;

            li {
                padding: .3125rem 0;
                cursor: pointer;
                margin: .625rem 0;
                display: flex;
                align-content: center;
                justify-content: center;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #FFFFFF;
                line-height: 1.1875rem;

                &:hover {
                    background-color: rgb(18, 16, 40);
                    transition: all .5s ease;
                }
            }
        }
    }

    .el-dropdown {
        cursor: pointer;
        width: 6.5rem;
        height: 3rem;
        background: #2F2C42;
        border-radius: 3.125rem 3.125rem 3.125rem 3.125rem;
        opacity: 1;
        border: 0px solid #111029;

        .el-dropdown-link {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            color: #FFFFFF;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 100;
            color: #FFFFFF;
            line-height: 1.1875rem;

            .add {
                display: block;
                margin-left: .625rem;
                width: .75rem;
                height: .75rem;
            }

            .del {
                display: block;
                margin-left: .625rem;
                width: 1.375rem;
                height: 1.375rem;
            }
        }

    }

    .isboeder {
        border-bottom-left-radius: 0rem;
        border-bottom-right-radius: 0rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

}


.el-dropdown-menu {
    width: 6.5rem;
    height: auto;
    background: #2F2C42;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    opacity: 1;
    margin-top: 0rem;
    border: 0px solid #2F2C42;

    ul {

        li {
            cursor: pointer;
            margin: .625rem 0;
            display: flex;
            align-content: center;
            justify-content: center;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 100;
            color: #FFFFFF;
            line-height: 1.1875rem;
        }
    }

    .popper__arrow {
        display: none;
    }


}

.el-popper {
    margin-top: 0px;
}
</style>