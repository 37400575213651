<template>
  <div class="abkal_xq screenWidth" style="height: 100vh">
    <div class="top flex-row-between">
      <div class="top_left flex">
        <img :src="userinfor.avatar" />
        <div class="top_left_right flex-column">
          <div class="flex">
            <span class="name font-size-16 font-weight-500">{{ userinfor.username }}</span>
            <span class="dengji font-color-red text-align-center flex font-size-12 font-weight-400"><img src="@/assets/images/home/V@2x.png" alt="" />{{ userinfor.level_context }}</span>
            <span class="dianzi font-color-green text-align-center font-size-12 font-weight-400" style="width: auto; padding: 0 4px">{{ userinfor.marketer_role_context }}</span>
          </div>
          <span class="fensi font-size-14 font-weight-400">粉丝：{{ userinfor.fans_num }}</span>
        </div>
      </div>
      <div class="top_right">
        <!-- <span class="top_right_btn text-align-center hover-black cursor-pointer">合作创作者</span> -->
        <div class="errorexamine" v-if="audit_status == 2">
          <img src="@/assets/images/userprofile/errorexamine.png" alt="" />
          审核驳回
        </div>
        <div class="successexamine" v-if="audit_status == 1">
          <img src="@/assets/images/userprofile/successexamine.png" alt="" />
          审核成功
        </div>
        <div class="review" v-if="audit_status == 0">
          <img src="@/assets/images/userprofile/review.png" alt="" />
          审核中
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main_left">
        <div class="main_title">
          <p class="font-weight-500 font-size-20">{{ detail.name }}</p>
        </div>
        <!-- <el-carousel :autoplay="false" indicator-position="none">
          <el-carousel-item v-for="(item, index) in carouselImgs" :key="index">
            <img :src="item" alt="" />
          </el-carousel-item>
        </el-carousel> -->
        <img class="imgs" :src="detail.cover" />
      </div>
      <div class="main_right">
        <el-tabs v-model="activeName">
          <el-tab-pane v-for="(item, index) in tabs" :key="index" :name="item.name" :label="item.label">
            <!-- 作品介绍 -->
            <template v-if="activeName === '1'">
              <p class="font-size-16 font-weight-400">
                {{ detail.introduction }}
              </p>
            </template>
            <!-- 作品标签 -->
            <template v-if="activeName === '2'">
              <div class="tabs_box">
                <span class="tag" v-for="(item, index) in tags" :key="index">{{ item.name }}</span>
              </div>
            </template>
          </el-tab-pane>
        </el-tabs>

        <div class="main_right_bottom flex" v-show="false">
          <el-button type="primary" @click="referenceEditorHandle" class="hover-blue">借鉴编辑</el-button>
          <div class="shoucang flex text-align-center cursor-pointer">
            <img src="@/assets/images/bkal/sc@2x.png" />
            <span class="font-size-16 font-weight-400">1046</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tip_bar flex" style="">
      <!-- <img class="bj cursor-pointer" src="@/assets/images/bkal/bj@2x.png" alt="" @click="qrcode" /> -->
      <!-- <span class="xx flex">
        <img src="@/assets/images/bkal/xx@2x.png" alt="" />
        <span class="xx_num font-size-14 font-weight-400">12</span>
      </span> -->
      <span class="time font-size-14 font-weight-500">{{ detail.created_at }}</span>

      <!-- 二维码弹框 -->
      <div class="qr flex-column-center" v-if="showQr">
        <span class="font-size-14 font-weight-400">扫一扫分享内容</span>
        <img src="https://s3.bmp.ovh/imgs/2022/06/13/43d3fbb4f7eca373.jpg" alt="二维码图片" />
        <span class="url cursor-pointer font-color-blue">点击复制连接</span>
      </div>
    </div>
    <div v-show="false">
      <div class="pl">
        <!--评论内容  -->
        <el-input class="text_box" v-model="inputValue" type="textarea" placeholder="说点什么吧"></el-input>
        <div class="pl_btn">
          <span class="font-size-16 font-weight-400 cursor-pointer" :class="inputValue.length > 0 ? 'isBlack' : ''" @click="pl">评论</span>
        </div>
      </div>
      <!-- 消息列表 -->
      <div class="msg">
        <div class="msg_title text-align-left font-size-20 font-weight-500">全部评论</div>
        <div class="msg_item" :class="item.children.length > 0 ? '' : 'border'" v-for="(item, index) in msgs.slice(0, expendNum)" :key="index">
          <div class="item_left">
            <img :src="item.avatar" alt="" />
          </div>
          <div class="item_center">
            <div class="title flex">
              <span class="name font-size-16 font-weight-500">{{ item.name }}</span>
              <tag-btn class="redtag" :config="{ text: '初级', type: 'red' }"></tag-btn>
              <tag-btn :config="{ text: '点子王' }"></tag-btn>
            </div>
            <div class="ms">{{ item.description }}</div>
            <div class="times_bar flex flex-row-between">
              <span class="times font-size-14 font-weight-400">{{ item.time }}</span>
              <div class="bar_tight">
                <img src="@/assets/images/bkal/xx@2x.png" alt="" />
                <span class="font-size-14 font-weight-400">{{ item.msgnum }}</span>
              </div>
            </div>

            <!-- 子消息列表 -->
            <div class="msg_children bgc">
              <div class="msg_item" :class="index === item.children.length - 1 ? '' : 'border '" v-for="(it, index1) in item.children" :key="index1">
                <div class="item_left">
                  <img :src="it.avatar" alt="" />
                </div>
                <div class="item_center">
                  <div class="title flex">
                    <span class="name font-size-16 font-weight-500">{{ it.name }}</span>
                    <tag-btn class="redtag" :config="{ text: '初级', type: 'red' }"></tag-btn>
                    <tag-btn :config="{ text: '点子王' }"></tag-btn>
                  </div>
                  <div class="ms font-size-16 font-weight-400">
                    回复<span class="names font-size-16 font-weight-500">{{ it.name }}：</span>{{ it.description }}
                  </div>
                  <div class="times_bar flex flex-row-between">
                    <span class="times font-size-14 font-weight-400">{{ it.time }}</span>
                    <div class="bar_tight">
                      <img src="@/assets/images/bkal/xx@2x.png" alt="" />
                      <span class="font-size-14 font-weight-400">{{ it.msgnum }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 展开更多 -->
      <div class="more">
        <div class="item cursor-pointer" @click="expendHandle">
          <span class="font-size-14 font-weight-400">展开更多</span>
          <img class="" src="@/assets/images/xqdt/zk@2x.png" alt="icon" />
        </div>
      </div>
    </div>
    <el-empty description="快来发表你的评论吧" v-if="msgs.length == 0"></el-empty>
  </div>
</template>

<script>
import { exaimCase, getUserApi } from "@/api/api"

export default {
  name: "detail",

  data() {
    return {
      audit_status: "",
      detailId: "", //详情id
      userinfor: {},
      detail: {},
      carouselImgs: [],
      activeName: "1",
      tabs: [
        {
          label: "作品介绍",
          name: "1",
        },
        {
          label: "作品标签",
          name: "2",
        },
      ],
      msgs: [],
      inputValue: "", // 评论 内容
      showQr: false, // 是否显示二维码弹框
      expendNum: 3, // 默认显示3条
      payDialog: false, // 支付弹框
      tags: [],
    }
  },
  computed: {},
  created() {
    this.getList()
  },
  mounted() {
    if (this.$route.query.id) {
      this.detailId = this.$route.query.id
      this.getDetail()
      this.getUserInfor()
    }
  },
  methods: {
    // 获取个人信息
    getUserInfor() {
      getUserApi().then((res) => {
        if (res.code == 10000) {
          this.userinfor = res.data
        }
      })
    },
    // 获取详情
    getDetail() {
      exaimCase(this.detailId).then((res) => {
        this.detail = res.data.case
        this.carouselImgs = res.data.case.imgs.split(",")
        this.tags = res.data.tag
        this.audit_status = res.data.audit_status
        this.detail.created_at = this.getMyDate(parseInt(res.data.case.created_at))
        console.log()
      })
    },
    // 时间转换
    getMyDate(str) {
      let oDate = new Date(str),
        oYear = oDate.getFullYear(),
        oMonth = oDate.getMonth() + 1,
        oDay = oDate.getDate(),
        oHour = oDate.getHours(),
        oMin = oDate.getMinutes(),
        oSen = oDate.getSeconds(),
        oTime = oYear + "-" + this.addZero(oMonth) + "-" + this.addZero(oDay) + " " + this.addZero(oHour) + ":" + this.addZero(oMin) + ":" + this.addZero(oSen)
      return oTime
    },
    //补零操作
    addZero(num) {
      if (parseInt(num) < 10) {
        num = "0" + num
      }
      return num
    },

    /*
     * @Description: 借鉴编辑按钮
     * @MethodAuthor:  liulian
     * @Date: 2022-06-16 15:52:08
     * @param: ''
     */
    referenceEditorHandle() {
      this.payDialog = true
    },
    /*
     * @Description: 展开更多
     * @MethodAuthor:  liulian
     * @Date: 2022-06-14 11:52:31
     * @param: ''
     */
    expendHandle() {
      this.expendNum = this.msgs.length + 1
    },
    /*
     * @Description: 是否显示二维码弹框
     * @MethodAuthor:  liulian
     * @Date: 2022-06-13 14:19:53
     * @param: ''
     */
    qrcode() {
      this.showQr = !this.showQr
    },
    /*
     * @Description: 评论内容
     * @MethodAuthor:  liulian
     * @Date: 2022-06-13 11:56:44
     * @param: ''
     */
    pl() {
      this.msgs.push({
        avatar: "https://s3.bmp.ovh/imgs/2022/06/09/fedd208b167ccf86.jpg",
        name: this.inputValue,
        description: this.inputValue,
        time: "2022.05.20 16:24:32",
        msgnum: 2,
        children: [],
      })
      this.inputValue = ""
    },
    getList() {
      for (let i = 0; i < 6; i++) {
        this.msgs.push({
          avatar: "https://e3f49eaa46b57.cdn.sohucs.com/2021/11/10/14/27/MTAwMTIyXzE2MzY1MjU2NDQ4OTM=.png",
          name: "名字名字名字名字名字",
          description: "做的真好！",
          time: "2022.05.20 16:24:32",
          msgnum: 2,
          children:
            i < 3
              ? [
                  {
                    avatar: "https://e3f49eaa46b57.cdn.sohucs.com/2021/11/10/14/27/MTAwMTIyXzE2MzY1MjU2NDQ4OTM=.png",
                    name: "名字名字名字名字名字",
                    description: "做的真好！",
                    time: "2022.05.20 16:24:32",
                    msgnum: 2,
                  },
                  {
                    avatar: "https://e3f49eaa46b57.cdn.sohucs.com/2021/11/10/14/27/MTAwMTIyXzE2MzY1MjU2NDQ4OTM=.png",
                    name: "名字名字名字名字名字",
                    description: "做的真好！",
                    time: "2022.05.20 16:24:32",
                    msgnum: 2,
                  },
                ]
              : [],
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.abkal_xq {
  margin: auto;

  .top {
    margin-top: 2.5625rem;
    border-bottom: 1px solid #eff0f6;

    .top_left {
      margin-bottom: 1.375rem;

      img {
        border-radius: 100%;
        width: 2.875rem;
        height: 2.875rem;
      }

      .top_left_right {
        margin-left: 1rem;

        img {
          width: 10px;
          height: 10px;
        }

        .fensi {
          color: #57596c;
          margin-top: 0.25rem;
        }

        .dengji {
          width: 2.625rem;
          height: 1.0625rem;
          background: #ffeff0;
          border-radius: 1.5625rem;
          opacity: 1;
          display: block;
          margin-right: 0.375rem;
        }

        .dianzi {
          width: 2.625rem;
          height: 1.0625rem;
          background: #def2e6;
          border-radius: 1.5625rem;
          opacity: 1;
          display: block;
        }

        .name {
          margin-right: 0.625rem;
        }
      }
    }

    .top_right {
      margin-bottom: 1.375rem;
      .errorexamine,
      .successexamine,
      .review {
        width: 6.875rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 100;
        line-height: 1.1875rem;
        border-radius: 2.1875rem 2.1875rem 2.1875rem 2.1875rem;

        img {
          display: block;
          width: 1.375rem;
          height: 1.375rem;
          margin-right: 0.25rem;
        }
      }

      .errorexamine {
        color: #dc2b2b;
        background: #ffeff0;
      }

      .successexamine {
        color: #11845b;
        background: #def2e6;
      }

      .review {
        color: #086cd9;
        background: #eaf4ff;
      }
    }
  }

  .main {
    margin-top: 2rem;
    display: flex;

    .main_left {
      margin-right: 3.875rem;
      width: 57.25rem;
      height: 32.25rem;

      .main_title {
        margin-bottom: 2rem;

        p {
          color: #111029;
        }
      }
      .imgs {
        width: 916px;
        height: 516px;
        border: 1px solid #eee;
      }

      ::v-deep .el-carousel {
        border-radius: 2.9375rem !important;
        overflow: hidden;

        img {
          border-radius: 2.9375rem !important;
        }
      }

      ::v-deep .el-carousel__container {
        border-radius: 2.9375rem !important;
        height: 32.25rem;
      }

      ::v-deep .el-carousel__item {
        border-radius: 2.9375rem !important;
        padding-bottom: 20px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 2.9375rem;
        }
      }

      ::v-deep .el-carousel__item .is-active {
        border-radius: 2.9375rem;
      }

      ::v-deep .el-carousel__item .is-animating {
        border-radius: 2.9375rem;
      }
    }

    .main_right {
      height: 12.375rem;
      width: 24.5rem;
      margin-top: -0.625rem;
      flex: 1;

      ::v-deep .el-tabs__item {
        font-weight: 100;
        font-size: 1rem;
        color: #111029;
      }

      ::v-deep .el-tabs__nav-wrap::after {
        display: none;
      }

      ::v-deep .el-tabs__active-bar {
        height: 0.25rem;
      }

      ::v-deep .el-tabs__active-bar {
        border-radius: 0.125rem;
      }

      p {
        color: #57596c;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 9;
        line-clamp: 9;
        word-break: break-all;
        overflow: hidden;
      }

      .tabs_box {
        .tag {
          display: inline-block;
          font-size: 0.875rem;
          font-weight: 400;
          color: #57596c;
          padding: 0.25rem 0.625rem;
          border-radius: 1.125rem;
          border: 0.0625rem solid #eff0f6;
          margin-right: 1rem;
          margin-bottom: 1rem;
        }
      }

      .main_right_bottom {
        margin-top: 18rem;

        .el-button {
          width: 17.5rem;
          height: 3.875rem;
          border-radius: 2.25rem;
          margin-right: 1rem;
          font-weight: 400;
          font-size: 1rem;

          span {
            font-weight: 400;
            font-size: 1rem !important;
          }
        }

        .shoucang {
          width: 5.9375rem;
          height: 3.875rem;
          line-height: 3.875rem;
          border-radius: 2.8125rem;
          opacity: 1;
          border: 2px solid #57596c;
          justify-content: center;

          img {
            width: 1.375rem;
            height: 1.375rem;
            color: #57596c;
          }

          span {
            color: #57596c;
          }
        }
      }
    }
  }

  .tip_bar {
    margin-top: 7.18rem;
    position: relative;

    img {
      width: 1.25rem;
      height: 1.25rem;
    }

    .xx {
      margin-left: 13.5rem;
      margin-right: 30.3125rem;

      .xx_num {
        color: #a0a3bd;
        margin-left: 0.375rem;
      }
    }

    .time {
      color: #a0a3bd;
    }

    .qr {
      width: 12.75rem;
      height: 15.125rem;
      background: #ffffff;
      box-shadow: 0rem 0.125rem 0.375rem 0.0625rem rgba(20, 20, 43, 0.06);
      border-radius: 0.625rem;
      opacity: 1;
      border: 0.0625rem solid #eff0f6;
      position: absolute;
      top: 1.625rem;
      left: 0;
      z-index: 33;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        width: 8.75rem;
        height: 8.75rem;
        margin-top: 0.375rem;
        margin-bottom: 1rem;
      }
    }
  }

  .pl {
    margin-top: 3.5rem;

    ::v-deep .el-textarea__inner {
      height: 6.25rem;
      box-shadow: 0rem 0.25rem 0.625rem 0.0625rem rgba(58, 114, 255, 0.06);
      border-radius: 0.5rem;
      opacity: 1;
    }

    .pl_btn {
      margin-top: 1rem;
      display: flex;
      justify-content: flex-end;

      span {
        width: 4.75rem;
        height: 3.125rem;
        background: #f7f7fc;
        border-radius: 3.25rem;
        opacity: 1;
        color: #111029;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .isBlack {
        background: #111029;
        color: #ffffff;
      }
    }
  }

  .msg {
    .msg_item {
      margin-top: 2rem;
      position: relative;
      display: flex;

      .item_left {
        position: relative;

        img {
          width: 3rem;
          height: 3rem;
          border-radius: 100%;
        }
      }

      .item_center {
        margin-left: 1rem;
        flex: 1;

        .title {
          .name {
            color: #111029;
          }

          .redtag {
            margin-left: 0.5rem;
            margin-right: 0.375rem;
          }
        }

        .ms {
          color: #2f2c42;
          margin-top: 1rem;

          .names {
            color: #2f2c42;
            margin-left: 0.625rem;
          }
        }

        .times_bar {
          .times {
            color: #a0a3bd;
            margin-top: 1rem;
            display: block;
            margin-bottom: 2rem;
          }

          .bar_tight {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;

            img {
              width: 0.9875rem;
              height: 0.8962rem;
              margin-right: 0.5rem;
            }

            span {
              color: #a0a3bd;
            }
          }
        }

        // 子消息列表
        .msg_children {
          border-radius: 1rem;

          .msg_item {
            margin-top: 0;

            .item_left {
              margin-top: 1rem;
              margin-left: 1rem;
            }

            .item_center {
              margin-top: 1rem;

              .bar_tight {
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }

    .border {
      border-bottom: 1px solid #eff0f6;
    }

    .bgc {
      background: #f7f7fc;
    }
  }

  .more {
    .item {
      display: inline-block;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
    }

    img {
      width: 1.25rem;
      height: 1.25rem;
      margin-left: 0.5rem;
    }

    span {
      color: #a0a3bd;
    }
  }

  ::v-deep .el-carousel__arrow {
    background-color: #bebdbf;
    display: block !important;

    i {
      color: #111029;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .rq-dialog {
    ::v-deep .el-dialog__headerbtn {
      font-size: 1.625rem;
    }

    ::v-deep .el-dialog__headerbtn .el-dialog__close {
      color: #111029;
    }

    .rq-img {
      margin: 2.5rem auto;
      width: 17.25rem;
      height: 17.25rem;
    }

    .url {
      margin-top: -0.75rem;
    }

    .title {
      span {
        color: #ff7b52;
      }
    }
  }
}
</style>
