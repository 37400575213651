<template>
    <div class="article">
        <tabs ref="tabs" @UploadList="UploadList"></tabs>
        <my-article :article="artList" @UploadList="UploadList" @editArticle="editArticle"></my-article>
        <!-- 空状态 -->
        <div class="empty" v-show="emptyShow">
            <img src="@/assets/images/empty.png" alt="">
            <p>哎呀，这里什么也没有~快试试其他搜索词吧！</p>
        </div>
    </div>
</template>
<script>
import TIM from 'tim-js-sdk';
import { articleList } from "@/api/api.js"
import tabs from "../../../components/tabs/tabs.vue"
import myArticle from "./myarticle/myarticle.vue"
export default {
    components: {
        tabs,
        myArticle

    },
    data() {
        return {
            emptyShow: false,
            tabindex: 1,
            tabslist: [
                {
                    id: "1",
                    name: "案例"
                },
                {
                    id: "2",
                    name: "文章"
                }
            ],
            artList: [],
            query: {
                page: 1,
                limit: 10
            },
            total: "",
        }
    },
    mounted() {
        let onSdkReady = function (event) {
            let message = this$.tim.createTextMessage({ to: 'user1', conversationType: 'C2C', payload: { text: 'Hello world!' } });
            this.$tim.sendMessage(message);
        };
        this.$tim.on(TIM.EVENT.SDK_READY, onSdkReady);
        console.log(onSdkReady)
        this.getArticle()
        var that_ = this
        this.$nextTick(() => {
            this.scroll.sctollBot("", (data => {
                if (data == true) {
                    if (that_.artList.length < that_.total) {
                        this.query.page += 1
                        articleList(this.query)
                            .then(res => {
                                if (res.code == 10000) {
                                    if (res.data.length > 0) {
                                        this.emptyShow = false
                                        res.data.map(e => {
                                            that_.artList.push(e)
                                        })
                                    } else {
                                        this.emptyShow = true
                                    }
                                    this.total = res.count
                                }
                            })
                    }
                }
            }))
        })
    },
    methods: {
        // 获取文章列表
        getArticle() {
            articleList(this.query)
                .then(res => {
                    if (res.code == 10000) {
                        if (res.data.length > 0) {
                            this.emptyShow = false
                        } else {
                            this.emptyShow = true
                        }
                        this.artList = res.data
                        this.total = res.count
                    }
                })
        },
        UploadList() {
            this.getArticle()
        },
        //    编辑文章
        editArticle(item) {
            this.$refs.tabs.onTabs(item)
        }

    }
}
</script>
<style  lang="scss" scoped>
.article {
    padding: 1.5625rem 2rem;

    .empty {
        margin-top: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            width: 18.75rem;
            height: 12.5rem;
        }

        p {
            margin-top: 2rem;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #A0A3BD;
            line-height: 1.1875rem;
        }
    }
}
</style>