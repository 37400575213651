<!--
* @description 444
* @fileName swiper.vue
* @author liulian
* @date 2022/06/10 18:22:29
-->
<template>
  <div class="swiper-container">
    <div class="swiper-wrapper" ref="swiper">
      <div class="swiper-slide swiper-slide1" :class="index === listsData.length - 1 ? 'swiper-no-swiping' : ''" v-for="(item, index) in listsData" :key="index">
        <avatarCard :item="item"></avatarCard>
      </div>
      <div class="swiper-slide swiper-slide1"></div>
      <div class="swiper-slide swiper-slide1"></div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </div>
</template>

<script>
import Swiper from "swiper" // 注意引入的是Swiper
import "swiper/css/swiper.min.css" // 注意这里的引入
import avatarCard from "@/components/common/avatar-card.vue"
export default {
  name: "CarouselShow",
  components: {
    avatarCard,
  },
  props: {
  // 列表数据
    listsData: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    new Swiper(".swiper-container", {
      slidesPerView: 5.65,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      calculateHeight: true,
      cssWidthAndHeight: true,
    })
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: { },
}
</script>

<style scoped lang="scss">
.swiper-container {
  margin: auto;
  ::v-deep .swiper-slide {
    // height: 22.8125rem;
    height: 21.8125rem;
    margin-right: 0.8rem;
    margin-left: 1rem;
    border-radius: 0.625rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 0.625rem;
    }
  }
  ::v-deep .swiper-button-prev {
    background-image: url("../../../assets/images/bkal/left@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 2.375rem !important;
    height: 2.375rem !important;
  }
  ::v-deep .swiper-button-prev::after {
    display: none;
    width: 2.375rem;
    height: 2.375rem;
  }
  ::v-deep .swiper-button-next {
    background-image: url("../../../assets/images/bkal/right@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 2.375rem !important;
    height: 2.375rem !important;
  }
  ::v-deep .swiper-button-next::after {
    display: none;
    width: 2.375rem;
    height: 2.375rem;
  }

  .swiper_btn {
    width: 20px;
    height: 20px;
    background-size: contain;
  }
  ::v-deep .card {
    width: 15.625rem;
    // height: 22.4375rem;
  }
  ::v-deep .swiper-button-prev.swiper-button-disabled {
    pointer-events: unset !important;
  }
}
</style>
