<!--
* @description 优秀营销师
* @fileName yxyxs.vue
* @author liulian
* @date 2022/06/09 10:52:46
-->
<template>
  <div class="avatar_card">
    <div class="card cursor-pointer" @click="goDetail(item)">
      <div class="avatar avatar_imgs" :style="{ backgroundImage: 'url(' + item.avatar + ')' }">
        <!-- <img class="aratar-img" :src="item.avatar" alt=""> -->
        <div class="top"></div>
        <div class="botom">
          <!-- <img class="icon" v-if="item.flag===0" src="@/assets/images/home/yellow_icon@2x.png" alt="">
          <img class="icon" v-else src="@/assets/images/home/blue_icon@2x.png" alt=""> -->
          <div v-if="item.official_type === 1 || item.official_type === 2" class="icons" :class="item.official_type === 1 ? 'icons_blue' : 'icons_yellow'"></div>
          <div v-else class="icons"></div>
          <!-- 1机构，2品牌 -->
          <template v-if="isLoginUser">
            <span v-if="item.is_follow" class="guanzhu font-size-16 font-weight-400" @click="cancelfollowHandle(item)">已关注</span>
            <span v-else class="guanzhu font-size-16 font-weight-400" @click="followHandle(item)">关注</span>
          </template>
        </div>
      </div>
      <div class="card_con text-align-center">
        <div class="name font-size-16 font-weight-500">{{ item.username }}</div>
        <div class="card_con-center flex-row-center">
          <tag-btn v-if="item.level_context" class="degnji" :config="{ type: 'red', text: item.level_context }"></tag-btn>
          <tag-btn v-if="item.marketer_role_context" :config="{ type: 'green', text: item.marketer_role_context }"></tag-btn>
        </div>
        <div class="card_con-bottom font-size-14 font-weight-400">
          <span v-if="item.newTag.area[0]">{{ $util.substr(item.newTag.area[0].name, 4, "") }}</span>
          <span v-if="item.newTag.area[1]">{{ $util.substr(item.newTag.area[1].name, 4, "") }}</span>
        </div>
      </div>
      <div class="card_bottom flex-row-between">
        <span class="card_bottom-left flex font-weight-400 font-size-14">
          <img v-if="item.flag === 0" src="@/assets/images/home/str_yellow@2x.png" alt="" />
          <img v-else src="@/assets/images/home/str_yellow@2x.png" alt="" />
          <span class="font-color-yellow font-weight-400 font-size-14"> {{ item.score }}</span>
        </span>
        <div class="card_bottom-right font-weight-400 font-size-14">
          <span>执行经验</span><span class="jingyan font-weight-500"> {{ item.trade_num }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TagBtn from "./tag-btn.vue"
import { followApi, cancelfollowApi } from "@/api/api.js"
export default {
  name: "avatar_card",
  components: { TagBtn },
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          avatar: "https://s3.bmp.ovh/imgs/2022/06/09/fedd208b167ccf86.jpg",
          username: "名字名字名字名字名字",
          level_context: "初级",
          official_type: 1,
          marketer_role_context: "点子王",
          score: "4.5",
          trade_num: "5",
          newTag: {
            area: [{ name: "222" }],
          },
        }
      },
    },
  },
  watch: {},
  data() {
    return {}
  },
  computed: {
  // 是否是当前用户
    isLoginUser() {
      if (this.$store.state.userInfo) {
        if (this.item.id === this.$store.state.userInfo.id) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    followHandle(item) {
      event.stopPropagation()
      followApi({ follow_uid: item.id }).then((res) => {
        if (res.code === 10000) {
          this.$message.success("关注成功！")
          this.$set(item, "is_follow", true)
        }
      })
    },
    /*
     * @Description: 取消关注
     * @MethodAuthor:  liulian
     * @Date: 2022-07-22 09:42:15
     * @param: ''
     */
    cancelfollowHandle(item) {
      event.stopPropagation()
      cancelfollowApi(item.id).then((res) => {
        if (res.code === 10000) {
          this.$message.success("已取消关注")
           this.$set(item, "is_follow", false)
        }
      })
    },
    goDetail(item) {
      if (this.$route.path === "/yxyxsJg") {
        return
      }
      this.$router.push(`/yxyxsJg?id=${item.id}`)
    },
  },
}
</script>

<style scoped lang="scss">
.avatar_card {
  .card_box {
    display: flex;
    width: 100%;
    overflow: hidden;
    .content {
      display: flex;
    }
  }
  .card {
    width: 15.625rem;
    height: 21.4375rem;
    background: #ffffff;
    box-shadow: 0rem 0.125rem 0.375rem 0.0625rem rgba(20, 20, 43, 0.06);
    border-radius: 2rem;
    opacity: 1;
    border: 0.0625rem solid #eff0f6;
    margin-bottom: 1.9375rem;
    .avatar {
      width: 7.5rem;
      height: 7.5rem;
      border-radius: 100%;
      margin: 2.125rem auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-size: 100% 100%;
      object-fit: cover;
      .aratar-img {
        width: 7.5rem;
        height: 7.5rem;
        border-radius: 100%;
      }
      .icon {
        width: 2.375rem;
        height: 2.375rem;
        margin-left: 76px;
        margin-top: 15px;
        z-index: -1;
      }
      .top {
        height: 170px;
        width: 30px;
        padding-top: 80px;
      }
    }
    .guanzhu {
      width: 5rem;
      height: 3.625rem;
      background: #ffffff;
      border-radius: 3.25rem;
      opacity: 1;
      border: 0.0625rem solid #111029;
      display: block;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -0.8125rem;
      z-index: 44;
      margin-left: 1.25rem;
    }
    .icons {
      background-size: 100% 100%;
      width: 2.375rem;
      height: 2.375rem;
      margin-left: 5.125rem;
      margin-top: 2.125rem;
    }
    .icons_blue {
      background-image: url("../../assets/images/home/blue_icon@2x.png");
    }
    .icons_yellow {
      background-image: url("../../assets/images/home/yellow_icon@2x.png");
    }
    .card_con {
      margin: 3.5625rem auto;
      width: 13.625rem;
      .name {
        color: #111029;
        margin-top: -0.875rem;
      }
      .card_con-center {
        margin-top: 0.5rem;
        .degnji {
          margin-right: 0.375rem;
        }
      }
      .card_con-bottom {
        height: 2.375rem;
        span {
          height: 1.75rem;
          border-radius: 1.4375rem;
          opacity: 1;
          border: 0.0625rem solid #eff0f6;
          display: inline-block;
          line-height: 1.75rem;
          margin-top: 0.5rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
        span:nth-child(1) {
          margin-right: 6px;
        }
      }
    }
    .card_bottom {
      margin: -2.6875rem auto;
      width: 13.625rem;
      border-top: 0.0625rem solid #f4f4f4;
      .card_bottom-left {
        margin-top: 0.8125rem;
        img {
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 0.125rem;
        }
      }
      .card_bottom-right {
        margin-top: 0.8125rem;
        .jingyan {
          margin-left: 0.375rem;
        }
      }
    }
  }
}
</style>
