<!--
* @description 首页
* @fileName index.vue
* @author liulian
* @date 2022/06/08 09:25:57
-->
<template>
  <div class="home-container">
    <div class="top">
      <div class="top_box">
        <div class="title flex-column-center">
          <span class="text-align-center">快速成长的品牌</span>
          <span class="text-align-center">都在用keyy.社媒营销案例工厂</span>
        </div>
        <div class="search_box">
          <el-input placeholder="" v-model="inputValue" @keyup.enter.native="searchHandle"> </el-input>
          <div class="append" @click="searchHandle"><img class="cursor-pointer" src="@/assets/images/home/home_search@2x.png" alt="" /></div>
        </div>
        <div class="hotword_box font-size-16">
          <div class="hot_title font-size-16 font-weight-500">行业热搜：</div>
          <div class="word_box">
            <div :key="index" v-for="(item, index) in tags" @click="clickWord(item)">
              <span class="cursor-pointer font-size-16 font-weight-400">#{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="keyy_box flex">
      <div class="left_box">
        <img class="out_img" src="@/assets/images/home/home_left_icon@2x.png" alt="" />
        <img class="key_img" src="@/assets/images/home/key@2x.png" alt="" />
      </div>
      <div class="right_box">
        <div class="right_title flex">
          <img src="@/assets/images/home/home_right_icon@2x.png" alt="" />
          <span class="font-size-24">什么是KEYY</span>
        </div>
        <div class="right_con font-size-20 pre-wrap">{{ webInfo.web_info_introduction }}</div>
        <div class="right_btn">
          <el-button type="primary" @click="keyYDetail">查看详情</el-button>
        </div>
      </div>
    </div>
    <!-- 加背景 -->
    <div class="bg_box">
      <!-- 需求大厅 -->
      <div class="xqdt_box">
        <div class="filter_box flex-row-between screenWidth">
          <div class="left">
            <filterSelect class="item" :filters="filters" @vals="valsHandle"> </filterSelect>
          </div>
          <div class="right cursor-pointer" @click="goMore('需求大厅')">
            <span class="font-size-20">查看更多</span>
            <img src="@/assets/images/gengduo@2x.png" alt="" />
          </div>
        </div>
        <div class="xq screenWidth">
          <demandHall :query="hallQueryInfo"></demandHall>
        </div>
      </div>

      <!-- 爆款案例推荐 -->
      <div class="baokuan screenWidth">
        <div class="bkal">
          <div class="bkal_top flex-row-between screenWidth">
            <span class="bkal_top_left">爆款案例推荐</span>
            <div class="bkal_top_right cursor-pointer flex" @click="goMore('爆款案例')">
              <span class="font-size-20">更多</span>
              <img src="@/assets/images/gengduo@2x.png" alt="" />
            </div>
          </div>
          <div class="bkal_con">
            <bkaltj></bkaltj>
          </div>
        </div>
      </div>
      <!-- 优秀营销师 -->
      <div class="yxs screenWidth">
        <div class="yxs_top flex-row-between">
          <span class="yxs_top_left">优秀社媒顾问</span>
          <div class="yxs_top_right cursor-pointer flex" @click="goMore('优秀营销师')">
            <span class="font-size-20">更多</span>
            <img src="@/assets/images/gengduo@2x.png" alt="" />
          </div>
        </div>
        <div class="yxs_con">
          <yxyxs></yxyxs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import filterSelect from "@/components/common/filter-select.vue"
import yxyxs from "@/views/home/children/yxyxs.vue"
import bkaltj from "@/views/home/children/bkaltj.vue"
import demandHall from "@/components/common/demand-hall.vue"
import { hotKeywordListApi, webInfoApi } from "@/api/api.js"

export default {
  name: "index",
  components: { filterSelect, bkaltj, yxyxs, demandHall },
  data() {
    return {
      hotwords: [],
      inputValue: "", // 输入框绑定值
      filters: [
        {
          icon: require("@/assets/images/sjwl@2x.png"),
          name: "社交网络",
          isOpen: false,
          slot: "select1",
        },
        {
          icon: require("@/assets/images/schy@2x.png"),
          isOpen: false,
          name: "擅长行业",
          slot: "select2",
        },
        {
          icon: require("@/assets/images/scly@2x.png"),
          isOpen: false,
          name: "擅长领域",
          slot: "select3",
        },
      ],
      tags: [],
      // 需求大厅参数
      hallQueryInfo: {
        page: 1,
        limit: 30,
        sort: "time_desc",
        keyword: "",
        tag_arr: [], // 标签数组
      },
      webInfo: {}, // 网站信息
    }
  },
  computed: {},
  created() {
    this.getTags()
    this.getWebInfo()
  },
  mounted() {},
  methods: {
    /*
     * @Description: 获取网站信息
     * @MethodAuthor:  liulian
     * @Date: 2022-07-22 09:03:40
     * @param: ''
     */
    getWebInfo() {
      webInfoApi().then((res) => {
        if (res.code === 10000) {
          this.webInfo = res.data
        }
      })
    },
    /*
     * @Description: 获取行业热搜词
     * @MethodAuthor:  liulian
     * @Date: 2022-07-14 14:43:58
     * @param: ''
     */
    getTags() {
      hotKeywordListApi({ cate: "global" }).then((res) => {
        if (res.code === 10000) {
          res.data.length > 0 &&
            res.data.map((i) => {
              let obj = {
                name: i.keyword,
              }
              this.tags.push(obj)
            })
        }
      })
    },
    searchHandle() {
      this.$router.push("/gd")
      this.$store.dispatch("setSearchKey", this.inputValue)
    },
    /*
     * @Description: 筛选框选择
     * @MethodAuthor:  liulian
     * @Date: 2022-07-13 11:27:22
     * @param: ''
     */
    valsHandle(val) {
      this.hallQueryInfo.tag_arr = []
      val.map((item) => {
        if (item.name === "社交网络" || item.name === "擅长行业") {
          if (item.selects.length > 0) {
            item.selects.map((i) => {
              this.hallQueryInfo.tag_arr.push(i)
            })
          }
        }
        if (item.name === "擅长领域") {
          if (item.selects.length > 0) {
            item.selects.map((it) => {
              if (it.value.length > 0) {
                it.value.map((i) => {
                  this.hallQueryInfo.tag_arr.push(i)
                })
              }
            })
          }
        }
      })
    },
    /*
     * @Description: 点击了搜索词
     * @MethodAuthor:  liulian
     * @Date: 2022-07-07 16:14:42
     * @param: ''
     */
    clickWord(item) {
      this.inputValue = item.name
    },
    /*
     * @Description: keyy详情
     * @MethodAuthor:  liulian
     * @Date: 2022-06-16 09:15:53
     * @param: ''
     */
    keyYDetail() {
      this.$router.push("/keyYJj")
    },
    /*
     * @Description: 跳转到更多页面
     * @MethodAuthor:  liulian
     * @Date: 2022-06-10 17:09:10
     * @param: ''
     */
    goMore(key) {
      this.$store.dispatch("setSearchKey", "")
      // this.$router.push(`/gd?type=${key}`)
      let path = ""
      switch (key) {
        case "需求大厅":
          path = "/xqdt"
          break
        case "爆款案例":
          path = "/zbkal"
          break
        case "优秀营销师":
          path = "/zyxyxs"
          break
      }
      this.$router.push(path)
    },
    /*
     * @Description: 控制筛选框一次只能展开一个
     * @MethodAuthor:  liulian
     * @Date: 2022-06-08 16:11:04
     * @param: ''
     */
    selectHandle(key) {
      switch (key) {
        case "select1":
          this.headerConfig1.isOpen = true
          this.headerConfig2.isOpen = false
          this.headerConfig3.isOpen = false
          break
        case "select2":
          this.headerConfig1.isOpen = false
          this.headerConfig2.isOpen = true
          this.headerConfig3.isOpen = false
          break
        case "select3":
          this.headerConfig1.isOpen = false
          this.headerConfig2.isOpen = false
          this.headerConfig3.isOpen = true
          break
      }
    },
  },
}
</script>

<style scoped lang="scss">
.home-container {
  .top {
    background-image: url("../../assets/images/home/home-bg3@2x.png");
    // height: 1861px;
    height: 700px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // background-position: 0px -1.4rem !important;
  }
  .top_box {
    padding-top: 3.8rem;
    background: rgba(255, 255, 255, 0.75);
    // width: 780px;
    width: 86.25rem;
    // margin: 52px auto;
    margin: 21px auto;
    // min-height: 25.875rem;
    border-radius: 1rem 1rem 1rem 1rem;
    border: 1px solid #ffffff;
    padding-bottom: 3.2rem;
    

    .title {
      font-size: 3.375rem;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #111029;
      span {
        display: block;
      }
    }
    .search_box {
      width: 43.75rem;
      margin: 2rem auto;
      position: relative;
      .append {
        width: 5.25rem;
        height: 3.375rem;
        background: #111029;
        border-radius: 2.5rem;
        opacity: 1;
        position: absolute;
        top: 0.375rem;
        right: 0.375rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      ::v-deep .el-input__inner {
        height: 4.125rem;
        background: #ffffff;
        border-radius: 2.5rem;
        width: 43.75rem;
        opacity: 1;
        border: 1px solid #111029;
        position: relative;
      }
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .hotword_box {
      // width: 52.3125rem;
      width: 72.5625rem;
      font-weight: 100;
      color: #2f2c42;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-left: 38.125rem;
      // padding-left: 1.4375rem;
      margin: auto;

      .hot_title {
        white-space: nowrap;
        margin-right: 0.9375rem;
        // margin-left: 2.6875rem;
      }
      .word_box {
        display: flex;
        flex-wrap: wrap;
        width: 1065px;
        min-height: 106px;
        // border-image: linear-gradient(90deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)) 1 1;
        padding-top: 1.5rem;
        background-image: url("../../assets/images/home/border@2x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        div {
          // flex: 0 0 19%;
          margin-bottom: 1.125rem;
        }
        span {
          display: block;
          min-width: 9.3125rem;
          margin-left: 3.375rem;
          white-space: nowrap;
        }
      }
    }
  }
  .keyy_box {
    width: 71%;
    border-radius: 0px;
    opacity: 1;
    margin: 3.125rem auto;
    .left_box {
      width: 15.5rem;
      height: 15.5rem;
      position: relative;
      margin-left: 7.5rem;
      margin-right: 10rem;
      img {
        width: 15.5rem;
        height: 15.5rem;
      }

      .key_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 11.625rem;
        height: 11.625rem;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .left_box:hover {
      .key_img {
        -webkit-animation: bounce-in-top 1.1s both;
        animation: bounce-in-top 1.1s both;
      }
    }
    @-webkit-keyframes bounce-in-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
      }
      38% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
      }
      55% {
        -webkit-transform: translateY(-65px);
        transform: translateY(-65px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
      72% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
      81% {
        -webkit-transform: translateY(-28px);
        transform: translateY(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
      90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
      95% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
    }
    @keyframes bounce-in-top {
      0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
      }
      38% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
      }
      55% {
        -webkit-transform: translateY(-65px);
        transform: translateY(-65px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
      72% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
      81% {
        -webkit-transform: translateY(-28px);
        transform: translateY(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
      90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
      95% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
    }

    .right_box {
      .right_title {
        img {
          width: 3.7125rem;
          height: 3.7125rem;
          margin-right: 0.75rem;
        }
        span {
          height: 2.125rem;
          font-family: PingFang SC-Heavy, PingFang SC;
          font-weight: 800;
          color: #240f45;
          line-height: 1.75rem;
        }
      }
      .right_con {
        margin-top: 0.9375rem;
        max-width: 37.5rem;
        min-height: 10.5rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 100;
        color: #240f45;
        line-height: 1.4375rem;
        // display: -webkit-box; /*重点，不能用block等其他，将对象作为弹性伸缩盒子模型显示*/
        // /*从上到下垂直排列子元素*/
        // -webkit-box-orient: vertical;
        // /*行数，超出三行隐藏,用省略号表示...*/
        // -webkit-line-clamp: 6;
        // line-clamp: 6;
        // word-break: break-all;
        // overflow: hidden;
        width: 45.625rem;
        margin-bottom: 2.625rem;
      }
      .right_btn {
        .el-button {
          width: 7rem;
          height: 3.625rem;
          background: #111029;
          border-radius: 3.25rem;
          opacity: 1;
          font-size: 1rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 1.1875rem;
        }
        .el-button:hover {
          background: #3a71ff;
          border: none;
        }
      }
    }
  }

  .bg_box {
    background-image: url("../../assets/images/home/bgi@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .xqdt_box {
    width: 86.25rem;
    box-shadow: 0px 8px 28px 0px rgba(20, 20, 43, 0.1);
    height: 47.375rem;
    border-radius: 3.75rem;
    border: 1px solid #57596c;
    margin: 120px auto;
    overflow: hidden;
  }
  .filter_box {
    margin: 6.25rem auto;
    // border-top: 0.0625rem solid #eff0f6;
    // padding-top: 6.25rem;
    margin-left: 3.125rem;
    .right {
      height: 1.75rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #57596c;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.625rem;
      margin-right: 3.125rem;
      img {
        width: 1.25rem;
        height: 1.25rem;
        margin-left: 6px;
        margin-right: 3.125rem;
      }
    }
  }
  .xq {
    margin: -28px auto;
    margin-left: 3.125rem;
  }
  // 爆款案例
  .baokuan {
    // background-image: url("../../assets/images/home/Mask-bg@2x.png");
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    margin: -7.5rem auto;
    .bkal {
      margin: 13.75rem auto;
      // padding-top: 6.25rem;
      padding-bottom: 5.75rem;
      overflow: hidden;
      .bkal_top {
        margin: auto;
        .bkal_top_left {
          font-size: 2.75rem;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #240f45;
        }
      }
      .bkal_top_right {
        img {
          width: 1.25rem;
          height: 1.25rem;
        }
        span {
          font-weight: 500;
          color: #57596c;
          margin-right: 0.375rem;
        }
      }
      .bkal_con {
        margin-top: 5rem;
      }
    }
    ::v-deep .con .card {
      margin-left: 10px;
      margin-right: 16px;
    }
    ::v-deep .cover_card .card {
      margin-left: 0;
      margin-right: 0px;
    }
  }
  // 优秀营销师
  .yxs {
    margin: -7.5rem auto;
    .yxs_top {
      .yxs_top_left {
        font-size: 2.75rem;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #240f45;
      }
      .yxs_top_right {
        img {
          width: 1.25rem;
          height: 1.25rem;
        }
        span {
          font-weight: 500;
          color: #57596c;
          margin-right: 0.375rem;
        }
      }
    }
    .yxs_con {
      margin-top: 6.25rem;
      padding-bottom: 7.625rem;
    }
  }
}
</style>
