<!--
* @description 需求大厅
* @fileName index.vue
* @author liulian
* @date 2022/06/08 09:37:58
-->
<template>
  <div class="xqdt screenWidth">
    <!-- 展示部分 -->
    <div class="xqdt_box">
      <div class="top">
        <demandHall></demandHall>
      </div>
    </div>

    <!-- 筛选部分 -->
    <div class="filter">
      <!-- 搜索框 -->
      <div class="search_box">
        <el-input placeholder="「可按 小红书种草/抖音直播/品牌TVC等 关键词 筛选品牌需求」" v-model="inputValue" @keyup.enter.native="searchHandle"> </el-input>
                 <!-- 发布需求按钮 -->
      <span class="fb_btn font-size-14 font-weight-400" @click="fabu">发布需求</span>
        <div class="append" @click="searchHandle"><img class="cursor-pointer" src="@/assets/images/home/home_search@2x.png" alt="" /></div>
          
      </div>

      <!-- 搜索词 -->
      <div class="words">
   
        <div class="words_item" v-for="(item, index) in words" :key="index">
          <!-- <span v-if="item.type !== '角色'" class="type_span font-size-16 font-weight-400">
          {{ item.type }}：
          </span> -->

          <!-- 文字修改 -->
          <span v-if="item.type !== '角色'" class="type_span font-size-16 font-weight-400">
            <template v-if="item.type === '擅长行业'"> 行业领域： </template>
            <template v-if="item.type === '擅长领域'"> 传播内容： </template>
            <template v-if="item.type !== '擅长行业' && item.type !== '擅长领域'"> {{ item.type }}： </template>
          </span>
          <span v-else class="type_span font-size-16 font-weight-400 flex-row-between"><span class="font-size-16 font-weight-400">角</span><span class="font-size-16 font-weight-400">色：</span></span>
          <div class="word_right">
            <span class="first_span font-size-16 font-weight-400 cursor-pointer" :class="item.children[0].isActive ? 'is-active' : ''" :key="index" @click="clickWordHandle(item.children[0], item.children, item)">{{ item.children[0].name }}</span>
            <div class="right_rigth">
              <template v-for="(it, index1) in item.children">
                <span v-if="it.name !== '全部'" class="font-size-16 font-weight-400 cursor-pointer" :class="it.isActive ? 'is-active' : ''" :key="index1" @click="clickWordHandle(it, item.children, item)">{{ it.name }}</span>
              </template>
            </div>
          </div>
        </div>

        <!-- 排序区 -->
        <div class="sort text-align-right">
          <template v-if="queryInfo.sort === 'time_asc'">
            <div class="sort_btn flex cursor-pointer" @click="sortCoreHandle('time_asc')">
              <span class="font-size-20 font-weight-400">按时间排序</span>
              <div class="icon_s">
                <!-- 上面图标 -->
                <img class="icon cursor-pointer" src="@/assets/images/top_blue@2x.png" />
                <img class="icon bottom_icon cursor-pointer" src="@/assets/images/bottom_gray@2x.png" />
              </div>
            </div>
          </template>

          <!-- 下面图标 -->
          <template v-if="queryInfo.sort === 'time_desc'">
            <div class="sort_btn flex cursor-pointer" @click="sortCoreHandle('time_desc')">
              <span class="font-size-20 font-weight-400">按时间排序</span>
              <div class="icon_s">
                <!-- 上面图标 -->
                <img class="icon cursor-pointer" src="@/assets/images/top_gray@2x.png" />
                <img class="icon bottom_icon cursor-pointer" src="@/assets/images/bottom_blue@2x.png" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 列表区 -->
    <div class="list" v-if="lists.length > 0">
      <loading-more :completed="completed" :pageInit="queryInfo.page" @changeData="loadMore" :loadingIcon="loadingIcon" :key="loadingKey">
        <leftImageCard :item="item" v-for="(item, index) in lists" :key="index"></leftImageCard>
      </loading-more>
    </div>
    <emptyPage v-else></emptyPage>

    <up-top></up-top>
  </div>
</template>

<script>
import demandHall from "@/components/common/demand-hall.vue"
import leftImageCard from "@/components/common/left-image-card.vue"
import { getTagListApi, demandListApi } from "@/api/api"
import emptyPage from "@/components/common/empty-page.vue"
import loadingMore from "@/components/common/loadingMore.vue"
export default {
  name: "index",
  components: {
    demandHall,
    leftImageCard,
    emptyPage,
    loadingMore,
  },
  data() {
    return {
      lists: [], // 列表数组
      words: [], // 筛选关键词
      sort: 0, // 按时间排序
      inputValue: "", // 输入框
      showPage: false, // 是否展示空页面
      completed: false, // 加载
      loadingIcon: true, // 加载
      total: null, // 加载
      HallList: [], // 需求大厅滚动数据
      keys: new Date().getTime(),
      loadingKey: new Date().getTime(),
      queryInfo: {
        page: 1,
        limit: 10,
        sort: "time_desc", // 排序方式 time_desc,time_asc
        keyword: "", //关键词
        tag_arr: [], // 标签数组
        recruit_role: null, // 角色id
        demand_min_budget: null, // 最小价格
        demand_max_budget: null, // 最大价格
      },
      queryInfo2: {
        page: 1,
        limit: 30,
        sort: "time_desc", // 排序方式 time_desc,time_asc
      },
    }
  },
  computed: {},
  created() {
    this.getWords()
    this.getList()
  },
  mounted() {},
  methods: {
  /*
   * @Description: 发布需求
   * @MethodAuthor:  liulian
   * @Date: 2022-08-29 17:38:07
   * @param: '' 
  */
  fabu() {
  this.$router.push('/release')
  },
    loadMore(e) {
      if (this.total > 0) {
        let check = e > Math.ceil(this.total / this.queryInfo.limit)
        if (check) {
          this.completed = true
          this.loadingIcon = false
          return
        }
      }
      setTimeout((res) => {
        this.queryInfo.page = e
        this.getLoadList() // 重新请求数据
      }, 2000)
    },
    async getLoadList() {
      this.completed = false
      this.loadingIcon = true
      let data = await this.listApi()
      this.lists = this.lists.concat(data)
    },

    /*
     * @Description: 搜索事件
     * @MethodAuthor:  liulian
     * @Date: 2022-07-11 16:09:59
     * @param: ''
     */
    searchHandle() {
      this.queryInfo.keyword = this.inputValue
      this.getList()
    },

    sortCoreHandle(key) {
      this.queryInfo.sort = key === "time_desc" ? "time_asc" : "time_desc"
      this.getList()
    },
    /*
     * @Description: 获取标签列表
     * @MethodAuthor:  liulian
     * @Date: 2022-07-12 14:03:29
     * @param: ''
     */
    getTagsList() {
      return new Promise((resolve, rejects) => {
        getTagListApi().then((res) => {
          if (res.code === 10000) {
            resolve(res.data)
          }
        })
      })
    },
    sortHandle(key) {
      this.sort = key === 0 ? 1 : 0
    },
    /*
     * @Description: 点击筛选词
     * @MethodAuthor:  liulian
     * @Date: 2022-06-14 09:28:59
     * @param: ''
     */
    async clickWordHandle(item, arr, parentObj) {
      if (parentObj.key === "social" || parentObj.key === "industry" || parentObj.key === "area") {
        if (parentObj.selectList.length === 5 && !item.isActive) {
          this.$message.error("最多只能选择五个")
        }
        this.words.map((it) => {
          if (it.key === parentObj.key) {
            if (it.selectList.length !== 5) {
              this.$set(item, "isActive", !item.isActive)
              // 判断是否点击了全部，全部和其他标签不能并存
              if (item.isActive) {
                if (item.name !== "全部") {
                  this.$set(arr[0], "isActive", false)
                } else {
                  arr.map((i) => {
                    this.$set(i, "isActive", i.name === "全部")
                  })
                }
              } else {
                let check = arr.some((i) => {
                  if (i.isActive) {
                    return true
                  }
                })
                if (!check) {
                  this.$set(arr[0], "isActive", true)
                }

                if (parentObj.selectList.length === 0) {
                  this.$set(arr[0], "isActive", true)
                }
              }
            } else {
              this.$set(item, "isActive", false)
            }
          }
        })
      } else {
        arr.map((i) => {
          this.$set(i, "isActive", i.key === item.key)
        })
      }
      let data = await this.filterisActive(item)
      this.queryInfo.page = 1
      this.getListByWord(data)
    },
    // 重新发起请求
    /*
     * @Description: 根据选择的标签重新请求数据
     * @MethodAuthor:  liulian
     * @Date: 2022-07-12 16:01:40
     * @param: ''
     */
    getListByWord(data) {
      let ids = []
      data.length > 0 &&
        data.map((item) => {
          if (item.key === "role") {
            this.queryInfo.recruit_role = item.selectList || null
          }
          if (item.key === "price") {
            this.queryInfo.demand_min_budget = item.minValue || null
            this.queryInfo.demand_max_budget = item.maxValue || null
          }
          if (item.key == "social" || item.key === "industry" || item.key === "area") {
            item.selectList.length > 0 &&
              item.selectList.map((i) => {
                if (i.name !== "全部") {
                  ids.push(i.id)
                }
              })
          }
        })
      this.queryInfo.tag_arr = ids
      this.getList()
    },
    /*
     * @Description: 将选中的值push到对应的字段中
     * @MethodAuthor:  liulian
     * @Date: 2022-07-12 14:43:48
     * @param: ''
     */
    filterisActive() {
      return new Promise((resolve, rejects) => {
        this.words.map((item) => {
          if (item.key === "social" || item.key === "industry" || item.key === "area") {
            item.selectList = []
            if (item.children.length > 0) {
              item.children.map((it) => {
                // 属于擅长领域
                if (it.isActive) {
                  item.selectList.push(it)
                }
              })
            }
          }
          if (item.key === "role") {
            item.children.map((i) => {
              if (i.isActive) {
                this.$set(item, "selectList", i.value)
              }
            })
          }
          if (item.key === "price") {
            item.children.map((i) => {
              if (i.isActive) {
                this.$set(item, "minValue", i.min)
                this.$set(item, "maxValue", i.max)
              }
            })
          }
        })
        resolve(this.words)
      })
    },

    listApi() {
      let _this = this
      return new Promise((resolve, rejects) => {
        demandListApi(this.queryInfo).then((res) => {
          if (res.code === 10000) {
            // _this.showPage = this.lists.length === 0
            _this.total = res.count
            resolve(res.data)
          }
        })
      })
    },
    async getList() {
      this.queryInfo.page = 1
      this.completed = false
      this.loadingIcon = true
      this.lists = await this.listApi()
    },
    /*
     * @Description: 获取筛选的词语
     * @MethodAuthor:  liulian
     * @Date: 2022-06-13 18:31:01
     * @param: ''
     */
    async getWords() {
      let data = await this.getTagsList()
      this.words = [
        {
          type: "角色",
          key: "role",
          children: [
            {
              name: "全部",
              value: null,
            },
            {
              name: "个人",
              value: 1,
            },
            {
              name: "机构",
              value: 2,
            },
          ],
        },
        {
          type: "社交网络",
          key: "social",
          children: [],
        },
        {
          type: "擅长行业",
          key: "industry",
          children: [],
        },
        {
          type: "擅长领域",
          key: "area",
          children: [],
        },
        {
          type: "预算区间",
          key: "price",
          children: [
            {
              name: "全部",
              min: null,
              max: null,
            },
            {
              name: "0-5k",
              min: 0,
              max: 5000,
            },
            {
              name: "5k-1万",
              min: 5000,
              max: 10000,
            },
            {
              name: "1万-5万",
              min: 10000,
              max: 50000,
            },
            {
              name: "5万以上",
              min: 50000,
              max: 1000000,
            },
          ],
        },
      ]
      this.words.map((item) => {
        data.length > 0 &&
          data.map((it) => {
            // 非擅长领域
            if (item.key === it.bind && item.key !== "area") {
              item.children = it.children
              let obj = {
                name: "全部",
                value: null,
              }
              item.children.unshift(obj)
            }
            // 擅长领域
            if (item.key === it.bind && item.key === "area") {
              let arr = []
              it.children.length > 0 &&
                it.children.map((i) => {
                  arr = arr.concat(i.children)
                })
              item.children = arr
              let obj = {
                name: "全部",
                value: null,
              }
              item.children.unshift(obj)
            }
          })
      })
      this.stylewords()
    },
    /*
     * @Description: 词的点击样式
     * @MethodAuthor:  liulian
     * @Date: 2022-06-14 09:23:35
     * @param: ''
     */
    stylewords() {
      this.words.map((item, index) => {
        if (item.key === "social" || item.key === "industry" || item.key === "area") {
          this.$set(item, "selectList", [])
        }
        if (item.key === "role") {
          this.$set(item, "selectList", null)
        }
        if (item.key === "price") {
          this.$set(item, "minValue", null)
          this.$set(item, "maxValue", null)
        }
        if (item.children.length > 0) {
          item.children.map((i, index1) => {
            this.$set(i, "key", `${index}-${index1}`)
            this.$set(i, "isActive", i.name === "全部")
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.xqdt {
  margin: auto;
  .xqdt_box {
    width: 86.25rem;
    box-shadow: 0px 8px 28px 0px rgba(20, 20, 43, 0.1);
    border-radius: 3.75rem;
    border: 1px solid #57596c;
    margin: 2.5rem auto;
    overflow: hidden;
  }
  .top {
    margin-top: 2.5rem;
    padding-bottom: 4rem;
    margin-left: 3.125rem;
  }
  .filter {
    .search_box {
      width: 43.75rem;
      margin: 3.25rem auto;
      position: relative;
      .append {
        width: 5.25rem;
        height: 3.375rem;
        background: #111029;
        border-radius: 2.5rem;
        opacity: 1;
        position: absolute;
        top: 0.375rem;
        right: 0.375rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      ::v-deep .el-input__inner {
        height: 4.125rem;
        background: #ffffff;
        border-radius: 2.5rem;
        width: 43.75rem;
        opacity: 1;
        border: 1px solid #111029;
        position: relative;
      }
      img {
        width: 1.5rem;
        height: 1.5rem;
      }

      ::v-deep.el-input__inner::-webkit-input-placeholder {
        color: #a0a3bd;

        font-size: 1.25rem;
        font-weight: 400;
      } /*Chrome/Opera/Safari*/
      ::v-deep .el-input__inner::-moz-placeholder {
        color: #a0a3bd;

        font-size: 1.25rem;
        font-weight: 400;
      } /*Firefox 19*/
      ::v-deep.el-input__inner:-ms-input-placeholder {
        color: #a0a3bd;

        font-size: 1.25rem;
        font-weight: 400;
      } /*id10+*/
      ::v-deep .el-input__inner:-moz-placeholder {
        color: #a0a3bd;
        font-size: 1.25rem;
        font-weight: 400;
      }
    }
    .fb_btn {
      display: block;
      width: 8rem;
      height: 3rem;
      line-height: 3rem;
      background: #ffffff;
      border-radius: 47px;
      opacity: 1;
      // border: 1px solid #2f2c42;
      font-size: 1rem;
      font-weight: 400;
      color: #2f2c42;
      margin-top: 5rem;
      text-align: center;
      position: absolute;
      top: -72px;
      right: -305px;
      cursor: pointer;
      color: #fff;
      background-color: #111029;
    }
  }
  .words {
    position: relative;

    .words_item {
      display: flex;
      margin-bottom: 1rem;
      .type_span {
        display: block;
        min-width: 5rem;
        width: auto !important;
        color: #240f45;
        justify-content: space-between;
        flex-wrap: nowrap;
        display: flex;
        span {
          display: block;
        }
      }
      .word_right {
        display: flex;
        span {
          color: #240f45;
          margin-right: 1rem;
          height: 1.375rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          white-space: nowrap;
        }
        .is-active {
          line-height: 1.375rem;
          background: #111029;
          border-radius: 1.3125rem;
          opacity: 1;
          color: #ffffff;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
        .first_span {
          display: block;
        }
        .right_rigth {
          display: flex;
          width: auto;
          flex-wrap: wrap;
          span {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .sort {
    position: absolute;
    right: 0;
    top: 0;
    margin-bottom: 1rem;
    display: flex;

    .sort_btn {
      width: 10rem;
      height: 3.25rem;
      background: #ffffff;
      border-radius: 1rem;
      border: 1px solid #eff0f6;
      color: #111029;
      display: flex;
      justify-content: center;

      .icon_s {
        display: flex;
        flex-direction: column;
        margin-left: 1.0625rem;
      }
      .icon {
        width: 0.625rem;
        height: 0.3906rem;
      }
      .bottom_icon {
        margin-top: 0.125rem;
      }
    }
  }
  .list {
    min-height: 300px;
  }
}
</style>
