<!--
* @description 弹框
* @fileName menu-con.vue
* @author liulian
* @date 2022/07/07 08:57:48
-->
<template>
  <div ref="menuBox">
    <div v-if="visible" class="menus_con">
     <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "menu-con",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  watch: {
    show: function (val) {
      this.visible = val
    },
    // 监听子组件的值传给父组件，更新dom
    visible: function (val) {
      this.$emit("update:show", val)
    },
  },
  data() {
    return {
      visible: false, // 是否显示弹框
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.checkClick()
  },
  methods: {
    showMenu() {
      this.visible = !this.visible
    },
    checkClick() {
      document.addEventListener("click", (e) => {
        if (this.$refs.menuBox) {
          let isSelf = e.target.getAttribute("menuId")
          if (!isSelf) {
            this.$emit("update:show", false)
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.menus_con {
  width: 4.25rem;
  background-color: #fff;
  position: absolute;
  top: 1.25rem;
  right: -1.625rem;
  border: 1px solid #eee;
  border-radius: 0.25rem;
  z-index: 111;
  -webkit-box-shadow: -0.0625rem 0.0625rem 0.8125rem 0rem rgba(10, 8, 10, 0.21);
  -moz-box-shadow: -0.0625rem 0.0625rem 0.8125rem 0rem rgba(10, 8, 10, 0.21);
  box-shadow: -0.0625rem 0.0625rem 0.8125rem 0rem rgba(10, 8, 10, 0.21);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.625rem;

}
</style>
