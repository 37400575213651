<!--
* @description wps
* @fileName index.vue
* @author liulian
* @date 2022/07/04 14:42:57
-->
<template>
  <div id="wpsId">
    <wpsView v-if="showWps" v-bind:wpsUrl="jwpsUrl" v-bind:token="jtoken" :key="key" ></wpsView>
  </div>
</template>

<script>
import wpsView from "@/components/common/wpsView.vue";
export default {
  name: "index",
  components: { wpsView },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.path === "/wps") {
          this.showWps = true;
        } else {
          this.showWps = false;
        }
       this.key = new Date().getTime()
      },
      // 深度观察监听
      immediate: true,
    },
  },
  data() {
    return {
      jwpsUrl: "",
      jtoken: "",
      key: "",
      showWps:false
    };
  },
  computed: {},
  created() {
    this.getInfo();
  },
  mounted() {},
  methods: {
    /*
     * @Description: 获取数据
     * @MethodAuthor:  liulian
     * @Date: 2022-07-04 16:21:51
     * @param: ''
     */
    getInfo() {
      let info = JSON.parse(sessionStorage.getItem("wspInfo"));
      this.jwpsUrl = info.url;
      this.jtoken = info.token;
    },
  },
};
</script>

<style scoped lang="scss"></style>
