<template>
    <!-- 执行中 -->
    <div id="productdetail">
        <div class="container">
            <p class="tle">
                项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称项目名称
            </p>
            <div class="project-no">
                <div class="num">项目编号：873539479658</div>
                <div class="price">
                    <img src="@/assets/images/price.png" alt="">
                    ￥5000
                </div>
                <div class="data">
                    <img src="@/assets/images/date.png" alt=""></img>
                    30天
                </div>
            </div>
            <div class="userinfor">
                <div class="user">
                    <img src="" alt="">
                    <p>姓名姓名姓名</p>
                </div>
                <div class="information">
                    <p>
                        <img src="../../../../assets/images/userprofile/user.png" alt="">
                        <span>周华</span>
                    </p>
                    <span>/</span>
                    <p>
                        <img src="../../../../assets/images/userprofile/mobel.png" alt=""></img>
                        <span>17079100088</span>
                    </p>
                    <span>/</span>
                    <p>
                        <img src="../../../../assets/images/userprofile/email.png" alt=""></img>
                        <span>kpzssens@qq.com</span>
                    </p>
                    <el-button>私信</el-button>
                </div>

            </div>
            <div class="notice">
                <img src="../../../../assets/images/userprofile/notice.png" alt="">
                <p>需求方未同意您的项目划分，请修改重新发送给需求方</p>
            </div>
            <div class="content">
                <!-- 步骤条 -->
                <steps :stepsLabel="stepsLabel" :totalSteps="totalSteps" :currentStep="currentStep"></steps>
                <!-- 阶段列表 -->
                <div class="stage">
                    <div class="list">
                        <div class="list-tle">
                            <div class="tle">
                                <p>P1阶段</p>
                                <span> |</span>
                                <p>阶段名称</p>
                            </div>
                            <div class="icon" style="background: #EFF0F6;color: #A0A3BD;">
                                <img src="../../../../assets/images/yijieshu.png" alt="">
                                已结束
                            </div>
                        </div>
                        <div class="list-content">
                            <div class="deliverdata">
                                <p>计划交付日期：<span>2022-05-26</span></p>
                                <p>实际交付日期<span>2022-05-26</span></p>
                            </div>
                            <div class="price">
                                <p>
                                    计划金额
                                    <span>￥12000</span>
                                </p>
                                <p>
                                    实际金额
                                    <span>￥12000</span>
                                </p>
                            </div>
                            <div class="explain">
                                交付说明
                                <span>交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明：交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明</span>
                            </div>
                            <div class="file">
                                交付文档
                                <div class="file-box">
                                    xxxx.ppt
                                </div>
                            </div>

                        </div>

                        <div class="btn">
                            <p>需求方通过了您的「阶段验收」申请，阶段已验收</p>
                        </div>

                    </div>

                </div>


                <div class="stage" style="margin-top:1.8125rem">
                    <div class="list">
                        <div class="list-tle">
                            <div class="tle">
                                <p>P1阶段</p>
                                <span> |</span>
                                <p>阶段名称</p>
                            </div>
                            <!-- <div class="icon" style="background: #DEF2E6;color: #11845B;">
                                <img src="../../../../assets/images/userprofile/zhixingzhong.png" alt="">
                                执行中
                            </div> -->
                            <div class="icon" style="background: #EFF0F6;color: #A0A3BD;">
                                <img src="../../../../assets/images/yijieshu.png" alt="">
                                已结束
                            </div>
                        </div>
                        <div class="list-content">
                            <div class="deliverdata">
                                计划交付日期：<span>2022-05-26</span>
                            </div>
                            <div class="price">
                                计划金额
                                <span>￥12000</span>
                            </div>
                            <div class="explain">
                                交付说明
                                <span>交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明：交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明交付说明</span>
                            </div>
                            <div class="file">
                                交付文档
                                <div class="file-box">
                                    xxxx.ppt
                                </div>
                            </div>



                        </div>
                        <div class="btn">
                            <p>需求方通过了您的「阶段验收」申请，阶段已验收</p>
                            <el-button v-show="false">提交验收</el-button>
                        </div>
                    </div>

                </div>
                <div class="score">
                    <p>需求方完成评分</p>
                    <el-rate v-model="value" disabled show-score text-color="#ff9900" score-template="{value}">
                    </el-rate>
                </div>
                <div class="edit">
                    <el-button>重新编辑</el-button>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import steps from "../../../../components/steps/step.vue"
export default {
    components: {
        steps
    },
    data() {
        return {
            value: 3.7,
            stepsLabel: ["创建项目", "待需求方确认", "执行中", "已结束"],//步骤条数组
            totalSteps: 4,
            currentStep: 1, //步骤条执行到第几步
        }
    }

}
</script>
<style lang="scss" scoped>
#productdetail {
    width: 100%;
    height: auto;
    overflow: hidden;

    .container {
        margin: 0 auto;
        max-width: 86.25rem;
        padding: 2.5rem 3.125rem;
        height: 100%;

        .tle {
            font-size: 1.25rem;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #111029;
            line-height: 1.4375rem;
        }

        .project-no {
            margin-top: 2rem;
            font-size: 1.125rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 100;
            color: #2F2C42;
            line-height: 1.3125rem;
            display: flex;
            align-items: center;

            .price {
                margin: 0 3.5rem 0 4.0625rem;
            }

            .price,
            .data {
                display: flex;
                align-items: center;
                color: #57596C;
                font-size: 1rem;

                img {
                    display: block;
                    margin-right: .5rem;
                }
            }

        }

        .userinfor {
            margin-top: 4rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .user {
                display: flex;
                align-items: center;

                img {
                    display: block;
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                }

                p {
                    margin-left: 1rem;
                    font-size: 1.25rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1.4375rem;
                }
            }

            .information {
                display: flex;
                align-items: center;

                p {
                    margin: 0 2rem;
                    display: flex;
                    align-content: center;

                    img {
                        display: block;
                        width: 1.25rem;
                        height: 1.25rem;
                        margin-right: .5rem;
                    }
                }

                .el-button {
                    width: 4.25rem;
                    height: 2.375rem;
                    background: #111029;
                    border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                    font-size: .875rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                }
            }
        }

        .notice {
            margin-top: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: auto;
            background: #FFEFF0;
            border-radius: .625rem .625rem .625rem .625rem;

            img {
                display: block;
                width: 2rem;
                height: 2rem;
            }

            p {
                padding: 1.3125rem 0;
                font-size: 1rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #DC2B2B;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            padding: 4rem 2rem;
            margin-top: 2rem;
            background: #F7F7FC;
            border-radius: .625rem .625rem .625rem .625rem;

            .stage {
                background: #FFFFFF;
                padding: 1rem 2rem;
                height: auto;
                border-radius: .625rem .625rem 0px 0px;

                .list {
                    display: flex;
                    flex-direction: column;

                    .list-tle {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .tle {
                            display: flex;
                            align-items: center;

                            :nth-child(1) {
                                font-size: 1.125rem;
                                font-family: PingFang SC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #111029;
                                line-height: 1.3125rem;
                            }

                            :nth-child(3) {
                                font-size: 1.125rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #2F2C42;
                                line-height: 1.3125rem;
                            }

                            span {
                                display: inline-block;
                                margin: 0 2rem;
                            }
                        }

                        .icon {
                            display: flex;
                            align-items: center;
                            background: #DEF2E6;
                            border-radius: 2.1875rem 2.1875rem 2.1875rem 2.1875rem;
                            padding: .625rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #11845B;
                            line-height: 1.1875rem;

                            img {
                                display: block;
                                margin-right: .25rem;

                            }
                        }
                    }

                    .list-content {
                        margin-top: 1rem;
                        padding: 2rem 0;
                        border-top: 1px solid #DCDDEB;
                        border-bottom: 1px solid #DCDDEB;

                        .deliverdata {
                            display: flex;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #2F2C42;
                            line-height: 1.1875rem;

                            p {
                                &:nth-child(2) {
                                    margin-left: 2.7rem;
                                }
                            }

                            span {
                                display: inline-block;
                                margin-left: 1rem;
                                color: #57596C;
                            }
                        }

                        .price {
                            display: flex;
                            margin: 1.0625rem 0 1.125rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #111029;
                            line-height: 1.1875rem;

                            p {
                                &:nth-child(2) {
                                    margin-left: 7.5625rem;
                                }
                            }

                            span {
                                display: inline-block;
                                margin-left: 1rem;
                                color: #FF6868;
                                font-weight: bold;
                            }
                        }

                        .explain {
                            display: flex;
                            align-items: flex-start;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #2F2C42;
                            line-height: 1.1875rem;
                            white-space: nowrap;

                            span {
                                white-space: pre-wrap;
                                display: inline-block;
                                margin-left: 1rem;
                                color: #57596C;
                            }
                        }

                        .file {
                            display: flex;
                            margin-top: 2rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #2F2C42;
                            line-height: 1.1875rem;

                            .file-box {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #57596C;
                                line-height: 1.1875rem;
                                margin-left: 1rem;
                                width: 15.0625rem;
                                height: 3.75rem;
                                background: #F7F7FC;
                                border-radius: .625rem .625rem .625rem .625rem;
                            }
                        }

                    }

                    .btn {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        // margin-left: auto;
                        margin-top: 2rem;

                        p {
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #57596C;
                            line-height: 1.1875rem;
                        }

                        .el-button {
                            margin-left: auto;
                            width: 7rem;
                            height: 3.625rem;
                            background: #111029;
                            border-radius: 3.25rem 3.25rem 3.25rem 3.25rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #FFFFFF;
                            line-height: 1.1875rem;

                        }
                    }
                }
            }

            .score {
                margin-left: auto;
                margin-top: 2rem;
                display: flex;

                p {
                    margin-right: 1rem;
                    font-size: 1.25rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2F2C42;
                    line-height: 1.4375rem;
                }

            }

            .edit {
                margin: 0 auto;

                .el-button {
                    width: 7rem;
                    height: 3.625rem;
                    background: #FFFFFF;
                    border-radius: 3.25rem 3.25rem 3.25rem 3.25rem;
                    border: 1px solid #111029;
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #111029;
                    line-height: 1.1875rem;
                }
            }
        }
    }
}
</style>