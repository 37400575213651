/******手机号校验13,14,15,16,17,18,19开头******/
export const isPhone = (value) => /^(?:(?:\+|00)86)?1[3-9]\d{9}$/g.test(value)
/******邮箱校验******/
export const isEmail = (value) => /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/.test(value)
/******用户名校验******/
//  export const isUsername =value => /^\w{0,10}$/.test(value)
export const isUsername = (value) => /^.{0,10}$/.test(value)
//  密码校验
export const isPassword = (value) => /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/.test(value)
// 机构标识校验1到6位英文数字混合
export const isUniCode = (value) => /^[a-zA-Z0-9]{0,6}$/.test(value)
// 机构名称
export const isUniCodeName = (value) => /^.{0,10}$/.test(value)
// 邮箱
export const checkEmail = (rule, value, cb) => {
  // 验证邮箱的正则表达式
  const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
  if (regEmail.test(value)) {
    // 合法的邮箱
    return cb()
  }
  cb(new Error("请输入正确的邮箱"))
}
//   手机号
export const checkMobile = (value, cb) => {
  // 验证手机号的正则表达式
  const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
  if (regMobile.test(value)) {
    return cb()
  }
  cb(new Error("请输入正确的手机号"))
}

