<!--
* @description 案例编辑器
* @fileName index.vue
* @author liulian
* @date 2022/06/08 09:36:46
-->
<template>
  <div class="albjq" id="albjq">
    <div class="top_btn flex-row-between">
      <!-- <el-button class="add_btn" type="primary" @click="newTemplate">新建<i class="el-icon-plus"></i></el-button> -->
      <div class="left_box">
        <img class="icon" src="@/assets/images/albjq/edit_icon@2x.png" />
        <div class="box_right">
          <div class="font-size-20 font-weight-400">案例编辑器</div>
          <span class="text font-size-16 font-weight-400">Dashboard</span>
        </div>
      </div>

      <!-- 导入文件 -->
      <el-upload class="upload-demo" :data="upload.datas" :on-success="onSuccess" :multiple="false" :limit="1" :action="upload.url" :headers="upload.headers" :show-file-list="false" :before-upload="beforeUploadHandle" accept=".ppt,.pptx" :on-error="onErrorHandle">
        <el-button plain class="upload_btn"><span>导入</span><img src="@/assets/images/daoru@2x.png" alt="icon" /></el-button>
      </el-upload>
    </div>
    <div class="content_main">
      <!-- 侧边栏目 -->
      <div class="slide_box">
        <div class="menu_list">
          <a class="menu_item cursor-pointer" :class="item.isActive ? 'active' : ''" v-for="(item, index) in listArry" :key="index" @click="clickItem(item, index)">{{ item.type }}</a>
        </div>
      </div>
      <div class="slide_box2"></div>

      <!-- 右侧内容 -->
      <div class="content_right" id="content">
        <template v-for="(item, index) in listArry">
          <!-- 编辑区 -->
          <template v-if="item.key === 'editArea' && curryIndex === index">
            <div class="model_con">
              <div class="card_con">
                <div class="card_type font-size-24 font-weight-500">
                  <span>新建</span>
                </div>
                <div class="card_row flex">
                  <!-- 新建模块 -->
                  <template>
                    <newTemplate></newTemplate>
                  </template>
                </div>
              </div>
            </div>
            <div class="model_con">
              <div class="card_con">
                <div class="card_type font-size-24 font-weight-500">
                  <span>更多模板</span>
                </div>
                <div class="card_row flex">
                  <!-- 更多模板 -->
                  <template>
                    <div class="card cursor-pointer" v-for="(it, index1) in templList" :key="index1" @click="goDetail(it)">
                      <div class="avatar_box">
                        <img class="cover" :src="it.case_cover" alt="封面图" />
                        <!-- <img class="icon" src="@/assets/images/shangchuan@2x.png" /> -->
                      </div>
                      <div class="card_center flex">
                        <img class="avatar" :src="it.avatar" alt="头像" />
                        <div class="card_center_right">
                          <div class="name font-size-16 font-weight-500">
                            {{ it.username }}
                          </div>
                          <div class="tas_box flex">
                            <tag-btn :config="{ text: '初级', type: 'red' }"></tag-btn>
                            <tag-btn class="green" :config="{ text: '点子王', type: 'green' }"></tag-btn>
                          </div>
                        </div>
                      </div>
                      <div class="ms font-size-14 font-weight-400">
                        {{ it.case_name }}
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <!-- 我的创作 -->
          <template v-if="item.key === 'create' && curryIndex === index">
            <div class="model_con">
              <div class="card_con">
                <div class="card_type font-size-24 font-weight-500">
                  <span>我的创作</span>
                </div>
                <div class="card_row flex">
                  <!-- 我的创作 -->
                  <loading-more v-if="createList.length > 0" :completed="completed" @changeData="loadMore" :loadingIcon="loadingIcon">
                    <div class="flex my_create">
                      <div class="card" v-for="(it, index1) in createList" :key="index1">
                        <div class="avatar_box cursor-pointer" @click="editHandle(it)">
                          <!-- <img class="cover" :src="require('@/assets/images/albjq/covers/cover_' + it.imgKey + '.png')" alt="封面图" /> -->
                          <img class="cover" :src="it.cover" alt="封面图" :key="new Date().getTime() + index1" />
                          <!-- <img class="icon" src="@/assets/images/shangchuan@2x.png" /> -->
                        </div>
                        <div class="title font-size-16 font-weight-500 cursor-pointer" @click="editHandle(it)">
                          {{ it.filename }}
                        </div>
                        <div class="time flex-row-between">
                          <span class="font-size-14 font-weight-400"> {{ it.created_at }}</span>
                          <i class="el-icon-more cursor-pointer" @click="showMenu(it, createList)" menuId="menuId"></i>

                          <MenuCon class="menu_box" :show.sync="it.showTip" :key="index1">
                            <div class="con">
                              <div class="cursor-pointer" @click="uploadHandle(it)">上传</div>
                              <div class="cursor-pointer" @click="delHandle(it, 'create')">删除</div>
                              <div class="cursor-pointer" @click="downloadHandle(it)">下载</div>
                              <div class="cursor-pointer" @click="renameHandle(it, 'create')">重命名</div>
                            </div>
                          </MenuCon>
                        </div>
                      </div>
                    </div>
                  </loading-more>
                </div>
              </div>
            </div>
          </template>
          <!-- 购买案例 -->
          <template v-if="item.key === 'buy' && curryIndex === index">
            <div class="model_con">
              <div class="card_con">
                <div class="card_type font-size-24 font-weight-500">
                  <span>购买案例</span>
                </div>
                <div class="card_row flex">
                  <!-- 购买案例 -->
                  <loading-more v-if="buyCaseList.length > 0" :completed="completed1" @changeData="loadMore1" :loadingIcon="loadingIcon1">
                    <div class="flex my_create">
                      <div class="card" v-for="(it, index1) in buyCaseList" :key="index1">
                        <div class="avatar_box cursor-pointer" @click="editHandle(it)">
                          <!-- <img class="cover" :src="require('@/assets/images/albjq/covers/cover_' + it.imgKey + '.png')" alt="封面图" /> -->
                          <img class="cover" :src="it.cover" alt="封面图" :key="index1" />
                          <!-- <img class="icon" src="@/assets/images/shangchuan@2x.png" /> -->
                        </div>
                        <div class="title font-size-16 font-weight-500 cursor-pointer" @click="editHandle(it)">
                          {{ it.filename }}
                        </div>
                        <div class="time flex-row-between">
                          <span class="font-size-14 font-weight-400"> {{ it.created_at }}</span>
                          <i class="el-icon-more cursor-pointer" @click="showMenu(it, buyCaseList)" menuId="menuId"></i>

                          <MenuCon class="menu_box" :show.sync="it.showTip" :key="index1">
                            <div class="con">
                              <div class="cursor-pointer" @click="uploadHandle(it)">上传</div>
                              <div class="cursor-pointer" @click="delHandle(it, 'buy')">删除</div>
                              <div class="cursor-pointer" @click="downloadHandle(it)">下载</div>
                              <div class="cursor-pointer" @click="renameHandle(it, 'buy')">重命名</div>
                            </div>
                          </MenuCon>
                        </div>
                      </div>
                    </div>
                  </loading-more>
                </div>
              </div>
            </div>
          </template>
          <!-- 最近编辑 -->
          <template v-if="item.key === 'editNow' && curryIndex === index">
            <div class="model_con editNow">
              <div class="card_con">
                <div class="card_type font-size-24 font-weight-500">
                  <span>最近编辑</span>
                </div>
                <div class="card_row flex">
                  <!-- 最近编辑 -->
                  <template >
                    <div class="card" v-for="(it, index1) in editNow">
                      <div class="avatar_box cursor-pointer" @click="editHandle(it)">
                        <!-- <img class="cover" :src="require('@/assets/images/albjq/covers/cover_' + it.imgKey + '.png')" alt="封面图" /> -->
                        <img class="cover" :src="it.cover" alt="封面图" :key="it.random" :id="`img${index1}`" />
                        <!-- <img class="icon" src="@/assets/images/shangchuan@2x.png" /> -->
                      </div>
                      <div class="title font-size-16 font-weight-500 cursor-pointer" @click="editHandle(it)">
                        {{ it.filename }}
                      </div>
                      <div class="time flex-row-between">
                        <span class="font-size-14 font-weight-400"> 更新于{{ it.updated_at }}</span>
                        <i class="el-icon-more cursor-pointer" @click="showMenu(it, editNow)" menuId="menuId"></i>
                        <MenuCon class="menu_box" :show.sync="it.showTip" :key="index1">
                          <div class="con">
                            <div class="cursor-pointer" @click="uploadHandle(it)">上传</div>
                            <div class="cursor-pointer" @click="delHandle(it, 'edit')">删除</div>
                            <div class="cursor-pointer" @click="downloadHandle(it)">下载</div>
                            <div class="cursor-pointer" @click="renameHandle(it, 'edit')">重命名</div>
                          </div>
                        </MenuCon>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <up-top></up-top>

    <!-- 重命名弹框 -->
    <dialogCpnVue :show.sync="renameDialog" :title="'重命名'" width="400px" @isOk="submitForm" @cancel="cancelHandle">
      <el-form :model="renameForm" ref="renameForm" label-width="80px" class="demo-ruleForm">
        <el-form-item label="文件名称">
          <el-input type="age" v-model="renameForm.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
    </dialogCpnVue>
  </div>
</template>

<script>
import { getWpsTokenApi, recentFileListApi, allFileListApi, deleteFileApi, renameFileApi, fileEditorUrlApi, recommendedListApi, uploadOssApi, buyFileListApi } from "@/api/api.js"
import loadingMore from "@/components/common/loadingMore.vue"
import MenuCon from "@/components/common/menu-con.vue"
import newTemplate from "@/views/albjq/children/addTemplate.vue"
import dialogCpnVue from "@/components/common/dialog-cpn.vue"

export default {
  name: "index",
  components: { loadingMore, MenuCon, newTemplate, dialogCpnVue },
  data() {
    return {
      listArry: [
        {
          type: "编辑区",
          list: [],
          key: "editArea",
          isActive: true,
        },
        {
          type: "我的创作",
          list: [],
          key: "create",
          isActive: false,
        },
        {
          type: "购买案例",
          list: [],
          key: "buy",
          isActive: false,
        },
        {
          type: "最近编辑",
          list: [],
          key: "editNow",
          isActive: false,
        },
      ],
      upload: {
        key: new Date().getTime(),
        headers: {
          Authorization: this.$store.state.token,
        },
        // url: "/ph/api/case/workspace/uploadFile",
        url: "",
        datas: {}, // 上传附带参数
        wpsUrl: "", // wpsUrl
      },
      // loading: false,
      // infiniteScrollDisabled: false,
      query: {
        page: 1, // 当前页数
        limit: 10, // 请求条数
      },
      renameForm: {
        name: "",
        id: null,
      },
      curryIndex: 0, // 当前展示的模块
      mycreateTotal: false, // 总条数
      buyCaseTotal: false, // 总条数
      completed: false, // 显示加载
      completed1: false, // 显示加载
      loadingIcon: true, // 显示加载
      loadingIcon1: true, // 显示加载
      loadings: null, // 上传列表
      templList: [], // 更多模板
      editNow: [], // 最近编辑
      createList: [], // 我的创作
      buyCaseList: [], // 购买案例
      renameDialog: false,
      nowbje: {},
      case: {
        query: {
          limit: 10,
          page: 1,
          type: 1,
        },
      },
    }
  },
  computed: {},
  created() {
    this.checkRoute()
    this.getList()
    this.getCaseList()
    this.getMyCreate()
  },
  mounted() {},
  methods: {
    /*
     * @Description: 取消
     * @MethodAuthor:  liulian
     * @Date: 2022-09-07 17:20:38
     * @param: ''
     */
    cancelHandle() {
      this.renameForm.name = ""
    },
    submitForm() {
      if (!this.renameForm.name) {
        this.$message.error("文件名称不能为空！")
        return
      }
      this.renameForm.id = this.nowbje.id
      renameFileApi(this.renameForm).then(async (res) => {
        if (res.code === 10000) {
          this.$message.success("重命名成功！")
          this.renameForm.name = ""
          // 判断属于那个模块的重命名，刷新数据
          switch (this.nowbje.type) {
            case "edit":
              this.editNow = await this.getRecentEdits()
              break
            case "create":
              this.getMyCreate()
              break
            case "buy":
              this.getCaseList()
              break
          }
        }
      })
    },
    /*
     * @Description: 文件重命名
     * @MethodAuthor:  liulian
     * @Date: 2022-09-07 16:33:18
     * @param: ''
     */
    renameHandle(item, type) {
      this.renameDialog = true
      this.nowbje = item
      this.nowbje.type = type
      let name = item.filename.split(".")
    },
    /*
     * @Description: 点击菜单
     * @MethodAuthor:  liulian
     * @Date: 2022-08-19 17:14:55
     * @param: ''
     */
    async clickItem(item, index) {
      this.curryIndex = index
      this.listArry.map((it) => {
        this.$set(it, "isActive", it.key === item.key)
      })
      // 判断属于那个模块的重命名，刷新数据
      switch (this.curryIndex) {
        case 1:
        this.getMyCreate()
          break
        case 2:
        this.getCaseList()
          break
        case 3:
          this.editNow = await this.getRecentEdits()
          break
      }
    },
    /*
     * @Description: 判断是否是从支付成功之后进行跳转
     * @MethodAuthor:  liulian
     * @Date: 2022-08-05 11:34:38
     * @param: ''
     */
    checkRoute() {
      if (this.$route.query.total_amount) {
        this.$message.info("借鉴的案例展示在我的创作模块")
      }
    },
    /*
     * @Description: 跳转案例详情
     * @MethodAuthor:  liulian
     * @Date: 2022-07-14 15:45:08
     * @param: ''
     */
    goDetail(item) {
      this.$router.push(`/zbkalDetail?id=${item.case_id}`)
    },
    onErrorHandle(file) {},
    showMenu(it, list) {
      list.map((item) => {
        if (item.key === it.key) {
          this.$set(item, "showTip", !item.showTip)
        } else {
          this.$set(item, "showTip", false)
        }
      })
    },
    /*
     * @Description: 上传案例
     * @MethodAuthor:  liulian
     * @Date: 2022-07-07 09:52:12
     * @param: ''
     */
    uploadHandle(item) {
      this.$router.push({ path: "/uploadcase", query: { uploadid: item.id, uploadname: item.filename } })
    },
    /*
     * @Description: 下载
     * @MethodAuthor:  liulian
     * @Date: 2022-07-07 09:48:19
     * @param: ''
     */
    downloadHandle(item) {
      window.open(item.url)
    },
    /*
     * @Description: 删除事件
     * @MethodAuthor:  liulian
     * @Date: 2022-07-06 16:34:58
     * @param: ''
     */
    delHandle(item, type) {
      event.stopPropagation()
      this.$messagebox
        .confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          if (type === "create") {
            this.delCreate(item.id)
          } else if (type === "edit") {
            this.delEdit(item.id)
          } else if (type === "buy") {
            this.delBuy(item.id)
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        })
    },
    /*
     * @Description: 删除我的创作
     * @MethodAuthor:  liulian
     * @Date: 2022-08-22 14:50:18
     * @param: ''
     */
    delCreate(id) {
      let _this = this
      deleteFileApi(id).then(async(res) => {
        if (res.code === 10000) {
          this.$message.success("删除成功！")
          this.createList = await this.createApi()
        }
      })
    },
    /*
     * @Description: 删除最近编辑
     * @MethodAuthor:  liulian
     * @Date: 2022-08-22 14:51:52
     * @param: ''
     */
    delEdit(id) {
      deleteFileApi(id).then(async (res) => {
        if (res.code === 10000) {
          this.$message.success("删除成功！")
          this.editNow = await this.getRecentEdits()
        }
      })
    },
    /*
     * @Description: 删除购买案例
     * @MethodAuthor:  liulian
     * @Date: 2022-08-22 17:03:58
     * @param: ''
     */
    delBuy(id) {
      let _this = this
      deleteFileApi(id).then(async(res) => {
        if (res.code === 10000) {
          this.$message.success("删除成功！")
          this.buyCaseList = await this.caseApi()
        }
      })
    },

    /*
     * @Description: 编辑事件
     * @MethodAuthor:  liulian
     * @Date: 2022-07-06 16:49:15
     * @param: ''
     */
    editHandle(item) {
      this.$router.push(`/alWps?id=${item.id}`)
      // this.getWpsToken()
      // fileEditorUrlApi(item.id).then((res) => {
      //   if (res.code === 10000) {
      //     let obj = {
      //       url: res.data.redirect_url,
      //       token: this.$store.state.wpsToken,
      //     }
      //     sessionStorage.setItem("wspInfo", JSON.stringify(obj))
      //     this.$router.push("/wps")
      //   }
      // })
    },
    async loadMore(e) {
      let _this = this
      if (this.mycreateTotal > 0) {
        let check = e > Math.round(this.mycreateTotal / this.query.limit)
        if (check) {
          this.completed = true
          this.loadingIcon = false
          return
        }
      }
      let data = await this.createApi()
      setTimeout((res) => {
        _this.query.page = e
        // this.getMyCreate() // 重新请求数据
        _this.createList = _this.createList.concat(data)
      }, 3000)
    },
    async loadMore1(e) {
      let _this = this
      if (this.buyCaseTotal > 0) {
        let check = e > Math.round(this.buyCaseTotal / this.case.query.limit)
        if (check) {
          this.completed1 = true
          this.loadingIcon1 = false
          return
        }
      }
      let data = await this.caseApi()
      setTimeout((res) => {
        this.case.query.page = e
        // this.getMyCreate() // 重新请求数据
        _this.buyCaseList = _this.buyCaseList.concat(data)
      }, 3000)
    },
    /*
     * @Description: 新建模板
     * @MethodAuthor:  liulian
     * @Date: 2022-07-05 12:24:19
     * @param: ''
     */
    newTemplate() {
      this.$router.push("/addTemplate")
    },
    async beforeUploadHandle(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1)
      const extension = testmsg === "ppt"
      const extension2 = testmsg === "pptx"
      const isLt2M = file.size / 1024 / 1024 < 200
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 .ppt，.pptx格式!",
          type: "warning",
        })
        return
      }
      if (!isLt2M) {
        this.$message({
          message: "上传文件不能超过 200M!",
          type: "warning",
        })
        event.stopPropagation() // 阻止事件冒泡到父元素
        return
      }
      this.getWpsToken()
      let data = await this.uploadFileOss(file.name)
      this.upload.datas.policy = data.policy
      this.upload.datas.OSSAccessKeyId = data.accessid
      this.upload.datas.signature = data.signature
      this.upload.datas.callback = data.callback
      let files = file.name
      let fileExtension = files.substring(files.lastIndexOf("."))
      let key = data.dir + Number(Math.random().toString().substr(3, 6) + Date.now()).toString(36) + fileExtension
      this.upload.datas.key = key
      // this.upload.wpsUrl=data.callback
      this.upload.url = data.host
      this.loadings = this.$loading({
        background: "rgba(36,15,69,0.5)",
        text: "上传中...",
      })
    },
    uploadFileOss(file) {
      return new Promise((resolve, reject) => {
        uploadOssApi({ file_original_name: file }).then((res) => {
          if (res.code === 10000) {
            resolve(res.data)
          }
        })
      })
    },
    getWpsToken() {
      getWpsTokenApi().then((res) => {
        if (res.code === 10000) {
          this.$store.commit("SET_WPSTOKEN", res.data)
        }
      })
    },
    onSuccess(res) {
      if (res.code === 10000) {
        // let obj = {
        //   url: res.data.redirect_url,
        //   token: this.$store.state.wpsToken,
        // }
        // sessionStorage.setItem("wspInfo", JSON.stringify(obj))
        this.loadings.close()
        let id = res.data.file_id
        this.$router.push(`/alWps?id=${id}`)
        // this.$router.push("/wps")
      } else {
        if (res.message) {
          this.$message.error(res.response.message)
          this.loadings.close()
        }
      }
    },

    async getList() {
      let data = await this.getSelect()
      this.editNow = await this.getRecentEdits()
    },
    /*
     * @Description: 获取购买案例
     * @MethodAuthor:  liulian
     * @Date: 2022-08-22 11:20:10
     * @param: ''
     */
    async getCaseList() {
      this.buyCaseList = await this.caseApi()
    },
    /*
     * @Description: 案例订单api
     * @MethodAuthor:  liulian
     * @Date: 2022-08-22 11:21:23
     * @param: ''
     */
    caseApi() {
      return new Promise((resolve, reject) => {
        buyFileListApi(this.case.query).then((res) => {
          if (res.code === 10000) {
            this.buyCaseTotal = res.count
            if (res.count < this.case.query.limit) {
              this.completed1 = true
              this.loadingIcon1 = false
            }
            res.data.length > 0 &&
              res.data.map((it) => {
                it.imgKey = parseInt(Math.random() * (10 - 0) + 0)
                this.$set(it, "key", this.GetRandomNum(0, 1000000))
                // 解决图片不重新加载
                let src = `${it.cover}?r=${Math.random()}`
                it.cover = src
              })
            resolve(res.data)
          }
        })
      })
    },
    /*
     * @Description: 数据格式画
     * @MethodAuthor:  liulian
     * @Date: 2022-07-06 17:47:11
     * @param: ''
     */
    initStyle(arr) {
      arr.length > 0 &&
        arr.map((item) => {
          if (item.list && item.list.length > 0) {
            item.list.map((i) => {
              this.$set(i, "key", this.GetRandomNum(0, 1000000))
              this.$set(i, "showTip", false)
            })
          }
        })
    },

    GetRandomNum(Min, Max) {
      var Range = Max - Min
      var Rand = Math.random()
      return Min + Math.round(Rand * Range)
    },
    /*
     * @Description: 获取更多模板
     * @MethodAuthor:  liulian
     * @Date: 2022-06-15 09:10:04
     * @param: ''
     */
    getSelect() {
      return new Promise((resolve, reject) => {
        recommendedListApi().then((res) => {
          if (res.code === 10000) {
            this.templList = res.data.length > 0 && res.data.slice(0, 5)
            // this.listArry.map((item) => {
            //   if (item.type === "更多模板") {
            //     item.list = res.data.length > 0 && res.data.slice(0, 5)
            //   }
            // })
            resolve(this.templList)
          }
        })
      })
    },
    /*
     * @Description: 获取最近编辑
     * @MethodAuthor:  liulian
     * @Date: 2022-06-15 09:10:35
     * @param: ''
     */
    getRecentEdits() {
      return new Promise((resolve, reject) => {
        recentFileListApi().then((res) => {
          if (res.code === 10000) {
            res.data.length > 0 &&
              res.data.map((i, index) => {
                i.imgKey = parseInt(Math.random() * (10 - 0) + 0)
                this.$set(i, "key", this.GetRandomNum(0, 1000000))
                // 解决图片不重新加载
                let src = `${i.cover}?r=${Math.random()}`
                i.cover = src
              })
            // this.editNow = res.data
            resolve(res.data)
            // this.listArry.map((item) => {
            //   if (item.type === "最近编辑") {
            //     item.list = res.data
            //     item.list.length > 0 &&
            //       item.list.map((i) => {
            //         i.imgKey = parseInt(Math.random() * (10 - 0) + 0)
            //       })
            //   }
            // })
            // resolve(this.listArry)
          }
        })
      })
    },
    /*
     * @Description: 我的创作
     * @MethodAuthor:  liulian
     * @Date: 2022-07-05 11:18:54
     * @param: ''
     */
    async getMyCreate() {
      this.createList = await this.createApi()
    },

    /*
     * @Description: 我的创作
     * @MethodAuthor:  liulian
     * @Date: 2022-08-22 14:26:15
     * @param: ''
     */
    createApi() {
      return new Promise((resolve, reject) => {
        allFileListApi(this.query).then((res) => {
          this.mycreateTotal = res.count
          if (res.code === 10000) {
            res.data.length > 0 &&
              res.data.map((item) => {
                item.imgKey = parseInt(Math.random() * (10 - 0) + 0)
                this.$set(item, "key", this.GetRandomNum(0, 1000000))
                // 解决图片不重新加载
                let src = `${item.cover}?r=${Math.random()}`
                item.cover = src
              })
            resolve(res.data)
            if (res.count < this.query.limit) {
              this.completed = true
              this.loadingIcon = false
            }
            this.initStyle(res.data)
          }
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
.albjq {
  margin: auto;
  ::v-deep .loadMore {
    width: 100%;
  }
  .card_type {
    color: #111029;
    margin-top: 2rem;
    margin-bottom: 1rem;
    span {
      margin-left: 0.75rem;
    }
  }
  .card_row {
    flex-wrap: wrap;
    .card {
      width: 15.625rem;
      margin-right: 1.425rem;
      background-color: #fff;
      min-height: 272px;
      margin-bottom: 2rem;
      border-radius: 10px;
      border: 1px solid #eff0f6;
      .cover {
        width: 100%;
        height: 11.8125rem;
        border-radius: 0.625rem;
      }
      .avatar_box {
        display: flex;
        position: relative;
        .cover {
          width: 100%;
          height: 11.8125rem;
          object-fit: cover;
        }
        .icon {
          position: absolute;
          width: 2rem;
          height: 2rem;
          border-radius: 0.625rem;
          right: 0.625rem;
          bottom: 0.625rem;
        }
      }
      .card_center {
        margin-top: 1rem;
        margin-bottom: 1rem;
        .avatar {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 100%;
          margin-left: 16px;
        }
        .card_center_right {
          margin-left: 0.625rem;
          .name {
            color: #111029;
            margin-bottom: 0.125rem;
          }
          .green {
            margin-left: 0.375rem;
          }
        }
      }
      .ms {
        height: 2.5rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        word-break: break-all;
        overflow: hidden;
        margin-left: 16px;
      }
      .title {
        color: #111029;
        margin: 1rem auto;
        margin-bottom: 0.5rem;
        width: 15.5rem;
        height: 1.375rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        word-break: break-all;
        overflow: hidden;
        width: 90%;
      }
      .time {
        color: #57596c;
        position: relative;
        width: 90%;
        margin: auto;
      }
      .menu_box {
        position: absolute;
        top: 0.25rem;
        right: -0.625rem;
        z-index: 111;

        .con {
          margin-top: 0.625rem;
          div {
            margin-bottom: 0.3125rem;
            font-size: 0.875rem;
            font-weight: 400;
          }
        }
      }
    }
  }
  .top_btn {
    display: flex;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 5.375rem;
    background: #ffffff;
    border: 1px solid #dcddeb;
    align-items: center;
    position: fixed;
    top: 106px;
    left: 0;
    width: 98%;
    z-index: 9;
    .left_box {
      display: flex;
      align-items: center;
      .icon {
        width: 50px;
        height: 50px;
        margin-right: 16px;
      }
      div {
        color: #111029;
      }
      .text {
        color: #a0a3bd;
      }
    }
    ::v-deep .el-button {
      width: 7.5rem;
      height: 3.625rem;
      border-radius: 3.125rem;
      opacity: 1;
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 100;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      i {
        margin-left: 0.625rem;
      }
      img {
        width: 1.375rem;
        height: 1.375rem;
        margin-left: 0.625rem;
      }
    }
  }
  .content_main {
    display: flex;
    position: relative;
    .slide_box {
      width: 18%;
      height: 100vh;
      // background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      border: 1px solid #dcddeb;
      border-bottom: transparent;
      position: fixed;
      top: 12rem;
      left: 0;
      .menu_list {
        width: 86%;
        margin: 16px auto;
        display: flex;
        flex-direction: column;
        .menu_item {
          width: 85%;
          height: 54px;
          line-height: 3.375rem;
          border-radius: 50px 50px 50px 50px;
          opacity: 1;
          border: 0px solid #111029;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          color: #111029;
          margin-bottom: 8px;
        }
        .active {
          background: #3a71ff;
          border-radius: 50px 50px 50px 50px;
          opacity: 1;
          border: 0px solid #111029;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
    .slide_box2 {
      width: 18%;
    }
    .content_right {
      background: #f7f7fc;
      flex: 1;
      overflow: hidden;
      // overflow-y: auto;
      padding-top: 190px;
      min-height: 80vh;

      .model_con {
        width: 100%;
        padding-left: 5%;
      }
      .editNow {
        padding-bottom: 100px;
      }

      #new:target,
      #select:target,
      #edit:target {
        padding-top: 200px; // 这里更改padding-top的值使其定位到不同地方
      }
    }
  }
  .loading {
    text-align: center;
    margin-top: 3.375rem;
    .loadingIcon {
      font-size: 3.125rem;
      margin: auto;
    }
  }
  .my_create {
    flex-wrap: wrap;
  }
  .top_btn {
    .add_btn {
      background: #111029;
      border: none;
    }
    .upload_btn {
      border: 1px solid #111029;
      color: #111029;
    }
    .upload_btn:hover {
      border: 1px solid #111029;
      color: #111029;
    }
  }
}
</style>
