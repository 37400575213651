<template>
  <div class="needs2">
    <div class="container">
      <h1>{{ title }}</h1>
      <el-form :model="releaseform" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="案例标题" prop="name">
          <el-input type="text" placeholder="请输入案例标题" v-model="releaseform.name" maxlength="20" show-word-limit> </el-input>
        </el-form-item>
        <el-form-item label="项目案值" prop="price">
          <el-input type="number" placeholder="请输入项目案值" v-model="releaseform.price">
            <i slot="suffix">元</i>
          </el-input>
        </el-form-item>
        <div v-for="item in tag" :key="item.id" class="tag" :style="{ alignItems: item.bind == 'social' || item.bind == 'industry' ? 'flexStart' : 'stretch', marginBottom: item.bind == 'social' || item.bind == 'industry' ? '1rem' : '0rem' }">
          <p class="category">{{ item.name }}</p>
          <div v-if="item.bind == 'area'">
            <div class="" v-for="(item1, index1) in item.children" :key="index1">
              <div class="tags-box">
                <p>{{ item1.name }}</p>
                <div class="tags">
                  <ul>
                    <li v-for="(item2, index2) in item1.children" :key="index2" :class="item2.isActive == true ? 'isactive' : ''" @click="onTag(item2, item.bind, item.children)">
                      {{ item2.name }}
                    </li>
                    <li class="custom" @click="onCustom(item1, item.children, item1.bind)" v-if="!item1.onInput"><span>自定义</span><i class="el-icon-plus"></i></li>
                    <li v-else class="cusinp">
                      <el-input v-model="tagslist.contentvalue" @blur="onInputBlur(tagslist.contentvalue, item1.bind)"> </el-input>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.bind == 'social' || item.bind == 'industry'">
            <div class="tags-box soc">
              <div class="tags" style="margin-bottom: 0px">
                <ul>
                  <li v-for="(item1, index1) in item.children" :key="index1" :class="item1.isActive == true ? 'isactive' : ''" @click="onTag(item1, item.bind, item.children)">
                    {{ item1.name }}
                  </li>
                  <li class="custom" @click="onCustomtwo(item, item.children, item.bind)" v-if="!item.onInput"><span>自定义</span><i class="el-icon-plus"></i></li>
                  <li v-else class="cusinp">
                    <el-input v-model="tagslist.socialvalue" @blur="onInputBlur(tagslist.socialvalue, item.bind)"> </el-input>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <el-form-item label="案例描述" id="describe" style="align-items: flex-start" prop="introduction">
          <el-input type="textarea" placeholder="请尽量详细描述您的需求" v-model="releaseform.introduction" maxlength="2000" show-word-limit> </el-input>
        </el-form-item>
        <el-form-item label="案例封面" style="align-items: flex-start" prop="cover" class="tip_item">
          <el-upload :file-list="fileListCover" class="upload-demo fengmian cover" drag :action="uploadurlimg" multiple :show-file-list="false" :before-upload="beforeUploadOne" :on-success="onSuccessOne" :headers="importHeaders" v-loading="coverloading">
            <img :src="releaseform.cover" alt="" v-if="releaseform.cover != ''" class="cover" />
            <img v-else src="@/assets/images/userprofile/plus.png" alt="" style="width: 2rem; height: 1.875rem" />
          </el-upload>

          <span class="tip_span">建议尺寸1150*600</span>
        </el-form-item>
        <!-- <el-form-item label="案例图片" style="align-items: flex-start" id="caseup" class="tip_item" prop="imgs">
          <el-upload ref="myUpload" :limit="limitSize" :action="uploadurlimg" class="anliimg" :class="{hide:hideUpload}" :headers="importHeaders" :show-file-list="fileshow" :file-list="fileList" list-type="picture-card" :before-upload="beforeUploadOne" :on-success="onSuccessTwo" :on-remove="onRemove" :on-change="handleImgChange">
            <i >
              <img src="@/assets/images/userprofile/plus.png" style="width: 2rem; height: 1.875rem" alt="" />
            </i>
          </el-upload>  
          <span class="tip_span">建议尺寸1150*600</span>
        </el-form-item> -->
        <el-form-item label="PPT文档" style="align-items: flex-start; minwidth: 10rem" id="ppt" prop="file_id">
          <el-upload class="upload-demo uploadppt" :data="upload.datas" drag :action="upload.url" :headers="upload.headers" multiple :show-file-list="false" v-loading="loadingfile" :before-upload="beforeUploadTwo" :on-success="onSuccessPPT">
            <img src="@/assets/images/userprofile/upload.png" alt="" />
            <!-- <p v-if="pptName != ''">{{ pptName }}</p> -->
            <p>上传PPT</p>
          </el-upload>

          <div class="file_boxs" v-if="pptName != ''"><img src="@/assets/images/userprofile/Frame.png" alt="" /> {{ pptName }}</div>
        </el-form-item>
        <el-form-item label="是否借鉴" prop="allow_buy">
          <el-radio-group @change="clearValidate" v-model="releaseform.allow_buy">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item id="relesebtn">
          <el-button type="primary" @click="submitForm('ruleForm')">{{ subtitle }}</el-button>
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
var mytoken = localStorage.getItem("token")
import { addCase, getTagOperateApi, uploadFiles, caseEdit, editCase, exaimCase, againCase, uploadOssApi } from "@/api/api"
export default {
  data() {
    return {
      fileshow: false,
      loadingfile: false,
      coverloading: false,
      subtitle: "提交",
      title: "上传案例",
      detailId: "",
      tag_id_arr: [],
      radio: "",
      tagsindex: null,
      flaginput1: false,
      flaginput2: false,
      flaginput3: false,
      flaginput4: false,
      flaginput5: false,
      tag: [],
      fileListCover: ["https://e3f49eaa46b57.cdn.sohucs.com/2021/11/10/14/27/MTAwMTIyXzE2MzY1MjU2NDQ4OTM=.png"],
      custom_tag_arr: [],
      fileList: [],
      tagslist: {
        contentvalue: "",
        implevalue: "",
        flowvalue: "",
        socialvalue: "",
        industryvalue: "",
      },
      filename: "",
      pptName: "",
      uoloadPPT: "/ph/api/case/workspace/uploadFile",
      uploadurlimg: "/ja/upload/oss/img",
      importHeaders: { Authorization: mytoken },
      uploadimg: require("@/assets/images/userprofile/upload.png"),
      releaseform: {
        custom_tag_arr: [],
        custom_tag_arr: [],
        cover: "",
        // 案例图片
        imgs: [],
        allow_buy: "",
        file_id: null,
      },

      imglist: [],
      uploadIndex: 0,
      inputVisible: false,
      inputValue: "",
      rules: {
        name: [
          { required: true, message: "请输入需求标题", trigger: "blur" },
          { min: 3, max: 20, message: "长度在 3 到 20 个字符", trigger: "blur" },
        ],
        price: [{ required: true, message: "请输入项目案值", trigger: "blur" }],
        introduction: [{ required: true, message: "请输入案例描述", trigger: "blur" }],
        cover: [{ required: true, message: "请上传案例封面", trigger: "change" }],
        imgs: [{ required: true, message: "请上传案例图片", trigger: "change" }],
        file_id: [{ required: true, message: "请上传PP案例", trigger: "blur" }],
        allow_buy: [{ required: true, message: "请选择是否借鉴", trigger: "change" }],
      },

      upload: {
        key: new Date().getTime(),
        headers: {
          Authorization: this.$store.state.token,
        },
        // url: "/ph/api/case/workspace/uploadFile",
        url: "",
        datas: {}, // 上传附带参数
        wpsUrl: "", // wpsUrl
      },
      loadings: null, // 上传列表
      limitSize: 10,
      hideUpload: false, // 是否隐藏
      loadingAni: null, // 是否隐藏
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.detailId = this.$route.query.id
      if (this.$route.query.status == 0) {
        this.title = "编辑案例"
        this.getCaseDetail()
      } else {
        this.title = "编辑案例"
        this.getExaimCase()
      }
      this.subtitle = "提交"
    } else {
      this.title = "上传案例"
      this.subtitle = "发布"
      if (this.$route.query.uploadid) {
        this.pptName = this.$route.query.uploadname
        this.releaseform.file_id = this.$route.query.uploadid
        this.getTagList()
      } else {
        this.getTagList()
      }
    }
  },
  methods: {
    handleImgChange(file, fileList) {
      this.hideUpload = fileList.length >= this.limitSize
    },
    clearValidate() {
      this.$refs.ruleForm.clearValidate("allow_buy")
    },
    // 案例详情
    getCaseDetail() {
      caseEdit(this.detailId).then((res) => {
        if (res.code == 10000) {
          let imgs = []
          this.releaseform = res.data
          this.releaseform.allow_buy = res.data.allow_buy.toString()
          imgs = res.data.imgs.split(",")
          this.releaseform.file_id = "001"
          this.uploadIndex = imgs.length
          imgs.map((e, index) => {
            this.fileList.push({
              name: "img",
              url: e,
            })
            this.imglist.push(e)
          })
          this.fileshow = true
          this.pptName = res.data.filename
          this.tag_id_arr = res.data.tag_id_arr
          this.getTagList()
        }
      })
    },
    // 审核信息详情
    getExaimCase() {
      var that_ = this
      exaimCase(that_.detailId).then((res) => {
        if (res.code == 10000) {
          let img = []
          that_.releaseform = res.data.case
          that_.releaseform.allow_buy = res.data.case.allow_buy.toString()
          img = res.data.case.imgs.split(",")
          that_.releaseform.file_id = "001"
          that_.uploadIndex = img.length
          img.map((e, index) => {
            that_.fileList.push({
              name: "img",
              url: e,
            })
            this.imglist.push(e)
          })
          that_.fileshow = true
          that_.pptName = res.data.case.filename
          that_.getTagList()
        }
      })
    },
    // 获取标签列表
    getTagList() {
      getTagOperateApi().then((res) => {
        if (res.code == 10000) {
          res.data.map((e) => {
            e.children.map((ch1) => {
              // 判断有无children属性
              if (ch1.hasOwnProperty("children") == true) {
                ch1.children.map((ch2) => {
                  if (this.$route.query.id) {
                    if (this.tag_id_arr.includes(ch2.id) == true) {
                      ch2.isActive = true
                    } else {
                      ch2.isActive = false
                    }
                  } else {
                    ch2.isActive = false
                  }
                  ch2.custorm = 0
                })
              } else {
                if (this.$route.query.id) {
                  if (this.tag_id_arr.includes(ch1.id) == true) {
                    ch1.isActive = true
                  } else {
                    ch1.isActive = false
                  }
                } else {
                  ch1.isActive = false
                }
                ch1.custorm = 0
              }
            })
          })
          this.tag = res.data
          if (this.$route.query.id) {
            this.checkItem()
          }
        }
      })
    },
    onTag(item, tagname, arr) {
      let arrnum = []
      let industryNum = []
      let socailNum = []
      this.$set(item, "isActive", !item.isActive)
      if (tagname == "area") {
        arr.map((e) => {
          e.children.map((st) => {
            if (st.isActive == true) {
              arrnum.push(st)
            }
          })
        })
      } else if (tagname == "industry") {
        arr.map((e) => {
          if (e.isActive == true) {
            industryNum.push(e)
          }
        })
      } else if (tagname == "social") {
        arr.map((e) => {
          if (e.isActive == true) {
            socailNum.push(e)
          }
        })
      }
      if (arrnum.length > 5 || industryNum.length > 5 || socailNum.length > 5) {
        this.$message.error("最多只能选5个")
        this.$set(item, "isActive", false)
        return
      } else {
        this.checkItem()
      }
    },
    checkItem() {
      var arr = []
      var custom = []
      this.tag.map((e) => {
        e.select = []
        e.children.map((item) => {
          if (item.hasOwnProperty("children") == true) {
            item.children.map((i) => {
              if (i.custorm == 0 && i.isActive == true) {
                arr.push(i.id)
              } else if (i.custorm == 1 && i.isActive == true) {
                custom.push({
                  parent_bind: i.bind,
                  name: i.name,
                })
              }
            })
          } else {
            if (item.custorm == 0 && item.isActive == true) {
              arr.push(item.id)
            } else if (item.custorm == 1 && item.isActive == true) {
              custom.push({
                parent_bind: item.bind,
                name: item.name,
              })
            }
          }
        })
      })
      this.releaseform.common_tag_arr = arr
      this.releaseform.custom_tag_arr = custom
    },
    onCustom(item, children, bind) {
      let arr = children
      arr.map((e) => {
        this.$set(e, "onInput", e.bind === bind)
      })
    },
    onCustomtwo(item, children, bind) {
      if (item.bind == bind) {
        this.$set(item, "onInput", true)
      } else {
        this.$set(item, "onInput", false)
      }
    },
    // 自定义tag标签
    onInputBlur(tagname, bind) {
      this.tag.map((e) => {
        if (e.bind == "area") {
          e.children.map((i) => {
            if (i.onInput == true) {
              i.children.push({
                isActive: false,
                name: tagname,
                custorm: 1,
                bind: bind,
              })
              this.$set(i, "onInput", false)
              this.tagslist.contentvalue = ""
            }
          })
        } else {
          if (e.onInput == true) {
            if (tagname != "") {
              e.children.push({
                isActive: false,
                name: tagname,
                custorm: 1,
                bind: bind,
              })
              this.$set(e, "onInput", false)
              this.tagslist.socialvalue = ""
            } else {
              this.$set(e, "onInput", false)
            }
          }
        }
      })
    },
    // 上传图片

    handleClose(tag) {
      this.releaseform.tagsone.splice(this.releaseform.tagsone.indexOf(tag), 1)
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        this.releaseform.tagsone.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ""
    },
    // 上传封面
    beforeUploadOne(file) {
      // this.coverloading=true
      this.loadingAni = this.$loading({
        text: "上传中...", //加载图标下的文字
        // spinner: 'el-icon-loading', //加载图标
        background: "rgba(36, 15, 69, 0.5)", //背景色
        // customClass:'loading' //自定义样式的类名
      })
      if (file.type !== "image/png" && file.type !== "image/jpeg") {
        this.$message.error("仅支持上传jpg/png格式的文件")
        this.coverloading = false
      }
      this.coverloading = false
      return file.type === "image/png" || file.type === "image/jpeg"
    },
    onSuccessOne(res) {
      if (res.code == 10000) {
        this.loadingAni.close()
        this.loadingAni = null
        this.releaseform.cover = res.data
        this.coverloading = false
        this.$refs.ruleForm.clearValidate("cover")
      }
    },
    // 上传案例
    onSuccessTwo(res) {
      if (res.code == 10000) {
        this.$refs.ruleForm.clearValidate("imgs")
        this.fileshow = true
        this.imglist.push(res.data)
        this.fileList.push({
          name: "img",
          url: res.data,
        })
        // this.uploadIndex += 1
        // if (this.uploadIndex == 1) {
        //     this.imglist[0] = res.data
        //     this.$set(this.imglist, 0, res.data)
        // } else if (this.uploadIndex == 2) {
        //     this.imglist[1] = res.data
        //     this.$set(this.imglist, 1, res.data)
        // } else if (this.uploadIndex == 3) {
        //     this.imglist[2] = res.data
        //     this.$set(this.imglist, 2, res.data)
        // } else if (this.uploadIndex == 4) {
        //     this.imglist[3] = res.data
        //     this.$set(this.imglist, 3, res.data)
        // } else if (this.uploadIndex == 5) {
        //     this.imglist[4] = res.data
        //     this.$set(this.imglist, 4, res.data)
        // } else if (this.uploadIndex == 6) {
        //     this.imglist[5] = res.data
        //     this.$set(this.imglist, 5, res.data)
        // }
      }
    },
    /**
     * @MethodAuthor: 任重浩
     * @Date: 2022-08-18 12:12:47
     * @description: 删除案例图片
     * @return {*}
     */
    onRemove(file, fileList) {
      this.hideUpload = fileList.length >= this.limitSize
      const filePath = file.url
      // 2.从pics数组中，找到图片对应的索引值
      const i = this.imglist.findIndex((x) => x === filePath)
      const img = this.fileList.findIndex((x) => x.url === filePath)
      // 3.调用splice方法，移除图片信息
      this.imglist.splice(i, 1)
      this.fileList.splice(img, 1)
    },
    // 鼠标经过
    onMouseover(item, index) {
      if (item.length > 0) this.$refs.dels[index].style.top = "0"
    },
    // 鼠标离开
    onMouseout(item, index) {
      this.$refs.dels[index].style.top = "-200%"
    },
    // 删除图片
    onDel(item, index) {
      switch (index) {
        case 0:
          this.uploadIndex = 0
          break
        case 1:
          this.uploadIndex = 1
          break
        case 2:
          this.uploadIndex = 2
          break
        case 3:
          this.uploadIndex = 3
          break
        case 4:
          this.uploadIndex = 4
          break
        case 5:
          this.uploadIndex = 5
          break
      }
      this.imglist[index] = ""
      this.$set(this.imglist, index, "")
      this.$refs.dels[index].style.top = "-200%"
    },
    // 上传文档
    async beforeUploadTwo(file) {
      // this.loadingfile = true
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1)
      const extension = testmsg === "ppt" || testmsg === "pptx"
      if (!extension) {
        this.$message({
          message: "上传文件只能是ppt类型!",
          type: "warning",
        })
        this.loadingfile = false
        return false
      }
      let imgSize = Number(file.size / 1024 / 1024)
      if (imgSize > 200) {
        this.$message.warning("文件大小不能超过200M ！请重新上传")
        this.loadingfile = false
        return false
      }
      this.loadingAni = this.$loading({
        text: "上传中...", //加载图标下的文字
        // spinner: 'el-icon-loading', //加载图标
        background: "rgba(36, 15, 69, 0.5)", //背景色
        // customClass:'loading' //自定义样式的类名
      })
      this.filename = file.name
      let data = await this.uploadFileOss(file.name)
      this.upload.datas.policy = data.policy
      this.upload.datas.OSSAccessKeyId = data.accessid
      this.upload.datas.signature = data.signature
      this.upload.datas.callback = data.callback
      let files = file.name
      let fileExtension = files.substring(files.lastIndexOf("."))
      let key = data.dir + Number(Math.random().toString().substr(3, 6) + Date.now()).toString(36) + fileExtension
      this.upload.datas.key = key
      // this.upload.wpsUrl=data.callback
      this.upload.url = data.host
    },
    uploadFileOss(file) {
      return new Promise((resolve, reject) => {
        uploadOssApi({ file_original_name: file }).then((res) => {
          if (res.code === 10000) {
            this.loadingAni.close()
            this.loadingAni = null
            resolve(res.data)
          }else{
            this.loadingAni.close()
            this.loadingAni = null
          }
        })
      })
    },
    onSuccessPPT(res) {
      if (res.code == 10000) {
        // this.releaseform.file_id = res.data.file_id
        // this.pptName = this.filename
        // this.loadingfile = false
        // let obj = {
        //   url: res.data.redirect_url,
        //   token: this.$store.state.wpsToken,
        // }
        // sessionStorage.setItem("wspInfo", JSON.stringify(obj))
        this.releaseform.file_id = res.data.file_id
        this.loadingfile = false
        this.pptName = res.data.filename
        // this.$router.push(`/alWps?id=${id}`)
        // this.$router.push("/wps")
      } else {
        if (res.message) {
          this.$message.error(res.response.message)
          this.loadings.close()
        }
      }
    },
    // 发布
    submitForm(formName) {
      let imgs = []
      this.imglist.map((e) => {
        if (e.length > 0) {
          imgs.push(e)
        }
      })
      this.releaseform.imgs = imgs.toString()
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.$route.query.id) {
            if (this.releaseform.file_id == undefined || this.releaseform.file_id == "001") {
              this.releaseform.file_id = ""
            }
            let data = {
              file_id: this.releaseform.file_id,
              name: this.releaseform.name,
              cover: this.releaseform.cover,
              imgs: this.releaseform.imgs,
              price: Number(this.releaseform.price),
              introduction: this.releaseform.introduction,
              allow_buy: this.releaseform.allow_buy,
              common_tag_arr: this.releaseform.common_tag_arr,
              custom_tag_arr: this.releaseform.custom_tag_arr,
            }
            if (this.$route.query.status == 0) {
              ;(data.case_id = this.detailId),
                editCase(data).then((res) => {
                  if (res.code == 10000) {
                    this.$message.success(res.message)
                    this.$router.go(-1)
                  }
                })
            } else {
              ;(data.audit_id = this.detailId),
                againCase(data).then((res) => {
                  if (res.code == 10000) {
                    this.$message.success(res.message)
                    this.$router.go(-1)
                  }
                })
            }
          } else {
            addCase(this.releaseform).then((res) => {
              if (res.code == 10000) {
                this.$message.success(res.message)
                this.$refs[formName].resetFields()
                this.uploadIndex = 0
                this.pptName = ""
                this.releaseform.common_tag_arr = []
                this.releaseform.custom_tag_arr = []
                this.$refs.myUpload.clearFiles()
                this.getTagList()
                this.imglist = []
                this.fileshow()
              }
            })
          }
        } else {
          return false
        }
      })
    },
    //   取消
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.uploadIndex = 0
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss">
.needs2 {
  width: 100%;
  height: auto;
  overflow: hidden;

  .container {
    margin: 0 auto;
    max-width: 86.25rem;
    height: 100%;
    padding: 2.5rem 3.125rem;

    h1 {
      font-size: 2.875rem;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      color: #240f45;
      line-height: 3.375rem;
    }

    .el-form {
      margin-top: 2rem;

      .tag {
        display: flex;
        // margin-left: 2.0625rem;
        margin-left: 2.75rem;
        .category {
          // width: 6.25rem;
          font-size: 1rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 100;
          color: #111029;
          line-height: 1.1875rem;
          white-space: nowrap;
          position: relative;
          text-align: right;

          &::after {
            content: "*";
            position: absolute;
            top: -0.3125rem;
            right: -0.625rem;
            color: red;
          }
        }
      }

      .tags-box {
        margin-left: 1.5625rem;

        p {
          width: 5rem;
          height: 2.625rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #57596c;
          background: #eff0f6;
          border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
        }

        .tags {
          margin-bottom: 1.5rem;

          ul {
            display: flex;
            flex-wrap: wrap;

            li {
              cursor: pointer;

              display: flex;
              align-items: center;
              justify-content: center;
              width: auto;
              padding: 0 1rem;
              font-size: 1rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 100;
              color: #57596c;
              line-height: 1.1875rem;
              margin: 1rem 0.25rem 0;
              white-space: nowrap;
              height: 2.625rem;
              background: #ffffff;
              border-radius: 2rem;
              border: 0.0625rem solid #dcddeb;
              transition: all 0.5s ease;
            }

            .custom {
              span {
                display: block;
                margin-right: 1rem;
              }
            }

            .cusinp {
              min-width: 6.875rem;

              .el-input {
                width: 6.875rem;
                min-width: 6.875rem;
                border: none;
                box-shadow: none;

                .el-input__inner {
                  border: none;
                  box-shadow: none;
                  width: 6.875rem;
                  min-width: 6.875rem;
                }
              }
            }

            .isactive {
              border: 1px solid #2f2c42;
              color: #2f2c42;
            }
          }
        }

        .el-tag {
          display: inline-block;
          height: 2.625rem;
          padding: 0.625rem 1rem;
          background: #ffffff;
          border-radius: 2rem 2rem 2rem 2rem;
          border: 0.0625rem solid #dcddeb;
          margin: 1rem 0 1rem 0.625rem;
          font-size: 1rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 100;
          color: #57596c;
          line-height: 1.1875rem;
        }

        .el-button {
          font-size: 1rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 100;
          color: #57596c;
          line-height: 0.75rem;
          padding: 0.625rem 1rem;
          background: #ffffff;
          border-radius: 2rem 2rem 2rem 2rem;
          border: 1px solid #dcddeb;
        }

        .button-new-tag {
          margin-top: 1rem;
          margin-left: 0.625rem;
          margin-bottom: 1rem;
          padding: 0.625rem 1rem;
          height: 2.625rem;
          line-height: 1.375rem;
          background: #ffffff;
          border-radius: 32px 32px 32px 32px;
          border: 1px solid #2f2c42;
          font-size: 1rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 100;
          color: #57596c;
          line-height: 1.1875rem;
        }

        .input-new-tag {
          max-width: 120px;
          margin-left: 10px;
          margin-top: 1rem;

          .el-input__inner {
            padding: 0.625rem 1rem;
            height: 2.625rem;
            line-height: 1.375rem;
            background: #ffffff;
            border-radius: 32px 32px 32px 32px;
            border: 1px solid #2f2c42;
            margin-bottom: 1rem;
          }
        }
      }

      .soc {
        .tags {
          ul {
            li {
              margin: 0rem 0.25rem 1rem;
            }
          }
        }
      }
      .tip_item {
        .el-form-item__content {
          display: flex;

          .tip_span {
            font-size: 14px;
            color: #adb0b8;
            margin-top: 50px;
            margin-left: 30px;
          }
        }
      }
      .el-form-item {
        width: 100%;
        display: flex;
        margin: 1rem 0;
        align-items: center;

        .el-form-item__label {
          width: 7.5rem !important;
          position: relative;
          text-align: right;
          white-space: nowrap;
          font-size: 1rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 100;
          color: #111029;
          line-height: 1.1875rem;

          &::before {
            position: absolute;
            right: -0.1875rem;
            top: -0.3125rem;
          }
        }

        .el-form-item__content {
          width: 100%;
          margin-left: 1rem !important;
          flex: 1;
          .cover {
            width: 6.25rem;
            height: 6.25rem;
          }

          .el-input {
            width: 35.125rem;
            height: 2.625rem;
            background: #ffffff;
            box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
            border-radius: 10px 10px 10px 10px;

            .el-input__inner {
              height: 100%;
              font-size: 1rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 100;
              color: #111029;
              line-height: 1.1875rem;

              &::placeholder {
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #a0a3bd;
                line-height: 1.1875rem;
              }

              &::-webkit-input-placeholder {
                /* WebKit browsers 适配谷歌 */
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #a0a3bd;
                line-height: 1.1875rem;
              }

              &:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 适配火狐 */
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #a0a3bd;
                line-height: 1.1875rem;
              }

              &::-moz-placeholder {
                /* Mozilla Firefox 19+ 适配火狐 */
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #a0a3bd;
                line-height: 1.1875rem;
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10+  适配ie*/
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #a0a3bd;
                line-height: 1.1875rem;
              }
            }

            .el-input__suffix {
              margin-right: 0.625rem;
            }
          }

          .upload-demo {
            .el-upload-dragger {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 6.25rem;

              height: 3.75rem;
              background: #ffffff;
              box-shadow: 0px 0.25rem 0.625rem 0.0625rem rgba(58, 114, 255, 0.06);
              border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
              border: 0.0625rem solid #eff0f6;

              .cover {
                display: block;
                width: 100%;
                height: 100%;
              }

              p {
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #a0a3bd;
                line-height: 1.1875rem;
              }
            }
          }

          .uploadppt {
            min-width: 10rem;

            .el-loading-mask {
              min-width: 10rem;
            }
          }

          .fengmian {
            .el-upload-dragger {
              width: 100px;
              height: 100px;
              background: #ffffff;
              box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
              border-radius: 10px 10px 10px 10px;
              border: 1px solid #eff0f6;
            }
          }

          .anliimg {
            .el-upload {
              width: 6.25rem;
              height: 6.25rem;
              background: #ffffff;
              border: none;
              box-shadow: 0px 4px 10px 1px rgb(58 114 255 / 6%);
              border-radius: 10px 10px 10px 10px;

              i {
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
              }
            }

            .el-upload-list__item {
              width: 6.25rem;
              height: 6.25rem;
              transition: none;
              .el-upload-list__item-status-label {
                display: none;
              }
            }

            .el-upload-dragger {
              height: 100%;
              width: 100%;
              border: none;
              box-shadow: none;
              border-radius: none;

              ul {
                width: 100%;
                display: flex;
                align-items: flex-start;

                li {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 6.25rem;
                  height: 6.25rem;
                  background: #ffffff;
                  box-shadow: none;
                  margin: 0 0.5rem;
                  border: 1px solid #eff0f6;
                  box-shadow: 0px 0.25rem 0.625rem 1px rgba(58, 114, 255, 0.06);
                  border-radius: 0.625rem;
                  opacity: 1;
                  border: 1px solid #eff0f6;
                  position: relative;

                  .img {
                    display: block;
                    width: 100%;
                    height: 100%;
                  }

                  .del {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background: rgba(0, 0, 0, 0.5);
                    right: 0;
                    left: 0;
                    top: -200%;
                    z-index: 100;
                    transition: all 0.5s ease;

                    img {
                      display: block;
                      width: 1.5625rem;
                      height: 1.5625rem;
                    }
                  }
                }
              }
            }
          }
        }

        .el-radio {
          .el-radio__input {
            width: 1.375rem;
            height: 1.375rem;
            border-radius: 50%;

            .el-radio__inner {
              width: 1.375rem;
              height: 1.375rem;
              border-radius: 50%;
            }
          }

          .is-checked {
            width: 1.375rem;
            height: 1.375rem;

            border-radius: 50%;

            .el-radio__inner {
              background: #3a71ff !important;

              &::after {
                width: 0.5rem;
                height: 0.5rem;
              }
            }
          }
        }
      }
      #caseup {
        .el-form-item__label {
          position: relative;
          &:before {
            content: "*";
            display: block;
            color: #f56c6c;
          }
        }
      }

      #ppt {
        .el-upload-dragger {
          width: auto;
          padding: 0 1.875rem;
        }
        .el-form-item__content {
          width: auto;
        }
      }

      #relesebtn {
        width: 100%;

        .el-form-item__content {
          width: 100%;
          display: flex;
          justify-content: center;

          .el-button {
            margin-top: 2rem;
            width: 5rem;
            height: 3.625rem;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 100;
            color: #ffffff;
            line-height: 1.1875rem;
            background: #111029;
            border-radius: 3.25rem 3.25rem 3.25rem 3.25rem;

            &:nth-child(2) {
              margin-left: 2rem;
              background: #ffffff;
              color: #111029;
            }
          }
        }
      }

      #describe {
        .el-form-item__label {
          margin-left: 2.6875rem;
        }

        .el-form-item__content {
          // margin-left: 2rem !important;

          .el-textarea {
            width: 80.625rem;
            min-height: 11.8125rem;
            background: #ffffff;
            box-shadow: 0px 0.25rem 0.625rem 0.0625rem rgba(58, 114, 255, 0.06);
            border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
            border: 0.0625rem solid #eff0f6;

            .el-textarea__inner {
              padding: 0.6875rem 1rem;
              height: 11.8125rem;
              font-size: 1rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 100;
              color: #111029;
              line-height: 1.1875rem;

              &::placeholder {
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #a0a3bd;
                line-height: 1.1875rem;
              }

              &::-webkit-textarea-placeholder {
                /* WebKit browsers 适配谷歌 */
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #a0a3bd;
                line-height: 1.1875rem;
              }

              &:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 适配火狐 */
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #a0a3bd;
                line-height: 1.1875rem;
              }

              &::-moz-placeholder {
                /* Mozilla Firefox 19+ 适配火狐 */
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #a0a3bd;
                line-height: 1.1875rem;
              }

              &:-ms-textarea-placeholder {
                /* Internet Explorer 10+  适配ie*/
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #a0a3bd;
                line-height: 1.1875rem;
              }
            }
          }
        }
      }

      #recruit {
        .el-form-item__label {
          margin-left: 2.6875rem;
        }

        .el-form-item__content {
          margin-left: 2rem !important;

          .el-textarea {
            width: 80.625rem;
            min-height: 6.25rem;
            background: #ffffff;
            box-shadow: 0px 0.25rem 0.625rem 0.0625rem rgba(58, 114, 255, 0.06);
            border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
            border: 0.0625rem solid #eff0f6;

            .el-textarea__inner {
              padding: 0.6875rem 1rem;
              min-height: 6.25rem !important;
              font-size: 1rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 100;
              color: #a0a3bd;
              line-height: 1.1875rem;
            }
          }
        }
      }

      .beggod {
        .el-form-item__label {
          position: relative;

          &::before {
            content: "*";
            color: #f56c6c;
            margin-right: 4px;
            position: absolute;
            right: -0.1875rem;
            top: -0.3125rem;
          }
        }
      }
    }
  }
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: none;
  border: none !important;
}
/*去除upload组件过渡效果*/
.el-upload-list__item {
  transition: none;
}
.file_boxs {
  width: 15.0625rem;
  min-height: 60px;
  background: #f7f7fc;
  border-radius: 10px;
  opacity: 1;
  margin-top: 1rem;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #57596c;
  display: flex;
  align-items: center;
  img {
    width: 36px;
    height: 36px;
  }
}
//  .needs2 .container .el-form .el-form-item .el-form-item__content .noUpload .el-upload {
//   display: none;
// }
.hide .el-upload--picture-card {
  display: none;
}
</style>
