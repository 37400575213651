<template>
    <div>
        <div class="datalist" v-for="(item, index) in datalist" :key="index" @click="onDetail(item)">
            <div class="userinfo">
                <div class="userinfo-left">
                        <img class="avatar" :src="item.avatar" alt="">
                    <div class="lei">
                        <p>{{ item.username }}</p>
                        <div class="lei_one">
                            <img src="@/assets/images/userprofile/V.png" alt="" class="leiimg">
                            <span>{{ item.level_context }}</span>
                        </div>
                        <div class="lei_two" v-if="item.marketer_role_context != ''">
                            {{ item.marketer_role_context }}
                        </div>
                    </div>
                </div>
                <div class="userinfo-right">
                    <!-- <div class="errorexamine">
                        <img src="@/assets/images/userprofile/errorexamine.png" alt="">
                        审核驳回
                    </div>
                    <div class="successexamine" v-show="false">
                        <img src="@/assets/images/userprofile/successexamine.png" alt="">
                        审核成功
                    </div>
                    <div class="review" v-show="false">
                        <img src="@/assets/images/userprofile/review.png" alt="">
                        审核中
                    </div> -->
                    <div class="edit" @click.stop="edits(index)">
                        <img src="@/assets/images/userprofile/edit.png" alt="">
                        <div class="edit-box" v-if="editshow == true && editIndex == index">
                            <p @click="onEdit(item)">编辑</p>
                            <p @click="onDelete(item)">删除</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="content-main">
                    <img :src="item.cover" alt="">
                    <div class="content-tle">
                        <p>{{ item.case_name }}</p>
                        <span>{{ item.introduction }}</span>
                    </div>
                </div>
                <div class="content-bot">
                    <p>{{ item.created_at }}</p>
                    <div class="forward">
                        <div class="comment">
                            <!-- <img src="../../../assets/images/userprofile/forward.png" alt=""> -->
                            <share @onMove="onMoves(item)" :QRCodeMsg="qrurl" > </share>
                        </div>
                        <div class="comment">
                            <img src="@/assets/images/userprofile/xx@2x.png" alt="">
                            <span>{{item.commentTotal}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 删除确认弹窗 -->
        <dialogs :show.sync="showDialog" width="690px" :center="true" title="删除">
            <p>是否删除这条案例？</p>
            <template slot="footer-slot">
                <el-button class="cancle" @click="cancle">
                    取消
                </el-button>
                <el-button class="determine" @click="determine">
                    确认
                </el-button>
            </template>
        </dialogs>
    </div>
</template>
<script>
import { delCase } from "@/api/api"
import share from "@/components/share/share.vue"
import dialogs from "@/components/common/dialog-cpn.vue"
export default {
    components: {
        share,
        dialogs
    },
    props: {
        case: {
            type: Array / String,
            default: ""
        }
    },
    data() {
        return {
            deleteId: null,//删除id
            showDialog: false,//删除弹窗
            editshow: false,
            editIndex: "",
            qrurl: "",
            editlist: [
                {
                    name: "编辑",
                    value: 1
                },
                {
                    name: "删除",
                    value: 2
                }
            ],
            datalist: []
        }
    },
    watch: {
        case: {
            handler(data) {
                if (data) {
                    console.log(data)
                    this.datalist = data
                }
            }
        }
    },
    mounted(){
         document.addEventListener("click", this.handleClose);
    },
    methods: {
        handleClose(){
           if(this.editshow){
            this.editshow=false
           }
        },
        // 详情
        onDetail(item) {
            // this.$router.push({path:"/wdal/examinedetail",query:{id:item.case_id}})
            this.$router.push({ path: "/zbkalDetail", query: { id: item.case_id, status: 0 } })
        },
        edits(index) {
            console.log(index)
            this.editIndex = index
            if (this.editshow == false) {
                this.editshow = true
            } else {
                this.editshow = false
            }
        },
        onMoves(item) {
            console.log(item)
            this.qrurl =this.$url + " #/pages/bkal/children/detail/detail?id=" + item.id 
        },
        // 编辑案例
        onEdit(item) {
            this.$router.push({ path: '/uploadcase', query: { id: item.case_id, status: 0 } })
        },
        // 删除案例
        onDelete(item) {
            console.log(item)
            this.deleteId = item.case_id
            this.showDialog = true
        },
        // 取消删除
        cancle() {
            this.showDialog = false
        },
        determine() {
            delCase(this.deleteId)
                .then(res => {
                    if (res.code == 10000) {
                        this.$message.success(res.message)
                        this.showDialog = false
                        this.$emit("uploadCase")
                    }
                })
        },
    }
}
</script>
<style lang="scss" scoped>
.datalist {
    cursor: pointer;
    padding: 2rem 0;
    border-top: 1px solid #EFF0F6;

    .userinfo {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .userinfo-left {
            display: flex;
            align-items: center;

            .avatar {
                border-radius: 50%;
                display: block;
                width: 2.5rem;
                height: 2.5rem;
                opacity: 1;
            }

            .lei {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                margin: .5rem 0;

                p {
                    font-size: 1.125rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1.3125rem;
                    margin: 0 .5rem;
                }

                .lei_one {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 2.625rem;
                    height: 1.0625rem;
                    background: #FFEFF0;
                    border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                    opacity: 1;

                    .leiimg {
                        display: block;
                        width: .5rem !important;
                        height: .5625rem !important;
                    }

                    span {
                        font-size: .75rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #DC2B2B;
                        line-height: .875rem;
                    }
                }

                .lei_two {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: .5625rem;
                    width: auto;
                    padding: 0 .1875rem;
                    height: 1.0625rem;
                    background: #DEF2E6;
                    border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                    opacity: 1;
                    font-size: .75rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #11845B;
                    line-height: .875rem;

                }
            }
        }


        .userinfo-right {
            display: flex;
            cursor: pointer;
            align-items: center;

            .errorexamine,
            .successexamine,
            .review {
                width: 6.875rem;
                height: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                line-height: 1.1875rem;
                border-radius: 2.1875rem 2.1875rem 2.1875rem 2.1875rem;

                img {
                    display: block;
                    width: 1.375rem;
                    height: 1.375rem;
                    margin-right: .25rem;
                }
            }

            .errorexamine {
                color: #DC2B2B;
                background: #FFEFF0;
            }

            .successexamine {
                color: #11845B;
                background: #DEF2E6;
            }

            .review {
                color: #086CD9;
                background: #EAF4FF;
            }


            .edit {
                position: relative;
                margin-left: 2rem;

                img {
                    display: block;
                    width: 1.25rem;
                    height: 1.25rem;
                }

                .edit-box {

                    position: absolute;
                    padding: .625rem 1rem;
                    left: -3.125rem;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 6px 1px rgba(20, 20, 43, 0.0600);
                    border-radius: .625rem;
                    opacity: 1;
                    border: 1px solid #EFF0F6;

                    p {
                        padding: .375rem 0;
                        font-size: .875rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #57596C;
                        line-height: 1rem;
                    }
                }
            }


        }

    }

    .content {
        margin-top: 1.4375rem;
        padding-left: 3.125rem;

        .content-main {
            display: flex;
            align-items: center;

            img {
                flex-shrink: 1;
                display: block;
                width: 8.125rem;
                height: 8.125rem;
                border-radius: .625rem .625rem .625rem .625rem;
            }

            .content-tle {
                margin-left: 1rem;
                flex: 1;
                p {
                    font-size: 1rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1.1875rem;
                    margin: 1rem 0;
                }

                span {
                    font-size: .875rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #57596C;
                    line-height: 1rem;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

            }


        }

        .content-bot {
            margin-top: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #A0A3BD;
                line-height: 1rem;
            }

            .forward {
                display: flex;
                align-items: center;

                .comment {
                    display: flex;
                    align-content: center;
                    img {
                        display: block;
                        width: 1.25rem;
                        height: 1.25rem;
                    }
                    span {
                        display: inline-block;
                        margin-left: .3125rem;
                        font-size: .875rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #A0A3BD;
                        line-height: 1rem;
                    }

                    &:nth-child(1) {
                        margin-right: 4.5625rem;
                    }

                    ::v-deep.share-box {
                        right: 0;
                    }
                }
            }
        }
    }
}

.el-dialog {
    .el-dialog__body {
        p {
            text-align: center;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 300;
            color: #5C6466;
            line-height: 1.1875rem;
        }

    }

    .el-dialog__footer {
        .dialog-footer {

            .cancle {
                width: 4.25rem;
                height: 2.375rem;
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #111029;
                line-height: 1rem;
                background: #FFFFFF;
                border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                border: 1px solid #111029;
            }

            .determine {
                margin-left: 11.375rem;
                width: 4.25rem;
                height: 2.375rem;
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 1rem;
                background: #111029;
                border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                border: 1px solid #111029;
            }
        }
    }
}

.el-dropdown-menu {

    padding: 1rem 0;
    background: #FFFFFF;
    box-shadow: 0px .125rem .375rem .0625rem rgba(20, 20, 43, 0.06);
    border-radius: .625rem .625rem .625rem .625rem;

    .el-dropdown-menu__item {
        font-size: .875rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 100;
        color: #57596C;
        line-height: 1rem;
        margin: .375rem 0;
    }
}
</style>