<template>
  <div class="follow">
    <div class="followlist" v-for="item in followlist" :key="item.id">
      <div class="followtle">
        <div class="img-box">
          <img :src="item.followInfo.avatar" class="avatar" alt="" @click.stop="onUserinfor(item)" />
          <img src="@/assets/images/b.png" alt="" class="icon" v-if="item.followInfo.official_type == 1" />
          <img src="@/assets/images/y.png" alt="" class="icon" v-if="item.followInfo.official_type == 2" />
        </div>
        <div class="user">
          <div class="user-top">
            <p>{{ item.followInfo.username }}</p>
            <div class="lei">
              <div class="lei_one">
                <img src="@/assets/images/userprofile/V.png" alt="" class="leiimg" />
                <span>{{ item.followInfo.level_context }}</span>
              </div>
              <div class="lei_two">
                {{ item.followInfo.marketer_role_context }}
              </div>
            </div>
          </div>
          <div class="content">
            {{ item.followInfo.introduction }}
          </div>
        </div>
      </div>
      <div class="operation">
        <!-- <el-button class="calcle font">{{item.flag==true?'回关':'取消关注'}}</el-button> -->
        <el-button class="calcle font" @click.stop="cancnleFollow(item)">取消关注</el-button>
        <el-button class="letter font" @click="privateLetter(item)">私信</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { cancleFollow } from "@/api/api"
export default {
  props: {
    follow: {
      type: Array / String,
      default: [],
    },
  },
  data() {
    return {
      followlist: [
        {
          id: 1,
          name: "姓名",
          flag: true,
        },
        {
          id: 2,
          name: "姓名",
          flag: false,
        },
      ],
      chartlist: [],
    }
  },
  watch: {
    follow: {
      handler(data) {
        this.followlist = data
      },
    },
  },
  methods: {
    // 个人详情页
    onUserinfor(item) {
      this.$router.push({ path: "/yxyxsJg", query: { id: item.followInfo.id } })
    },
    cancnleFollow(item) {
      cancleFollow(item.id).then((res) => {
        if (res.code == 10000) {
          this.$message.success("取消关注！")
          this.$emit("upload")
        }
      })
    },
    // 私信
    privateLetter(item) {
      let isLogin = this.$store.state.token
      if (!isLogin) {
        this.$messageBox
          .alert("未登录，请重新登录", {
            type: "warning",
          })
          .then(() => {
            this.$store.commit("REMOVE_INFO")
            this.$router.push("/login")
            this.$messageBox.close()
          })
          .catch(() => {
            this.$messageBox.close()
          })

        return
      }
      this.$router.push({ path: "/chartroom", query: { id: item.followInfo.id } })
      this.chartlist.push(item)
      // localStorage.setItem('chart',this.chartlist)
      // localStorage.setItem('chartuserinfor',JSON.parse(item))
    },
  },
}
</script>
<style lang="scss" scoped>
.follow {
  padding: 0 1rem;

  .followlist {
    padding: 1rem 0 2rem;
    margin-top: 0.8125rem;
    border-top: 1px solid #eff0f6;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .followtle {
      display: flex;

      .img-box {
        position: relative;

        .avatar {
          cursor: pointer;
          border-radius: 50%;
          display: block;
          width: 5rem;
          height: 5rem;
        }
        .icon {
          display: block;
          width: 1.3125rem;
          height: 1.3125rem;
          position: absolute;
          bottom: 0px;
          right: -0.625rem;
        }
      }

      .user {
        margin-left: 2.0625rem;

        .user-top {
          display: flex;
          align-items: center;

          p {
            font-size: 1.125rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #111029;
            line-height: 1.3125rem;
            margin-right: 0.5rem;
          }

          .lei {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.5rem 0;

            .lei_one {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 2.625rem;
              height: 1.0625rem;
              background: #ffeff0;
              border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
              opacity: 1;
              text-align: center;

              .leiimg {
                display: block;
                width: 0.5rem !important;
                height: 0.5625rem !important;
              }

              span {
                font-size: 0.75rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #dc2b2b;
                line-height: 0.875rem;
              }
            }

            .lei_two {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 0.5625rem;
              width: auto;
              padding: 0 0.3125rem;
              height: 1.0625rem;
              background: #def2e6;
              border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
              opacity: 1;
              font-size: 0.75rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 100;
              color: #11845b;
              line-height: 0.875rem;
            }
          }
        }

        .content {
          width: 87%;
          font-size: 0.875rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 100;
          color: #57596c;
          line-height: 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box; //使用自适应布局
          -webkit-line-clamp: 2; //设置超出行数，要设置超出几行显示省略号就把这里改成几
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .operation {
    display: flex;
    align-items: center;

    .calcle {
      width: auto;
      height: 3rem;
      background: #ffffff;
      border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
      color: #2f2c42;
      border: 1px solid #2f2c42;
    }

    .font {
      font-size: 1rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 100;
      line-height: 1.1875rem;
    }

    .letter {
      width: 4.5rem;
      height: 3rem;
      margin-left: 1rem;
      background: #2f2c42;
      border-radius: 3.125rem 3.125rem 3.125rem 3.125rem;
      color: #ffffff;
      border: 0px solid #8a9699;
    }
  }
}
</style>
