<template>
  <div class="personnel">
    <div class="container">
      <h1>人才推荐</h1>
      <!-- <div class="tablist">
        <p>角 <i></i>色：</p>
        <ul>
          <li v-for="item in rolelist" :key="item.id" :class="roleactive == item.id ? 'active' : ''" @click="onRole(item)">{{ item.name }}</li>
        </ul>
      </div> -->
      <div class="tablist" v-for="(item, index) in tagList" :key="index">
        <!-- <p>{{ item.name }}：</p> -->
        <!-- 文字修改 -->

        <p>
              <template v-if="item.name === '擅长行业'">行业领域：</template>
              <template v-if="item.name === '擅长领域'">传播内容：</template>
              <template v-if="item.name !== '擅长行业' && item.name !== '擅长领域'"> {{ item.name }}：</template>
        </p>
        <ul v-if="item.children.length > 0">
          <li v-for="(it, index1) in item.children" :key="index1" :class="socailactive == it.isActive ? 'active' : ''" @click="onSocail(it, item.children)">{{ it.name }}</li>
        </ul>
      </div>
      <div class="datalist">
        <!-- 列表 -->
        <template v-if="!(lists.length === 0)">
            <loading-more :completed="completed" :pageInit="queryInfo.page" @changeData="loadMore" :loadingIcon="loadingIcon">
          <div class="list screenWidth">
            <div class="list_item cursor-pointer" v-for="(item, index) in lists" :key="index" @click="goDetail(item)">
              <div class="item_left">
                <div class="avatar">
                  <img class="avatar-img" :src="item.avatar" alt="头像" />
                  <!-- 1机构，2品牌 -->
                  <img class="flag" v-if="item.official_type === 2" src="@/assets/images/home/yellow_icon@2x.png" alt="icon" />
                  <img class="flag" v-if="item.official_type === 1" src="@/assets/images/home/blue_icon@2x.png" alt="icon" />
                </div>
                <template v-if="item.showBtn">
                  <div v-if="item.is_follow" class="gz_btn font-size-16 font-weight-400" @click="cancelfollowHandle(item)">取消关注</div>
                  <div v-else class="gz_btn font-size-16 font-weight-400" @click="followHandle(item)">关注</div>
                </template>
                <div class="name text-align-center font-size-16 font-weight-500" :class="!item.showBtn ? 'name2' : ''">{{ item.username }}</div>
                <div class="tag1">
                  <tag-btn v-if="item.level_context" class="degnji" :config="{ type: 'red', text: item.level_context }"></tag-btn>
                  <tag-btn v-if="item.marketer_role_context" :config="{ type: 'green', text: item.marketer_role_context }"></tag-btn>
                </div>
                <div class="bar flex">
                  <div class="div flex shoucang">
                    <img src="@/assets/images/home/str_yellow@2x.png" alt="" />
                    <span class="font-color-yellow font-size-16 font-weight-500">{{ item.score }}</span>
                  </div>
                  <span class="line"></span>
                  <div class="div flex fensi">
                    <span class="font-size-14 font-weight-400">粉丝</span>
                    <span class="font-size-16 font-weight-500 num">{{ item.fans_num ? item.fans_num : 0 }}</span>
                  </div>
                  <span class="line"></span>
                  <div class="div flex jingyan">
                    <span class="font-size-14 font-weight-400">执行经验</span>
                    <span class="font-size-16 font-weight-500 num">{{ item.trade_num }}</span>
                  </div>
                </div>
              </div>
              <div class="line"></div>
              <div class="item_tight">
                <div class="types flex" v-if="item.newTag.social.length > 0">
                  <span class="type font-size-14 font-weight-400">社交网络</span>
                  <div class="right_tags flex text-align-center">
                    <span class="font-size-14 font-weight-400" v-for="(it, index1) in item.newTag.social" :key="index1">{{ it.name }}</span>
                  </div>
                </div>
                <div class="types flex" v-if="item.newTag.area.length > 0">
                  <span class="type font-size-14 font-weight-400">擅长领域</span>
                  <div class="right_tags flex text-align-center">
                    <span class="font-size-14 font-weight-400" v-for="(it, index1) in item.newTag.area" :key="index1">{{ it.name }}</span>
                  </div>
                </div>
                <div class="types flex" v-if="item.newTag.industry.length > 0">
                  <span class="type font-size-14 font-weight-400">擅长行业</span>
                  <div class="right_tags flex text-align-center">
                    <span class="font-size-14 font-weight-400" v-for="(it, index1) in item.newTag.industry" :key="index1">{{ it.name }}</span>
                  </div>
                </div>
                <p class="ms font-size-14 font-weight-400">
                  {{ item.introduction }}
                </p>
              </div>
            </div>
          </div>
        </loading-more>
        </template>
        <emptyPageVue v-else></emptyPageVue>
      </div>
    </div>
  </div>
</template>
<script>
import { getTagListApi, marketerListApi, cancelfollowApi, followApi } from "@/api/api.js"
import loadingMore from "@/components/common/loadingMore.vue"
import emptyPageVue from "@/components/common/empty-page.vue"
export default {
  components: {
    loadingMore,
    emptyPageVue
  },
  data() {
    return {
      lists: [],
      roleactive: 1,
      rolelist: [
        {
          id: 1,
          name: "全部",
        },
        {
          id: 2,
          name: "个人",
        },
        {
          id: 3,
          name: "机构",
        },
      ],
      socailactive: 1,
      tagslist: ["小红薯", "微博", "知乎"],
      types: [],
      tagList: [], // 标签列表
      queryInfo: {
        page: 1,
        limit: 10,
        sort: "score_desc", // 默认倒叙 time_desc,time_asc,score_desc,score_asc
        keyword: "", //关键词
        tag_arr: [], // 标签数组
      },
      total: 0,
      completed: false, // 加载
      loadingIcon: true, // 加载
    }
  },
  mounted() {},
  created() {
    this.getList()
    this.getTags()
  },
  methods: {
    loadMore(e) {
      if (this.total > 0) {
        let check = e > Math.ceil(this.total / this.queryInfo.limit)
        if (check) {
          this.completed = true
          this.loadingIcon = false
          return
        }
      }
      setTimeout((res) => {
        this.queryInfo.page = e
        this.getLoadList() // 重新请求数据
      }, 2000)
    },
    async getLoadList() {
      let data = await this.listApi()
      this.lists = this.lists.concat(data)
    },
    getList() {
      this.queryInfo.page = 1
      this.queryInfo.tag_arr = JSON.parse(sessionStorage.getItem("demandTag"))
      this.getData()
    },
    async getData() {
      this.lists = await this.listApi()
    },

    /*
     * @Description: 取消关注
     * @MethodAuthor:  liulian
     * @Date: 2022-07-22 09:42:15
     * @param: ''
     */
    cancelfollowHandle(item) {
      event.stopPropagation()
      cancelfollowApi(item.id).then((res) => {
        if (res.code === 10000) {
          this.$message.success("已取消关注")
          this.$set(item, "is_follow", false)
        }
      })
    },
    followHandle(item) {
      event.stopPropagation()
      followApi({ follow_uid: item.id }).then((res) => {
        if (res.code === 10000) {
          this.$message.success("关注成功！")
          this.$set(item, "is_follow", true)
        }
      })
    },
    listApi() {
      return new Promise((resolve, rejects) => {
        marketerListApi(this.queryInfo).then((res) => {
          if (res.code === 10000) {
            this.total = res.count
            this.completed = false
            this.loadingIcon = true
            if(res.count < this.queryInfo.limit) {
              this.completed = true
              this.loadingIcon = false
            }
          
            res.data.length > 0 &&
              res.data.map((item) => {
                // 判断是否是当前用户
                if (this.$store.state.userInfo) {
                  if (item.id === this.$store.state.userInfo.id) {
                    this.$set(item, "showBtn", false)
                  } else {
                    this.$set(item, "showBtn", true)
                  }
                } else {
                  this.$set(item, "showBtn", true)
                }
                let tags = {
                  area: [], //擅长领域
                  industry: [], // 擅长行业
                  social: [], // 社交网络
                }
                // 标签分类
                let keys = Object.keys(tags)
                keys.map((key) => {
                  item.tag.length > 0 &&
                    item.tag.map((i) => {
                      if (key === i.top_parent_bind) {
                        tags[key].push(i)
                      }
                    })
                })
                this.$set(item, "newTag", tags)
              })
            resolve(res.data)
          }
        })
      })
    },
    async getTags() {
      let data = await this.tagListApi()
      if (data) {
        this.tagList = await this.initStyle(data)
        let arr = JSON.parse(sessionStorage.getItem("demandTag"))
        arr && arr.map((item) => {
          this.tagList.map((it) => {
            it.children.length > 0 &&
              it.children.map((i) => {
                if (i.id === item) {
                  this.$set(i, "isActive", i.id === item)
                }
              })
          })
        })
      }
    },
    /*
     * @Description: 获取标签列表
     * @MethodAuthor:  liulian
     * @Date: 2022-07-29 10:15:07
     * @param: ''
     */
    tagListApi() {
      return new Promise((resolve, rejects) => {
        getTagListApi().then((res) => {
          if (res.code === 10000) {
            resolve(res.data)
          }
        })
      })
    },
    /*
     * @Description: 标签列表格式化
     * @MethodAuthor:  liulian
     * @Date: 2022-07-29 10:15:21
     * @param: ''
     */
    initStyle(data) {
      return new Promise((resolve, rejects) => {
        data.length > 0 &&
          data.map((item) => {
            if (item.bind === "area") {
              let arr = []
              item.children.length > 0 &&
                item.children.map((i) => {
                  i.children.length > 0 &&
                    i.children.map((j) => {
                      arr.push(j)
                    })
                })
              item.children = arr
            }
          })
        resolve(data)
      })
    },

    // 进入个人主页
    /*
     * @Description: 跳转个人或者是机构页面
     * @MethodAuthor:  liulian
     * @Date: 2022-06-15 10:58:23
     * @param: ''
     */
    goDetail(item) {
      this.$router.push(`/yxyxsJg?id=${item.id}`)
    },
    onRole(e) {
      this.roleactive = e.id
    },
    async onSocail(e, arr) {
      let echkc = await this.checkActiveLen(arr)
      if (echkc) {
        if (e.isActive) {
          this.$set(e, "isActive", false)
        } else {
          this.$message.error("每个分类只能选择五个")
        }
      } else {
        this.$set(e, "isActive", !e.isActive)
      }
      this.getParams()
    },

    getParams() {
      this.queryInfo.page = 1
      let arr = []
      this.tagList.map((ite) => {
        ite.children.length > 0 &&
          ite.children.map((i) => {
            if (i.isActive) {
              arr.push(i.id)
            }
          })
      })
      this.queryInfo.tag_arr = arr
      this.getData()
    },

    /*
     * @Description: 检查当前所选的个数
     * @MethodAuthor:  liulian
     * @Date: 2022-07-29 11:10:26
     * @param: ''
     */
    checkActiveLen(data) {
      return new Promise((resolve, rejects) => {
        let check = false
        let arr = []
        data.map((it) => {
          if (it.isActive) {
            arr.push(it)
          }
        })
        if (arr.length === 5) {
          check = true
        }
        resolve(check)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.personnel {
  width: 100%;
  height: auto;
  overflow: hidden;

  .container {
    max-width: 86.25rem;
    margin: 0 auto;
    height: 100%;
    padding-top: 2.5rem;

    h1 {
      font-size: 2.875rem;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      color: #240f45;
      line-height: 3.375rem;
      padding-bottom: 3.375rem;
    }

    .tablist {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1rem;
      p {
        white-space: nowrap;
        font-size: 1rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 100;
        color: #240f45;
        i {
          display: inline-block;
        }
      }

      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        li {
          cursor: pointer;
          margin-right: 16px;
          font-size: 1rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 100;
          color: #240f45;
          line-height: 1.1875rem;
          transition: all 0.5s ease; // 动画
          padding: 0 0.5rem;
          white-space: nowrap;
          margin-bottom: 1rem;
        }

        .active {
          padding: 0 0.5rem;
          color: #ffffff;
          font-size: 1rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 100;
          line-height: 1.1875rem;
          background: #111029;
          border-radius: 1.3125rem 1.3125rem 1.3125rem 1.3125rem;
        }
      }
    }

    .datalist {
      .list {
        margin: auto;
        .list_item {
          width: 100%;
          height: 17.3125rem;
          background: #ffffff;
          border-radius: 1.75rem;
          opacity: 1;
          border: 1px solid #eff0f6;
          margin-top: 2rem;
          display: flex;
          align-items: center;
          .item_left {
            // border-right: 1px solid #f4f4f4;
            width: 16.25rem;
            .avatar {
              margin-left: 5.25rem;
              width: 5rem;
              height: 5rem;
              display: flex;
              align-items: flex-end;
              img {
                width: 5rem;
                height: 5rem;
                border-radius: 100%;
              }
              .flag {
                width: 1.75rem;
                height: 1.75rem;
                margin-left: -26px;
              }
            }
            .gz_btn {
              width: 4.75rem;
              height: 3.125rem;
              background: #ffffff;
              border-radius: 3.25rem;
              opacity: 1;
              border: 1px solid #111029;
              display: block;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: -0.8125rem 5.375rem;
              background-color: #fff;
              position: absolute;
            }
            .name {
              color: #240f45;
              margin-top: 3.4rem;
            }
            .name2 {
              margin-top: 1.4rem;
            }
            .tag1 {
              display: flex;
              margin-top: 0.5rem;
              .degnji {
                margin-right: 0.375rem;
              }
              margin: 0.5rem 4.9375rem;
            }
            .bar {
              margin-left: 2.0625rem;
              margin-top: 0.5625rem;
              .line {
                height: 0.625rem;
                width: 0px;
                opacity: 1;
                border: 1px solid #dcddeb;
                margin-left: 0.25rem;
                margin-right: 0.25rem;
              }
              .shoucang {
                img {
                  width: 1.0625rem;
                  height: 1.0163rem;
                  margin-right: 0.375rem;
                }
              }
              .fensi {
                color: #2f2c42;
                .num {
                  margin-left: 0.375rem;
                }
              }
              .jingyan {
                color: #2f2c42;
                .num {
                  margin-left: 0.375rem;
                }
              }
            }
          }
          .line {
            height: 11.6875rem;
            width: 0rem;
            opacity: 1;
            border: 0.0625rem solid #f4f4f4;
          }
          .item_tight {
            .types {
              margin-left: 2rem;
              margin-bottom: 1.375rem;
              .type {
                margin-right: 1rem;
                color: #111029;
                white-space: nowrap;
              }
              .right_tags {
                flex-wrap: wrap;
                span {
                  height: 1.75rem;
                  line-height: 1.75rem;
                  border-radius: 1.625rem;
                  opacity: 1;
                  border: 1px solid #eff0f6;
                  margin-right: 0.5rem;
                  display: block;
                  color: #57596c;
                  padding-left: 0.5rem;
                  padding-right: 0.5rem;
                }
              }
            }
          }
          .ms {
            margin-left: 2rem;
            color: #57596c;
            line-height: 19px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            word-break: break-all;
            overflow: hidden;
            margin-right: 0.625rem;
          }
        }
      }
    }
  }
}
</style>
