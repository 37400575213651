<template>
  <div class="case_order">
    <tabs :tabs="tabslsit" @handleClick="handleClick"></tabs>
    <oederList :listData="listData"></oederList>
    <!-- <template v-if="!(listData.length === 0)">
    
      <loadingMoreVue :completed="completed" :loadingIcon="loadingIcon" @changeData="loadMore" :key="keys">
      </loadingMoreVue>
    </template>
    <template v-else>
      <emptyPageVue :config="config"></emptyPageVue>
    </template> -->
    <!-- 空状态 -->
    <div class="empty" v-show="emptyShow">
      <img src="@/assets/images/empty.png" alt="">
      <p>哎呀，这里什么也没有~快试试其他搜索词吧！</p>
    </div>
  </div>
</template>
<script>
import tabs from "../../../components/tabs/tabs.vue"
import oederList from "./oederlist/orderlist.vue"
import { orderListApi } from "@/api/api.js"
import paginationCpn from "@/components/common/pagination"
import emptyPageVue from "@/components/common/empty-page.vue"
import loadingMoreVue from "@/components/common/loadingMore.vue"
export default {
  components: {
    tabs,
    oederList,
    paginationCpn,
    emptyPageVue,
    loadingMoreVue,
  },
  data() {
    return {
      emptyShow: false,
      tabslsit: [
        {
          id: "1",
          name: "我的买入",
        },
        {
          id: "2",
          name: "我的卖出",
        },
      ],
      queyrInfo: {
        type: 1,
        page: 1,
        limit: 10,
      },
      listData: [], // 列表数据
      total: 0,
      completed: false,
      loadingIcon: true,
      keys: 1,
      config: {
        emptyImage: require("@/assets/images/home/empty@2x.png"),
        description: "",
      },
    }
  },
  created() {
    // this.getList()

  },
  mounted() {
    this.getlist()
    var that_ = this
    this.$nextTick(() => {
      that_.scroll.sctollBot("", data => {
        if (data == true) {
          if (this.listData.length < this.total) {
            this.queyrInfo.page += 1
            orderListApi(this.queyrInfo).then((res) => {
              if (res.code === 10000) {
                if (res.data.length == 0) {
                  this.emptyShow = true
                } else {
                  this.emptyShow = false
                }
                res.data.map(e => {
                  this.listData.push(e)
                })
                this.total = res.count
              }
            })
          }
        }
      })
    })
  },
  methods: {
    getlist() {
      orderListApi(this.queyrInfo).then((res) => {
        if (res.code === 10000) {
          if (res.data.length == 0) {
            this.emptyShow = true
          } else {
            this.emptyShow = false
          }
          this.total = res.count
          this.listData = res.data
        }
      })
    },

    handleClick(e) {
      this.queyrInfo.page = 1
      this.keys = e
      this.completed = false
      this.loadingIcon = true
      this.queyrInfo.type = e
      this.getlist()
    },

  },
}
</script>
<style lang="scss" scoped>
.case_order {
  padding: 1.6875rem 2rem;

  .tabs {
    margin-left: 1rem;
  }

  .empty {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 18.75rem;
      height: 12.5rem;
    }

    p {
      margin-top: 2rem;
      font-size: 1rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #A0A3BD;
      line-height: 1.1875rem;
    }
  }
}
</style>
