<template>
  <div class="orderlist">
    <div class="list" v-for="item in listData" :key="item.id" @click="onDetail(item)">
      <div class="list_top">
        <div class="order_date">
          <p>订单编号：{{ item.out_trade_no }}</p>
          <p>下单时间：{{ item.created_at }}</p>
        </div>
        <div class="price">￥{{ item.pay_money }}</div>
      </div>
      <div class="list-bot">
        <img :src="item.case_cover" />
        <div class="list——content">
          <p>{{ item.case_name }}</p>
          <span>{{ item.case_introduction }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      list: [],
    }
  },
  mounted() {
   
  },
  methods: {
    onDetail(item) {
      this.$router.push({ path: "/zbkalDetail", query: { id: item.case_id } })
    },
   

  }
}
</script>
<style lang="scss" scoped>
.orderlist {
  .list {
    cursor: pointer;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #eff0f6;
    margin-top: 1rem;

    .list_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.0625rem 2rem 1.375rem;
      border-bottom: 1px solid #eff0f6;

      .order_date {
        display: flex;
        align-content: center;

        p {
          font-size: 1rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 100;
          color: #57596c;
          line-height: 1.1875rem;

          &:nth-child(2) {
            margin-left: 4rem;
          }
        }
      }

      .price {
        font-size: 1.25rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #ff6868;
        line-height: 1.4375rem;
      }
    }

    .list-bot {
      display: flex;
      padding: 1rem 2rem;

      img {
        flex-shrink: 1;
        display: block;
        width: 8.125rem;
        height: 8.125rem;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
      }

      .list——content {
        margin-left: 1rem;
        flex: 1;
        p {
          margin: 1rem 0;
          font-size: 1rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #111029;
          line-height: 1.1875rem;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        span {
          font-size: 0.875rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 100;
          color: #57596c;
          line-height: 1rem;
          word-break: break-all;
          display: -webkit-box;
          line-height: 1rem;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
