<!--
* @description 找social爆款案例
* @fileName index.vue
* @author liulian
* @date 2022/06/10 17:17:31
-->
<template>
  <div class="bkal">
    <div class="scroll">
      <div class="bkal_main screenWidth">
        <div class="top_con">
          <div class="search_box">
            <el-input placeholder="搜竟品案例" v-model="inputValue" @keyup.enter.native="searchHandle"> </el-input>
            <div class="append" @click="searchHandle"><img class="cursor-pointer" src="@/assets/images/home/home_search@2x.png" alt="" /></div>
          </div>
          <div class="top_word flex">
            <div class="type">大家都在搜的爆款案例：</div>
            <div class="top_word_right">
              <ul class="flex-row-around">
                <li class="cursor-pointer" v-for="(item, index) in words" :key="index" @click="clickWord(item)">
                  <span class="font-size-16 font-weight-400">{{ item.name ? "#" + item.name : "" }}</span>
                </li>
                <li class="auto_span"></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="canter">
          <div class="top_title text-align-center">精选优质案例</div>
          <swiperPage></swiperPage>
        </div>
        <div class="bototm">
          <!-- <zbkalGd :key="new Date().getTime()"></zbkalGd> -->
          <zbkalGd></zbkalGd>
        </div>
      </div>
      <up-top></up-top>
    </div>
  </div>
</template>

<script>
import swiperPage from "@/views/zbkal/children/swiper.vue"
import zbkalGd from "@/views/home/children/zbkal_gd.vue"
import { hotKeywordListApi } from "@/api/api.js"
export default {
  name: "index",
  components: { swiperPage, zbkalGd },
  data() {
    return {
      inputValue: "",
      words: [], // 热搜词
    }
  },
  computed: {},
  created() {
    this.getList()
    this.searchHandle()
  },
  mounted() {},
  methods: {
    searchHandle() {
      this.$store.dispatch("setSearchKey", this.inputValue)
    },
    /*
     * @Description: 点击搜索词
     * @MethodAuthor:  liulian
     * @Date: 2022-07-07 16:19:26
     * @param: ''
     */
    clickWord(item) {
      this.inputValue = item.name
    },
    getList() {
      hotKeywordListApi({cate:'case'}).then((res) => {
        if (res.code === 10000) {
          res.data.length > 0 &&
            res.data.map((i) => {
              let obj = {
                name: i.keyword,
              }
              this.words.push(obj)
            })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.bkal {
  background-image: url("../../assets/images/bkal/bk-bg@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 1rem;
  background-attachment: fixed;
  .bkal_main {
    margin: auto;
    .search_box {
      width: 43.75rem;
      margin: 1.5rem auto;
      position: relative;
      .append {
        width: 5.25rem;
        height: 3.375rem;
        background: #111029;
        border-radius: 2.5rem;
        opacity: 1;
        position: absolute;
        top: 0.375rem;
        right: 0.375rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      ::v-deep .el-input__inner {
        height: 4.125rem;
        background: #ffffff;
        border-radius: 2.5rem;
        width: 43.75rem;
        opacity: 1;
        border: 1px solid #111029;
        position: relative;
      }
      img {
        width: 1.5rem;
        height: 1.5rem;
      }

      ::v-deep.el-input__inner::-webkit-input-placeholder {
        color: #a0a3bd;

        font-size: 1.25rem;
        font-weight: 400;
      } /*Chrome/Opera/Safari*/
      ::v-deep .el-input__inner::-moz-placeholder {
        color: #a0a3bd;

        font-size: 1.25rem;
        font-weight: 400;
      } /*Firefox 19*/
      ::v-deep.el-input__inner:-ms-input-placeholder {
        color: #a0a3bd;

        font-size: 1.25rem;
        font-weight: 400;
      } /*id10+*/
      ::v-deep .el-input__inner:-moz-placeholder {
        color: #a0a3bd;
        font-size: 1.25rem;
        font-weight: 400;
      }
    }
    .top_word {
      align-items: flex-start;
      margin: 2.0625rem auto;
      display: flex;
      width: 830px;
      padding-left: 73px;
      .type {
        height: 100%;
        white-space: nowrap;
        margin-right: 0.9375rem;
      }
      .top_word_right {
        ul {
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        li {
          margin-bottom: 0.5rem;
          margin-right: 4.5625rem;
          flex-wrap: wrap;
          display: block;
          span {
            white-space: nowrap;
          }
        }
        .auto_span {
          flex: 1;
        }
      }
    }
    .canter {
      margin: 54px auto;
      padding-bottom: 2rem;
      padding-top: 1rem;
      border-radius: 16px;
      border: 1px solid #dcddeb;
      background: #ffffff;
      margin-right: 30px;
      box-shadow: 0px 10px 28px 0px rgba(58,114,255,0.0800);
      .top_title {
        margin-bottom: 2rem;
        font-size: 1.25rem;
        font-weight: 400;
        color: #111029;
      }
    }
    .bototm {
      margin: auto;
    }
  }
}
</style>
