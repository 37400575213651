<!--
* @description 需求大厅
* @fileName demand-hall.vue
* @author liulian
* @date 2022/06/13 18:09:10
-->
<template>
  <div class="xq_container">
    <div class="left">
      <div class="top flex"><span class="font-size-20">需求大厅</span></div>
      <div class="border_box">
        <div class="con font-size-16">
          <vue-seamless-scroll :key="keys" :data="lists" class="seamless-warp" :class-option="optionSetting1" ref="seamless">
            <p class="cursor-pointer font-weight-500" @click="goDetail(item)" v-for="(item, index) in lists" :key="index">{{ item.demand_name }}</p>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
    <div class="right">
      <!-- <div class="card flex" :class=" index === 2 ? 'is_margin_left' : index === cards.length - 1 || index === cards.length - 2 ? 'no_margin' : 'margin'" v-for="(item, index) in cards" :key="index">
        <img :src="item.avatar"/>
        <div class="con">
          <img class="icon" src="@/assets/images/home/douhao.png" />
          <p class="font-size-16">{{ item.demand_name }}</p>
          <img class="icon" src="@/assets/images/home/douhao2.png" />
        </div>
      </div> -->
      <vue-seamless-scroll :key="keys" :data="lists" class="seamless-warp" :class-option="optionSetting" ref="seamless">
        <div class="card_box">
          <div class="card flex cursor-pointer" :class="index < 2 ? 'item1' : index % 4 !== 0 && index % 2 === 0 ? 'is_margin_left' : index === lists.length - 1 || index === lists.length - 2 ? 'no_margin' : 'margin'" v-for="(item, index) in lists" :key="index" @click="goDetail(item)">
            <img :src="item.avatar"  />
            <div class="con">
              <img class="icon" src="@/assets/images/home/douhao.png" />
              <p class="font-size-16 cursor-pointer" >{{ item.demand_name }}</p>
              <img class="icon" src="@/assets/images/home/douhao2.png" />
            </div>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import { demandListApi } from "@/api/api"
export default {
  name: "demand-hall",
  components: {},
  watch: {
    query: {
      handler: function (va) {
        this.getHallList()
      },
      deep: true,
    },
  },
  props: {
    // 数据列表
    query: {
      type: Object,
      default: () => {
        return {
          page: 1,
          limit: 30,
          sort: "time_desc", // 排序方式 time_desc,time_asc
        }
      },
    },
  },
  data() {
    return {
      cards: [],
      lists: [],
      cardList: [],
      page: 1,
      limit: 6, // 每页显示的条数
      totalPage: null, // 总页面
      keys: 123, // 总页面
      timer: null,
    }
  },
  computed: {
    optionSetting1() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        autoPlay: this.cardList.length > 6, // 是否自动播放
      }
    },
     optionSetting() {
      return {
        step: 0.8, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        autoPlay: this.cardList.length > 6, // 是否自动播放
      }
    },
  },
  created() {
    this.getHallList()
  },
  mounted() {},
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    async getHallList() {
      let data = await this.listApi()
      this.lists = JSON.parse(JSON.stringify(data))
      this.cardList = JSON.parse(JSON.stringify(data))
      this.cards = this.cardList.slice(0, 6)
      this.getCards()
    },
    /*
     * @Description: 列表数据
     * @MethodAuthor:  liulian
     * @Date: 2022-07-26 09:38:44
     * @param: ''
     */
    listApi() {
      return new Promise((resolve, reject) => {
        demandListApi(this.query).then((res) => {
          if (res.code === 10000) {
            this.keys = new Date().getTime()
            resolve(res.data)
          }
        })
      })
    },
    getCards() {
      this.totalPage = Math.ceil(this.cardList.length / this.limit)
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.timer = setInterval((res) => {
        this.page = this.page + 1
        if (this.page > this.totalPage) {
          this.page = 1
        }
        if (this.page === 0) {
          this.cards = this.cardList.slice((this.page - 1) * 6, 6)
        } else {
          this.cards = this.cardList.slice((this.page - 1) * 6, this.page * 6)
        }
      }, 3000)
    },

    goDetail(item) {
      this.$router.push(`/xqdtDetail?id=${item.id}`)
    },
  },
}
</script>

<style scoped lang="scss">
.xq_container {
  display: flex;
  .left {
    width:25.375rem;
    background: #ffffff;
    border-radius: 1.875rem;
    opacity: 1;
    .border_box {
      border: 1px solid #111029;
      border-top: none;
      height: 22.25rem;
      box-shadow: 0rem 0.125rem 0.75rem 0.0625rem rgba(20, 20, 43, 0.08);
      border-bottom-right-radius: 1.875rem;
      border-bottom-left-radius: 1.875rem;
    }
    .top {
      background-image: url("../../assets/images/header_bg@2x.png");
      height: 3.625rem;
      background-size: cover;
      span {
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #111029;
        margin: auto;
      }
    }
    .con {
      width: 20.625rem;
      margin: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-weight: 500;
      color: #111029;
      height: 85%;
      overflow: hidden;
      p {
        margin-bottom: 2rem;
        display: -webkit-box; /*重点，不能用block等其他，将对象作为弹性伸缩盒子模型显示*/
        /*从上到下垂直排列子元素*/
        -webkit-box-orient: vertical;
        /*行数，超出三行隐藏,用省略号表示...*/
        -webkit-line-clamp: 1;
        line-clamp: 1;
        word-break: break-all;
        overflow: hidden;
        max-width: 100%;
      }
    }
  }
  .right {
    width: 53.875rem;
    display: flex;
    flex-wrap: wrap;
    margin-left: 2.5rem;
    height: 25.875rem;
    overflow: hidden;
    .card {
      width: 18.625rem;
      height: 5rem;
      // box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 2px 12px 1px rgba(20, 20, 43, 0.08);
      border-radius: 0.625rem;
      border: 1px solid #dcddeb;
      opacity: 1;
        margin-right: 4.5rem;
      .con {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-left: 1rem;
      }
      p {
        display: -webkit-box; /*重点，不能用block等其他，将对象作为弹性伸缩盒子模型显示*/
        /*从上到下垂直排列子元素*/
        -webkit-box-orient: vertical;
        /*行数，超出三行隐藏,用省略号表示...*/
        -webkit-line-clamp: 2;
        line-clamp: 2;
        word-break: break-all;
        overflow: hidden;
        max-width: 100%;
        width: 10.125rem;
        margin-left: 0.625rem;
        margin-right: 0.625rem;
      }
      img {
        width: 3.375rem;
        height: 3.375rem;
        border-radius: 0.75rem;
        opacity: 1;
        margin-left: 0.4375rem;
      }
      .icon {
        width: 0.625rem;
        height: 0.625rem;
        margin-left: 0px;
        border-radius: 0px;
      }
    }
    .no_margin {
      margin-bottom: 0px;
      margin-right: 4.5rem;
    }
    .is_margin_left {
      margin-left: 6rem;
      // margin-right: 4.5rem;
    }
  }
  .card_box {
    display: flex;
    flex-wrap: wrap;
    .card {
      margin-right: 4.5rem;
      margin-bottom: 2.5rem;
    }
  }
}
</style>
