<!--
* @description 找social爆款案例详情页面
* @fileName detail.vue
* @author liulian
* @date 2022/06/13 09:10:02
-->
<template>
    <div class="abkal_xq screenWidth">
        <div class="top flex-row-between">
            <div class="top_left flex">
                <img :src="detailInfo.creator_info.avatar" />
                <div class="top_left_right flex-column">
                    <div class="flex">
                        <span class="name font-size-16 font-weight-500">{{ detailInfo.creator_info.username }}</span>
                        <span class="dengji font-color-red text-align-center flex font-size-12 font-weight-400"><img
                                src="@/assets/images/home/V@2x.png" alt="" />{{ detailInfo.creator_info.level_context
                                }}</span>
                        <span class="dianzi font-color-green text-align-center font-size-12 font-weight-400">{{
                                detailInfo.creator_info.marketer_role_context
                        }}</span>
                    </div>
                    <span class="fensi font-size-14 font-weight-400">粉丝：null</span>
                </div>
            </div>
            <div class="top_right" v-show="false">
                <span class="top_right_btn text-align-center hover-black cursor-pointer">合作创作者</span>
            </div>
        </div>
        <div class="main">
            <div class="main_left">
                <div class="main_title">
                    <p class="font-weight-500 font-size-20">{{ detailInfo.case_info.name }}</p>
                </div>
                <el-carousel :autoplay="false" indicator-position="none" :loop="false">
                    <el-carousel-item v-for="(item, index) in carouselImgs" :key="index">
                        <img :src="item" alt="" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="main_right">
                <el-tabs v-model="activeName">
                    <el-tab-pane v-for="(item, index) in tabs" :key="index" :name="item.name" :label="item.label">
                        <!-- 作品介绍 -->
                        <template v-if="activeName === '1'">
                            <p class="font-size-16 font-weight-400">
                                {{ detailInfo.case_info.introduction }}
                            </p>
                        </template>
                        <!-- 作品标签 -->
                        <template v-if="activeName === '2'">
                            <div class="tabs_box"
                                v-if="detailInfo.case_info.tag_list && detailInfo.case_info.tag_list.length > 0">
                                <span class="tag" v-for="(item, index) in detailInfo.case_info.tag_list" :key="index">{{
                                        item.name
                                }}</span>
                            </div>
                        </template>
                    </el-tab-pane>
                </el-tabs>

                <div class="main_right_bottom flex" v-show="false">
                    <el-button type="primary" :disabled="detailInfo.case_info.allow_buy === 0"
                        @click="referenceEditorHandle">借鉴编辑</el-button>
                    <div class="shoucang flex text-align-center cursor-pointer">
                        <img src="@/assets/images/bkal/sc@2x.png" alt="" />
                        <span class="font-size-16 font-weight-400">{{ detailInfo.case_info.collect_num }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="tip_bar flex">
            <img class="bj cursor-pointer" src="@/assets/images/bkal/bj@2x.png" alt="" @mouseenter="mouseenterHandle" />
            <span class="xx flex">
                <img src="@/assets/images/bkal/xx@2x.png" alt="" />
                <span class="xx_num font-size-14 font-weight-400">{{ msgCount }}</span>
            </span>
            <span class="time font-size-14 font-weight-500">{{ detailInfo.case_info.created_at }}</span>

            <!-- 二维码弹框 -->
            <div class="qr flex-column-center" v-if="showQr" @mouseleave="mouseleaveHandle">
                <span class="font-size-14 font-weight-400">扫一扫分享内容</span>
                <img src="https://s3.bmp.ovh/imgs/2022/06/13/43d3fbb4f7eca373.jpg" alt="二维码图片" />
                <span class="url cursor-pointer font-color-blue">点击复制连接</span>
            </div>
        </div>



        <div class="pl">
            <!--评论内容  -->
            <el-input class="text_box" v-model="inputValue" type="textarea" placeholder="说点什么吧"
                @keyup.enter.native="plHandle"></el-input>
            <div class="pl_btn">
                <span class="font-size-16 font-weight-400 cursor-pointer"
                    :class="inputValue.length > 0 ? 'isBlack' : ''" @click="plHandle">评论</span>
            </div>
        </div>
        <!-- 消息列表 -->
        <div class="msg" >
            <div class="msg_title text-align-left font-size-20 font-weight-500">全部评论</div>
            <div v-if="msgs.length>0">
            <div class="msg_item" :class="item.children.length > 0 ? '' : 'border'"
                v-for="(item, index) in msgs.slice(0, expendNum)" :key="index">
                <div class="item_left">
                    <img v-if="item.avatar" :src="item.avatar" />
                </div>
                <div class="item_center">
                    <div class="title flex">
                        <span class="name font-size-16 font-weight-500">{{ item.username }}</span>
                        <tag-btn v-if="item.level_context" class="redtag"
                            :config="{ text: item.level_context, type: 'red' }"></tag-btn>
                        <tag-btn v-if="item.marketer_role_context" :config="{ text: item.marketer_role_context }">
                        </tag-btn>
                    </div>
                    <div class="ms">{{ item.content }}</div>
                    <div class="times_bar flex flex-row-between">
                        <span class="times font-size-14 font-weight-400">{{ item.created_at }}</span>
                        <div class="bar_tight cursor-pointer" @click="showInputHandle(item, msgs)">
                            <img src="@/assets/images/bkal/xx@2x.png" />
                            <span class="font-size-14 font-weight-400">{{ item.children.length }}</span>
                        </div>
                    </div>
                    <!-- 输入框盒子 -->
                    <div class="input_box" v-if="item.showInput">
                        <el-input v-model="item.inputValue" :placeholder="`回复${item.username}`"
                            :maxlength="item.maxlength" @keyup.enter.native="childPlHandle(item)"></el-input>
                        <div class="pl_btn">
                            <span class="font-size-16 font-weight-400 cursor-pointer"
                                :class="item.inputValue && item.inputValue.length > 0 ? 'isBlack' : ''"
                                @click="childPlHandle(item)">评论</span>
                        </div>
                    </div>

                    <!-- 子消息列表 -->
                    <div class="msg_children bgc" v-if="item.children.length > 0">
                        <div class="msg_item" :class="index === item.children.length - 1 ? '' : 'border '"
                            v-for="(it, index1) in item.children.slice(0, item.expendNum)" :key="index1">
                            <div class="item_left">
                                <img :src="it.avatar" alt="" />
                            </div>
                            <div class="item_center">
                                <div class="title flex">
                                    <span class="name font-size-16 font-weight-500">{{ it.username }}</span>
                                    <tag-btn v-if="item.level_context" class="redtag"
                                        :config="{ text: item.level_context, type: 'red' }"></tag-btn>
                                    <tag-btn v-if="item.marketer_role_context"
                                        :config="{ text: item.marketer_role_context }"></tag-btn>
                                </div>
                                <div class="ms font-size-16 font-weight-400">
                                    回复<span class="names font-size-16 font-weight-500">{{ it.toUserName }}：</span>{{
                                            it.content
                                    }}
                                </div>
                                <div class="times_bar flex flex-row-between">
                                    <span class="times font-size-14 font-weight-400">{{ it.created_at }}</span>
                                    <div class="bar_tight cursor-pointer" @click="showInputHandle(it, item.children)">
                                        <img src="@/assets/images/bkal/xx@2x.png" />
                                        <!-- <span class="font-size-14 font-weight-400">{{ it }}</span> -->
                                    </div>
                                </div>
                                <!-- 输入框盒子 -->
                                <div class="input_box" v-if="it.showInput">
                                    <el-input v-model="it.inputValue" :placeholder="`回复${item.username}`"
                                        :maxlength="it.maxlength" @keyup.enter.native="childPlHandle2(it, item)">
                                    </el-input>
                                    <div class="pl_btn">
                                        <span class="font-size-16 font-weight-400 cursor-pointer"
                                            :class="it.inputValue && it.inputValue.length > 0 ? 'isBlack' : ''"
                                            @click="childPlHandle2(it, item)">评论</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 子消息展开更多 -->
                    <div class="more" v-if="item.children.length > item.expendNum">
                        <div class="item cursor-pointer" @click="expendChildHandle(item, 1)">
                            <span class="font-size-14 font-weight-400">展开更多</span>
                            <img class="" src="@/assets/images/xqdt/zk@2x.png" alt="icon" />
                        </div>
                    </div>
                    <div class="more" v-if="item.children.length === item.expendNum && item.children.length !== 3">
                        <div class="item cursor-pointer" @click="expendChildHandle(item, 2)">
                            <span class="font-size-14 font-weight-400">收起</span>
                            <img class="shou" src="@/assets/images/xqdt/zk@2x.png" alt="icon" />
                        </div>
                    </div>
                </div>
            </div>
             </div>
        </div>
        <!-- 展开更多 -->
        <div class="more">
            <div v-if="msgs.length > expendNum" class="item cursor-pointer" @click="expendHandle(1)">
                <span class="font-size-14 font-weight-400">展开更多</span>
                <img class="" src="@/assets/images/xqdt/zk@2x.png" alt="icon" />
            </div>
            <div v-if="msgs.length === expendNum && msgs.length !== 3" class="item cursor-pointer"
                @click="expendHandle(2)">
                <span class="font-size-14 font-weight-400">收起</span>
                <img class="shou" src="@/assets/images/xqdt/zk@2x.png" alt="icon" />
            </div>
        </div>
        <el-empty description="快来发表你的评论吧" v-if="msgs.length==0"></el-empty>    
    </div>
</template>

<script>
import dialogCpn from "@/components/common/dialog-cpn.vue"
import { caseDetailApi, commentPageApi, commentApi, replyApi } from "@/api/api"
export default {
    name: "detail",
    components: {
        dialogCpn,
    },
    data() {
        return {
            carouselImgs: [],
            activeName: "1",
            tabs: [
                {
                    label: "作品介绍",
                    name: "1",
                },
                {
                    label: "作品标签",
                    name: "2",
                },
            ],
            msgs: [],
            inputValue: "", // 评论 内容
            showQr: false, // 是否显示二维码弹框
            expendNum: 3, // 默认显示3条
            payDialog: false, // 支付弹框
            tags: [],
            detailInfo: {
                case_info: {},
                creator_info: {},
            },
            commentQuery: {
                module: "",
                module_id: null,
                page: 1,
                limit: 999,
            },
            msgCount: 0, // 总评论条数
        }
    },
    computed: {},
    created() {
        this.getList()
        this.getTagsList()
    },
    mounted() { },
    methods: {
        /*
          * @Description: 子消息评论
          * @MethodAuthor:  liulian
          * @Date: 2022-07-15 15:45:27
          * @param: ''
          */
        childPlHandle(item) {
            if (!item.inputValue) {
                this.$message.info("回复内容不能为空")
                return
            }
            let obj = {
                content: item.inputValue,
                to_uid: item.creatorInfo.id, // 评论人id
                from_uid: this.$store.state.userInfo.id, // 当前登录人id
                comment_id: item.id, // 评论id
            }
            replyApi(obj).then((res) => {
                if (res.code === 10000) {
                    this.$message.success("回复成功！")
                    this.getCommentList()
                }
            })
        },
        /*
      * @Description: 展开更多
      * @MethodAuthor:  liulian
      * @Date: 2022-06-14 11:52:31
      * @param: ''
      */
        expendHandle(key) {
            key === 1 && (this.expendNum = this.msgs.length)
            key === 2 && (this.expendNum = 3)
        },
        /*
      * @Description: 最后一级评论
      * @MethodAuthor:  liulian
      * @Date: 2022-07-15 16:52:48
      * @param: ''
      */
        childPlHandle2(item, parentObj) {
            if (!item.inputValue) {
                this.$message.info("回复内容不能为空")
                return
            }
            let obj = {
                content: item.inputValue,
                to_uid: item.fromUserInfo.id, // 评论人id
                from_uid: this.$store.state.userInfo.id, // 当前登录人id
                comment_id: parentObj.id, // 评论id
            }
            replyApi(obj).then((res) => {
                if (res.code === 10000) {
                    this.$message.success("回复成功！")
                    this.getCommentList()
                }
            })
        },
        // 获取消息列表
        /*
         * @Description: 评论内容
         * @MethodAuthor:  liulian
         * @Date: 2022-06-13 11:56:44
         * @param: ''
         */
        plHandle() {
            console.log(1)
            if (!this.inputValue) {
                this.$message.info("评论内容不能为空")
                return
            }
            let obj = {
                module: "bkal",
                module_id: this.detailInfo.case_info.id,
                content: this.inputValue,
            }
            commentApi(obj).then((res) => {
                if (res.code === 10000) {
                    this.$message.success("评论成功！")
                    this.inputValue = ""
                    this.getCommentList()
                }
            })
        },
        getCommentList() {
            return new Promise((resolve, reject) => {
                this.commentQuery.module = "bkal"
                this.commentQuery.module_id = this.detailInfo.case_info.id
                commentPageApi(this.commentQuery).then((res) => {
                    if (res.code === 10000) {
                        if (res.data.length > 0) {
                            this.msgs = res.data
                            this.msgCount = res.count
                            resolve(res)
                            this.initStyle()
                        }

                    }
                })
            })
        },
        /*
         * @Description: 列表参数化
         * @MethodAuthor:  liulian
         * @Date: 2022-07-15 15:38:36
         * @param: ''
         */
        initStyle() {
            this.msgs.map((i) => {
                this.$set(i, "children", i.replyList)
                this.$set(i, "avatar", i.creatorInfo.avatar)
                this.$set(i, "username", i.creatorInfo.username)
                this.$set(i, "level_context", i.creatorInfo.level_context)
                this.$set(i, "marketer_role_context", i.creatorInfo.marketer_role_context)
                this.$set(i, "showInput", false)
                this.$set(i, "input", null)
                this.$set(i, "maxlength", 2000)
                this.$set(i, "expendNum", 3) // 最多展示几条

                if (i.children.length > 0) {
                    i.children.map((j) => {
                        this.$set(j, "avatar", j.fromUserInfo.avatar)
                        this.$set(j, "username", j.fromUserInfo.username)
                        this.$set(j, "level_context", j.fromUserInfo.level_context)
                        this.$set(j, "marketer_role_context", j.fromUserInfo.marketer_role_context)
                        this.$set(j, "toUserName", j.toUserInfo.username)
                    })
                }
            })
        },
        /*
         * @Description: 获取标签列表
         * @MethodAuthor:  liulian
         * @Date: 2022-07-07 14:56:22
         * @param: ''
         */
        getTagsList() {
            for (let i = 0; i < 10; i++) {
                this.tags.push({
                    name: "精神支柱",
                })
            }
        },
        /*
         * @Description: 借鉴编辑按钮
         * @MethodAuthor:  liulian
         * @Date: 2022-06-16 15:52:08
         * @param: ''
         */
        referenceEditorHandle() {
            // 判断是否是营销师
            if (this.detailInfo.creator_info.marketer_role === 0) {
                this.$message.info("您现在还不是社媒顾问，可前去认证成为社媒顾问后借鉴编辑！")
                return
            } else {
                this.payDialog = true
            }
        },
        /*
         * @Description: 展开更多
         * @MethodAuthor:  liulian
         * @Date: 2022-07-15 17:16:01
         * @param: '' key = 1展开，2收起
         */
        expendChildHandle(item, key) {
            key === 1 && this.$set(item, "expendNum", item.children.length)
            key === 2 && this.$set(item, "expendNum", 3)
        },
        /*
         * @Description:
         * @MethodAuthor:  liulian
         * @Date: 2022-07-13 10:19:04
         * @param: ''
         */
        mouseenterHandle() {
            this.showQr = true
        },
        mouseleaveHandle() {
            this.showQr = false
        },
        /*
      * @Description: 展开评论输入框
      * @MethodAuthor:  liulian
      * @Date: 2022-07-15 15:37:48
      * @param: ''
      */
        showInputHandle(item, arr) {
            this.$set(item, "showInput", true)
        },
        async getList() {
            let data = await this.getDetail()
            console.log("%c [ data ]-349", "font-size:13px; background:pink; color:#bf2c9f;", data)
            if (data.case_info.id) {
                let msgs = await this.getCommentList()
            }

            for (let i = 0; i < 6; i++) {
                this.msgs.push({
                    avatar: "https://e3f49eaa46b57.cdn.sohucs.com/2021/11/10/14/27/MTAwMTIyXzE2MzY1MjU2NDQ4OTM=.png",
                    name: "名字名字名字名字名字",
                    description: "做的真好！",
                    time: "2022.05.20 16:24:32",
                    msgnum: 2,
                    children:
                        i < 3
                            ? [
                                {
                                    avatar: "https://e3f49eaa46b57.cdn.sohucs.com/2021/11/10/14/27/MTAwMTIyXzE2MzY1MjU2NDQ4OTM=.png",
                                    name: "名字名字名字名字名字",
                                    description: "做的真好！",
                                    time: "2022.05.20 16:24:32",
                                    msgnum: 2,
                                },
                                {
                                    avatar: "https://e3f49eaa46b57.cdn.sohucs.com/2021/11/10/14/27/MTAwMTIyXzE2MzY1MjU2NDQ4OTM=.png",
                                    name: "名字名字名字名字名字",
                                    description: "做的真好！",
                                    time: "2022.05.20 16:24:32",
                                    msgnum: 2,
                                },
                            ]
                            : [],
                })
            }
        },
        getDetail() {
            return new Promise((resolve, reject) => {
                let id = this.$route.query.id
                caseDetailApi(id).then((res) => {
                    if (res.code === 10000) {
                        this.detailInfo = res.data
                        if (this.detailInfo.case_info.imgs) {
                            this.carouselImgs = this.detailInfo.case_info.imgs.split(",")
                        }
                        resolve(this.detailInfo)
                    }
                })
            })
        },
    },
}
</script>

<style scoped lang="scss">
.abkal_xq {
    margin: auto;

    .top {
        margin-top: 2.5625rem;
        border-bottom: 1px solid #eff0f6;

        .top_left {
            margin-bottom: 1.375rem;

            img {
                border-radius: 100%;
                width: 2.875rem;
                height: 2.875rem;
            }

            .top_left_right {
                margin-left: 1rem;

                img {
                    width: 10px;
                    height: 10px;
                }

                .fensi {
                    color: #57596c;
                    margin-top: 0.25rem;
                }

                .dengji {
                    width: 2.625rem;
                    height: 1.0625rem;
                    background: #ffeff0;
                    border-radius: 1.5625rem;
                    opacity: 1;
                    display: block;
                    margin-right: 0.375rem;
                }

                .dianzi {
                    min-width: 2.625rem;
                    height: 1.0625rem;
                    background: #def2e6;
                    border-radius: 1.5625rem;
                    opacity: 1;
                    display: block;
                    padding-left: 0.1875rem;
                    padding-right: 0.1875rem;
                }

                .name {
                    margin-right: 0.625rem;
                }
            }
        }

        .top_right {
            margin-bottom: 1.375rem;

            .top_right_btn {
                width: 8rem;
                height: 3.625rem;
                line-height: 3.625rem;
                background: #ffffff;
                border-radius: 3.25rem;
                opacity: 1;
                border: 0.0625rem solid #111029;
                display: block;
            }
        }
    }

    .main {
        margin-top: 2rem;
        display: flex;
        position: relative;

        .main_left {
            margin-right: 3.875rem;
            width: 57.25rem;
            height: 32.25rem;

            .main_title {
                margin-bottom: 2rem;

                p {
                    color: #111029;
                }
            }

            ::v-deep .el-carousel {
                border-radius: 2.9375rem !important;
                overflow: hidden;

                img {
                    border-radius: 2.9375rem !important;
                }
            }

            ::v-deep .el-carousel__container {
                border-radius: 2.9375rem !important;
                height: 32.25rem;
            }

            ::v-deep .el-carousel__item {
                border-radius: 2.9375rem !important;
                padding-bottom: 20px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 2.9375rem;
                }
            }

            ::v-deep .el-carousel__item .is-active {
                border-radius: 2.9375rem;
            }

            ::v-deep .el-carousel__item .is-animating {
                border-radius: 2.9375rem;
            }
        }

        .main_right {
            height: 12.375rem;
            width: 24.5rem;
            margin-top: -0.625rem;
            flex: 1;

            ::v-deep .el-tabs__item {
                font-weight: 100;
                font-size: 1rem;
                color: #111029;
            }

            ::v-deep .el-tabs__nav-wrap::after {
                display: none;
            }

            ::v-deep .el-tabs__active-bar {
                height: 0.25rem;
            }

            ::v-deep .el-tabs__active-bar {
                border-radius: 0.125rem;
            }

            p {
                color: #57596c;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 20;
                line-clamp: 20;
                word-break: break-all;
                overflow: hidden;
            }

            .tabs_box {
                .tag {
                    display: inline-block;
                    font-size: 0.875rem;
                    font-weight: 400;
                    color: #57596c;
                    padding: 0.25rem 0.625rem;
                    border-radius: 1.125rem;
                    border: 0.0625rem solid #eff0f6;
                    margin-right: 1rem;
                    margin-bottom: 1rem;
                }
            }

            .main_right_bottom {
                margin-top: 18rem;
                position: absolute;
                bottom: -3.6875rem;

                .el-button {
                    width: 17.5rem;
                    height: 3.875rem;
                    border-radius: 2.25rem;
                    margin-right: 1rem;
                    font-weight: 400;
                    font-size: 1rem;

                    span {
                        font-weight: 400;
                        font-size: 1rem !important;
                    }
                }

                .el-button:hover {
                    background-color: #3a71ff;
                    border: none;
                }

                .is-disabled:hover {
                    background-color: #888894;
                    border: none;
                }

                .shoucang {
                    width: 5.9375rem;
                    height: 3.875rem;
                    line-height: 3.875rem;
                    border-radius: 2.8125rem;
                    opacity: 1;
                    border: 2px solid #57596c;
                    justify-content: center;

                    img {
                        width: 1.375rem;
                        height: 1.375rem;
                        color: #57596c;
                    }

                    span {
                        color: #57596c;
                    }
                }
            }
        }
    }

    .tip_bar {
        margin-top: 7.18rem;
        position: relative;

        img {
            width: 1.25rem;
            height: 1.25rem;
        }

        .xx {
            margin-left: 13.5rem;
            margin-right: 30.3125rem;

            .xx_num {
                color: #a0a3bd;
                margin-left: 0.375rem;
            }
        }

        .time {
            color: #a0a3bd;
        }

        .qr {
            width: 12.75rem;
            height: 15.125rem;
            background: #ffffff;
            box-shadow: 0rem 0.125rem 0.375rem 0.0625rem rgba(20, 20, 43, 0.06);
            border-radius: 0.625rem;
            opacity: 1;
            border: 0.0625rem solid #eff0f6;
            position: absolute;
            top: 1.625rem;
            left: 0;
            z-index: 33;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            img {
                width: 8.75rem;
                height: 8.75rem;
                margin-top: 0.375rem;
                margin-bottom: 1rem;
            }
        }
    }

    .pl {
        margin-top: 3.5rem;

        ::v-deep .el-textarea__inner {
            height: 6.25rem;
            box-shadow: 0rem 0.25rem 0.625rem 0.0625rem rgba(58, 114, 255, 0.06);
            border-radius: 0.5rem;
            opacity: 1;
        }
    }

    .pl_btn {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;

        span {
            width: 4.75rem;
            height: 3.125rem;
            // background: #f7f7fc;
            background: #eff0f6;
            border-radius: 3.25rem;
            opacity: 1;
            color: #111029;
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .isBlack {
            background: #111029;
            color: #ffffff;
        }
    }

    .msg {
        margin-top: 0.875rem;

        .msg_item {
            margin-top: 2rem;
            position: relative;
            display: flex;

            .item_left {
                position: relative;
                width: 3rem;
                height: 3rem;
                border-radius: 100%;
                background-color: #eee;

                img {
                    width: 3rem;
                    height: 3rem;
                    border-radius: 100%;
                }
            }

            .item_center {
                margin-left: 1rem;
                flex: 1;

                .title {
                    .name {
                        color: #111029;
                    }

                    .redtag {
                        margin-left: 0.5rem;
                        margin-right: 0.375rem;
                    }
                }

                .ms {
                    color: #2f2c42;
                    margin-top: 1rem;
                    font-size: 1rem;
                    font-weight: 400;

                    .names {
                        color: #2f2c42;
                        margin-left: 0.625rem;
                    }
                }

                .times_bar {
                    .times {
                        color: #a0a3bd;
                        margin-top: 1rem;
                        display: block;
                        margin-bottom: 2rem;
                    }

                    .bar_tight {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        right: 0;

                        img {
                            width: 0.9875rem;
                            height: 0.8962rem;
                            margin-right: 0.5rem;
                        }

                        span {
                            color: #a0a3bd;
                        }
                    }
                }

                .input_box {
                    margin-bottom: 2rem;

                    ::v-deep .el-input__inner {
                        height: 3rem;
                    }
                }

                // 子消息列表
                .msg_children {
                    border-radius: 1rem;

                    .msg_item {
                        margin-top: 0;

                        .item_left {
                            margin-top: 1rem;
                            margin-left: 1rem;
                        }

                        .item_center {
                            margin-top: 1rem;

                            .bar_tight {
                                margin-right: 1rem;
                            }
                        }
                    }
                }
            }
        }

        .border {
            border-bottom: 1px solid #eff0f6;
        }

        .bgc {
            background: #f7f7fc;
        }
    }

    .more {
        .item {
            display: inline-block;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;
        }

        img {
            width: 1.25rem;
            height: 1.25rem;
            margin-left: 0.5rem;
        }

        span {
            color: #a0a3bd;
        }
    }

    ::v-deep .el-carousel__arrow {
        background-color: #bebdbf;
        display: block !important;

        i {
            color: #111029;
            font-weight: bold;
            font-size: 20px;
        }
    }

    .rq-dialog {
        ::v-deep .el-dialog__headerbtn {
            font-size: 1.625rem;
        }

        ::v-deep .el-dialog__headerbtn .el-dialog__close {
            color: #111029;
        }

        .rq-img {
            margin: 2.5rem auto;
            width: 17.25rem;
            height: 17.25rem;
        }

        .url {
            margin-top: -0.75rem;
        }

        .title {
            span {
                color: #ff7b52;
            }
        }
    }
}
</style>
