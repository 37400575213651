
<template>
    <div class="share">
        <div class="img">
            <img src="@/assets/images/userprofile/share.png" alt="" @mouseenter="move(1)" @mouseout="out">
        </div>
        <div class="share-box" ref="sharebox" @mouseenter="move(2)" @mouseleave="out">
            <p>扫一扫分享内容</p>
            <div class="qrcode" id="qrcode" ref="qrCodeUrl"></div>
            <!-- <p class="copy" @click.stop="onCopy" >点击复制链接</p> -->
        </div>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
    props: {
        QRCodeMsg: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            codeurl: ""
        }
    },
    watch: {
        QRCodeMsg: {
            handler(e) {
                if (e) {
                    this.codeurl = e
                    this.$refs.qrCodeUrl.innerHTML = ""
                    this.creatQrCode();
                }else {
                    this.codeurl="http://www.baidu.com"
                }
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        this.creatQrCode();
    },
    methods: {
        onCode(e) {
            console.log(e)
        },
        creatQrCode() {
            let that_ = this
            var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: that_.codeurl, // 需要转换为二维码的内容
                width: 140,
                height: 140,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
        },
        move(id) {
            // let that_ = this
            // if (id == 1) {
            //     that_.$emit("onMove")
            //     that_.$refs.sharebox.style.opacity = "1"
            //     that_.$refs.sharebox.style.visibility = "visible"
            // } else {
            //     that_.$refs.sharebox.style.opacity = "1"
            //     that_.$refs.sharebox.style.visibility = "visible"
            // }


        },
        onCopy() {
            this.$emit('copylink')
        },
        out() {
            let that_ = this
            that_.$refs.sharebox.style.opacity = "0"
            that_.$refs.sharebox.style.visibility = "hidden"
        },
    },

}
</script>
</script>
<style lang="scss">
.share {
    position: relative;

    .img {
        cursor: pointer;
        width: 1.25rem;
        height: 1.25rem;

        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .share-box {
        visibility: hidden;
        position: absolute;
        z-index: 100;
        opacity: 0;
        transition: all .5s ease;
        width: 12.75rem;
        height: 15.125rem;
        background: #FFFFFF;
        box-shadow: 0px .125rem .375rem .0625rem rgba(20, 20, 43, 0.06);
        border-radius: .625rem;
        border: 1px solid #EFF0F6;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            font-size: .875rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 100;
            color: #240F45;
            line-height: 1rem;
        }

        #qrcode {
            margin: 1rem 0 1rem;
        }

        .copy {
            color: #3A71FF;
            cursor: pointer;
        }

    }
}
</style>
