<template>
  <div class="comment">
    <div class="container">
      <div class="tle">
        <p>全部评论/回复</p>
      </div>
      <!-- 消息列表 -->
      <template v-if="!showPage">
        <loadingMoreVue :completed="completed" :loadingIcon="loadingIcon" @changeData="loadMore">
          <div class="msg">
            <template v-for="(item, index) in msgs">
              <!-- 别人回复我在某个作品中的评论 -->
              <div v-if="item.module === 'reply'" class="msg_item" :class="item.children && item.children.length > 0 ? '' : 'border'">
                <div class="item_left">
                  <img class="cursor-pointer" :src="item.fromInfo.avatar" @click="goPersonal(item.fromInfo.id)" />
                  <!-- v-if="item.official_type === 1" -->
                  <img v-if="item.fromInfo.official_type === 1" class="icons" src="@/assets/images/home/blue_icon@2x.png" />
                  <img v-if="item.fromInfo.official_type === 2" class="icons" src="@/assets/images/home/yellow_icon@2x.png" />
                </div>
                <div class="item_center">
                  <div class="title flex">
                    <span class="name font-size-16 font-weight-500">{{ item.fromInfo.username }}</span>
                    <tag-btn v-if="item.fromInfo.level_context" class="redtag" :config="{ text: item.fromInfo.level_context, type: 'red' }"></tag-btn>
                    <tag-btn v-if="item.fromInfo.marketer_role_context" :config="{ text: item.fromInfo.marketer_role_context }"></tag-btn>
                    <el-badge is-dot class="item" :hidden="item.isRead">
                      回复了你在 <span class="cursor-pointer" @click="goDetail(item)">{{ item.titleName }} </span> 的评论
                    </el-badge>
                    <div class="date">{{ item.replyInfo.created_at }}</div>
                  </div>
                  <div class="ms">{{ item.replyInfo.content }}</div>
                  <!-- <div class="times_bar flex flex-row-between">
                            <span class="times font-size-14 font-weight-400">{{ item.time }}</span>
                        </div> -->

                  <!-- 子消息列表 -->
                  <div class="msg_children bgc">
                    <div class="msg_item" :class="index === item.children && item.children.length - 1 ? 'border' : ' '">
                      <div class="item_left">
                        <img class="cursor-pointer" :src="item.toInfo.avatar" @click="goPersonal(item.toInfo.id)" />
                        <img v-if="item.toInfo.official_type === 1" class="icons" src="@/assets/images/home/blue_icon@2x.png" />
                        <img v-if="item.toInfo.official_type === 2" class="icons" src="@/assets/images/home/yellow_icon@2x.png" />
                      </div>
                      <div class="item_center">
                        <div class="title flex">
                          <span class="name font-size-16 font-weight-500">{{ item.toInfo.username }}</span>
                          <tag-btn v-if="item.toInfo.level_context" class="redtag" :config="{ text: item.toInfo.level_context, type: 'red' }"></tag-btn>
                          <tag-btn v-if="item.toInfo.marketer_role_context" :config="{ text: item.toInfo.marketer_role_context }"></tag-btn>
                        </div>
                        <div class="ms">{{ item.replyInfo.commentInfo.content }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--第二种类型  我在某个作品中的评论 -->
              <div v-if="item.module === 1" class="msg_item" :class="item.children.length > 0 ? '' : 'border'">
                <div class="item_left">
                  <img :src="item.avatar" alt="" />
                  <!-- v-if="item.official_type === 1" -->
                  <img v-if="item.fromInfo.official_type === 1" class="icons" src="@/assets/images/home/blue_icon@2x.png" />
                  <img v-else class="icons" src="@/assets/images/home/yellow_icon@2x.png" />
                </div>
                <div class="item_center">
                  <div class="title flex">
                    <span class="name font-size-16 font-weight-500">{{ item.name }}</span>
                    <tag-btn class="redtag" :config="{ text: '初级', type: 'red' }"></tag-btn>
                    <tag-btn :config="{ text: '点子王' }"></tag-btn>
                    <el-badge is-dot class="item">你在 <span class="cursor-pointer" @click="goDetail(item)">XXXX </span> 的评论 </el-badge>
                    <div class="date">2022.05.20 16:24:32</div>
                  </div>
                  <div class="ms">{{ item.description }}</div>
                </div>
              </div>
              <!--第三种类型  我在某个作品中评论了该作品中的评论 -->
              <div v-if="item.module === 2" class="msg_item" :class="item.children.length > 0 ? '' : 'border'">
                <div class="item_left">
                  <img :src="item.avatar" alt="" />
                  <!-- v-if="item.official_type === 1" -->
                  <img v-if="item.fromInfo.official_type === 1" class="icons" src="@/assets/images/home/blue_icon@2x.png" />
                  <img v-else class="icons" src="@/assets/images/home/yellow_icon@2x.png" />
                </div>
                <div class="item_center">
                  <div class="title flex">
                    <el-badge is-dot class="item">你在<span class="cursor-pointer" @click="goDetail(item)">XXXX </span>评论了<span style="margin-right: 4px"></span> </el-badge>
                    <span class="name font-size-16 font-weight-500">{{ item.name }}</span>
                    <tag-btn class="redtag" :config="{ text: '初级', type: 'red' }"></tag-btn>
                    <tag-btn :config="{ text: '点子王' }"></tag-btn>
                    <div class="date">2022.05.20 16:24:32</div>
                  </div>
                  <div class="ms">{{ item.description }}</div>
                </div>
              </div>
              <!--第四个种类型  别人评论了我的作品 -->
              <div v-if="item.module === 'comment'" class="msg_item" :class="item.children && item.children.length > 0 ? '' : 'border'">
                <div class="item_left">
                  <img class="cursor-pointer" :src="item.fromInfo.avatar" @click="goPersonal(item.fromInfo.id)" />
                  <!-- v-if="item.official_type === 1" -->
                  <img v-if="item.fromInfo.official_type === 1" class="icons" src="@/assets/images/home/blue_icon@2x.png" />
                  <img v-if="item.toInfo.official_type === 2" class="icons" src="@/assets/images/home/yellow_icon@2x.png" />
                </div>
                <div class="item_center">
                  <div class="title flex">
                    <span class="name font-size-16 font-weight-500">{{ item.fromInfo.username }}</span>
                    <tag-btn v-if="item.fromInfo.level_context" class="redtag" :config="{ text: item.fromInfo.level_context, type: 'red' }"></tag-btn>
                    <tag-btn v-if="item.fromInfo.marketer_role_context" :config="{ text: item.fromInfo.marketer_role_context }"></tag-btn>
                    <el-badge is-dot class="item" :hidden="item.isRead"
                      >评论你的作品 <span class="cursor-pointer" @click="goDetail(item)">{{ item.titleName }} </span>
                    </el-badge>
                    <div class="date">{{ item.commentInfo.created_at }}</div>
                  </div>
                  <div class="ms">{{ item.commentInfo.content }}</div>
                </div>
              </div>
            </template>
          </div>
        </loadingMoreVue>
      </template>

      <!-- 空页面 -->
      <!-- 空状态 -->
      <emptyPageVue v-else :config="config"></emptyPageVue>
    </div>
  </div>
</template>
<script>
import emptyPageVue from "@/components/common/empty-page.vue"
import { messageApi, clearCountApi } from "@/api/api.js"
import { resolve } from "path"
import loadingMoreVue from "@/components/common/loadingMore.vue"
export default {
  components: {
    emptyPageVue,
    loadingMoreVue,
  },
  data() {
    return {
      msgs: [],
      expendHandle: 1,
      config: {
        emptyImage: require("@/assets/images/home/empty@2x.png"),
        description: "",
      },
      queryInfo: {
        type: "comment",
        page: 1,
        limit: 10,
      },
      total: 0,
      completed: false,
      loadingIcon: true,
      showPage: false,
    }
  },
  watch: {
    msgs: {
      handler: function () {
        if (this.msgs.length < this.queryInfo.limit) {
          this.loadingIcon = false
        } else {
          this.loadingIcon = true
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getList()
    this.clearMsg()

  },
  
  mounted() {
  },
  methods: {
  /*
   * @Description: 清空消息
   * @MethodAuthor:  liulian
   * @Date: 2022-08-18 11:21:13
   * @param: '' 
  */
    clearMsg() {
      clearCountApi({module:'comment'}).then((res) => {
        if (res.code === 10000) {
          this.$store.dispatch("setMsgCount", "comment")
        }
      })
    },
    // 跳转到个人主页
    goPersonal(id) {
      if (id) {
        this.$router.push(`/yxyxsJg?id=${id}`)
      }
    },
    /*
     * @Description: 加载更多
     * @MethodAuthor:  liulian
     * @Date: 2022-07-26 15:18:31
     * @param: ''
     */
    async loadMore(e) {
      let page = Math.ceil(this.total / this.queryInfo.limit)
      if (e > page) {
        this.completed = true
        this.loadingIcon = false
        return
      }
      this.queryInfo.page = e
      let data = await this.listAPi()
      setTimeout((res) => {
        this.loadingIcon = false
        this.msgs = this.msgs.concat(data)
      }, 2000)
    },

    /*
     * @Description: 跳转详情页面
     * @MethodAuthor:  liulian
     * @Date: 2022-07-20 17:19:59
     * @param: ''
     */
    goDetail(item) {
      this.$router.push(item.routePath)
    },
    async getList() {
      this.msgs = await this.listAPi()
    },
    listAPi() {
      return new Promise((resolve, reject) => {
        messageApi(this.queryInfo).then((res) => {
          if (res.code === 10000) {
          this.showPage = res.data.length === 0
            this.total = res.count
            if (res.count < this.queryInfo.limit) {
              this.completed = true
              this.loadingIcon = false
            }
            res.data.length > 0 &&
              res.data.map((item) => {
                //   评论
                if (item.module === "comment" && item.commentInfo.module === "bkal") {
                  let name = item.commentInfo.caseInfo.name
                  this.$set(item, "titleName", name)
                  let path = `/zbkalDetail?id=${item.commentInfo.caseInfo.id}`
                  this.$set(item, "routePath", path)
                }
                if (item.module === "comment" && item.commentInfo.module === "article") {
                  let name = item.commentInfo.articleInfo.title
                  this.$set(item, "titleName", name)
                  let path = `/wdwz/articledetail?id=${item.commentInfo.articleInfo.id}`
                  this.$set(item, "routePath", path)
                }
                if (item.module === "comment" && item.commentInfo.module === "xqdt") {
                  let name = item.commentInfo.demandInfo.name
                  this.$set(item, "titleName", name)
                  let path = `/xqdtDetail?id=${item.commentInfo.demandInfo.id}`
                  this.$set(item, "routePath", path)
                }

                //   回复
                if (item.module === "reply" && item.replyInfo.commentInfo.module === "bkal") {
                  let name = item.replyInfo.commentInfo.caseInfo.name
                  this.$set(item, "titleName", name)
                  let path = `/zbkalDetail?id=${item.replyInfo.commentInfo.caseInfo.id}`
                  this.$set(item, "routePath", path)
                }
                if (item.module === "reply" && item.replyInfo.commentInfo.module === "article") {
                  let name = item.replyInfo.commentInfo.articleInfo.title
                  this.$set(item, "titleName", name)
                  let path = `/zbkalDetail?id=${item.replyInfo.commentInfo.articleInfo.id}`
                  this.$set(item, "routePath", path)
                }
                if (item.module === "reply" && item.replyInfo.commentInfo.module === "xqdt") {
                  let name = item.replyInfo.commentInfo.demandInfo.name
                  this.$set(item, "titleName", name)
                  let path = `/zbkalDetail?id=${item.replyInfo.commentInfo.demandInfo.id}`
                  this.$set(item, "routePath", path)
                }
              })

            resolve(res.data)
          }
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.comment {
  .container {
    margin: 0 auto;
    max-width: 86.25rem;
    height: 100%;
    padding-bottom: 3.4375rem;
    .tle {
      border-bottom: 1px solid #eff0f6;
      p {
        margin: 1rem 0;
        font-size: 1rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #111029;
        line-height: 1.1875rem;
      }
    }

    .msg {
      .msg_item {
        margin-top: 2rem;
        position: relative;
        display: flex;
        padding-bottom: 2rem;

        .item_left {
          position: relative;
          img {
            width: 3rem;
            height: 3rem;
            border-radius: 100%;
            position: relative;
          }
          .icons {
            width: 12px;
            height: 12px;
            position: absolute;
            top: 34px;
            right: 1px;
          }
        }

        .item_center {
          margin-left: 1rem;
          flex: 1;

          .el-badge {
            // margin: 0 1rem;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 100;
            color: #2f2c42;
            line-height: 1.1875rem;

            span {
              color: #3a71ff;
            }
          }
          ::v-deep .green {
            margin-right: 0.25rem;
          }

          .date {
            font-size: 0.875rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 100;
            color: #a0a3bd;
            line-height: 1rem;
            margin-left: 0.25rem;
          }

          .title {
            .name {
              color: #111029;
            }

            .redtag {
              margin-left: 0.5rem;
              margin-right: 0.375rem;
            }
          }

          .ms {
            color: #2f2c42;
            margin-top: 1rem;
          }

          .times_bar {
            .times {
              color: #a0a3bd;
              margin-top: 1rem;
              display: block;
              margin-bottom: 2rem;
            }

            .bar_tight {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              right: 0;

              img {
                width: 0.9875rem;
                height: 0.8962rem;
                margin-right: 0.5rem;
              }

              span {
                color: #a0a3bd;
              }
            }
          }

          // 子消息列表
          .msg_children {
            border-radius: 1rem;
            margin-top: 2rem;

            .msg_item {
              margin-top: 0;
              padding-bottom: 1rem;
              .item_left {
                margin-top: 1rem;
                margin-left: 1rem;
              }

              .item_center {
                margin-top: 1rem;

                .bar_tight {
                  margin-right: 1rem;
                }
              }
            }
          }
        }
      }

      .border {
        border-bottom: 1px solid #eff0f6;
      }

      .bgc {
        background: #f7f7fc;
      }
    }
  }
}
</style>
