<template>
  <div class="follow">
    <div class="partake-top">
      <p>全部关注</p>
      <!-- <p>全部标为已读</p> -->
    </div>
    <div class="partake-list">
      <ul v-if="!showPage">
        <loadingMoreVue :completed="completed" :loadingIcon="loadingIcon" @changeData="loadMore">
          <li v-for="(item, index) in lists" :key="index">
            <div class="img">
              <img :src="item.fromInfo.avatar ? item.fromInfo.avatar : '@/assets/images/header/avatar.png'" class="avatar cursor-pointer" alt="" @click="goPersonal(item.fromInfo.id)" />
              <img v-if="item.fromInfo.official_type === 1" src="@/assets/images/userprofile/isvip.png" class="isvip" alt="" />
              <img v-if="item.fromInfo.official_type === 2" src="@/assets/images/home/yellow_icon@2x.png" class="isvip" alt="" />
            </div>
            <div class="content">
              <div class="tle-date">
                <!-- <p>2022.05.20 16:24:32</p> -->
                <div class="lei flex">
                  <p>{{ item.fromInfo.username }}</p>
                  <div class="lei_one">
                    <tag-btn v-if="item.fromInfo.level_context" class="redtag" :config="{ text: item.fromInfo.level_context, type: 'red' }"></tag-btn>
                    <el-badge is-dot class="item" :hidden="item.isRead">
                      <tag-btn v-if="item.fromInfo.marketer_role_context" :config="{ text: item.fromInfo.marketer_role_context }"></tag-btn>
                    </el-badge>
                  </div>
                </div>
              </div>
              <div class="con">
                <p>{{ item.fromInfo.introduction }}</p>
                <p v-if="item.follow_status == 0" @click="onDetail(1, item)">关注</p>
                <p v-else @click="onDetail(2,item)" style="background: #2f2c42; color: #ffffff">互关</p>
              </div>
            </div>
          </li>
        </loadingMoreVue>
      </ul>
      <!-- 空状态 -->
      <emptyPageVue v-else :config="config"></emptyPageVue>
    </div>
  </div>
</template>
<script>
import emptyPageVue from "@/components/common/empty-page.vue"
import { messageApi, followApi,cancelfollowApi,clearCountApi } from "@/api/api.js"
import loadingMoreVue from "@/components/common/loadingMore.vue"
export default {
  components: {
    emptyPageVue,
    loadingMoreVue,
  },
  data() {
    return {
      list: [
        {
          id: 1,
          flag: false,
        },
        {
          id: 2,
          flag: true,
        },
      ],
      lists: [],

      config: {
        emptyImage: require("@/assets/images/home/empty@2x.png"),
        description: "",
      },
      queryInfo: {
        type: "follow",
        page: 1,
        limit: 10,
      },
      total: 0,
      completed: false,
      loadingIcon: true,
      showPage: false,
    }
  },
  watch: {
    lists: {
      handler: function () {
        if (this.lists.length < this.queryInfo.limit) {
          this.loadingIcon = false
        } else {
          this.loadingIcon = true
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getList()
  this.clearMsg()

  },
  mounted() {
  },
  methods: {
      /*
   * @Description: 清空消息
   * @MethodAuthor:  liulian
   * @Date: 2022-08-18 11:21:13
   * @param: '' 
  */
    clearMsg() {
      clearCountApi({module:'follow'}).then((res) => {
        if (res.code === 10000) {
          this.$store.dispatch("setMsgCount", "follow")
        }
      })
    },

    /*
     * @Description: 跳转个人主页
     * @MethodAuthor:  liulian
     * @Date: 2022-08-05 09:27:35
     * @param: ''
     */
    goPersonal(id) {
      this.$router.push(`/yxyxsJg?id=${id}`)
    },

    /*
     * @Description: 加载更多
     * @MethodAuthor:  liulian
     * @Date: 2022-07-26 15:18:31
     * @param: ''
     */
    async loadMore(e) {
      let page = Math.ceil(this.total / this.queryInfo.limit)
      if (e > page) {
        this.completed = true
        this.loadingIcon = false
        return
      }
      this.queryInfo.page = e
      let data = await this.listAPi()
      setTimeout((res) => {
        this.lists = this.lists.concat(data)
      }, 2000)
    },

    onDetail(key, item) {
      if (key === 1) {
        followApi({ follow_uid: item.fromInfo.id }).then((res) => {
          if (res.code === 10000) {
            this.$message.success("关注成功！")
            this.$set(item, "follow_status", 1)

          }
        })
      }else {
        cancelfollowApi(item.fromInfo.id ).then((res) => {
          if (res.code === 10000) {
            this.$message.success("已取消关注")
           this.$set(item, "follow_status", 0)
          }
        })
      }
    },
    async getList() {
      this.lists = await this.listAPi()
    },
    listAPi() {
      return new Promise((resolve, reject) => {
        messageApi(this.queryInfo).then((res) => {
          if (res.code === 10000) {
            this.total = res.count
            this.showPage = res.data && res.data.length === 0
            if (res.count < this.queryInfo.limit) {
              this.completed = true
              this.loadingIcon = false
            }
            resolve(res.data)
          }
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.follow {
  padding: 1rem 2rem;

  .partake-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eff0f6;

    p {
      font-size: 1rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #111029;
      line-height: 1.1875rem;
      padding-bottom: 1rem;

      &:nth-child(2) {
        cursor: pointer;
      }
    }
  }

  .partake-list {
    ul {
      display: flex;
      width: 100%;
      flex-direction: column;

      li {
        width: 100%;
        display: flex;
        padding: 2rem 0;
        border-bottom: 1px solid #eff0f6;

        .img {
          display: block;
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          position: relative;

          .avatar {
            display: block;
            width: 100%;
            height: 100%;
          }

          .isvip {
            position: absolute;
            right: 0.125rem;
            bottom: 0.125rem;
            display: block;
            width: 1.1875rem;
            height: 1.1875rem;
          }
        }

        .content {
          margin-left: 2rem;
          flex: 1;

          .lei {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            // margin: .5rem 0;

            p {
              font-size: 1.125rem;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #111029;
              line-height: 1.3125rem;
              // margin: 0 .5rem;
              margin-right: 1rem;
            }

            .lei_one {
              display: flex;
              flex-direction: row;
              align-items: center;
              position: relative;
              .redtag {
                margin-right: 8px;
              }
            }
          }

          .tle-date {
            display: flex;
            align-items: flex-end;
            padding-bottom: 1rem;

            p {
              font-size: 1.125rem;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #111029;
              line-height: 1.3125rem;

              &:nth-child(2) {
                margin-left: 1rem;
                font-size: 0.875rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #a0a3bd;
                line-height: 1rem;
              }
            }
          }

          .con {
            display: flex;
            align-items: center;

            p {
              width: 70%;
              font-size: 0.875rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 100;
              color: #57596c;
              line-height: 1rem;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;

              &:nth-child(2) {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                width: 4.5rem;
                height: 3rem;
                background: #ffffff;
                border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                border: 1px solid #2f2c42;
                cursor: pointer;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #2f2c42;
                line-height: 1.1875rem;
              }
            }

            .isp {
              background: #2f2c42;
              color: #8a9699;
            }
          }
        }
      }
    }
  }
}
</style>
