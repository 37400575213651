<template>
    <div class="wdxqdetail">
        <div class="container" :style="{ minHeight: pathActive == 3 ? '100vh' : 'auto' }">
            <div class="article-tle">
                <div class="tle">
                    <p>{{ details.name }}</p>
                    <div class="introduce">
                        <div class="per">
                            <img src="../../../../assets/images/userprofile/per.png" alt="">
                            <span v-if="details.recruit_role == 1">个人</span>
                            <span v-else>机构</span>
                        </div>
                        <div class="introduce-box">
                            <div class="price">
                                <img src="../../../../assets/images/xqdt/price.png" alt="">
                                ￥{{ details.budget }}
                            </div>
                            <div class="price">
                                <img src="../../../../assets/images/xqdt/rl.png" alt="">
                                {{ details.delivery_time }}天
                            </div>
                            <div class="price" v-if="pathActive == 1">
                                <img src="../../../../assets/images/xqdt/gr1.png" alt="">
                                {{ details.apply_num }}人
                            </div>
                            <div class="price" v-if="pathActive == 1">
                                <img src="../../../../assets/images/xqdt/yj.png" alt="">
                                {{ details.pv }}
                            </div>

                        </div>
                        <div class="userinfo-right">
                            <div class="errorexamine" v-if="audit_status == 2">
                                <img src="@/assets/images/userprofile/errorexamine.png" alt="">
                                审核驳回
                            </div>

                            <div class="successexamine" v-if="audit_status == 1">
                                <img src="@/assets/images/userprofile/successexamine.png" alt="">
                                审核通过
                            </div>
                            <div class="review" v-if="audit_status == 0">
                                <img src="@/assets/images/userprofile/review.png" alt="">
                                审核中
                            </div>
                        </div>

                    </div>
                </div>
                <div class="userinfor">
                    <!-- <div class="user">
                        <div class="user-left">
                            <img :src="details.avatar" alt="">
                            <p>{{details.username}}</p>
                        </div>
                        <el-button>私信</el-button>
                    </div>
                    <div class="tips">
                        <img src="../../../../assets/images/userprofile/tips.png" alt="">
                        请谨慎甄别需求方信息，防止诈骗和非法项目！
                    </div> -->
                    <div class="infor">
                        <div class="name">
                            <img src="@/assets/images/xqdt/gr3@2x.png" alt="">
                            {{ details.contact_name }}
                        </div>
                        <div class="iphone">
                            <img src="@/assets/images/xqdt/phone@2x.png" alt="">
                            {{ details.contact_mobile }}
                        </div>
                        <div class="email">
                            <img src="@/assets/images/xqdt/email@2x.png" alt="">
                            {{ details.contact_mail }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="social">
                    <p>社交网络</p>
                    <ul>
                        <li v-for="item in tagList.socialList" :key="item.id">{{ item.name }}</li>
                    </ul>
                </div>
                <div class="social">
                    <p>擅长领域</p>
                    <ul>
                        <li v-for="item in tagList.areaList" :key="item.id">{{ item.name }}</li>
                    </ul>
                </div>
                <div class="social">
                    <p>擅长行业</p>
                    <ul>
                        <li v-for="item in tagList.industryList" :key="item.id">{{ item.name }}</li>
                    </ul>
                </div>
                <div class="social" style="align-items: flex-start; margin-top: 2rem;">
                    <p>需求描述</p>
                    <p class="describe" style="white-space: pre-wrap;">
                        {{ details.demand_introduction }}
                    </p>
                </div>
                <div class="social" style="align-items: flex-start;  margin-top: 2rem;">
                    <p>招募要求</p>
                    <p class="describe">
                        {{ details.recruit_introduction }}
                    </p>
                </div>
                <div class="file">
                    <p>相关文档</p>
                    <div class="file-box" v-if="details.doc_url">
                        <img src="../../../../assets/images/xqdt/txt.png" alt="">
                        <p>{{ details.doc_name }}</p>
                        <span @click.stop="download(details.doc_url)">下载</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { myDemandDetail, exaimDeamd } from "@/api/api"

export default {
    components: {

    },
    data() {
        return {
            audit_status: "",//报名状态
            detailId: null,//详情id
            pathActive: null,
            userinfor: {},
            details: {},
            tagList: {
                socialList: [],//社交网络
                areaList: [],//擅长领域
                industryList: [],
            },
        }
    },
    mounted() {
        if (this.$route.query) {
            this.detailId = this.$route.query.id
            this.pathActive = this.$route.query.tabindex
            this.getExaimDetail()

        }
    },
    methods: {
        getExaimDetail() {
            exaimDeamd(this.detailId)
                .then(res => {
                    if (res.code == 10000) {
                        var that_ = this
                        that_.audit_status = res.data.audit_status
                        that_.details = res.data.demand
                        // that_.userinfor=res.data.creator_info
                        let area = []
                        let social = []
                        let industry = []
                        res.data.tag.map(e => {
                            if (e.top_parent_bind == "area") {
                                area.push(e)
                            } else if (e.top_parent_bind == "social") {
                                social.push(e)
                            } else {
                                industry.push(e)
                            }
                        })
                        // 社交网络
                        this.tagList.socialList = social
                        // 擅长领域
                        this.tagList.areaList = area
                        // 擅长行业
                        this.tagList.industryList = industry
                    }
                })
        },
        // 下载文件流
        download(url) {
            window.open(url)
        }
    },
}
</script>
<style lang="scss" scoped>
.wdxqdetail {
    width: 100%;
    height: auto;
    overflow: hidden;

    .container {
        padding-top: 2.5rem;
        max-width: 86.25rem;
        margin: 0 auto;
        height: 100%;

        .article-tle {
            display: flex;

            .tle {
                width: 57.5rem;

                p {
                    font-size: 1.25rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1.4375rem;
                }
            }

            .introduce {
                display: flex;
                margin-top: 5.3125rem;
                align-items: flex-end;

                .per {
                    display: flex;
                    font-size: 1rem;
                    height: 1.375rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2F2C42;
                    line-height: 1.1875rem;
                    background: #EFF0F6;
                    border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
                    padding: .375rem .75rem;

                    img {
                        display: block;
                        width: 1.375rem;
                        height: 1.375rem;
                        margin-right: .5rem;
                    }
                }

                .introduce-box {
                    display: flex;
                    align-items: flex-end;
                    margin-left: 1rem;

                    .price {
                        margin: 0 1rem;
                        display: flex;
                        align-items: center;
                        font-size: 1rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #57596C;
                        line-height: 1.1875rem;

                        img {
                            display: block;
                            width: 1.25rem;
                            height: 1.25rem;
                            margin-right: .625rem;
                        }
                    }
                }

                .userinfo-right {
                    display: flex;
                    margin-left: auto;
                    align-items: center;

                    .errorexamine,
                    .successexamine,
                    .review {
                        width: 6.875rem;
                        height: 2.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        line-height: 1.1875rem;
                        border-radius: 2.1875rem 2.1875rem 2.1875rem 2.1875rem;

                        img {
                            display: block;
                            width: 1.375rem;
                            height: 1.375rem;
                            margin-right: .25rem;
                        }
                    }

                    .errorexamine {
                        color: #DC2B2B;
                        background: #FFEFF0;
                    }

                    .successexamine {
                        color: #11845B;
                        background: #DEF2E6;
                    }

                    .review {
                        color: #086CD9;
                        background: #EAF4FF;
                    }

                }
            }

            .userinfor {
                flex: 1;
                position: relative;
                margin-left: 4rem;
                display: flex;
                align-items: center;

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 1px;
                    height: 9.25rem;
                    background: #EFF0F6;
                    left: -2rem;
                    top: 1.75rem;
                }

                .user {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .user-left {
                        display: flex;
                        align-items: center;

                        img {
                            width: 3rem;
                            height: 3rem;
                            border-radius: 50%;
                        }

                        p {
                            margin-left: 1rem;
                            font-size: 1.25rem;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #111029;
                            line-height: 1.4375rem;
                        }
                    }

                    .el-button {
                        width: 4.25rem;
                        height: 2.375rem;
                        font-size: .875rem;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                        background: #111029;
                        border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                    }
                }

                .tips {
                    margin: .5rem 0 2rem;
                    display: flex;
                    align-items: center;
                    font-size: .75rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #DC2B2B;
                    line-height: .875rem;

                    img {
                        display: block;
                        width: 1rem;
                        height: 1rem;
                        margin-right: .3125rem;
                    }
                }

                .infor {

                    .name,
                    .iphone,
                    .email {
                        display: flex;
                        align-items: center;
                        font-size: 1rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #2F2C42;
                        line-height: 1.1875rem;
                        margin-bottom: 1rem;

                        img {
                            display: block;
                            width: 1.25rem;
                            height: 1.25rem;
                            margin-right: .5rem;
                        }
                    }
                }
            }
        }

        .content {
            margin-top: 4rem;
            padding: 2rem;
            background: #F7F7FC;
            border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
            margin-bottom: 3.375rem;

            .social {
                display: flex;
                align-items: center;
                margin-bottom: 1.1875rem;
                white-space: nowrap;

                p {
                    font-size: 1.125rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2F2C42;
                    line-height: 1.3125rem;
                }

                ul {
                    display: flex;
                    align-items: center;
                    margin-left: 1rem;

                    li {
                        margin: 0 .25rem;
                        font-size: .875rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #57596C;
                        line-height: 1rem;
                        padding: .25rem .5rem;
                        border-radius: 2.5rem 2.5rem 2.5rem 2.5rem;
                        border: .0625rem solid #DCDDEB;
                    }
                }

                .describe {
                    margin-left: 1rem;
                    white-space: pre-wrap;
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #57596C;
                    line-height: 1.1875rem;
                }
            }

            .file,
            .sign {
                display: flex;
                align-items: flex-start;
                margin-top: 2.75rem;

                p {
                    font-size: 1.125rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2F2C42;
                    line-height: 1.3125rem;
                }

                .file-box {
                    margin-left: 1rem;
                    display: flex;
                    align-items: center;
                    background: #FFFFFF;
                    padding: .75rem 1rem;

                    img {
                        display: block;
                        width: 1.375rem;
                        height: 1.6875rem;
                    }

                    p {
                        margin: 0 1rem;
                        font-size: 1rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #57596C;
                        line-height: 1.1875rem;
                    }

                    span {
                        cursor: pointer;
                        font-size: .875rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #3A71FF;
                        line-height: 1rem;
                    }
                }

            }

            .sign {
                ul {
                    margin-left: .5rem;
                    display: flex;
                    align-items: center;

                    li {
                        margin: 0 .5rem;

                        img {
                            display: block;
                            width: 3rem;
                            height: 3rem;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }

    }
}
</style>