<!--
* @description 单页面详情
* @fileName navigationDetail.vue
* @author liulian
* @date 2022/07/22 09:10:45
-->
<template>
  <div class="navigation_con screenWidth" v-html="detailInfo.content"></div>
</template>

<script>
import { pageDetailApi } from "@/api/api.js"
export default {
  name: "navigationDetail",
  components: {},
  data() {
    return {
      detailInfo: {},
    }
  },
  computed: {},
  created() {
    this.getInfo()
  },
  mounted() {},
  methods: {
    getInfo() {
      this.listApi()
    },
    listApi() {
      let id = this.$route.query.id
      pageDetailApi(id).then((res) => {
        if (res.code === 10000) {
          this.detailInfo = res.data
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.navigation_con {
  margin: 1.875rem auto;
  padding-bottom: 4.125rem;
   overflow: hidden;
  ::v-deep p {
    img {
      width: 100%;
    }
  }
  ::v-deep img{
      width: 100%;
  }
}
</style>
