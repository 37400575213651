<!--
* @description 找爆款案例页面更多页面
* @fileName zbkal.vue
* @author liulian
* @date 2022/06/09 16:53:13
-->
<template>
  <div class="bkal_gd">
    <div class="filter_box flex-row-between">
      <div class="left">
        <filterSelect :filters="filters" @vals="valsHandle"></filterSelect>
      </div>
      <div class="right flex">
        <template v-if="sort1 === 'time_asc'">
          <div class="sort flex-row-center flex cursor-pointer" @click="sortCoreHandle1('time_desc')">
            <span class="font-size-20 font-weight-400">按时间排序</span>
            <div class="icons flex-column-center">
              <!-- 上面图标 -->
              <img class="cursor-pointer" src="@/assets/images/top_blue@2x.png" />
              <img class="bottom_icon cursor-pointer" src="@/assets/images/bottom_gray@2x.png" />
            </div>
          </div>
        </template>

        <!-- 下面图标 -->
        <template v-if="sort1 === 'time_desc'">
          <div class="sort flex-row-center flex cursor-pointer" @click="sortCoreHandle1('time_asc')">
            <span class="font-size-20 font-weight-400">按时间排序</span>
            <div class="icons flex-column-center">
              <!-- 上面图标 -->
              <img class="cursor-pointer" src="@/assets/images/top_gray@2x.png" />
              <img class="bottom_icon cursor-pointer" src="@/assets/images/bottom_blue@2x.png" />
            </div>
          </div>
        </template>
        <template v-if="!sort1">
          <div class="sort flex-row-center flex cursor-pointer" @click="sortCoreHandle1('time_desc')">
            <span class="font-size-20 font-weight-400">按时间排序</span>
            <div class="icons flex-column-center">
              <!-- 上面图标 -->
              <img class="cursor-pointer" src="@/assets/images/top_gray@2x.png" />
              <img class="bottom_icon cursor-pointer" src="@/assets/images/bottom_gray@2x.png" />
            </div>
          </div>
        </template>

        <template v-if="sort2 === 'score_asc'">
          <div class="sort flex-row-center flex cursor-pointer" @click="sortCoreHandle2('score_desc')">
            <span class="font-size-20 font-weight-400">按评分排序</span>
            <div class="icons flex-column-center">
              <!-- 上面图标 -->
              <img class="cursor-pointer" src="@/assets/images/top_blue@2x.png" />
              <img class="bottom_icon cursor-pointer" src="@/assets/images/bottom_gray@2x.png" />
            </div>
          </div>
        </template>

        <!-- 下面图标 -->
        <template v-if="sort2 === 'score_desc'">
          <div class="sort flex-row-center flex cursor-pointer" @click="sortCoreHandle2('score_asc')">
            <span class="font-size-20 font-weight-400">按评分排序</span>
            <div class="icons flex-column-center">
              <!-- 上面图标 -->
              <img class="cursor-pointer" src="@/assets/images/top_gray@2x.png" />
              <img class="bottom_icon cursor-pointer" src="@/assets/images/bottom_blue@2x.png" />
            </div>
          </div>
        </template>
        <template v-if="!sort2">
          <div class="sort flex-row-center flex cursor-pointer" @click="sortCoreHandle2('score_desc')">
            <span class="font-size-20 font-weight-400">按评分排序</span>
            <div class="icons flex-column-center">
              <!-- 上面图标 -->
              <img class="cursor-pointer" src="@/assets/images/top_gray@2x.png" />
              <img class="bottom_icon cursor-pointer" src="@/assets/images/bottom_gray@2x.png" />
            </div>
          </div>
        </template>
      </div>
    </div>
    <loading-more :completed="completed" :pageInit="queryInfo.page" @changeData="loadMore" :loadingIcon="loadingIcon" v-if="!(lists.length === 0  )" :key="loadingKey">
      <div class="cardlist flex">
        <coverCard class="item cursor-pointer" v-for="(item, index) in lists" :key="index" :item="item"></coverCard>
      </div>
    </loading-more>
    <emptyPage v-else></emptyPage>
  </div>
</template>

<script>
import filterSelect from "@/components/common/filter-select.vue"
import coverCard from "@/components/common/cover-card.vue"
import { caseListApi } from "@/api/api"
import emptyPage from "@/components/common/empty-page.vue"
import loadingMore from "@/components/common/loadingMore.vue"
export default {
  name: "zbkal",
  components: { filterSelect, coverCard, emptyPage, loadingMore },
  data() {
    return {
      filters: [
        {
          icon: require("@/assets/images/sjwl@2x.png"),
          name: "社交网络",
          isOpen: false,
          slot: "select1",
        },
        {
          icon: require("@/assets/images/schy@2x.png"),
          isOpen: false,
          name: "擅长行业",
          slot: "select2",
        },
        {
          icon: require("@/assets/images/scly@2x.png"),
          isOpen: false,
          name: "擅长领域",
          slot: "select3",
        },
        {
          icon: require("@/assets/images/xmaz@2x.png"),
          isOpen: false,
          name: "项目案值",
          slot: "select4",
        },
      ],
      sort1: "time_desc", // 评分排序
      sort2: null, // 评分排序
      lists: [],
      queryInfo: {
        page: 1,
        limit: 10,
        sort: "time_desc", // 默认倒叙 time_desc,time_asc,score_desc,score_asc
        keyword: "", //关键词
        tag_arr: [], // 标签数组
        case_min_price: null, // 最小案值
        case_max_price: null, // 最大案值
      },
      completed: false, // 加载
      loadingIcon: true, // 加载
      total: null,
      showPage: false,
      loadingKey: new Date().getTime(),
    }
  },
  computed: {
    getSearchKey() {
      return this.$store.state.headerInput
    },
  },
  watch: {
    getSearchKey: {
      handler(val) {
        this.queryInfo.keyword = val
        this.getList()
      },
    },
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    loadMore(e) {
      if (this.total > 0) {
        let check = e > Math.ceil(this.total / this.queryInfo.limit)
        if (check) {
          this.completed = true
          this.loadingIcon = false
          return
        }
      }
      setTimeout((res) => {
        this.queryInfo.page = e
        this.getLoadList() // 重新请求数据
      }, 2000)
    },
    async getLoadList() {
      this.completed = false
      this.loadingIcon = true
      let data = await this.apiList()
      this.lists = this.lists.concat(data)
    },
    /*
     * @Description: 筛选框选择
     * @MethodAuthor:  liulian
     * @Date: 2022-07-13 11:27:22
     * @param: ''
     */
    valsHandle(val) {
      this.queryInfo.tag_arr = []
      this.queryInfo.page = 1
      val.map((item) => {
        if (item.name === "社交网络" || item.name === "擅长行业") {
          if (item.selects.length > 0) {
            item.selects.map((i) => {
              this.queryInfo.tag_arr.push(i)
            })
          }
        }
        if (item.name === "擅长领域") {
          if (item.selects.length > 0) {
            item.selects.map((it) => {
              if (it.value.length > 0) {
                it.value.map((i) => {
                  this.queryInfo.tag_arr.push(i)
                })
              }
            })
          }
        }
        if (item.name === "项目案值") {
          this.queryInfo.case_min_price = item.selects[0] || null
          this.queryInfo.case_max_price = item.selects[1] || null
        }
      })

      this.getList()
    },
    /*
     * @Description: 数据请求
     * @MethodAuthor:  liulian
     * @Date: 2022-07-13 14:05:54
     * @param: ''
     */
    apiList() {
      // this.completed = false
      return new Promise((resolve, rejcet) => {
        caseListApi(this.queryInfo).then((res) => {
          if (res.code === 10000) {
            this.total = res.count
            if (res.count < this.queryInfo.limit) {
              this.completed = true
              this.loadingIcon = false
            }
            this.showPage = res.data.length === 0
            resolve(res.data)
          }
        })
      })
    },
    async getList() {
      if (this.$store.state.headerInput) {
        this.queryInfo.keyword = this.$store.state.headerInput
      }
      this.queryInfo.page = 1
      this.completed = false
      this.loadingIcon = true
      this.lists = await this.apiList()
    },
    /*
     * @Description: 评分排序
     * @MethodAuthor:  liulian
     * @Date: 2022-06-09 17:23:42
     * @param: ''
     */
    sortCoreHandle1(key) {
      this.sort2 = null
      this.sort1 = key
      this.queryInfo.sort = this.sort1
      this.getList()
    },
    sortCoreHandle2(key) {
      this.sort1 = null
      this.sort2 = key
      this.queryInfo.sort = this.sort2
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.bkal_gd {
  .filter_box {
    width: 99%;
    margin: auto;
    position: relative;
  }
  .right {
    margin-right: 0.3125rem;
    position: absolute;
    right: 0.75rem;
    .sort {
      width: 10rem;
      height: 3.25rem;
      background: #ffffff;
      border-radius: 1rem;
      opacity: 1;
      border: 1px solid #eff0f6;
      .icons {
        margin-left: 1.0625rem;
        height: 100%;
        img {
          width: 0.625rem;
          height: 0.3906rem;
        }
        .bottom_icon {
          margin-top: 0.125rem;
        }
      }
    }
    .sort:nth-child(1) {
      margin-right: 1rem;
    }

    img {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
  .cardlist {
    margin: 2rem auto;
    flex-wrap: wrap;
    .item {
      margin-left: 0.625rem;
      margin-right: 0.625rem;
    }
  }
}
</style>
