<!--
* @description 首页更多跳转页面
* @fileName index.vue
* @author liulian
* @date 2022/06/08 09:38:15
-->
<template>
  <div class="more">
    <div class="zbkal_con screenWidth">
      <div class="top text-align-center">
        <div class="el_but cursor-pointer" v-for="(item, index) in btns" :key="index" :class="item.key === keyValue ? 'is_active' : ''" @click="clickBtnHandle(item)">{{ item.text }}</div>
      </div>
      <div class="main">
        <!-- 找爆款案例 -->
        <zbkal v-if="keyValue === 1" :key="keyValue"></zbkal>
        <!-- 找优秀营销师 -->
        <zyxyxs v-if="keyValue === 2" :key="keyValue"></zyxyxs>
        <!-- 需求大厅 -->
        <xqdt v-if="keyValue === 3"  :key="keyValue"></xqdt>
      </div>
      <up-top></up-top>
    </div>
  </div>
</template>

<script>
import coverCard from "@/components/common/cover-card.vue"
import avatarCard from "@/components/common/avatar-card.vue"
import filterSelect from "@/components/common/filter-select.vue"
import zbkal from "@/views/home/children/zbkal_gd.vue"
import zyxyxs from "@/views/home/children/yxyxs_gd.vue"
import xqdt from "@/views/home/children/xqdt_gd.vue"
export default {
  name: "index",
  components: { coverCard, avatarCard, filterSelect, zbkal, zyxyxs, xqdt },
  data() {
    return {
      btns: [
        {
          text: "找social爆款案例",
          key: 1,
          isActive: false,
        },
        {
          text: "找优秀社媒顾问",
          key: 2,
          isActive: false,
        },
        {
          text: "需求大厅",
          key: 3,
          isActive: false,
        },
      ],
      item: {},
      item1: {},
      keyValue: 1,
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVla) {
        this.checkTypes(val.query.type)
      },
      immediate: true,
    },
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /*
     * @Description: 判断是那种类型
     * @MethodAuthor:  liulian
     * @Date: 2022-06-10 17:15:09
     * @param: ''
     */
    checkTypes(key) {
      switch (key) {
        case "爆款案例":
          this.keyValue = 1
          break
        case "优秀营销师":
          this.keyValue = 2
          break
        case "需求大厅":
          this.keyValue = 3
          break
      }
    },
    /*
     * @Description: 激活按钮高亮
     * @MethodAuthor:  liulian
     * @Date: 2022-06-09 16:35:49
     * @param: ''
     */
    clickBtnHandle(item) {
      this.keyValue = item.key
    },
  },
}
</script>

<style scoped lang="scss">
.more {
  background-image: url("../../../assets/images/home/b_bg@2x.png");
  background-repeat: no-repeat;
  background-position: 520px -600px;
  background-attachment: fixed;
  padding-bottom: 2rem;
  min-height: 100vh;
  .zbkal_con {
    margin: auto;
    .top {
      padding-top: 2.5rem;
      .el_but {
        border-radius: 3.25rem;
        height: 5.25rem;
        line-height: 5.25rem;
        font-weight: 400;
        border: 1px solid #111029;
        color: #111029;
        display: inline-block;
        padding-left: 3.5rem;
        padding-right: 3.5rem;
        font-size: 1.25rem;
        margin-right: 2rem;
      }
      .el_but:hover {
        background: #111029;
        color: #ffffff;
      }

      .is_active {
        background: #111029;
        border: 1px solid #111029;
        font-weight: 400;
        color: #ffffff;
      }
      .is_active:hover {
        background-color: #3a71ff;
        border: none;
      }
    }
    .main {
      margin-top: 3.375rem;
    }
  }
}
</style>
