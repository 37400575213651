<template>
    <div>
        <div class="datalist" v-for="item in list" :key="item.id" @click="onclick(item)">
            <div class="userinfo">
                <div class="userinfo-left">
                    <div class="img-box">
                        <img class="avatar"
                            :src="item.module == 'case' ? item.caseInfo.creatorInfo.avatar : '' || item.module == 'demand' ? item.demandInfo.creatorInfo.avatar : '' || item.module == 'article' ? item.articleInfo.increatorInfo.avatar : ''"
                            alt="" @click.stop="onUserinfor(item)">
                        <img src="@/assets/images/b.png" alt="" class="icon" v-if="item.module == 'case' && item.caseInfo.creatorInfo.official_type==1?true: false || item.module == 'demand' && item.demandInfo.creatorInfo.official_type==1?true: false  || item.module == 'article' && item.articleInfo.increatorInfo.official_type==1?true: false">
                        <img src="@/assets/images/y.png" alt="" class="icon" v-if="item.module == 'case' && item.caseInfo.creatorInfo.official_type==2?true: false || item.module == 'demand' && item.demandInfo.creatorInfo.official_type==2?true: false  || item.module == 'article' && item.articleInfo.increatorInfo.official_type==2?true: false">
                    </div>
                    <div class="lei">
                        <p>{{ item.module == 'case' ? item.caseInfo.creatorInfo.username : '' || item.module == 'demand'
                                ? item.demandInfo.creatorInfo.username : '' || item.module == 'article' ?
                                    item.articleInfo.increatorInfo.username : ''
                        }}</p>
                        <div class="lei_one">
                            <img src="@/assets/images/userprofile/V.png" alt="" class="leiimg">
                            <span>{{ item.module == 'case' ? item.caseInfo.creatorInfo.level_context : '' || item.module
                                    == 'demand' ? item.demandInfo.creatorInfo.level_context : '' || item.module == 'article'
                                    ? item.articleInfo.increatorInfo.level_context : ''
                            }}</span>
                        </div>
                        <div class="lei_two">
                            {{ item.module == 'case' ? item.caseInfo.creatorInfo.marketer_role_context : '' ||
                                    item.module == 'demand' ? item.demandInfo.creatorInfo.marketer_role_context : '' ||
                                        item.module == 'article' ? item.articleInfo.increatorInfo.marketer_role_context : ''
                            }}
                        </div>
                    </div>
                </div>
                <div class="userinfo-right" v-show="false">
                    <div class="errorexamine">
                        <img src="@/assets/images/userprofile/errorexamine.png" alt="">
                        审核驳回
                    </div>
                    <div class="successexamine" v-show="false">
                        <img src="@/assets/images/userprofile/successexamine.png" alt="">
                        审核成功
                    </div>
                    <div class="review" v-show="false">
                        <img src="@/assets/images/userprofile/review.png" alt="">
                        审核中
                    </div>
                    <el-dropdown trigger="hover">
                        <span class="el-dropdown-link">
                            <img src="@/assets/images/userprofile/edit.png" alt="">
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>编辑</el-dropdown-item>
                            <el-dropdown-item>删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="content">
                <div class="content-main">
                    <img :src="item.caseInfo.cover" alt="" v-if="item.module == 'case'">
                    <div class="content-tle">
                        <p> {{ item.module == 'case' ? item.caseInfo.name : '' || item.module == 'demand' ? item.demandInfo.name : ''
                                || item.module == 'article' ? item.articleInfo.title : ''
                        }}</p>
                        <span style="white-space: pre-wrap;">{{ item.module == 'case' ? item.caseInfo.introduction : '' ||
                                item.module == 'demand' ? item.demandInfo.demand_introduction : ''
                        }}</span>
                    </div>
                </div>
                <div class="content-bot">
                    <p>{{ item.created_at }}</p>
                    <div class="forward">
                        <div class="comment">
                            <!-- <img src="../../../assets/images/userprofile/forward.png" alt=""> -->
                            <share @onMove="onMoves(item)" :QRCodeMsg="qrurl" @copylink="copylink"></share>
                        </div>
                        <div class="comment">
                            <img src="@/assets/images/userprofile/comment.png" alt="">
                            <span> {{ item.module == 'case' ? item.caseInfo.commentTotal : '' ||
                                    item.module == 'demand' ? item.demandInfo.commentTotal : '' ||
                                        item.module == 'article' ? item.articleInfo.commentTotal : ''
                            }}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import share from "@/components/share/share.vue"
export default {
    components: {
        share
    },
    props: {
        isshow: {
            type: Boolean,
            default: true
        },
        dataList: {
            type: Array / String,
            default: []
        }
    },
    data() {
        return {
            editlist: [
                {
                    name: "编辑",
                    value: 1
                },
                {
                    name: "删除",
                    value: 2
                }
            ],
            qrurl: "",
            list: []
        }
    },
    watch: {
        dataList: {
            handler(data) {
                if (data) {
                    this.list = data
                }
            }
        }
    },
    methods: {
        // 个人主页 
        onUserinfor(item) {
            console.log(item)
            if (item.module == "article") {
                this.$router.push({ path: "/yxyxsJg", query: { id: item.articleInfo.increatorInfo.id } })
            } else if (item.module == "demand") {
                this.$router.push({ path: "/yxyxsJg", query: { id: item.demandInfo.creatorInfo.id } })
            } else {
                this.$router.push({ path: "/yxyxsJg", query: { id: item.caseInfo.creatorInfo.id } })
            }

        },
        onclick(e) {
            console.log(e)
            if (e.module == "case") {
                this.$router.push({ path: '/zbkalDetail', query: { id: e.caseInfo.id } })
            } else if (e.module == "demand") {
                this.$router.push({ path: '/xqdtDetail', query: { id: e.demandInfo.id } })
            } else {
                this.$router.push({ path: '/wdwz/articledetail', query: { id: e.articleInfo.id,status:0} })
            }
        },
        onMoves(item) {
            
            console.log('item--- ', item)
            if(item.module=='article'){
                this.qrurl = this.$url +"#/http://wap.keyy.tech//pages/my/article/detail?id="+item.id +"&status=" + 0
            }
            // this.qrurl = item.codeurl
        },
        copylink(){

        }
    }
}
</script>
<style lang="scss" scoped>
.datalist {
    cursor: pointer;
    margin-top: 1rem;
    padding: 2rem 0;
    border-top: 1px solid #EFF0F6;

    .userinfo {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .userinfo-left {
            display: flex;
            align-items: center;

            .img-box {
                position: relative;
                .avatar {
                    border-radius: 50%;
                    display: block;
                    width: 2.5rem;
                    height: 2.5rem;
                    opacity: 1;
                }
                .icon {
                    position: absolute;
                    right: 0;
                    bottom: 2px;
                }
            }

            .lei {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                margin: .5rem 0;

                p {
                    font-size: 1.125rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1.3125rem;
                    margin: 0 .5rem;
                }

                .lei_one {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 2.625rem;
                    height: 1.0625rem;
                    background: #FFEFF0;
                    border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                    opacity: 1;

                    .leiimg {
                        display: block;
                        width: .5rem !important;
                        height: .5625rem !important;
                    }

                    span {
                        font-size: .75rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #DC2B2B;
                        line-height: .875rem;
                    }
                }

                .lei_two {
                    margin-left: .5625rem;
                    width: auto;
                    padding: 0 .3125rem;
                    height: 1.0625rem;
                    text-align: center;
                    background: #DEF2E6;
                    border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                    opacity: 1;
                    font-size: .75rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #11845B;
                    line-height: 1.0625rem;

                }
            }
        }


        .userinfo-right {
            display: flex;

            align-items: center;

            .errorexamine,
            .successexamine,
            .review {
                width: 6.875rem;
                height: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                line-height: 1.1875rem;
                border-radius: 2.1875rem 2.1875rem 2.1875rem 2.1875rem;

                img {
                    display: block;
                    width: 1.375rem;
                    height: 1.375rem;
                    margin-right: .25rem;
                }
            }

            .errorexamine {
                color: #DC2B2B;
                background: #FFEFF0;
            }

            .successexamine {
                color: #11845B;
                background: #DEF2E6;
            }

            .review {
                color: #086CD9;
                background: #EAF4FF;
            }

            .el-dropdown {
                cursor: pointer;
                margin-left: 2rem;
            }


        }

    }

    .content {
        margin-top: 1.4375rem;
        padding-left: 3.125rem;

        .content-main {
            display: flex;
            align-items: center;

            img {
                display: block;
                width: 8.125rem;
                height: 8.125rem;
                border-radius: .625rem .625rem .625rem .625rem;
            }

            .content-tle {
                margin-left: 1rem;
                flex: 1;
                p {
                    font-size: 1rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1.1875rem;
                    margin: 1rem 0;
                }

                span {
                    font-size: .875rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #57596C;
                    line-height: 1rem;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

            }


        }

        .content-bot {
            margin-top: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #A0A3BD;
                line-height: 1rem;
            }

            .forward {
                display: flex;
                align-items: center;

                .comment {
                    display: flex;
                    align-content: center;

                    span {
                        display: inline-block;
                        margin-left: .3125rem;
                        font-size: .875rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #A0A3BD;
                        line-height: 1rem;
                    }

                    &:nth-child(1) {
                        margin-right: 4.5625rem;
                    }

                    ::v-deep.share-box {
                        right: 0;
                    }
                }
            }
        }
    }
}

.el-dropdown-menu {

    padding: 1rem 0;
    background: #FFFFFF;
    box-shadow: 0px .125rem .375rem .0625rem rgba(20, 20, 43, 0.06);
    border-radius: .625rem .625rem .625rem .625rem;

    .el-dropdown-menu__item {
        font-size: .875rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 100;
        color: #57596C;
        line-height: 1rem;
        margin: .375rem 0;
    }
}
</style>