<template>
  <div class="notify">
    <div class="partake-top">
      <p>全部通知</p>
    </div>
    <div class="partake-list">
      <ul v-if="!showPage">
        <loadingMoreVue :completed="completed" :loadingIcon="loadingIcon" @changeData="loadMore">
          <li v-for="item in list" :key="item.id">
            <img src="@/assets/images/userprofile/notify.png" alt="" />
            <div class="content">
              <div class="tle-date">
                <el-badge is-dot :hidden="item.isRead">
                  <p>系统消息</p>
                </el-badge>
                <p>{{ item.created_at }}</p>
              </div>
              <div class="con">
                <p>您的项目【{{ item.projectInfo && item.projectInfo.title }}】第{{ item.projectProcessInfo && item.projectProcessInfo.stage }}阶段已完成，请注意查看。</p>
                <p @click="onDetail(item)">查看</p>
              </div>
            </div>
          </li>
        </loadingMoreVue>
      </ul>
      <!-- 空状态 -->
      <emptyPageVue v-else :config="config"></emptyPageVue>
    </div>
  </div>
</template>
<script>
import emptyPageVue from "@/components/common/empty-page.vue"
import { messageApi,clearCountApi } from "@/api/api.js"
import loadingMoreVue from "@/components/common/loadingMore.vue"
export default {
  components: {
    emptyPageVue,
    loadingMoreVue,
  },
  data() {
    return {
      list: [],
      config: {
        emptyImage: require("@/assets/images/home/empty@2x.png"),
        description: "",
      },
      queryInfo: {
        type: "project",
        page: 1,
        limit: 10,
      },
      total: 0,
      completed: false,
      loadingIcon: true,
      showPage: false,
    }
  },
  created() {
    this.getList()
    this.clearMsg()
  },
  mounted() {
  // this.clearMsg()
  },
  methods: {
    /*
   * @Description: 清空消息
   * @MethodAuthor:  liulian
   * @Date: 2022-08-18 11:21:13
   * @param: '' 
  */
    clearMsg() {
      clearCountApi({module:'project'}).then((res) => {
        if (res.code === 10000) {
          this.$store.dispatch("setMsgCount", "project")
        }
      })
    },
    /*
     * @Description: 加载更多
     * @MethodAuthor:  liulian
     * @Date: 2022-07-26 15:18:31
     * @param: ''
     */
    async loadMore(e) {
      let page = Math.ceil(this.total / this.queryInfo.limit)
      if (e > page) {
        this.completed = true
        this.loadingIcon = false
        return
      }
      this.queryInfo.page = e
      let data = await this.listAPi()
      setTimeout((res) => {
        this.list = this.list.concat(data)
      }, 2000)
    },

    async getList() {
      this.list = await this.listAPi()
    },
    listAPi() {
      return new Promise((resolve, reject) => {
        messageApi(this.queryInfo).then((res) => {
          if (res.code === 10000) {
            this.total = res.count
            this.showPage =res.data &&  res.data.length === 0
            if (res.count < this.queryInfo.limit) {
              this.completed = true
              this.loadingIcon = false
            }
            resolve(res.data)
          }
        })
      })
    },
    onDetail(item) {
      let id = item.projectInfo.id
      if (!id) {
        this.$message.error("该项目不存在或已被销毁")
        return
      }
      this.$router.push(`/grzx/projectdetail?id=${id}`)
    },
  },
}
</script>
<style lang="scss" scoped>
.notify {
  padding: 1rem 2rem;

  .partake-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eff0f6;

    p {
      font-size: 1rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #111029;
      line-height: 1.1875rem;
      padding-bottom: 1rem;

      &:nth-child(2) {
        cursor: pointer;
      }
    }
  }

  .partake-list {
    ul {
      display: flex;
      width: 100%;
      flex-direction: column;
      li {
        width: 100%;
        display: flex;
        padding: 2rem 0;
        border-bottom: 1px solid #eff0f6;
        img {
          display: block;
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
        }

        .content {
          margin-left: 2rem;
          flex: 1;
          .tle-date {
            display: flex;
            align-items: flex-end;
            padding-bottom: 1rem;

            p {
              font-size: 1.125rem;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #111029;
              line-height: 1.3125rem;

              &:nth-child(2) {
                margin-left: 1rem;
                font-size: 0.875rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #a0a3bd;
                line-height: 1rem;
              }
            }
          }

          .con {
            display: flex;
            align-items: center;

            p {
              font-size: 0.875rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 100;
              color: #57596c;
              line-height: 1rem;

              &:nth-child(2) {
                margin-left: auto;
                cursor: pointer;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #3a71ff;
                line-height: 1.1875rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
