<!--
* @description 优秀营销师
* @fileName yxyxs.vue
* @author liulian
* @date 2022/06/09 10:52:46
-->
<template>
  <div class="yxyxs_container flex">
    <div class="card_box" ref="scroll">
      <div class="content">
        <div class="card" v-for="(item, index) in cards">
          <avatarCard :item="item" :key="index"></avatarCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BScroll from "@better-scroll/core"
import MouseWheel from "@better-scroll/mouse-wheel"
import avatarCard from "@/components/common/avatar-card.vue"
import { marketerListApi } from "@/api/api"
import { resolve } from "path"
BScroll.use(MouseWheel)
export default {
  name: "yxyxs",
  components: { avatarCard },
  data() {
    return {
      cards: [],
    }
  },
  computed: {},
  created() {
    this.getList()
  },
  mounted() {
    this.init()
  },
  methods: {
    /*
     * @Description: 横向滑动
     * @MethodAuthor:  liulian
     * @Date: 2022-06-09 12:03:09
     * @param: ''
     */
    init() {
      this.bs = new BScroll(this.$refs.scroll, {
        scrollX: true,
        scrollY: false,
        mouseWheel: true,
      })
    },
  async  getList() {
      for (let i = 0; i < 10; i++) {
        this.cards.push({
          avatar: "",
          username: "",
          level_context: "",
          official_type: 1,
          marketer_role_context: "",
          score: "",
          trade_num: "",
          newTag: {
            area: [{ name: "" }, { name: "" }],
          },
        })
      }
      this.cards = await this.listApi()

      console.log("%c [  ]-100", "font-size:13px; background:pink; color:#bf2c9f;", this.cards)
    },
    listApi() {
      let obj = {
        page: 1,
        limit: 10,
        sort: "score_desc",
      }

      return new Promise((resolve, rejcet) => {
        marketerListApi(obj).then((res) => {
          if (res.code === 10000) {
            res.data.length > 0 &&
              res.data.map((item) => {
                let tags = {
                  area: [], //擅长领域
                  industry: [], // 擅长行业
                  social: [], // 社交网络
                }
                // 标签分类
                let keys = Object.keys(tags)
                keys.map((key) => {
                  item.tag.length > 0 &&
                    item.tag.map((i) => {
                      if (key === i.top_parent_bind) {
                        tags[key].push(i)
                      }
                    })
                })
                this.$set(item, "newTag", tags)
              })
            resolve(res.data)
            // this.cards = res.data
            console.log("%c [ res.data ]-82", "font-size:13px; background:pink; color:#bf2c9f;", res.data)
          }
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
.yxyxs_container {
  padding-bottom: 7.625rem;
  .card_box {
    display: flex;
    width: 100%;
    overflow: hidden;
    .content {
      display: flex;
    }
  }
  .card {
    width: 15.625rem;
    margin-right: 1rem;
    padding-bottom: 0.625rem;
    .avatar {
      width: 7.5rem;
      height: 7.5rem;
      border-radius: 100%;
      position: relative;
      margin: 2.125rem auto;
      .aratar-img {
        width: 7.5rem;
        height: 7.5rem;
        border-radius: 100%;
      }
      .icon {
        width: 2.375rem;
        height: 2.375rem;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    .guanzhu {
      width: 5rem;
      height: 3.625rem;
      background: #ffffff;
      border-radius: 3.25rem;
      opacity: 1;
      border: 0.0625rem solid #111029;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      position: absolute;
      background-color: #fff;
      bottom: -2.7rem;
      left: 1.25rem;
    }
    .card_con {
      margin: 3.5625rem auto;
      width: 13.625rem;
      .name {
        color: #111029;
      }
      .card_con-center {
        .item1 {
          display: inline-block;
          width: 2.625rem;
          height: 1.0625rem;
          background: #ffeff0;
          border-radius: 1.5625rem;
          opacity: 1;
          margin-top: 0.5rem;
          margin-right: 6px;
          img {
            width: 0.4963rem;
            height: 0.5313rem;
          }
        }
        .item2 {
          width: 2.625rem;
          height: 1.0625rem;
          background: #def2e6;
          border-radius: 1.5625rem;
          display: inline-block;
          opacity: 1;
        }
      }
      .card_con-bottom {
        span {
          width: 4.5rem;
          height: 1.75rem;
          border-radius: 1.4375rem;
          opacity: 1;
          border: 0.0625rem solid #eff0f6;
          display: inline-block;
          line-height: 1.75rem;
          margin-top: 0.5rem;
        }
        span:nth-child(1) {
          margin-right: 6px;
        }
      }
    }
    .card_bottom {
      margin: -2.6875rem auto;
      width: 13.625rem;
      border-top: 0.0625rem solid #f4f4f4;
      .card_bottom-left {
        margin-top: 0.8125rem;
        img {
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 0.125rem;
        }
      }
      .card_bottom-right {
        margin-top: 0.8125rem;
        .jingyan {
          margin-left: 0.375rem;
        }
      }
    }
  }
}
</style>
