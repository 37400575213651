<template>
    <div class="needs">
        <div class="container">
            <h1>{{ title }}</h1>
            <el-form :model="releaseform" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="需求标题" prop="name">
                    <el-input type="text" placeholder="请输入标题" :disabled="disabled" v-model="releaseform.name"
                        maxlength="20" show-word-limit>
                    </el-input>
                </el-form-item>
                <el-form-item label="需求预算" prop="budget">
                    <el-input type="number" placeholder="预算需＞0元" :disabled="disabled" v-model="releaseform.budget">
                        <i slot="suffix">元</i>
                    </el-input>
                </el-form-item>
                <el-form-item label="期望交付周期" prop="delivery_time" class="qiwang">
                    <el-input type="text" placeholder="请输入交付周期" :disabled="disabled"
                        v-model="releaseform.delivery_time">
                        <i slot="suffix">天</i>
                    </el-input>
                </el-form-item>
                <el-form-item label="招募角色" prop="recruit_role">
                    <el-select v-model="releaseform.recruit_role" placeholder="请选择招募角色" :disabled="disabled">
                        <el-option :label="item.name" :value="item.id" v-for="(item, index) in rolelist" :key="index">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div>
                    <!-- 循环展示tags标签 -->
                    <div v-for="(item, index) in taglist" :key="index" >
                        <div class="tagslist" v-if="item.bind == 'area'"> 
                            <p class="name">{{ item.name }}</p>
                            <div>
                                <div class="tags-box" v-for="(item1, index1) in item.children" :key="index1">
                                    <p>{{ item1.name }}</p>
                                    <div class="tags">
                                        <ul>
                                            <li v-for="(item2, index2) in item1.children" :key="index2"
                                                :class="item2.isActive == true ? 'isactive' : ''"
                                                @click="onTag(item2, item.bind, item.children)">
                                                {{ item2.name }}
                                            </li>
                                            <li class="custom" @click="onCustom(item1, item.children, item1.bind)"
                                                v-if="!item1.onInput">
                                                <span>自定义</span><i class="el-icon-plus"></i>
                                            </li>
                                            <li v-else class="cusinp">
                                                <el-input v-model="tagslist.contentvalue"
                                                    @blur="onInputBlur(tagslist.contentvalue, item1.bind)">
                                                </el-input>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <p class="error" v-show="tagshow1">请选择擅长领域</p>
                        </div>
                        <div class="tagslist" v-if="item.bind == 'industry' || item.bind == 'social'" >
                            <p class="name" style="margin-top: .9375rem; white-space: nowrap;">{{ item.name }}</p>
                            <div class="tags-box">
                                <div class="tags" style="margin-bottom:0px">
                                    <ul>
                                        <li v-for="(i, index) in item.children" :key="index"
                                            :class="i.isActive == true ? 'isactive' : ''"
                                            @click="onTag(i, item.bind, item.children)">
                                            {{ i.name }}
                                        </li>
                                        <li class="custom" @click="onCustomtwo(item, item.children, item.bind)"
                                            v-if="!item.onInput">
                                            <span>自定义</span><i class="el-icon-plus"></i>
                                        </li>
                                        <li v-else class="cusinp">
                                            <el-input v-model="tagslist.socialvalue"
                                                @blur="onInputBlur(tagslist.socialvalue, item.bind)">
                                            </el-input>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <p class="error" v-show="tagshow2">请选择 社交网络/擅长行业</p>
                <el-form-item label="需求描述" id="describe" style="align-items: flex-start" prop="demand_introduction">
                    <el-input type="textarea" placeholder="请输入需求描述" :disabled="disabled"
                        v-model="releaseform.demand_introduction" maxlength="2000" show-word-limit>
                    </el-input>
                </el-form-item>
                <el-form-item label="招募需求" id="recruit" style="align-items: flex-start">
                    <el-input type="textarea" placeholder="请输入招募需求" :disabled="disabled"
                        v-model="releaseform.recruit_introduction" maxlength="500" show-word-limit>
                    </el-input>
                </el-form-item>
                <el-form-item label="相关文档" style="align-items: flex-start;minWidth:10rem;">
                    <el-upload class="upload-demo" drag :data="datas" :action="uploadurl" multiple :headers="importHeaders"
                        v-loading="loading"
                        :on-success="OnSuccess" :show-file-list="false" :before-upload="beforeUpload"
                        :disabled="disabled">
                        <div v-if="releaseform.doc_url == ''" class="filebox">
                            <img src="../../../../assets/images/userprofile/upload.png" alt="">
                            <p>上传相关文档</p>
                        </div>
                        <div v-else class="filebox">
                            <img src="../../../../assets/images/userprofile/Frame.png" class="article" alt="">
                            <p>{{ releaseform.doc_name }}</p>
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="姓名" prop="contact_name">
                    <el-input placeholder="请输入姓名 " :disabled="disabled" v-model="releaseform.contact_name">
                    </el-input>
                </el-form-item>
                <el-form-item label="手机" prop="contact_mobile">
                    <el-input placeholder="请输入手机号" :disabled="disabled" v-model="releaseform.contact_mobile" @change="checkHandle('contact_mobile')">
                    </el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="contact_mail">
                    <el-input placeholder="请输入邮箱" :disabled="disabled" v-model="releaseform.contact_mail"  @change="checkHandle('contact_mail')">
                    </el-input>
                </el-form-item>
                <el-form-item id="relesebtn">
                    <el-button type="primary" @click="submitForm('ruleForm')">{{ subtitle }}</el-button>
                    <el-button @click="resetForm('ruleForm')">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
var mytoken = localStorage.getItem('token')
import { replaseDemand, getTags, getRoleListApi, exaimsubmit, exaimDeamd, editDamandList, uploadDamandList } from "@/api/api"
import { uploadfile,getuploadOss} from "@/api/javaApi"
import { isEmail, isPhone } from "@/utils/validate"
export default {
    data() {
        return {
            loading:false,
            datas:{},
            host:"",
            dir:"",
            tagshow2: false,
            tagshow1: false,
            chaeckarr: {
                arrnum: [],
                industryNum: [],
                socailNum: [],
            },
            disabled: false,
            subtitle: "发布",
            title: "发布需求",
            taglist: [],
            demand_id: '',//修改需求id
            tag_id_arr: [],//编辑回显选中的标签
            tagsindex: null,
            flaginput1: false,
            flaginput2: false,
            flaginput3: false,
            flaginput4: false,
            flaginput5: false,
            tagslist: {
                contentvalue: "",
                implevalue: "",
                flowvalue: "",
                socialvalue: "",
                industryvalue: "",
            },
            tagList: {
                socialList: [],//社交网络
                areaList: [],//擅长领域
                industryList: [],
            },
            uploadurl: "",
            importHeaders: { Authorization: mytoken },
            releaseform: {
                doc_name: "",
                doc_url: "",
                common_tag_arr: [],//普通id标签
                custom_tag_arr: []//自定义tag标签

            },
            // 角色列表
            rolelist: [
                {
                    id: 1,
                    name: "个人"
                }, {
                    id: 2,
                    name: '机构'
                }
            ],
            inputVisible: false,
            inputValue: '',
            rules: {
                name: [
                    { required: true, message: '请输入需求标题', trigger: 'blur' },
                    { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
                ],
                budget: [
                    { required: true, message: '请输入需求预算', trigger: 'blur' },
                ],
                delivery_time: [
                    { required: true, message: '请输入期望交付周期', trigger: 'blur' },
                ],
                recruit_role: [
                    { required: true, message: '请选择招募角色', trigger: 'change' }
                ],
                demand_introduction: [
                    { required: true, message: '请输入需求描述', trigger: 'blur' }
                ],
                contact_name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                contact_mobile: [
                    { required: true, message: '请输入正确的手机号', trigger: 'blur' },
                    // {
                    //     validator: isPhone,
                    //     trigger: 'blur'
                    // }
                ],
                contact_mail: [
                    { required: true, message: '请输入正确的邮箱', trigger: 'blur' },
                    // {
                    //     validator: isEmail,
                    //     trigger: 'blur'
                    // }
                ]
            },
        }
    },
    mounted() {
        console.log(this.$route.query)
        if (this.$route.query.id) {
            this.demand_id = this.$route.query.id
            if (this.$route.query.audit_status == 0) {
                this.title = "编辑需求"
                this.getEdit(this.demand_id)
            } else {
                this.title = "编辑需求"
                this.getExaime(this.demand_id)
            }
            this.subtitle = "提交"

        } else {
            this.gettaglist()
            this.title = "发布需求"
            this.subtitle = "发布"

        }
    },
    methods: {
        /*
         * @Description: 格式校验
         * @MethodAuthor:  liulian
         * @Date: 2022-09-08 18:03:19
         * @param: '' 
        */
        checkHandle(type) {
            console.log('type ', type)
            if(type === 'contact_mobile') {
                if(this.releaseform.contact_mobile) {
                    let check = isPhone(this.releaseform.contact_mobile,'00000')
                    console.log('check ', check)
                    if(!check) {
                        this.$message.error('手机号格式不正确')
                    }
                }
            }
            if(type === 'contact_mail') {
                if(this.releaseform.contact_mail) {
                    let check = isEmail(this.releaseform.contact_mail)
                    if(!check) {
                        this.$message.error('邮箱格式不正确')
                    }
                }
            }
        },
       
        // 审核需求详情
        getExaime(id) {
            exaimDeamd(id)
                .then(res => {
                    if (res.code == 10000) {
                        this.releaseform = res.data.demand
                        this.gettaglist()
                    }
                    console.log(res)
                })
        },
        // 编辑需求
        getEdit(id) {
            editDamandList(id)
                .then(res => {
                    if (res.code == 10000) {
                        // 去除小数点
                        res.data.budget = Math.trunc(res.data.budget)
                        this.releaseform = res.data
                        this.tag_id_arr = res.data.tag_id_arr
                        console.log(this.tag_id_arr)
                        this.gettaglist()
                    }
                })
        },
        // 获取标签列表
        gettaglist() {
            getTags()
                .then(res => {
                    if (res.code == 10000) {
                        console.log(this.tag_id_arr)
                        var numlist = []
                        res.data.map((e, index) => {
                            e.onInput = false
                            e.select = []
                            e.children.map((e2, index2) => {
                                if (e2.hasOwnProperty("children") == true) {
                                    e2.onInput = false
                                    e2.children.map(((i, index3) => {
                                        i.custorm = 0
                                        numlist.push(i.id)
                                        if (this.getArrEqual(numlist, this.tag_id_arr).includes(i.id)) {
                                            i.isActive = true
                                            // this.checkItem()
                                        } else {
                                            i.isActive = false
                                        }
                                    }))
                                } else {
                                    numlist.push(e2.id)
                                    e2.custorm = 0
                                    if (this.getArrEqual(numlist, this.tag_id_arr).includes(e2.id)) {
                                        e2.isActive = true
                                        // this.checkItem()
                                    } else {
                                        e2.isActive = false
                                    }
                                }
                            })

                        })
                        this.taglist = res.data
                        this.checkItem()
                    }
                })
        },
        // 判断两个数组里是否有想同的元素
        getArrEqual(arr1, arr2) {
            let newArr = [];
            for (let i = 0; i < arr2.length; i++) {
                for (let j = 0; j < arr1.length; j++) {
                    if (arr1[j] === arr2[i]) {
                        newArr.push(arr1[j]);
                    }
                }
            }
            return newArr;
        },
        // 标签选中
        onTag(item, tagname, arr) {
            let arrnum = []
            let industryNum = []
            let socailNum = []
            this.$set(item, 'isActive', !item.isActive)
            if (tagname == "area") {
                arr.map(e => {
                    e.children.map(st => {
                        if (st.isActive == true) {
                            arrnum.push(st)
                        }
                    })
                })
                this.tagshow1 = false
            } else if (tagname == 'industry') {
                arr.map(e => {
                    if (e.isActive == true) {
                        industryNum.push(e)
                    }
                })
                this.tagshow2 = false
            } else if (tagname == "social") {
                arr.map(e => {
                    if (e.isActive == true) {
                        socailNum.push(e)
                    }
                })
                this.tagshow2 = false

            }

            if (arrnum.length > 5 || industryNum.length > 5 || socailNum.length > 5) {
                this.$message.error("最多只能选5个")
                this.$set(item, 'isActive', false)
                return
            } else {
                this.checkItem()
            }
        },
        checkItem() {
            var arr = []
            var custom = []
            this.taglist.map(e => {
                e.select = []
                e.children.map(item => {
                    if (item.hasOwnProperty("children") == true) {
                        item.children.map(i => {
                            if (i.custorm == 0 && i.isActive == true) {
                                arr.push(i.id)
                                this.chaeckarr.arrnum.push(i.id)
                            } else if (i.custorm == 1 && i.isActive == true) {
                                custom.push({
                                    parent_bind: i.bind,
                                    name: i.name
                                })
                            }
                        })
                    } else {
                        if (item.custorm == 0 && item.isActive == true) {
                            arr.push(item.id)
                            this.chaeckarr.industryNum.push(item.id)
                        } else if (item.custorm == 1 && item.isActive == true) {
                            custom.push({
                                parent_bind: item.bind,
                                name: item.name
                            })
                        }
                    }

                })
            })
            this.releaseform.common_tag_arr = arr
            this.releaseform.custom_tag_arr = custom
            console.log(arr)
            console.log(custom)
        },
        onCustom(item, children, bind) {
            let arr = children
            arr.map(e => {
                this.$set(e, 'onInput', e.bind === bind)
            })
        },
        onCustomtwo(item, children, bind) {
            if (item.bind == bind) {
                this.$set(item, 'onInput', true)
            } else {
                this.$set(item, 'onInput', false)
            }
        },
        // 自定义tag标签
        onInputBlur(tagname, bind) {
            console.log(bind)
            this.taglist.map(e => {
                if (e.bind == "area") {
                    e.children.map(i => {
                        if (i.onInput == true) {
                            i.children.push({
                                isActive: false,
                                name: tagname,
                                custorm: 1,
                                bind: bind

                            })
                            this.$set(i, 'onInput', false)
                            this.tagslist.contentvalue = ""
                        }
                    })
                    console.log(this.taglist)
                } else {
                    if (e.onInput == true) {
                        console.log(tagname)
                        if (tagname != "") {
                            console.log(e.children)
                            e.children.push({
                                isActive: false,
                                name: tagname,
                                custorm: 1,
                                bind: bind
                            })
                            this.$set(e, 'onInput', false)
                            this.tagslist.socialvalue = ""
                        } else {
                            this.$set(e, 'onInput', false)
                        }

                    }

                }
            })
        },
         getuplaod() {
            return new Promise((resolve, rejects) => {
                getuploadOss()
                    .then(res => {
                        resolve(res.data)
                    })
            })

        },
        // 文件上传之前
      async beforeUpload(file) {
            this.loading=true
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const extension = testmsg === "doc" || testmsg === "docx" || testmsg === "pdf";
            const isLt2M = file.size / 1024 / 1024 < 50
            if (!extension) {
                this.$message({
                    message: "上传文件只能是文档类型!",
                    type: "warning",
                });
                this.loading=false
                return false
            }   
            // 也可以在这里增加文件大小限制
            if (!isLt2M) {
                this.$message({
                    message: '上传文件大小不能超过50MB!',
                    type: 'warning'
                });
                this.loading=false
                 return false
            }
            // return extension && isLt2M
             var data = await this.getuplaod()
            this.dir = data.dir;
            this.datas.policy = data.policy;
            this.host = data.host;
            this.uploadurl=data.host
            this.datas.OSSAccessKeyId = data.accessid;
            this.datas.callback=data.callback
            this.datas.signature = data.signature;
            let files = file.name;
            var fileExtension = files.substring(files.lastIndexOf("."));
            var key = this.dir + Number(Math.random().toString().substr(3, 6) + Date.now()).toString(36)  + fileExtension;
            this.datas.key =key
            this.releaseform.doc_name = file.name
            

        },
        // 文件上传成功
        OnSuccess(res) {
            if (res.code == 10000) {
                this.releaseform.doc_url =this.host+ "/"+this.datas.key
                this.loading=false
                console.log(this.releaseform.doc_url)
            }
            console.log(this.releaseform)
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.releaseform.tagsone.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        // 发布
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(this.chaeckarr)
                    if (this.chaeckarr.arrnum.length == 0) {
                        this.tagshow1 = true
                        console.log('1')
                    } else if (this.chaeckarr.industryNum.length == 0) {
                        this.tagshow2 = true
                        console.log('2')
                    } else {
                        if (this.$route.query.id) {
                            if (this.$route.query.audit_status == 0) {
                                this.releaseform.demand_id = this.demand_id
                                // 更新需求
                                uploadDamandList(this.releaseform)
                                    .then(res => {
                                        if (res.code == 10000) {
                                            this.$router.go(-1)
                                            this.$message.success(res.message)

                                        }
                                    })
                            } else {
                                // 重新提交
                                this.releaseform.audit_id = this.demand_id
                                exaimsubmit(this.releaseform)
                                    .then(res => {
                                        if (res.code == 10000) {
                                            this.$router.go(-1)
                                            this.$message.success(res.message)
                                        }
                                    })
                            }
                        } else {
                            // 发布需求
                            replaseDemand(this.releaseform)
                                .then(res => {
                                    if (res.code == 10000) {
                                        this.$message.success(res.message)
                                        this.$refs[formName].resetFields();
                                        this.releaseform.recruit_introduction = ""
                                        this.releaseform.common_tag_arr = []
                                        this.releaseform.custom_tag_arr = []
                                        this.gettaglist()
                                    }
                                })
                        }
                    }
                } else {
                    console.log('error submit!!');
                    if (this.chaeckarr.arrnum.length == 0) {
                        this.tagshow1 = true
                    } else if (this.chaeckarr.industryNum.length == 0 || this.chaeckarr.socailNum.length == 0) {
                        this.tagshow2 = true
                    }
                    return false;
                }
            });
        },
        //   取消
        resetForm(formName) {
            this.$router.go(-1)
            this.$refs[formName].resetFields();

        }
    }
}
</script>
<style lang="scss" >
.needs {
    width: 100%;
    height: auto;
    overflow: hidden;

    .container {
        margin: 0 auto;
        max-width: 86.25rem;
        height: 100%;
        padding: 2.5rem 3.125rem;

        h1 {
            font-size: 2.875rem;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #240F45;
            line-height: 3.375rem;

        }

        .el-form {
            margin-top: 2rem;

            .el-form-item {
                width: 100%;
                display: flex;
                margin: 1rem 0;
                align-items: center;

                .el-form-item__label {
                    width: auto !important;
                    position: relative;
                    text-align: left;
                    white-space: nowrap;
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #111029;
                    line-height: 1.1875rem;

                    &::before {
                        position: absolute;
                        right: -0.3125rem;
                        top: -0.3125rem;
                    }
                }

                .el-form-item__content {
                    margin-left: 1rem !important;

                    .el-input {
                        width: 35.125rem;
                        height: 2.625rem;
                        background: #FFFFFF;
                        box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
                        border-radius: 10px 10px 10px 10px;

                        .el-input__inner {
                            height: 100%;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #111029;
                            line-height: 1.1875rem;

                            &::placeholder {
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &::-webkit-input-placeholder {
                                /* WebKit browsers 适配谷歌 */
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &:-moz-placeholder {
                                /* Mozilla Firefox 4 to 18 适配火狐 */
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &::-moz-placeholder {
                                /* Mozilla Firefox 19+ 适配火狐 */
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &:-ms-input-placeholder {
                                /* Internet Explorer 10+  适配ie*/
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }
                        }

                        .el-input__suffix {
                            margin-right: 1rem;
                        }
                    }

                    .upload-demo {
                        .el-upload-dragger {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: auto !important;
                            padding: 0 1.875rem;
                            height: 3.75rem;
                            background: #FFFFFF;
                            box-shadow: 0px .25rem .625rem .0625rem rgba(58, 114, 255, 0.06);
                            border-radius: .625rem .625rem .625rem .625rem;
                            border: .0625rem solid #EFF0F6;

                            .filebox {
                                height: 100%;
                                display: flex;
                                align-items: center;
                                position: relative;

                                .del {
                                    display: block;
                                    position: absolute;
                                    right: -1.875rem;
                                    top: .3125rem;
                                }

                                p {
                                    font-size: 1rem;
                                    font-family: PingFang SC-Regular, PingFang SC;
                                    font-weight: 100;
                                    color: #A0A3BD;
                                    line-height: 1.1875rem;
                                }
                            }

                        }

                    }



                }


            }
       

            .tagslist {
                display: flex;
                align-items: flex-start;
                position: relative;

                .error {
                    margin: 0;
                    position: absolute;
                    left: 6.25rem;
                    bottom: 0;
                    color: #F56C6C;
                    font-size: 12px;
                    line-height: 1;
                    padding-top: 4px;
                }

                .name {
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #111029;
                    line-height: 1.1875rem;
                    position: relative;

                    &::before {
                        content: "*";
                        color: red;
                        position: absolute;
                        right: -10px;
                        top: -0.3125rem;
                    }
                }

                .tags-box {
                    margin-left: 1.875rem;

                    p {
                        width: 5rem;
                        height: 2.625rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1rem;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #57596C;
                        background: #EFF0F6;
                        border-radius: .25rem .25rem .25rem .25rem;

                    }

                    .tags {
                        margin-bottom: 1.5rem;

                        ul {
                            display: flex;
                            flex-wrap: wrap;

                            li {
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: auto;
                                padding: 0 1rem;
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #57596C;
                                line-height: 1.1875rem;
                                margin: 1rem .25rem 0;
                                white-space: nowrap;
                                height: 2.625rem;
                                background: #FFFFFF;
                                border-radius: 2rem;
                                border: .0625rem solid #DCDDEB;
                            }

                            .custom {
                                span {
                                    display: block;
                                    margin-right: 1rem;
                                }
                            }

                            .cusinp {
                                min-width: 6.875rem;

                                .el-input {
                                    width: 6.875rem;
                                    min-width: 6.875rem;
                                    border: none;
                                    box-shadow: none;

                                    .el-input__inner {
                                        border: none;
                                        box-shadow: none;
                                        width: 6.875rem;
                                        min-width: 6.875rem;
                                    }
                                }
                            }

                            .isactive {
                                border: 1px solid #2F2C42;
                                color: #2F2C42;
                            }
                        }

                    }

                    .el-tag {
                        display: inline-block;
                        height: 2.625rem;
                        padding: .625rem 1rem;
                        background: #FFFFFF;
                        border-radius: 2rem 2rem 2rem 2rem;
                        border: .0625rem solid #DCDDEB;
                        margin: 1rem 0 1rem .625rem;
                        font-size: 1rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #57596C;
                        line-height: 1.1875rem;
                    }


                    .el-button {
                        font-size: 1rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #57596C;
                        line-height: .75rem;
                        padding: .625rem 1rem;
                        background: #FFFFFF;
                        border-radius: 2rem 2rem 2rem 2rem;
                        border: 1px solid #DCDDEB;
                    }

                    .button-new-tag {
                        margin-top: 1rem;
                        margin-left: .625rem;
                        margin-bottom: 1rem;
                        padding: .625rem 1rem;
                        height: 2.625rem;
                        line-height: 1.375rem;
                        background: #FFFFFF;
                        border-radius: 32px 32px 32px 32px;
                        border: 1px solid #2F2C42;
                        font-size: 1rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #57596C;
                        line-height: 1.1875rem;
                    }

                    .input-new-tag {
                        max-width: 120px;
                        margin-left: 10px;
                        margin-top: 1rem;

                        .el-input__inner {
                            padding: .625rem 1rem;
                            height: 2.625rem;
                            line-height: 1.375rem;
                            background: #FFFFFF;
                            border-radius: 32px 32px 32px 32px;
                            border: 1px solid #2F2C42;
                            margin-bottom: 1rem;
                        }
                    }
                }
            }

            .error {
                margin-top: 10px;
                margin-left: 100px;
                color: #F56C6C;
                font-size: 12px;
                line-height: 1;
                padding-top: 4px;
            }

            #relesebtn {
                width: 100%;

                .el-form-item__content {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .el-button {
                        margin-top: 2rem;
                        width: 5rem;
                        height: 3.625rem;
                        font-size: 1rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #FFFFFF;
                        line-height: 1.1875rem;
                        background: #111029;
                        border-radius: 3.25rem 3.25rem 3.25rem 3.25rem;

                        &:nth-child(2) {
                            margin-left: 2rem;
                            background: #FFFFFF;
                            color: #111029;
                        }
                    }
                }
            }

            #describe {

                .el-form-item__content {



                    .el-textarea {
                        width: 80.625rem;
                        min-height: 11.8125rem;
                        background: #FFFFFF;
                        box-shadow: 0px .25rem .625rem .0625rem rgba(58, 114, 255, 0.06);
                        border-radius: .625rem .625rem .625rem .625rem;
                        border: .0625rem solid #EFF0F6;

                        .el-textarea__inner {
                            height: 11.8125rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #111029;
                            line-height: 1.1875rem;

                            &::placeholder {
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &::-webkit-input-placeholder {
                                /* WebKit browsers 适配谷歌 */
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &:-moz-placeholder {
                                /* Mozilla Firefox 4 to 18 适配火狐 */
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &::-moz-placeholder {
                                /* Mozilla Firefox 19+ 适配火狐 */
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &:-ms-input-placeholder {
                                /* Internet Explorer 10+  适配ie*/
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }
                        }
                    }

                }
            }

            #recruit {


                .el-form-item__content {
                    .el-textarea {
                        width: 80.625rem;
                        min-height: 6.25rem;
                        background: #FFFFFF;
                        box-shadow: 0px .25rem .625rem .0625rem rgba(58, 114, 255, 0.06);
                        border-radius: .625rem .625rem .625rem .625rem;
                        border: .0625rem solid #EFF0F6;

                        .el-textarea__inner {
                            height: 6.25rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #111029;
                            line-height: 1.1875rem;

                            &::placeholder {
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &::-webkit-input-placeholder {
                                /* WebKit browsers 适配谷歌 */
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &:-moz-placeholder {
                                /* Mozilla Firefox 4 to 18 适配火狐 */
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &::-moz-placeholder {
                                /* Mozilla Firefox 19+ 适配火狐 */
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }

                            &:-ms-input-placeholder {
                                /* Internet Explorer 10+  适配ie*/
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #A0A3BD;
                                line-height: 1.1875rem;
                            }
                        }
                    }
                }

            }

            .beggod {
                .el-form-item__label {
                    position: relative;

                    &::before {
                        content: "*";
                        color: #F56C6C;
                        margin-right: 4px;
                        position: absolute;
                        right: -0.1875rem;
                        top: -0.3125rem;
                    }
                }


                .el-form-item__content {
                    .tags-box {
                        p {
                            width: 5rem;
                            height: 2.625rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 1rem;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #57596C;
                            background: #EFF0F6;
                            border-radius: .25rem .25rem .25rem .25rem;

                        }

                        .tags {
                            margin-bottom: 1.5rem;

                            ul {
                                display: flex;
                                flex-wrap: wrap;

                                li {
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: auto;
                                    padding: 0 1rem;
                                    font-size: 1rem;
                                    font-family: PingFang SC-Regular, PingFang SC;
                                    font-weight: 100;
                                    color: #57596C;
                                    line-height: 1.1875rem;
                                    margin: 1rem .25rem 0;
                                    white-space: nowrap;
                                    height: 2.625rem;
                                    background: #FFFFFF;
                                    border-radius: 2rem;
                                    border: .0625rem solid #DCDDEB;
                                }

                                .custom {
                                    span {
                                        display: block;
                                        margin-right: 1rem;
                                    }
                                }

                                .cusinp {
                                    min-width: 6.875rem;

                                    .el-input {
                                        width: 6.875rem;
                                        min-width: 6.875rem;
                                        border: none;
                                        box-shadow: none;

                                        .el-input__inner {
                                            border: none;
                                            box-shadow: none;
                                            width: 6.875rem;
                                            min-width: 6.875rem;
                                        }
                                    }
                                }

                                .isactive {
                                    border: 1px solid #2F2C42;
                                    color: #2F2C42;
                                }
                            }

                        }

                        .el-tag {
                            display: inline-block;
                            height: 2.625rem;
                            padding: .625rem 1rem;
                            background: #FFFFFF;
                            border-radius: 2rem 2rem 2rem 2rem;
                            border: .0625rem solid #DCDDEB;
                            margin: 1rem 0 1rem .625rem;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #57596C;
                            line-height: 1.1875rem;
                        }


                        .el-button {
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #57596C;
                            line-height: .75rem;
                            padding: .625rem 1rem;
                            background: #FFFFFF;
                            border-radius: 2rem 2rem 2rem 2rem;
                            border: 1px solid #DCDDEB;
                        }

                        .button-new-tag {
                            margin-top: 1rem;
                            margin-left: .625rem;
                            margin-bottom: 1rem;
                            padding: .625rem 1rem;
                            height: 2.625rem;
                            line-height: 1.375rem;
                            background: #FFFFFF;
                            border-radius: 32px 32px 32px 32px;
                            border: 1px solid #2F2C42;
                            font-size: 1rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #57596C;
                            line-height: 1.1875rem;
                        }

                        .input-new-tag {
                            max-width: 120px;
                            margin-left: 10px;
                            margin-top: 1rem;

                            .el-input__inner {
                                padding: .625rem 1rem;
                                height: 2.625rem;
                                line-height: 1.375rem;
                                background: #FFFFFF;
                                border-radius: 32px 32px 32px 32px;
                                border: 1px solid #2F2C42;
                                margin-bottom: 1rem;
                            }
                        }
                    }








                }
            }
        }
        .qiwang {
            .el-form-item__content{
                .el-input{
                    width: 530px  !important;
                    background-color: red;
                    overflow: hidden;
                }
               
            }
            }
          
           
    }
}
</style>