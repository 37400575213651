<template>
  <div class="loadMore">
    <vue-data-loading :loading="loading" :completed="completed" :offset="-1" :listens="['infinite-scroll', 'pull-down']" :init-scroll="false" @infinite-scroll="infiniteScroll" @pull-down="pullDown">
      <div>
        <slot></slot>
      </div>
      <div class="infinite-scroll-loading" v-if="loadingIcon">
        <slot name="infinite-scroll-loading "><img class="loading_img" src="@/assets/images/loading-ani.gif" /></slot>
      </div>
      <div slot="completed">到底啦__</div>
    </vue-data-loading>
  </div>
</template>

<script>
import VueDataLoading from "vue-data-loading"
export default {
  name: "loadMore",
  props: {
    //   是否显示加载到底部的文字
    completed: {
      type: Boolean,
      default: false,
    },
    // 是否显示加载更多的问文字
    loadingIcon: {
      type: Boolean,
      default: true,
    },
    // 当前页
    pageInit: {
      type: Number,
      default: 1,
    },
  },
  beforeCreate() {
    this.page = 1
    let sTop = document.documentElement.scrollTop || document.body.scrollTop
    if (sTop > 0) {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  },
  components: {
    VueDataLoading,
  },
  watch: {
    pageInit: function (v) {
      this.page = v
    },
    page: function (v) {
     
    },
  },
  data() {
    return {
      loading: false,
      page: 1,
      isBack: false,
    }
  },
  created() {},
  methods: {
    pullDown() {
      this.page = 1
      this.$emit("changeData", 1)
    },
    infiniteScroll() {
      var htmlHeight = document.body.scrollHeight || document.documentElement.scrollHeight
      //clientHeight是网页在浏览器中的可视高度，
      var clientHeight = document.body.clientHeight || document.documentElement.clientHeight
      //scrollTop是浏览器滚动条的top位置，
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      //通过判断滚动条的top位置与可视网页之和与整个网页的高度是否相等来决定是否到达底部
      if (scrollTop + clientHeight == htmlHeight) {
        //相关处理
        this.page++
        this.$emit("changeData", this.page)
      }

      //到底触发的事件
      //   this.page++
      //   this.$emit("changeData", this.page)
    },
  },
}
</script>
<style scoped lang="scss">
.infinite-scroll-loading {
  text-align: center;
  margin-top: 3.375rem;
  //  width: 86.125rem;
}
.loading_img {
  width: 3.125rem;
  height: 3.125rem;
}
</style>
