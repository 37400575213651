var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-steps-area"},[_c('div',{staticClass:"m-steps"},_vm._l((_vm.totalSteps),function(n){return _c('div',{key:n,class:['m-steps-item',
            {
                'finished': _vm.current > n,
                'process': _vm.current === n && n !== _vm.totalSteps,
                'last-process': _vm.current === _vm.totalSteps && n === _vm.totalSteps,
                'middle-wait': _vm.current < n && n !== _vm.totalSteps,
                'last-wait': _vm.current < n && n === _vm.totalSteps,
            }
        ],on:{"click":function($event){return _vm.onChange(n)}}},[_vm._m(0,true),_c('div',{staticClass:"m-steps-content"},[_c('div',{staticClass:"u-steps-title"},[_vm._v(_vm._s(_vm.stepsLabel[n - 1] || 'S ' + n))])])])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-steps-icon"},[_c('span',{staticClass:"u-icon"})])}]

export { render, staticRenderFns }