<!--
* @description 需求大厅详情
* @fileName detail.vue
* @author liulian
* @date 2022/06/14 10:12:17
-->
<template>
  <div class="xqdt_detail screenWidth">
    <!-- 描述区域 -->
    <div class="info">
      <div class="info_left">
        <div class="info_left_title">{{ detailInfo.demand_info.name }}</div>
        <div class="info_left_btns flex-row-between">
          <div class="btns_left flex">
            <div class="item isBtn">
              <!-- <img src="@/assets/images/xqdt/gr@2x.png" alt="icon" /> -->
              <img v-if="detailInfo.demand_info.recruit_role === 1" src="@/assets/images/home/geren@2x.png" alt="icon" />
              <!-- <img v-if="item.flag === 1" src="@/assets/images/home/pinpai@2x.png" alt="icon" /> -->
              <img v-if="detailInfo.demand_info.recruit_role === 2" src="@/assets/images/home/jigou@2x.png" alt="icon" />
              <span class="font-size-16 font-weight-500">{{ detailInfo.demand_info.recruit_role === 1 ? "个人" : "机构" }}</span>
            </div>
            <div class="item">
              <img src="@/assets/images/xqdt/price@2x.png" alt="icon" />
              <span class="font-size-16 font-weight-400">￥{{ detailInfo.demand_info.budget }}</span>
            </div>
            <div class="item">
              <img src="@/assets/images/xqdt/rl@2x.png" alt="icon" />
              <span class="font-size-16 font-weight-400">{{ detailInfo.demand_info.delivery_time }}天</span>
            </div>
            <div class="item">
              <img src="@/assets/images/xqdt/gr1@2x.png" alt="icon" />
              <span class="font-size-16 font-weight-400">{{ detailInfo.demand_info.apply_num }}人</span>
            </div>
            <div class="item">
              <img src="@/assets/images/xqdt/yj@2x.png" alt="icon" />
              <span class="font-size-16 font-weight-400">{{ detailInfo.demand_info.pv }}</span>
            </div>
          </div>
          <div class="bens_right" v-if="showBtn">
            <span v-if="detailInfo.is_join" class="font-size-16 font-weight-400 cursor-pointer hover-black">已报名</span>
            <span v-else class="font-size-16 font-weight-400 cursor-pointer hover-black" @click="partyInHandle">参与需求</span>
          </div>
        </div>
      </div>
      <div class="info_right">
        <div class="info_right_left">
          <div class="avatar flex">
            <img class="cursor-pointer" :src="detailInfo.creator_info.avatar" @click="goPersonal(detailInfo.creator_info.id)" />
            <span class="font-size-20 font-weight-500">{{ detailInfo.creator_info.username }}</span>
          </div>
          <div class="tip flex">
            <img src="@/assets/images/xqdt/jg@2x.png" alt="icon" />
            <span class="font-color-red font-size-12 font-weight-400">请谨慎甄别需求方信息，防止诈骗和非法项目！</span>
          </div>
          <div class="item flex">
            <img src="@/assets/images/xqdt/gr3@2x.png" alt="icon" />
            <span class="font-size-16 font-weight-400">{{ detailInfo.demand_info.contact_name }}</span>
          </div>
          <div class="item flex">
            <img src="@/assets/images/xqdt/phone@2x.png" alt="icon" />
            <span class="font-size-16 font-weight-400">{{ detailInfo.demand_info.contact_mobile }}</span>
          </div>
          <div class="item flex">
            <img src="@/assets/images/xqdt/email@2x.png" alt="icon" />
            <span class="font-size-16 font-weight-400">{{ detailInfo.demand_info.contact_mail }}</span>
          </div>
        </div>
        <div class="info_right_rihgt" v-if="status == ''">
          <span class="cursor-pointer font-size-14 font-weight-500" @click.stop="privateLetter(detailInfo)">私信</span>
        </div>
      </div>
    </div>

    <!--  内容展示区 -->
    <div class="content">
      <div class="con" :class="item.type === '相关文档' ? 'w words_box' : ''" v-for="(item, index) in contents" :key="index">
        <!-- <span class="type font-size-18 font-weight-500">{{ item.type }}</span> -->
        <!-- 文字修改 -->
        <span class="type font-size-18 font-weight-500">
          <template v-if="item.type === '擅长行业'">行业领域</template>
          <template v-if="item.type === '擅长领域'">传播内容</template>
          <template v-if="item.type !== '擅长行业' && item.type !== '擅长领域'"> {{ item.type }}</template>
        </span>
        <div class="right_con is-tag" v-if="item.children">
          <span class="tag font-size-14 font-weight-400" v-for="(it, index1) in item.children" :key="index1">{{ it.name }}</span>
        </div>
        <div class="right_con" v-else-if="item.content">
          <p class="font-size-14 font-weight-400">{{ item.content }}</p>
        </div>
        <div class="right_con" v-if="item.doc_name">
          <div class="word flex">
            <img src="@/assets/images/xqdt/txt@2x.png" alt="icon" />
            <span class="font-size-16 font-weight-400">{{ item.doc_name }}</span>
            <span class="url cursor-pointer font-color-blue font-size-14 font-weight-400" @click="downHandle(item.doc_url)">下载</span>
          </div>
        </div>
        <div class="right_con" v-if="item.type === '报名列表'">
          <div class="img_box flex" v-if="item.joins.length > 0">
            <template v-for="item in item.joins">
              <img class="img cursor-pointer" :src="item.avatar" @click="clickAvatar(item)" />
            </template>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div @mouseenter="mouseenterHandle">
          <img class="bj cursor-pointer" src="@/assets/images/xqdt/bj@2x.png" alt="icon" />
          <!-- 二维码弹框 -->
          <div class="qr flex-column-center" id="qrId" v-if="showQr" @mouseleave="mouseleaveHandle">
            <div class="font-size-14 font-weight-400">扫一扫分享内容</div>
            <!-- <img class="qr_img" src="https://files.catbox.moe/gz5qjr.jpg" alt="二维码图片" /> -->
            <div class="qrcode_box">
              <qrcode_cpn :url="qrcodeUrl"></qrcode_cpn>
            </div>
            <div class="url cursor-pointer font-color-blue font-size-16 font-weight-400" v-clipboard:copy="this.qrcodeUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">点击复制连接</div>
          </div>
        </div>
        <img class="xx" src="@/assets/images/xqdt/xx@2x.png" alt="icon" />
        <span class="font-size-14 font-weight-400">{{ detailInfo.demand_info.commentTotal }}</span>
      </div>
    </div>
    <!--评论内容  -->
    <div class="pl">
      <el-input class="text_box" v-model="inputValue" type="textarea" placeholder="说点什么吧" @keyup.enter.native="plHandle"></el-input>
      <div class="pl_btn">
        <span class="font-size-16 font-weight-400 cursor-pointer" :class="inputValue.length > 0 ? 'isBlack' : ''" @click="plHandle">评论</span>
      </div>
    </div>
    <!-- 消息列表 -->
    <div class="msg">
      <div class="msg_title text-align-left font-size-20 font-weight-500">全部评论</div>
      <template v-if="msgs.length > 0">
        <div class="msg_item" :class="item.children.length > 0 ? '' : 'border'" v-for="(item, index) in msgs.slice(0, expendNum)" :key="index">
          <div class="item_left">
            <img class="cursor-pointer" v-if="item.avatar" :src="item.avatar" @click="goPersonal(item.creator_id)" />
          </div>
          <div class="item_center">
            <div class="title flex">
              <span class="name font-size-16 font-weight-500">{{ item.username }}</span>
              <tag-btn v-if="item.level_context" class="redtag" :config="{ text: item.level_context, type: 'red' }"></tag-btn>
              <tag-btn v-if="item.marketer_role_context" :config="{ text: item.marketer_role_context }"></tag-btn>
            </div>
            <div class="ms">{{ item.content }}</div>
            <div class="times_bar flex flex-row-between">
              <span class="times font-size-14 font-weight-400">{{ item.created_at }}</span>
              <div class="bar_tight cursor-pointer">
                <i v-if="item.creatorInfo.id === ($store.state.userInfo && $store.state.userInfo.id)" class="el-icon-delete delete_icon" @click="delHandle(item, 0)"></i>
                <img src="@/assets/images/bkal/xx@2x.png" @click="showInputHandle(item, msgs)" />
                <span class="font-size-14 font-weight-400">{{ item.children.length }}</span>
              </div>
            </div>
            <!-- 输入框盒子 -->
            <div class="input_box" v-if="item.showInput">
              <el-input v-model="item.inputValue" :placeholder="`回复${item.username}`" :maxlength="item.maxlength" @keyup.enter.native="childPlHandle(item)"></el-input>
              <div class="pl_btn">
                <span class="font-size-16 font-weight-400 cursor-pointer" :class="item.inputValue && item.inputValue.length > 0 ? 'isBlack' : ''" @click="childPlHandle(item)">评论</span>
              </div>
            </div>

            <!-- 子消息列表 -->
            <div class="msg_children bgc" v-if="item.children.length > 0">
              <div class="msg_item" :class="index === item.children.length - 1 ? '' : 'border '" v-for="(it, index1) in item.children.slice(0, item.expendNum)" :key="index1">
                <div class="item_left">
                  <img class="cursor-pointer" :src="it.avatar" alt="" @click="goPersonal(it.creator_id)" />
                </div>
                <div class="item_center">
                  <div class="title flex">
                    <span class="name font-size-16 font-weight-500">{{ it.username }}</span>
                    <tag-btn v-if="item.level_context" class="redtag" :config="{ text: item.level_context, type: 'red' }"></tag-btn>
                    <tag-btn v-if="item.marketer_role_context" :config="{ text: item.marketer_role_context }"></tag-btn>
                  </div>
                  <div class="ms font-size-16 font-weight-400">
                    回复<span class="names font-size-16 font-weight-500">{{ it.toUserName }}：</span>{{ it.content }}
                  </div>
                  <div class="times_bar flex flex-row-between">
                    <span class="times font-size-14 font-weight-400">{{ it.created_at }}</span>
                    <div class="bar_tight cursor-pointer">
                      <i  v-if="it.fromUserInfo.id ===($store.state.userInfo && $store.state.userInfo.id)" class="el-icon-delete delete_icon" @click="delHandle(it, 1)"></i>
                      <img src="@/assets/images/bkal/xx@2x.png" @click="showInputHandle(it, item.children)" />
                      <!-- <span class="font-size-14 font-weight-400">{{ it }}</span> -->
                    </div>
                  </div>
                  <!-- 输入框盒子 -->
                  <div class="input_box" v-if="it.showInput">
                    <el-input v-model="it.inputValue" :placeholder="`回复${it.username}`" :maxlength="it.maxlength" @keyup.enter.native="childPlHandle2(it, item)"></el-input>
                    <div class="pl_btn">
                      <span class="font-size-16 font-weight-400 cursor-pointer" :class="it.inputValue && it.inputValue.length > 0 ? 'isBlack' : ''" @click="childPlHandle2(it, item)">评论</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 子消息展开更多 -->
            <div class="more" v-if="item.children.length > item.expendNum">
              <div class="item cursor-pointer" @click="expendChildHandle(item, 1)">
                <span class="font-size-14 font-weight-400">展开更多</span>
                <img class="" src="@/assets/images/xqdt/zk@2x.png" alt="icon" />
              </div>
            </div>
            <div class="more" v-if="item.children.length === item.expendNum && item.children.length !== 3">
              <div class="item cursor-pointer" @click="expendChildHandle(item, 2)">
                <span class="font-size-14 font-weight-400">收起</span>
                <img class="shou" src="@/assets/images/xqdt/zk@2x.png" alt="icon" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <el-empty v-else description="快来发表你的评论吧 "></el-empty>
    </div>

    <!-- 展开更多 -->
    <div class="more">
      <div v-if="msgs.length > expendNum" class="item cursor-pointer" @click="expendHandle(1)">
        <span class="font-size-14 font-weight-400">展开更多</span>
        <img class="" src="@/assets/images/xqdt/zk@2x.png" alt="icon" />
      </div>
      <div v-if="msgs.length === expendNum && msgs.length !== 3" class="item cursor-pointer" @click="expendHandle(2)">
        <span class="font-size-14 font-weight-400">收起</span>
        <img class="shou" src="@/assets/images/xqdt/zk@2x.png" alt="icon" />
      </div>
    </div>
    <!-- 弹框组件 -->
    <dailogCpn :show.sync="showDialog" width="690px" :center="true" title="报名">
      <div class="dialog flex-column">
        <p class="top font-size-16 font-weight-400">请简述你能胜任的原因（详细的说明可增加你的胜任率）</p>
        <div class="input_box">
          <el-input type="textarea" resize="none" placeholder="" v-model="textareaValue" maxlength="50" show-word-limit> </el-input>
          <div class="tip" v-if="!(textareaValue.length > 0)">描述你的相关经历、对需求的相关理解</div>
        </div>
        <el-checkbox v-model="isCheck">我承诺，如有接触非公开的项目信息，我将对这些信息保密。</el-checkbox>
      </div>
      <template slot="footer-slot">
        <div class="bottom-btns flex">
          <el-button plain @click="cancelHandle">取消</el-button>
          <el-button type="primary" @click="isOkHandle">确认</el-button>
        </div>
      </template>
    </dailogCpn>
  </div>
</template>

<script>
import dailogCpn from "@/components/common/dialog-cpn.vue"
import qrcode_cpn from "@/components/common/qrcode_cpn.vue"
import { demandDetailApi, signUp, commentApi, commentPageApi, replyApi, delCommontAPi, delReply } from "@/api/api"
export default {
  name: "detail",
  components: { dailogCpn, qrcode_cpn },
  data() {
    return {
      contents: [
        {
          type: "社交网络",
          key: "social",
          children: [],
        },
        {
          type: "擅长领域",
          key: "area",
          children: [],
        },
        {
          type: "擅长行业",
          key: "industry",
          children: [],
        },
        {
          type: "需求描述",
          key: "xqms",
          content: "",
        },
        {
          type: "招募要求",
          key: "zmyq",
          content: "",
        },
        {
          type: "相关文档",
          key: "doc",
          doc_name: "",
          doc_url: "",
        },
        {
          type: "报名列表",
          key: "join",
          joins: [],
        },
      ],
      msgs: [],
      expendNum: 3, // 默认显示3条
      inputValue: "",
      showDialog: false,
      isCheck: false, // 是否统一承诺
      textareaValue: "", // 弹框里输入框
      detailInfo: {
        demand_info: {},
        creator_info: {},
      }, // 需求详情内容
      showQr: false, // 二维码弹框
      commentQuery: {
        module: "",
        module_id: null,
        page: 1,
        limit: 999,
      },
      msgCount: 0, // 总评论条数
      showBtn: false,
      status: "",
      qrcodeUrl: "", //二维码链接
    }
  },
  computed: {},
  created() {
    this.getList()
    this.getUrl()
  },
  mounted() {
    if (this.$route.query.status) {
      this.status = this.$route.query.status
    } else {
      this.status = ""
    }
  },
  methods: {
    /*
     * @Description: 品论删除
     * @MethodAuthor:  liulian
     * @Date: 2022-09-08 09:38:04
     * @param: key 0,父级，1子级
     */
    delHandle(item, key) {
      this.$messageBox("确认删除该条内容么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          switch (key) {
            case 0:
              this.delCommonApi(item)
              break
            case 1:
              this.delReplyApi(item)
              break
          }
        })
        .catch(() => {})
    },
    /*
     * @Description: 回复删除
     * @MethodAuthor:  liulian
     * @Date: 2022-09-08 10:02:32
     * @param: ''
     */
    delReplyApi(item) {
      delReply(item.id).then((res) => {
        if (res.code === 10000) {
          this.$message.success("删除成功！")
          this.getCommentList()
          this.expendNum = 3
        }
      })
    },
    /*
     * @Description: 删除品论
     * @MethodAuthor:  liulian
     * @Date: 2022-09-08 09:45:07
     * @param: ''
     */
    delCommonApi(item) {
      delCommontAPi(item.id).then((res) => {
        if (res.code === 10000) {
          this.$message.success("删除成功！")
          this.getCommentList()
          this.expendNum = 3
        }
      })
    },
    // 获取当前url
    getUrl() {
      this.qrcodeUrl = location.href
    },
    // 跳转到个人主页
    goPersonal(id) {
      if (id) {
        this.$router.push(`/yxyxsJg?id=${id}`)
      }
    },
    // 私信
    privateLetter(detail) {
      let isLogin = this.$store.state.token
      if (!isLogin) {
        this.$messageBox
          .alert("未登录，请重新登录", {
            type: "warning",
          })
          .then(() => {
            this.$store.commit("REMOVE_INFO")
            this.$router.push("/login")
            this.$messageBox.close()
          })
          .catch(() => {
            this.$messageBox.close()
          })

        return
      }
      this.$router.push({ path: "/chartroom", query: { id: detail.creator_info.id, username: detail.creator_info.username } })
    },
    /*
     * @Description: 点击已参与报名头像
     * @MethodAuthor:  liulian
     * @Date: 2022-07-27 17:22:47
     * @param: ''
     */
    clickAvatar(item) {
      if (item.join_user_id) {
        this.$router.push(`/yxyxsJg?id=${item.join_user_id}`)
      }
    },
    /*
     * @Description: 展开更多
     * @MethodAuthor:  liulian
     * @Date: 2022-07-15 17:16:01
     * @param: '' key = 1展开，2收起
     */
    expendChildHandle(item, key) {
      key === 1 && this.$set(item, "expendNum", item.children.length)
      key === 2 && this.$set(item, "expendNum", 3)
    },
    /*
     * @Description: 子消息评论
     * @MethodAuthor:  liulian
     * @Date: 2022-07-15 15:45:27
     * @param: ''
     */
    childPlHandle(item) {
      item.inputValue = item.inputValue.replace(/^\s+|\s+$/g, "")
      if (!item.inputValue) {
        this.$message.info("回复内容不能为空")
        return
      }

      let obj = {
        content: item.inputValue,
        to_uid: item.creatorInfo.id, // 评论人id
        from_uid: this.$store.state.userInfo.id, // 当前登录人id
        comment_id: item.id, // 评论id 当前点击的item的id
      }
      replyApi(obj).then((res) => {
        if (res.code === 10000) {
          this.$message.success("回复成功！")
          this.getCommentList()
        }
      })
    },
    /*
     * @Description: 最后一级评论
     * @MethodAuthor:  liulian
     * @Date: 2022-07-15 16:52:48
     * @param: ''
     */
    childPlHandle2(item, parentObj) {
      if (!item.inputValue) {
        this.$message.info("回复内容不能为空")
        return
      }
      item.inputValue = item.inputValue.replace(/^\s+|\s+$/g, "")
      let obj = {
        content: item.inputValue,
        to_uid: item.fromUserInfo.id, // 评论人id
        from_uid: this.$store.state.userInfo.id, // 当前登录人id
        comment_id: parentObj.id, // 评论id
      }
      replyApi(obj).then((res) => {
        if (res.code === 10000) {
          this.$message.success("回复成功！")
          this.getCommentList()
        }
      })
    },
    /*
     * @Description: 展开评论输入框
     * @MethodAuthor:  liulian
     * @Date: 2022-07-15 15:37:48
     * @param: ''
     */
    showInputHandle(item, arr) {
      this.$set(item, "showInput", !item.showInput)
    },
    /*
     * @Description: 点击复制链接
     * @MethodAuthor:  liulian
     * @Date: 2022-07-13 17:13:52
     * @param: ''
     */
    onCopy() {
      this.$message.info("已复制到剪切板")
    },

    onError() {},
    /*
     * @Description:
     * @MethodAuthor:  liulian
     * @Date: 2022-07-13 10:19:04
     * @param: ''
     */
    mouseenterHandle() {
      this.showQr = true
    },
    mouseleaveHandle() {
      this.showQr = false
    },
    downHandle(url) {
      if (url) {
        window.open(url)
      } else {
        this.$message.error("原文件地址不存在")
      }
    },
    /*
     * @Description: 弹框确认
     * @MethodAuthor:  liulian
     * @Date: 2022-06-14 15:03:45
     * @param: ''
     */
    isOkHandle() {
      if (!this.isCheck) {
        this.$message.info("请先勾选承诺信息保密")
        return
      }
      let obj = {
        demand_id: this.$route.query.id,
        description: this.textareaValue,
      }
      if (!obj.description) {
        this.$message.info("描述信息不能为空")
        return
      }
      signUp(obj).then((res) => {
        if (res.code === 10000) {
          this.$message.success("参与报名成功")
          this.getList()
          this.showDialog = false
        }
      })
    },
    /*
     * @Description: 取消弹框
     * @MethodAuthor:  liulian
     * @Date: 2022-06-14 15:03:14
     * @param: ''
     */
    cancelHandle() {
      this.textareaValue = ""
      this.showDialog = false
    },
    /*
     * @Description: 进入参与
     * @MethodAuthor:  liulian
     * @Date: 2022-06-14 14:21:29
     * @param: ''
     */
    partyInHandle() {
      this.showDialog = true
    },
    /*
     * @Description: 展开更多
     * @MethodAuthor:  liulian
     * @Date: 2022-06-14 11:52:31
     * @param: ''
     */
    expendHandle(key) {
      key === 1 && (this.expendNum = this.msgs.length)
      key === 2 && (this.expendNum = 3)
    },
    /*
     * @Description: 评论内容
     * @MethodAuthor:  liulian
     * @Date: 2022-06-13 11:56:44
     * @param: ''
     */
    plHandle() {
      this.inputValue = this.inputValue.replace(/^\s+|\s+$/g, "")
      if (!this.inputValue) {
        this.$message.info("评论内容不能为空")
        return
      }
      let obj = {
        module: "xqdt",
        module_id: this.detailInfo.demand_info.id,
        content: this.inputValue,
      }
      commentApi(obj).then((res) => {
        if (res.code === 10000) {
          this.$message.success("评论成功！")
          this.inputValue = ""
          this.getCommentList()
        }
      })
    },
    async getList() {
      let data = await this.getDetail()
      if (data.demand_info.id) {
        let msgs = await this.getCommentList()
      }
    },
    getDetail() {
      return new Promise((resolve, ject) => {
        let id = this.$route.query.id
        demandDetailApi(id).then((res) => {
          if (res.code === 10000) {
            this.showBtn = !(this.$store.state.userInfo && this.$store.state.userInfo.id === res.data.creator_info.id)
            this.detailInfo = res.data
            this.detailInfo.newTag = {
              area: [], // 擅长领域
              social: [], // 社交网络
              industry: [], // 擅长行业
            }
            let Obj = Object.keys(this.detailInfo.newTag)
            let arr = res.data.demand_info.tag_list
            Obj.map((key) => {
              arr.length > 0 &&
                arr.map((item) => {
                  if (key === item.top_parent_bind) {
                    this.detailInfo.newTag[key].push(item)
                  }
                })
              this.contents.map((it) => {
                if (it.key === key) {
                  it.children = this.detailInfo.newTag[key]
                }
                if (it.key === "xqms") {
                  it.content = this.detailInfo.demand_info.demand_introduction
                }
                if (it.key === "zmyq") {
                  it.content = this.detailInfo.demand_info.recruit_introduction
                }
                if (it.key === "doc") {
                  it.doc_name = this.detailInfo.demand_info.doc_name
                  it.doc_url = this.detailInfo.demand_info.doc_url
                }
                if (it.key === "join") {
                  it.joins = res.data.join_users
                }
              })
            })
            resolve(res.data)
          }
        })
      })
    },
    getCommentList() {
      return new Promise((resolve, reject) => {
        this.commentQuery.module = "xqdt"
        this.commentQuery.module_id = this.detailInfo.demand_info.id
        commentPageApi(this.commentQuery).then((res) => {
          if (res.code === 10000) {
            this.msgs = res.data
            this.msgCount = res.count
            resolve(res)
            this.initStyle()
          }
        })
      })
    },
    /*
     * @Description: 列表参数化
     * @MethodAuthor:  liulian
     * @Date: 2022-07-15 15:38:36
     * @param: ''
     */
    initStyle() {
      this.msgs.map((i) => {
        this.$set(i, "children", i.replyList)
        this.$set(i, "avatar", i.creatorInfo.avatar)
        this.$set(i, "username", i.creatorInfo.username)
        this.$set(i, "level_context", i.creatorInfo.level_context)
        this.$set(i, "marketer_role_context", i.creatorInfo.marketer_role_context)
        this.$set(i, "showInput", false)
        this.$set(i, "input", null)
        this.$set(i, "maxlength", 2000)
        this.$set(i, "expendNum", 3) // 最多展示几条

        if (i.children.length > 0) {
          i.children.map((j) => {
            this.$set(j, "avatar", j.fromUserInfo.avatar)
            this.$set(j, "username", j.fromUserInfo.username)
            this.$set(j, "level_context", j.fromUserInfo.level_context)
            this.$set(j, "marketer_role_context", j.fromUserInfo.marketer_role_context)
            this.$set(j, "toUserName", j.toUserInfo.username)
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.xqdt_detail {
  margin: auto;
  .info {
    margin-top: 2.5rem;
    display: flex;
    .info_left {
      width: 57.5rem;
      .info_left_title {
        color: #111029;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        word-break: break-all;
        overflow: hidden;
        font-size: 1.25rem;
        font-weight: 500;
        min-height: 6.4375rem;
      }
      .info_left_btns {
        margin-top: 2.6875rem;
        .btns_left {
          .item {
            margin-right: 2rem;
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 1.375rem;
              height: 1.375rem;
              margin-right: 0.5rem;
            }
            span {
              color: #57596c;
            }
          }
          .isBtn {
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #eff0f6;
            width: 5.375rem;
            height: 2.125rem;
            border-radius: 1.25rem;
            img {
              width: 1.375rem;
              height: 1.375rem;
            }
            span {
              color: #2f2c42;
              border-radius: 1.25rem;
              opacity: 1;
            }
          }
        }
        .bens_right {
          span {
            width: 7rem;
            height: 3.625rem;
            background: #ffffff;
            border-radius: 3.25rem;
            opacity: 1;
            border: 1px solid #111029;
            color: #111029;
            display: block;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .info_right {
      border-left: 1px solid #eff0f6;
      margin-left: 2rem;
      display: flex;
      .info_right_left {
        margin-left: 2rem;
        .avatar {
          img {
            width: 3rem;
            height: 3rem;
            margin-right: 1rem;
            border-radius: 100%;
          }
          span {
            color: #111029;
          }
        }
        .tip {
          margin-top: 0.5rem;
          margin-bottom: 2rem;
          white-space: nowrap;
          img {
            width: 0.8588rem;
            height: 0.8469rem;
            margin-right: 0.5625rem;
          }
        }
        .item {
          margin-bottom: 1rem;
          img {
            width: 1.375rem;
            height: 1.375rem;
            margin-right: 0.5rem;
          }
          span {
            color: #2f2c42;
          }
        }
      }
      .info_right_rihgt {
        width: 4.25rem;
        height: 2.375rem;
        background: #111029;
        border-radius: 2.9375rem;
        opacity: 1;
        color: #ffffff;
        margin-left: 3.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .content {
    width: 86.25rem;
    background: #f7f7fc;
    border-radius: 1.25rem;
    opacity: 1;
    margin-top: 2.6875rem;
    padding-top: 2.125rem;
    padding-bottom: 2rem;
    .con {
      display: flex;
      margin-bottom: 1.1875rem;
      margin-left: 2rem;
      .type {
        margin-right: 1rem;
        color: #2f2c42;
        white-space: nowrap;
      }
      .right_con {
        width: 77.25rem;
        p {
          color: #57596c;
          line-height: 1.375rem;
        }
        .tag {
          border-radius: 25rem;
          opacity: 1;
          border: 1px solid #dcddeb;
          padding: 0.25rem 0.5rem;
          margin-right: 0.5rem;
        }

        .word {
          width: 15.5rem;
          height: 3.75rem;
          background: #ffffff;
          border-radius: 0.625rem;
          opacity: 1;
          border: 1px solid #f4f4f4;
          justify-content: center;
          padding-right: 0.625rem;
          color: #57596c;
          img {
            width: 2.25rem;
            height: 2.25rem;
            margin-right: 0.5rem;
          }
          .url {
            margin-left: 1rem;
          }
        }
        .img_box {
          flex-wrap: wrap;
          .img {
            width: 3rem;
            height: 3rem;
            border-radius: 100%;
            margin-right: 1rem;
            margin-bottom: 0.9375rem;
          }
        }
      }

      .is-tag {
        display: flex;
        flex-wrap: wrap;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0.5rem;
        }
      }
    }
    .words_box {
      height: 3.75rem;
    }
    .w {
      margin-top: 2.6875rem;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;
      position: relative;
      img {
        width: 1.25rem;
        height: 1.25rem;
      }
      .xx {
        margin-right: 0.375rem;
        margin-left: 4.5rem;
      }
      span {
        margin-right: 2rem;
        color: #a0a3bd;
      }
    }
  }
  .pl {
    margin-top: 3.375rem;
    ::v-deep .el-textarea__inner {
      height: 6.25rem;
      box-shadow: 0rem 0.25rem 0.625rem 0.0625rem rgba(58, 114, 255, 0.06);
      border-radius: 0.5rem;
      opacity: 1;
    }
  }
  .pl_btn {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    span {
      width: 4.75rem;
      height: 3.125rem;
      // background: #f7f7fc;
      background: #eff0f6;
      border-radius: 3.25rem;
      opacity: 1;
      color: #111029;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .isBlack {
      background: #111029;
      color: #ffffff;
    }
  }
  .msg {
    margin-top: 0.875rem;
    .msg_item {
      margin-top: 2rem;
      position: relative;
      display: flex;
      .item_left {
        position: relative;
        width: 3rem;
        height: 3rem;
        border-radius: 100%;
        background-color: #eee;
        img {
          width: 3rem;
          height: 3rem;
          border-radius: 100%;
        }
      }
      .item_center {
        margin-left: 1rem;
        flex: 1;
        .title {
          .name {
            color: #111029;
          }
          .redtag {
            margin-left: 0.5rem;
            margin-right: 0.375rem;
          }
        }
        .ms {
          color: #2f2c42;
          margin-top: 1rem;
          font-size: 1rem;
          font-weight: 400;
          .names {
            color: #2f2c42;
            margin-left: 0.625rem;
          }
        }

        .times_bar {
          .delete_icon {
            color: #a0a3bd;
            margin-right: 20px;
          }
          .times {
            color: #a0a3bd;
            margin-top: 1rem;
            display: block;
            margin-bottom: 2rem;
          }
          .bar_tight {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;
            img {
              width: 0.9875rem;
              height: 0.8962rem;
              margin-right: 0.5rem;
            }
            span {
              color: #a0a3bd;
            }
          }
        }
        .input_box {
          margin-bottom: 2rem;
          ::v-deep .el-input__inner {
            height: 3rem;
          }
        }
        // 子消息列表
        .msg_children {
          border-radius: 1rem;
          .msg_item {
            margin-top: 0;
            .item_left {
              margin-top: 1rem;
              margin-left: 1rem;
            }
            .item_center {
              margin-top: 1rem;
              .bar_tight {
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }
    .border {
      border-bottom: 1px solid #eff0f6;
    }
    .bgc {
      background: #f7f7fc;
    }
  }
  .more {
    .item {
      display: inline-block;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
    }
    img {
      width: 1.25rem;
      height: 1.25rem;
      margin-left: 0.5rem;
    }
    span {
      color: #a0a3bd;
    }
    .shou {
      transform: rotateX(180deg);
    }
  }
  .dialog {
    .el-checkbox__input.is-checked + .el-checkbox__label {
      font-size: 0.875rem;
      font-weight: 400;
      color: #2f2c42;
    }
    .input_box {
      position: relative;
      .tip {
        position: absolute;
        top: 3px;
        left: 16px;
        font-size: 1rem;
        font-weight: 400;
        color: #57596c;
      }
    }
    p {
      margin-bottom: 1rem;
    }
    ::v-deep .el-textarea {
      background: #eff0f6;
      border-radius: 0.25rem;
      margin-bottom: 2rem;
    }
    ::v-deep .el-textarea__inner {
      border: none;
      height: 6.25rem;
      border-radius: 0.25rem;
      background: #eff0f6;
      // margin-top: .625rem;
    }

    ::v-deep .el-input__count {
      background: #eff0f6;
      font-size: 0.875rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 100;
      color: #57596c;
    }
  }
  .bottom-btns {
    display: flex;
    justify-content: center;
    ::v-deep .el-button {
      width: 4.25rem;
      height: 2.375rem;
      background: #ffffff;
      border-radius: 2.9375rem;
      opacity: 1;
      border: 1px solid #111029;
      font-size: 0.875rem;
      font-weight: 500;
      color: #111029;
    }
    ::v-deep .el-button:nth-child(2) {
      width: 4.25rem;
      height: 2.375rem;
      background: #111029;
      border-radius: 2.9375rem;
      opacity: 1;
      border: 1px solid #111029;
      font-size: 0.875rem;
      font-weight: 500;
      color: #ffffff;
      margin-left: 11.5rem;
    }
  }
  .qr {
    width: 12.75rem;
    height: 15.125rem;
    background: #ffffff;
    box-shadow: 0rem 0.125rem 0.375rem 0.0625rem rgba(20, 20, 43, 0.06);
    border-radius: 0.625rem;
    opacity: 1;
    border: 0.0625rem solid #eff0f6;
    position: absolute;
    top: 1.625rem;
    right: 3.4375rem;
    z-index: 33;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span {
      text-align: center;
    }
    .qrcode_box {
      width: 8.75rem;
      height: 8.75rem;
      margin-top: 0.25rem;
      margin-bottom: 1rem;
    }
    .qr_img {
      width: 8.75rem !important;
      height: 8.75rem !important;
      margin-top: 0.375rem;
      margin-bottom: 1rem;
    }
  }
}
</style>
