<template>
  <div class="register">
    <div class="login_box" :class="tabValue === 1 ? '' : form2.official_type === '1' ? 'login_box3' : 'login_box2'">
      <div class="con_box">
        <!-- tab切换个人注册，官方注册 -->
        <div class="menu flex flex-row-center font-size-20 font-weight-400">
          <span class="cursor-pointer" :class="tabValue === 1 ? 'isAceive' : ''" @click="changeTabHandle(1)">个人注册</span>
          <span class="l_line"></span>
          <span class="cursor-pointer" :class="tabValue === 2 ? 'isAceive' : ''" @click="changeTabHandle(2)">官方注册</span>
        </div>
        <!-- 个人注册表单 -->
        <el-form v-if="tabValue === 1" class="form_box" :model="form1" status-icon :rules="rules1" ref="form1">
          <el-form-item>
            <el-input placeholder="用户名称" v-model="form1.username" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="机构标识（数字英文混合六位数唯一码）" v-model="form1.official_unique_code" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="手机号/邮箱" v-model="phoneOrMail1" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="输入6-10位密码" type="password" v-model="form1.password" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="确认密码" type="password" v-model="form1.repassword" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <div class="code_box flex">
              <el-input placeholder="验证码" v-model="form1.code" clearable></el-input>
              <span class="code_btn text-align-center font-size-16 font-weight-400" :class="codeText1 !== '获取验证码' ? 'gray-color' : ''" @click="codeText1 === '获取验证码' ? sendcode1() : null">{{ codeText1 }}</span>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="link font-size-16 font-weight-400">已有账号，<span class="font-color-blue cursor-pointer" @click="loginHandle">马上登录</span></div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="submitForm1('form1')">注册</el-button>
          </el-form-item>
        </el-form>

        <!-- 官方注册 -->
        <el-form v-if="tabValue === 2" class="form_box form_box2" :model="form2" status-icon :rules="rules2" ref="form2">
          <el-form-item>
            <el-select v-model="form2.official_type" placeholder="官方类型" clearable @change="typeChange">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-if="form2.official_type === '2'" placeholder="品牌名称" v-model="form2.official_name" clearable></el-input>
            <el-input v-else placeholder="机构名称" v-model="form2.official_name" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="手机号/邮箱" v-model="phoneOrMail2" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="输入6-10位密码" v-model="form2.password" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="确认密码" v-model="form2.repassword" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <div class="code_box flex">
              <el-input placeholder="验证码" v-model="form2.code" clearable></el-input>
              <span class="code_btn text-align-center font-size-16 font-weight-400" :class="codeText2 !== '获取验证码' ? 'gray-color' : ''" @click="codeText2 === '获取验证码' ? sendcode2() : null">{{ codeText2 }}</span>
            </div>
          </el-form-item>

          <!-- 企业营业执照 -->
          <!-- 上传图片 -->
          <el-form-item class="upload_item">
            <el-upload ref="upload" v-if="!isUpload" :limit="1" :with-credentials="true" accept=".jpg, .png,'jpeg,'JPG,'PNG" :action="upload.url" list-type="picture-card" :on-change="onSuccessHandle" :before-upload="beforeHandle">
              <div slot="default" class="top_con">
                <img src="@/assets/images/login/login_upload@2x.png" alt="上传icon" />
                <span v-if="form2.official_type === '1'" class="font-weight-500 font-size-14">企业营业执照</span>
                <span v-else class="font-weight-500 font-size-14">企业营业执照</span>
              </div>
            </el-upload>

            <div class="el-upload-list__item is-success" v-if="isUpload" :style="{ backgroundImage: `url(${form2.business_license})` }">
              <i class="icon el-icon-close" @click="delImage(1)"></i>
              <div class="mask"></div>
            </div>
          </el-form-item>

          <!-- 社交平台MCN机构 -->
          <el-form-item class="upload_item">
            <el-upload :key="isUpload2" ref="upload2" v-if="!isUpload2" :limit="1" :with-credentials="true" accept=".jpg, .png,'jpeg,'JPG,'PNG" :action="upload.url" list-type="picture-card" :on-change="onSuccessHandle2" :before-upload="beforeHandle">
              <div slot="default" class="top_con">
                <img src="@/assets/images/login/login_upload@2x.png" alt="上传icon" />
                <span v-if="form2.official_type === '1'" class="font-weight-500 font-size-14">社交平台MCN机构</span>
                <span v-else class="font-weight-500 font-size-14">品牌所有权相关资质</span>
              </div>
            </el-upload>

            <div class="el-upload-list__item" v-if="isUpload2" :style="{ backgroundImage: `url(${form2.mcn})` }">
              <i class="icon el-icon-close" @click="delImage(2)"></i>
              <div class="mask"></div>
            </div>
          </el-form-item>

          <!-- 上传图片 -->
          <el-form-item class="upload_item" v-if="form2.official_type === '1'">
            <el-upload :key="isUpload3" ref="upload3" v-if="!isUpload3" :limit="1" :with-credentials="true" accept=".jpg, .png,'jpeg,'JPG,'PNG" :action="upload.url" list-type="picture-card" :on-change="onSuccessHandle3" :before-upload="beforeHandle">
              <div slot="default" class="top_con">
                <img src="@/assets/images/login/login_upload@2x.png" alt="上传icon" />
                <span v-if="form2.official_type === '1'" class="font-weight-500 font-size-14">相关资质</span>
                <span v-else class="font-weight-500 font-size-14">上传资格认证</span>
              </div>
            </el-upload>

            <div class="el-upload-list__item" v-if="isUpload3" :style="{ backgroundImage: `url(${form2.qualification})` }">
              <i class="icon el-icon-close" @click="delImage(3)"></i>
              <div class="mask"></div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="link font-size-16 font-weight-400">已有账号，<span class="font-color-blue cursor-pointer" @click="loginHandle">马上登录</span></div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="submitForm2('form2')">注册</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 信息 -->
    <p class="bottom font-size-12 font-weight-400F text-align-center">©1999-2022北京创新乐知网络技术有限公司</p>
  </div>
</template>

<script>
import { isPhone, isEmail, isUsername, isPassword, isUniCode, isUniCodeName } from "@/utils/validate.js"
import { smsCodeApi, mailCodeApi, uploadFiles } from "@/api/javaApi.js"
import { registerPersonApi, registerOfficialApi, loginPwdApi, getUserApi, getUig, updataIm } from "@/api/api.js"
// import { showLoading, hideLoading } from '@/utils/loading.js';

export default {
  name: "login",
  components: {},

  data() {
    return {
      tabValue: 1,
      form1: {
        username: "",
        official_unique_code: "",
        password: "",
        repassword: "",
        code: "",
        mail: null,
        mobile: null,
      },
      phoneOrMail1: "", // 手机或者邮箱绑定值
      phoneOrMail2: "",
      // 个人注册表单
      form2: {
        official_name: "", // 品牌或是机构
        password: "",
        official_type: "1", // 官方类型 1是机构，2是品牌
        repassword: "",
        code: "",
        mail: null,
        mobile: null,
        business_license: "", // 图片地址
        mcn: "", // MCN图片
        qualification: "", // 相关资质
      }, // 官方注册
      rules1: {}, // 个人注册表单
      rules2: {}, // 官方注册
      options: [
        {
          label: "机构",
          value: "1",
        },
        {
          label: "品牌",
          value: "2",
        },
      ],
      isUpload: false, // 是否已经上传了文件
      isUpload2: false, // 是否已经上传了文件
      isUpload3: false, // 是否已经上传了文件
      img: "", // 是否已经上传了文件
      codeText1: "获取验证码", // 获取验证码
      codeText2: "获取验证码", // 获取验证码
      upload: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: "/ja/upload/oss/img",
        fileSize: 5, // 文件上传大小
      },
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /*
     * @Description: 官方类型切换
     * @MethodAuthor:  liulian
     * @Date: 2022-08-22 16:40:02
     * @param: ''
     */
    typeChange() {
      this.form2.business_license = ""
      this.form2.mcn = ""
      this.form2.qualification = ""
      this.isUpload = false
      this.isUpload2 = false
      this.isUpload3 = false
    },
    // handleExceed() {
    //   this.$message.error("只允许上传图片")
    // },
    beforeHandle(file) {
      const typeArry = [".jpg", ".png", ".bmp", ".JPG", ".PNG", ".BMP", ".gif", ".GIF"]
      const type = file.name.substring(file.name.lastIndexOf("."))
      const isImage = typeArry.indexOf(type) > -1
      if (!isImage) {
        this.$message.error("上传文件必须为图片")
        return
      }
      // return isImage;

      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize
        if (!isLt) {
          this.$modal.msgError(`上传文件大小不能超过 ${this.fileSize} MB!`)
          return false
        }
      }
    },
    /*
     * @Description: 个人注册
     * @MethodAuthor:  liulian
     * @Date: 2022-06-27 11:45:22
     * @param: ''
     */
    submitForm1() {
      let _this = this
      let c = this.checkData()
      if (!c) {
        registerPersonApi(this.form1).then((res) => {
          if (res.code === 10000) {
            this.$message.success("注册成功")
            if (this.form1.mail) {
              let obj = {
                mail: this.form1.mail,
                password: this.form1.password,
              }
              _this.isLogin(obj)
            } else if (this.form1.mobile) {
              let obj = {
                mobile: this.form1.mobile,
                password: this.form1.password,
              }
              _this.isLogin(obj)
            }
          } 
          // else {
          //   this.$message.error(res.message)
          // }
        })
      }
    },
    /*
     * @Description: 登录
     * @MethodAuthor:  liulian
     * @Date: 2022-08-08 16:40:05
     * @param: ''
     */
    isLogin(obj) {
      loginPwdApi(obj).then((res) => {
        if (res.code === 10000) {
          this.$store.commit("SET_TOKEN", res.data.token)
          setTimeout(() => {
            this.getUserInfo()
            this.getuisg()
          }, 1000)
        }
      })
    },
    /*
     * @Description: 获取用户信息
     * @MethodAuthor:  liulian
     * @Date: 2022-08-08 16:39:52
     * @param: ''
     */
    getUserInfo() {
      getUserApi().then((res) => {
        if (res.code === 10000) {
          this.$store.commit("SET_USERINFO", res.data)
          this.$router.push("/")
        }
      })
    },
    // 获取用户isg
    getuisg() {
      getUig().then((res) => {
        if (res.code == 10000) {
          let promise = this.$tim.login({ userID: res.data.user_id.toString(), userSig: res.data.user_sig })
          promise
            .then(function (imResponse) {
              if (imResponse.data.repeatLogin === true) {
                // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
                // 同步个人信息
                this.updataIm()
              }
            })
            .catch(function (imError) {})
        }
      })
    },
    // 同步个人信息
    updataIm() {
      updataIm().then((res) => {
        if (res.code == 10000) {
        }
      })
    },
    /*
     * @Description: 官方注册
     * @MethodAuthor:  liulian
     * @Date: 2022-06-27 12:25:17
     * @param: ''
     */
    submitForm2() {
      let c = this.checkData2()
      if (!c) {
        registerOfficialApi(this.form2).then((res) => {
          if (res.code === 10000) {
            this.$message.success("官方注册申请已提交，请耐心等待")
            this.$router.push("/home")
          } 
          // else {
          //   this.$message.error(res.message)
          // }
        })      
      }
    },
    checkData() {
      if (!this.form1.username) {
        this.$message.error("用户名称不能为空")
        return true
      }
      if (this.form1.username) {
        let check = isUsername(this.form1.username)
        if (!check) {
          this.$message.error("用户名小于等于10个字符")
          return true
        } else {
        }
      }
      if (this.form1.official_unique_code) {
        let c = isUniCode(this.form1.official_unique_code)
        if (!c) {
          this.$message.error("机构标识格式不正确")
          return true
        }
      }
      if (!this.phoneOrMail1) {
        this.$message.error("手机或邮箱不能为空")
        return true
      }
      if (this.phoneOrMail1) {
        this.form1.mobile = null
        this.form1.mail = null
        let phone = isPhone(this.phoneOrMail1)
        let mail = isEmail(this.phoneOrMail1)
        if (phone) {
          this.form1.mobile = this.phoneOrMail1
        } else if (mail) {
          this.form1.mail = this.phoneOrMail1
        } else {
          this.$message.error("手机或邮箱格式不正确")
          return true
        }
      }
      if (!this.form1.password) {
        this.$message.error("密码不能为空")
        return true
      }
      if (this.form1.password) {
        let check = isPassword(this.form1.password)
        if (!check) {
          this.$message.error("密码大于6个字符，包含字母加数字")
          return true
        }
      }
      if (!this.form1.repassword) {
        this.$message.error("请确认密码")
        return true
      }
      if (this.form1.repassword) {
        if (this.form1.repassword !== this.form1.password) {
          this.$message.error("两次输入密码不一致")
          return true
        }
      }
      if (!this.form1.code) {
        this.$message.error("验证码不能为空")
        return true
      }
    },
    checkData2() {
      if (!this.form2.official_type) {
        this.$message.error("请选择官方类型")
        return true
      }
      if (!this.form2.official_name) {
        this.$message.error("机构名称不能为空")
        return true
      }
      if (this.form2.official_name) {
        let check = isUniCodeName(this.form2.official_name)
        if (!check) {
          let name = this.form2.official_type === "1" ? "机构" : "品牌"
          this.$message.error(`${name}名称小于等于10个字符`)
          return true
        }
      }
      if (!this.phoneOrMail2) {
        this.$message.error("手机或邮箱不能为空")
        return true
      }
      if (this.phoneOrMail2) {
        this.form2.mobile = null
        this.form2.mail = null
        let phone = isPhone(this.phoneOrMail2)
        let mail = isEmail(this.phoneOrMail2)
        if (phone) {
          this.form2.mobile = this.phoneOrMail2
        } else if (mail) {
          this.form2.mail = this.phoneOrMail2
        } else {
          this.$message.error("手机或邮箱格式不正确")
          return true
        }
      }
      if (!this.form2.password) {
        this.$message.error("密码不能为空")
        return true
      }
      if (this.form2.password) {
        let check = isPassword(this.form2.password)
        if (!check) {
          this.$message.error("密码大于6个字符，包含字母加数字")
          return true
        }
      }
      if (!this.form2.repassword) {
        this.$message.error("请确认密码")
        return true
      }
      if (this.form2.repassword) {
        if (this.form2.repassword !== this.form2.password) {
          this.$message.error("两次输入密码不一致")
          return true
        }
      }
      if (!this.form2.code) {
        this.$message.error("验证码不能为空")
        return true
      }
      if (!this.form2.business_license) {
        let name = this.form2.official_type === "1" ? "资格认证" : "营业执照"
        this.$message.error(`请上传${name}`)
        return true
      }
      if (this.form2.official_type === "1") {
        if (!this.form2.mcn) {
          this.$message.error(`请上传MCN机构证明`)
          return true
        }
      }
      if (!this.form2.qualification) {
        this.$message.error(`请上传相关资质`)
        return true
      }
    },

    sendcode2() {
      if (!this.phoneOrMail2) {
        this.$message.error("手机号或邮箱不能为空")
        return
      } else {
        // 校验是手机号，还是验证码
        let c = this.checkPhoneOrEmail(this.phoneOrMail2, 2)
        if (c) {
          this.codeText2 = "验证码已发送"
          let num = 60
          let timer = setInterval((res) => {
            num = num - 1
            this.codeText2 = `${num}s后重新获取`
            if (num === 0) {
              clearInterval(timer)
              this.codeText2 = "获取验证码"
            }
          }, 1000)
        }
        if (this.form2.mobile) {
          smsCodeApi({ mobile: this.form2.mobile }).then((res) => {
            if (!res.code === 10000) {
              // this.$message.error(res.message)
            }
          })
        } else if (this.form2.mail) {
          mailCodeApi({ mail: this.form2.mail }).then((res) => {
            if (!res.code === 10000) {
              // this.$message.error(res.message)
            }
          })
        }
      }
    },
    // 发送验证嘛
    sendcode1() {
      if (!this.phoneOrMail1) {
        this.$message.error("手机号或邮箱不能为空")
        return
      } else {
        // 校验是手机号，还是验证码
        let c = this.checkPhoneOrEmail(this.phoneOrMail1, 1)
        if (c) {
          this.codeText1 = "验证码已发送"
          let num = 60
          let timer = setInterval((res) => {
            num = num - 1
            this.codeText1 = `${num}s后重新获取`
            if (num === 0) {
              clearInterval(timer)
              this.codeText1 = "获取验证码"
            }
          }, 1000)
        }
        if (this.form1.mobile) {
          smsCodeApi({ mobile: this.form1.mobile }).then((res) => {
            if (!res.code === 10000) {
              // this.$message.error(res.message)
            }
          })
        } else if (this.form1.mail) {
          mailCodeApi({ mail: this.form1.mail }).then((res) => {
            if (!res.code === 10000) {
              // this.$message.error(res.message)
            }
          })
        }
      }
    },
    loginHandle() {
      this.$router.push("/login")
    },
    /*
     * @Description: 判断是手机号还是邮箱
     * @MethodAuthor:  liulian
     * @Date: 2022-06-27 15:10:14
     * @param: ''
     */
    checkPhoneOrEmail(str, key) {
      this.form1.mobile = null
      this.form1.mail = null

      let phone = isPhone(str)
      let mail = isEmail(str)
      if (key === 1) {
        if (phone) {
          this.form1.mobile = str
        }
        if (mail) {
          this.form1.mail = str
        }
      } else {
        if (phone) {
          this.form2.mobile = str
        }
        if (mail) {
          this.form2.mail = str
        }
      }

      if (phone || mail) {
        return true
      } else {
        this.$message.error("手机或邮箱格式不正确")
      }
    },
    /*
     * @Description: 删除图片
     * @MethodAuthor:  liulian
     * @Date: 2022-06-10 14:32:38
     * @param: ''
     */
    delImage(key) {
      // this.img = ""
      // this.isUpload = false
      // this.$refs.upload.clearFiles()
      switch (key) {
        case 1:
          this.business_license = ""
          this.isUpload = false
          this.$refs.upload.clearFiles()
          break
        case 2:
          this.mcn = ""
          this.isUpload = false
          this.$refs.upload.clearFiles()
          break
        case 3:
          this.qualification = ""
          this.isUpload = false
          this.$refs.upload.clearFiles()
          break
      }
    },
    /*
     * @Description: 上传成功
     * @MethodAuthor:  liulian
     * @Date: 2022-06-10 12:25:16
     * @param: ''
     */
    onSuccessHandle(response, file) {
      const loading = this.$loading({
        // text:'加载中...', //加载图标下的文字
        // spinner: 'el-icon-loading', //加载图标
        background: "rgba(36, 15, 69, 0.5)", //背景色
        // customClass:'loading' //自定义样式的类名
      })
      // 上传成功
      if (response.response) {
        if (response.response.code === 10000) {
          this.form2.business_license = response.response.data
          this.isUpload = true
          loading.close()
        } else {
          this.isUpload = false
          this.$refs.upload.clearFiles()
          loading.close()
        }
      }
    },
    // MCN机构证明
    onSuccessHandle2(response, file) {
      const loading = this.$loading({
        background: "rgba(36, 15, 69, 0.5)", //背景色
      })
      // 上传成功
      if (response.response) {
        if (response.response.code === 10000) {
          this.form2.mcn = response.response.data
          this.isUpload2 = true
          loading.close()
        } else {
          this.isUpload2 = false
          this.$refs.upload2.clearFiles()
          loading.close()
        }
      }
    },
    // 相关资质
    onSuccessHandle3(response, file) {
      const loading = this.$loading({
        // text:'加载中...', //加载图标下的文字
        // spinner: 'el-icon-loading', //加载图标
        background: "rgba(36, 15, 69, 0.5)", //背景色
        // customClass:'loading' //自定义样式的类名
      })
      // 上传成功
      if (response.response) {
        if (response.response.code === 10000) {
          this.form2.qualification = response.response.data
          this.isUpload3 = true
          loading.close()
        } else {
          this.isUpload3 = false
          this.$refs.upload3.clearFiles()
          loading.close()
        }
      }
    },
    changeTabHandle(key) {
      this.tabValue = key
      if (this.tabValue === 1) {
        this.form2 = {}
        this.phoneOrMail2 = ""
        this.form2.business_license = ""
        this.form2.mcn = ""
        this.form2.qualification = ""
        this.isUpload = false
        this.isUpload2 = false
        this.isUpload3 = false
      } else {
        this.form1 = {}
        this.phoneOrMail1 = ""
      }
    },
  },
}
</script>

<style scoped lang="scss">
.register {
  width: 100%;
  background-image: url("../../assets/images/login/login_bg@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .login_box {
    margin-top: 4.6rem;
    margin-right: 6.25rem;
    width: 31.75rem;
    height: 37.75rem;
    float: right;
    background-color: #fff;
    box-shadow: 0px 0px 30px 1px rgba(237, 235, 245, 0.7);
    border-radius: 0.625rem;
    opacity: 1;
    border: 1px solid #f4f4f4;
    margin-bottom: 1rem;
    ::v-deep .el-select {
      width: 100%;
    }
    .con_box {
      width: 23.75rem;
      margin: auto;
      .menu {
        margin-top: 1.5rem;
        margin-bottom: 2.375rem;
        span {
          color: #57596c;
        }
        .l_line {
          height: 10px;
          width: 0px;
          background: #57596c;
          opacity: 1;
          border: 1px solid #240f45;
          margin-left: 2rem;
          margin-right: 2rem;
        }
        .isAceive {
          font-weight: 500;
          color: #111029;
        }
      }
      .form_box {
        ::v-deep .el-input__inner {
          box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
          border-radius: 10px 10px 10px 10px;
          height: 42px;
        }
        .code_box {
          .code_btn {
            height: 2.625rem;
            background: #ffffff;
            box-shadow: 0rem 0.25rem 0.625rem 0.0625rem rgba(58, 114, 255, 0.06);
            border-radius: 0.625rem;
            opacity: 1;
            border: 1px solid #eff0f6;
            white-space: nowrap;
            padding-left: 1rem;
            padding-right: 1rem;
            cursor: pointer;
          }
          .gray-color {
            color: #a0a3bd;
          }
          ::v-deep .el-input__inner {
            width: 252px;
            height: 42px;
            background: #ffffff;
            box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            border: 1px solid #eff0f6;
          }
          ::v-deep span {
            right: 1.25rem;
          }
        }
        .link {
          height: 1.375rem;
          color: #240f45;
          margin-top: 0.375rem;
        }
        ::v-deep.el-form-item {
          margin-top: -0.375rem;

          height: 2.625rem;
        }
        ::v-deep .el-button--primary {
          width: 100%;
          border-radius: 0.625rem;
          font-size: 0.875rem;
          font-weight: 500;
          height: 2.375rem;
          margin-top: 1.5625rem;
        }
      }
    }
  }
  .login_box2 {
    height: 49.75rem;
  }
  .login_box3 {
    height: 55.7rem;
  }
  .bottom {
    color: #969696;
    clear: both;
  }
  // 未上传文件样式
  ::v-deep .el-upload--picture-card {
    width: 100%;
    height: 5.25rem;
    background-color: transparent;
    box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
    border-radius: 0.625rem;
    opacity: 1;
    border: 1px solid #eff0f6;
    position: relative;
    line-height: 0;
    margin-bottom: 1rem;
  }
  ::v-deep li.el-upload-list__item.is-ready {
    width: 100%;
    height: 5.25rem;
    background-color: transparent;
    box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
    border-radius: 0.625rem;
    opacity: 1;
    border: 1px solid #eff0f6;
    position: relative;
    line-height: 0;
    margin-bottom: 1rem;
  }
  .el-upload-list__item {
    width: 100%;
    height: 5.25rem;
    background-color: transparent;
    box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
    border-radius: 0.625rem;
    opacity: 1;
    border: 1px solid #eff0f6;
    position: relative;
    line-height: 0;
    margin-bottom: 1rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    .icon {
      position: absolute;
      top: 0.3125rem;
      right: 0.3125rem;
      font-size: 20px;
      color: #fff;
      display: block;
      font-weight: bold;
      z-index: 222;
    }
  }
  .el-upload-list__item:hover {
    .mask {
      display: block;
    }
  }
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(17, 16, 41, 0.6);
    display: none;
    border-radius: 0.625rem;
  }
  .top_con {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 2.875rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    img {
      width: 1.25rem;
      height: 1.25rem;
    }
    span {
      margin-top: 0.8125rem;
      color: #a0a3bd;
    }
  }
  .form_box2 {
    .upload_item {
      margin-bottom: 4rem;
    }
    .link {
      height: 1.375rem;
      color: #240f45;
    }
    ::v-deep .el-upload-list--picture-card .el-upload-list__item {
      display: none;
    }
  }
  // ::v-deep .el-upload-list--picture-card:not(:empty) + .el-upload--picture-card {
  //   display: none;
  // }
}
.create-isLoading {
  .el-loading-spinner .el-loading-text {
    font-size: 1.8vh;
    color: rgb(4, 120, 211);
  }
}
</style>
