<template>
    <div class="m-steps-area">
        <div class="m-steps">
            <div :class="['m-steps-item',
                {
                    'finished': current > n,
                    'process': current === n && n !== totalSteps,
                    'last-process': current === totalSteps && n === totalSteps,
                    'middle-wait': current < n && n !== totalSteps,
                    'last-wait': current < n && n === totalSteps,
                }
            ]" v-for="n in totalSteps" :key="n" @click="onChange(n)">
                <div class="m-steps-icon">
                    <span class="u-icon"></span>
                    <!-- <span class="u-icon" >✓</span> -->
                </div>
                <div class="m-steps-content">
                    <div class="u-steps-title">{{ stepsLabel[n - 1] || 'S ' + n }}</div>
                    <!-- <div class="u-steps-description">{{ stepsDesc[n-1] || 'Desc ' + n }}</div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Steps',
    props: {
        stepsLabel: { // 步骤title数组
            type: Array,
            default: () => {
                return []
            }
        },
        stepsDesc: { // 步骤description数组
            type: Array,
            default: () => {
                return []
            }
        },
        totalSteps: { // 总的步骤数
            type: Number,
            default: 0
        },
        currentStep: { // 当前选中的步骤
            type: Number,
            default: 1
        }
    },
    watch:{
        currentStep:{
            handler(data){
                console.log(data)
                if(data){
                    if(data> this.totalSteps) {
                        this.current=1
                    }else {
                        this.current=data
                    }
                }
                
            }
        }
    },
    data() {
        return {
            // 若当前选中步骤超过总步骤数，则默认选择步骤1
            // current: this.currentStep > this.totalSteps ? 1 : this.currentStep
            current:""
        }
    },
    methods: {
        onChange(index) { // 点击切换选择步骤
        return
            console.log('index:', index)
            if (this.current !== index) {
                this.current = index
                this.$emit('change', index)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.m-steps-area {
    width: 1100px;
    margin: 0px auto;

    .m-steps {
        padding: 30px 0;
        display: flex;

        .m-steps-item {
            display: inline-block;
            flex: 1; // 弹性盒模型对象的子元素都有相同的长度，且忽略它们内部的内容
            overflow: hidden;
            font-size: 16px;
            line-height: 32px;

            .m-steps-icon {
                display: inline-block;
                margin-right: 8px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                text-align: center;
            }

            .m-steps-content {
                display: inline-block;
                vertical-align: top;
                padding-right: 16px;

                .u-steps-title {
                    position: relative;
                    display: inline-block;
                    padding-right: 16px;
                }

                .u-steps-description {
                    font-size: 14px;
                    max-width: 140px;
                }
            }
        }

        .finished {
            margin-right: 16px;
            cursor: pointer;


            // &:hover {
            //     .m-steps-content {
            //         .u-steps-title {
            //             color: #1890ff;
            //         }

            //         .u-steps-description {
            //             color: #1890ff;
            //         }
            //     }
            // }

            .m-steps-icon {
                // align-items: center;
                width: 1.25rem;
                height: 1.25rem;
                text-align: center;
                line-height: 1.375rem;
                background: #A0A3BD;
                border-radius: 50%;

                .u-icon {
                    display: inline-block;
                    width: .8125rem;
                    height: .8125rem;
                    border-radius: 50%;
                    background: #57596C;
                }
            }

            .m-steps-content {
                color: rgba(0, 0, 0, .65);

                .u-steps-title {
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #111029;
                    line-height: 1.1875rem;

                    &:after {
                        background: #111029;
                        position: absolute;
                        top: .5625rem;
                        left: 100%;
                        display: block;
                        width: 9999px;
                        height: 1px;
                        content: "";
                    }
                }

                .u-steps-description {
                    color: rgba(0, 0, 0, .45);
                }
            }
        }

        .process {
            margin-right: 16px;

            .m-steps-icon {


                // align-items: center;
                width: 1.25rem;
                height: 1.25rem;
                text-align: center;
                line-height: 1.375rem;
                background: #A0A3BD;
                border-radius: 50%;

                .u-icon {
                    display: inline-block;
                    width: .8125rem;
                    height: .8125rem;
                    border-radius: 50%;
                    background: #57596C;
                }
            }

            .m-steps-content {
                color: rgba(0, 0, 0, .65);

                .u-steps-title {
                    // font-weight: 600;
                    // color: rgba(0, 0, 0, .85);
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #111029;
                    line-height: 1.1875rem;

                    &:after {
                        background: #111029;
                        position: absolute;
                        top: .5625rem;
                        left: 100%;
                        display: block;
                        width: 9999px;
                        height: 1px;
                        content: "";
                    }
                }

                .u-steps-description {
                    color: rgba(0, 0, 0, .65);
                }
            }
        }

        .last-process {
            margin-right: 0;

            .m-steps-icon {


                // align-items: center;
                width: 1.25rem;
                height: 1.25rem;
                text-align: center;
                line-height: 1.375rem;
                background: #A0A3BD;
                border-radius: 50%;

                .u-icon {
                    display: inline-block;
                    width: .8125rem;
                    height: .8125rem;
                    border-radius: 50%;
                    background: #57596C;
                }
            }

            .m-steps-content {
                color: rgba(0, 0, 0, .65);

               .u-steps-title {
                    // font-weight: 600;
                    // color: rgba(0, 0, 0, .85);
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #111029;
                    line-height: 1.1875rem;
                }

                .u-steps-description {
                    color: rgba(0, 0, 0, .65);
                }
            }
        }

        .middle-wait {
            margin-right: 16px;
            cursor: pointer;

            // &:hover {
            //     .m-steps-icon {
            //         border: 1px solid #1890ff;

            //         .u-icon {
            //             color: #1890ff;
            //         }
            //     }

            //     .m-steps-content {
            //         .u-steps-title {
            //             color: #1890ff;
            //         }

            //         .u-steps-description {
            //             color: #1890ff;
            //         }
            //     }
            // }

            .m-steps-icon {
                // background: red;
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 50%;
                line-height: 1.375rem;
                background: #DCDDEB;

                .u-icon {

                    display: inline-block;
                    width: .8125rem;
                    height: .8125rem;
                    background: #A0A3BD;
                    border-radius: 50%;
                }
            }

            .m-steps-content {
                color: rgba(0, 0, 0, .65);

                .u-steps-title {
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #A0A3BD;
                    line-height: 1.1875rem;

                    &:after {
                        background: #DCDDEB;
                        position: absolute;
                        top: .5625rem;
                        left: 100%;
                        display: block;
                        width: 9999px;
                        height: 1px;
                        content: "";
                    }
                }

                .u-steps-description {
                    color: rgba(0, 0, 0, .45);
                }
            }
        }

        .last-wait {
            margin-right: 0;
            cursor: pointer;

            // &:hover {
            //     .m-steps-icon {
            //         border: 1px solid #1890ff;

            //         .u-icon {
            //             color: #1890ff;
            //         }
            //     }

            //     .m-steps-content {
            //         .u-steps-title {
            //             color: #1890ff;
            //         }

            //         .u-steps-description {
            //             color: #1890ff;
            //         }
            //     }
            // }

            .m-steps-icon {
                // background: red;
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 50%;
                background: #DCDDEB;
                line-height: 1.375rem;

                .u-icon {
                    display: inline-block;
                    width: .8125rem;
                    height: .8125rem;
                    background: #A0A3BD;
                    border-radius: 50%;
                }
            }

            .m-steps-content {
                color: rgba(0, 0, 0, .65);

                .u-steps-title {
                    color: rgba(0, 0, 0, .45);
                }

                .u-steps-description {
                    color: rgba(0, 0, 0, .45);
                }
            }
        }
    }
}
</style>