<!--
* @description 优秀营销师机构页面
* @fileName yxyxsJg.vue
* @author liulian
* @date 2022/06/15 10:53:59
-->
<template>
  <div class="yxyxsJg">
    <!-- banner图 -->
    <div class="top_banner"></div>
    <!-- 个人信息 -->
    <div class="info">
      <div class="info_con screenWidth">
        <div class="info_lef text-align-center">
          <div class="avatar">
            <img class="avatar" :src="userInfo.avatar" alt="头像" />
            <!-- 是否是结构 -->
            <img class="flag" src="@/assets/images/yxyxs/blue_icon@2x.png" />
          </div>
          <div class="name font-size-16 font-weight-500">{{ userInfo.username }}</div>
          <div class="tag_box flex">
            <tag-btn v-if="userInfo.level_context" :config="{ text: userInfo.level_context, type: 'red' }"></tag-btn>
            <tag-btn v-if="userInfo.marketer_role_context" class="green" :config="{ text: userInfo.marketer_role_context, type: 'green' }"></tag-btn>
          </div>
          <div class="bar flex">
            <div class="item flex">
              <img src="@/assets/images/yxyxs/str_yellow@2x.png" alt="icon" />
              <span class="font-color-yellow font-size-14 font-weight-400">{{ userInfo.score }}</span>
            </div>
            <div class="line"></div>
            <div class="item font-size-14 font-weight-400">
              粉丝 <span class="font-size-16 font-weight-500">{{ userInfo.fans_num }}</span>
            </div>
            <div class="line"></div>
            <div class="item font-size-14 font-weight-400">
              执行经验
              <span class="font-size-16 font-weight-500">{{ userInfo.trade_num }}</span>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="info_right">
          <template v-for="(item, index) in infos">
            <div v-if="item.type" class="right_row" :class="item.type === '自我介绍' || item.key === 'scx' ? 'wrip_row' : ''">
              <div class="right_title font-size-14 font-weight-500" v-if="item.type === '自我介绍' ||( item.key === 'scx' && (item.list[0].list.length !== 0 || item.list[1].list.length !== 0 || item.list[2].list.length !== 0))">{{ item.type }}</div>
              <!-- <div class="right_title font-size-14 font-weight-500" v-if="item.list && item.list.length > 0">{{ item.type }}</div> -->

              <!-- 擅长项 -->
              <div class="box" v-if="item.key === 'scx' && (item.list[0].list.length !== 0 || item.list[1].list.length !== 0 || item.list[2].list.length !== 0)">
                <div class="box_item flex" v-for="(it, index1) in item.list" :key="index1">
                  <div class="right_title font-size-14 font-weight-400">
                    <template v-if="it.type === '擅长行业'">行业领域</template>
                    <template v-if="it.type === '擅长领域'">传播内容</template>
                    <template v-if="it.type !== '擅长行业' && it.type !== '擅长领域'"> {{ it.type }}</template>
                  </div>

                  <div class="right_tag">
                    <span class="font-size-14 font-weight-400" v-for="(i, index2) in it.list" :key="index2">{{ i.name }}</span>
                  </div>
                </div>
              </div>
              <!-- <div class="right_title font-size-14 font-weight-500" v-if="item.list && item.list.length > 0">
                <template v-if="item.type === '擅长行业'">行业领域</template>
                <template v-if="item.type === '擅长领域'">传播内容</template>
                <template v-if="item.type !== '擅长行业' && item.type !== '擅长领域'"> {{ item.type }}</template>
              </div> -->
              <!-- 自我介绍 -->
              <template v-if="item.type === '自我介绍'">
                <p class="font-size-14 font-weight-400">{{ $util.substr(userInfo.introduction, 200, "...") }}</p>
              </template>
              <!-- 其他 -->
              <!-- <template v-else>
                <div class="right_tag">
                  <span class="font-size-14 font-weight-400" v-for="(it, index1) in item.list" :key="index1">{{ it.name }}</span>
                </div>
              </template> -->
            </div>
            <template v-else>
              <div class="right_row jg" v-if="flag === 1">
                <div class="right_title  font-size-14 font-weight-500">所属机构</div>
                <p class="font-size-14 font-weight-400 cursor-pointer" @click="goOfficial">{{ userInfo.official_name }}</p>
              </div>
            </template>
          </template>
        </div>
        <div class="btn_slid" v-if="showBtn">
          <span v-if="userInfo.is_follow" class="btn white_btn hover-black cursor-pointer" @click="cancelfollowHandle">取消关注</span>
          <span v-else class="btn white_btn hover-black cursor-pointer" @click="followHandle">关注</span>
          <span class="btn black_btn hover-blue cursor-pointer" @click.stop="weChart(userInfo)">私信</span>
        </div>
      </div>
    </div>
    <!-- 机构下面的创作者 -->
    <div class="czc" v-if="flag === 2 && userInfo.is_show_user === 1">
      <czzPage :listData="listData" @val="vals"></czzPage>
    </div>
    <!-- 个人 -->
    <div class="gr" v-else></div>

    <!-- 案例列表 -->
    <div class="anli screenWidth">
      <anli :key="$route.query.id" v-if="userInfo.id" :id="userInfo.id"></anli>
    </div>
  </div>
</template>

<script>
import czzPage from "@/views/zyxyxs/children/czz.vue"
import Anli from "@/views/zyxyxs/children/anli.vue"
import { userPageDetailApi, userListApi, followApi, cancelfollowApi } from "@/api/api.js"
export default {
  name: "yxyxsJg",
  components: { czzPage, Anli },
  // watch: {
  //   $route: {
  //     handler: function (val) {
  //       if (val.query.id) {
  //         this.goOfficial()
  //       }
  //     },
  //   },
  // },
  data() {
    return {
      flag: null, //机构-you轮播2 ，机构里的个人-有所属机构1，个人0

      infos: [
        {
          type: "自我介绍",
          list: [],
          content:
            "自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介",
        },
        {
          slot: "jg",
        },
        {
          type: "社交网络",
          key: "social",
          list: [],
        },
        {
          type: "擅长领域",
          key: "area",
          list: [],
        },
        {
          type: "擅长行业",
          key: "industry",
          list: [],
        },
      ],

      infos: [
        {
          type: "自我介绍",
          list: [],
          content:
            "自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介我介绍自我介绍自我介绍自我介绍自我介绍自我介自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介绍自我介",
        },
        {
          slot: "jg",
        },
        {
          type: "擅长项",
          key: "scx",
          list: [
            {
              type: "社交网络",
              key: "social",
              list: [],
            },
            {
              type: "擅长领域",
              key: "area",
              list: [],
            },
            {
              type: "擅长行业",
              key: "industry",
              list: [],
            },
          ],
        },
      ],
      userInfo: {},
      // 请求机构下的创作者参数
      getUserQuery: {
        page: 1,
        limit: 30,
        official_id: null,
      },
      listData: [], // 机构下面得到创作者
      showBtn: false, // 是否显示关注按钮
    }
  },
  computed: {},
  created() {
    this.getList()
    
  },
  mounted() {},
  methods: {
    /*
     * @Description: 子页面传递过来的参数
     * @MethodAuthor:  liulian
     * @Date: 2022-08-11 16:37:06
     * @param: ''
     */
    vals(it) {
      this.$router.replace(`/yxyxsJg?id=${it.id}`)
      this.getList()
    },
    /*
     * @Description: 所属机构点击事件
     * @MethodAuthor:  liulian
     * @Date: 2022-07-25 18:14:05
     * @param: ''
     */
    goOfficial() {
      let id = this.userInfo.official_admin_user_id
      this.$router.replace(`/yxyxsJg?id=${id}`)
      this.getList()
      
    },
    cancelfollowHandle() {
      let id = this.$route.query.id
      cancelfollowApi(id).then((res) => {
        if (res.code === 10000) {
          this.$message.success("已取消关注")
          this.getList()
        }
      })
    },
    /*
     * @Description: 关注
     * @MethodAuthor:  liulian
     * @Date: 2022-07-18 16:55:03
     * @param: ''
     */
    followHandle() {
      let id = this.$route.query.id
      followApi({ follow_uid: id }).then((res) => {
        if (res.code === 10000) {
          this.$message.success("关注成功！")
          this.getList()
        }
      })
    },
    async getList() {
      let data = await this.getUserInfo()
      this.userInfo = data.user_info
      this.userInfo.is_follow = data.is_follow
      if (this.$store.state.userInfo) {
        if (this.userInfo.id === this.$store.state.userInfo.id) {
          this.showBtn = false
        } else {
          this.showBtn = true
        }
      } else {
        this.showBtn = true
      }

      if (data.tag && data.tag.length > 0 && this.userInfo.marketer_role !== 0) {
        let objData = {
          area: [],
          social: [],
          industry: [],
        }
        let key = Object.keys(objData)
        data.tag.map((item) => {
          key.map((k) => {
            if (k === item.top_parent_bind) {
              objData[k].push(item)
            }
          })
        })
        this.infos.map((i) => {
          if (i.key === "scx") {
            i.list.map((j) => {
              key.map((k) => {
                if (k === j.key) {
                  j.list = objData[k]
                }
              })
            })
          }
        })
      }
      // 是机构
      if (data.user_info.official_type === 1 && data.user_info.is_official_admin === 1) {
        this.flag = 2
        // 判断是否隐藏机构下的用户 0否1是
        if (data.user_info.is_show_user === 1) {
          // data.user_info.is_show_user = 0
          this.getUserQuery.official_id = data.user_info.official_id
          this.getUserList()
        } else {
          // 不影藏
        }
      } else if (data.user_info.official_type === 1 && data.user_info.is_official_admin === 0) {
        // 是机构下的个人
        this.flag = 1
      } else {
        this.flag = 0
        if (data.user_info.marketer_role === 1) {
          // 是营销师
          // 是营销师
        } else {
          // 不是营销师
        }
      }
    },
    // 私信
    weChart(infor) {
      let isLogin = this.$store.state.token
      if(!isLogin){
        this.$messageBox.alert("未登录，请重新登录", {
          type: "warning",
        })
          .then(() => {
            this.$store.commit("REMOVE_INFO")
            this.$router.push("/login")
            this.$messageBox.close()
          })
          .catch(() => {
            this.$messageBox.close()
          })

          return
      }
      this.$router.push({ path: "/chartroom", query: { id: infor.id, username: infor.username } })
    },
    /*
     * @Description: 获取机构下的用户列表
     * @MethodAuthor:  liulian
     * @Date: 2022-07-14 17:28:20
     * @param: ''
     */
    getUserList() {
      userListApi(this.getUserQuery.official_id).then((res) => {
        if (res.code === 10000) {
          res.data.length > 0 &&
            res.data.map((item) => {
              let tags = {
                area: [], //擅长领域
                industry: [], // 擅长行业
                social: [], // 社交网络
              }
              // 标签分类
              let keys = Object.keys(tags)
              keys.map((key) => {
                item.tag.length > 0 &&
                  item.tag.map((i) => {
                    if (key === i.top_parent_bind) {
                      tags[key].push(i)
                    }
                  })
              })
              this.$set(item, "newTag", tags)
            })
          this.listData = res.data
        }
      })
    },
    getUserInfo() {
      return new Promise((resolve, rejects) => {
        let id = this.$route.query.id
        userPageDetailApi(id).then((res) => {
          if (res.code === 10000) {
            resolve(res.data)
          }
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
.yxyxsJg {
  .top_banner {
    width: 100%;
    height: 21.5rem;
    background-image: url("../../../assets/images/yxyxs/banner@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .info {
    background-image: url("../../../assets/images/yxyxs/jianbian@2x.png");
    background-size: 100% 100%;
    // padding-bottom: 0.625rem;
    min-height: 21.625rem;
    display: flex;
    align-items: center;
    .info_con {
      margin: auto;
      // padding-top: 2.125rem;
      display: flex;
      align-items: center;
      .info_lef {
        margin-right: 4.1875rem;
        .avatar {
          width: 100%;
          display: flex;
          align-items: flex-end;
          text-align: center;
          justify-content: center;
          img {
            // margin-left: 35px;
            width: 7.5rem;
            height: 7.5rem;
            object-fit: cover;
            border-radius: 100%;
          }
          .flag {
            width: 2.625rem;
            height: 2.625rem;
            margin-left: -40px;
          }
        }
        .name {
          white-space: nowrap;
          margin-top: 1.0625rem;
          margin-bottom: 0.5rem;
        }
        .tag_box {
          justify-content: center;
          margin-bottom: 0.5625rem;
        }
        .green {
          margin-left: 0.375rem;
        }
        .bar {
          .line {
            height: 0.625rem;
            opacity: 1;
            border: 1px solid #dcddeb;
            margin-left: 0.25rem;
            margin-right: 0.25rem;
          }
          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            span {
              margin-left: 0.375rem;
            }
          }
          img {
            width: 1.0625rem;
            height: 1rem;
          }
        }
      }
      .line {
        border-right: 1px solid #f4f4f4;
        height: 11.6875rem;
      }
      .info_right {
        margin-left: 2rem;
        width: 52.75rem;
        // height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .right_row {
          // margin-bottom: 1.375rem;
          display: flex;
          align-items: center;
          margin-bottom: 1.375rem;
          .right_title {
            color: #111029;
            margin-right: 1rem;
            white-space: nowrap;
            min-width: 60px;
          }
          .box {
            width: 52.5rem;
            border-radius: 12px;
            border: 1px solid #dcddeb;
            padding-top: 1rem;
            padding-bottom: 1rem;
            margin-top: 0.5rem;
            .box_item {
              align-items: flex-start;
              .right_tag {
                margin-bottom: 6px;
              }
            }
            .right_title {
              margin-left: 1rem;
              white-space: nowrap;
              min-width: 60px;
              color: #111029;
              margin-bottom: 0.875rem;
            }
            .right_title:last-child {
              margin-bottom: 0;
            }
          }
          p {
            margin-top: 0.5rem;
            color: #57596c;
          }
          .right_tag {
            display: flex;
            flex-wrap: wrap;
            span {
              color: #57596c;
              height: 1.75rem;
              line-height: 1.75rem;
              border-radius: 1.375rem;
              opacity: 1;
              border: 1px solid #eff0f6;
              padding-left: 0.5rem;
              padding-right: 0.5rem;
              margin-right: 0.5rem;
              display: block;
            }
          }
        }
        .right_row:last-child {
          margin-bottom: 0;
        }
        right_row:first-child {
          margin-top: 22px;
        }
        .jg {
          p {
            margin-top: 0;
            text-decoration: underline;
            color: #57596c;
          }
        }
        .wrip_row {
          display: block;
        }
      }
    }
    .btn_slid {
      margin-left: 6.125rem;
      width: 7rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .btn {
        width: 7rem;
        height: 4.8125rem;
        background: #ffffff;
        border-radius: 3.25rem;
        opacity: 1;
        border: 0.0625rem solid #111029;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .black_btn {
        margin-top: 1rem;
        background: #111029;
        color: #fff;
      }
      ::v-deep .el-button:nth-child(2):hover {
        background-color: #fff;
      }
    }
  }
  .gr {
    width: 100%;
    height: 1rem;
    background-image: url("../../../assets/images/yxyxs/borderImage@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .anli {
    margin-top: 2rem;
    margin: auto;
    min-height: 400px;
  }
}
</style>
