<!--
* @description 新建模板
* @fileName addTemplate.vue
* @author liulian
* @date 2022/07/05 11:36:56
-->
<template>
  <div class="add_template">
    <div class="con flex">
      <!-- 新建空白演示 -->
      <!-- <div class="card">
        <div
          class="img_box cursor-pointer first flex-column-center"
          @click="clickItemHandle()"
        >
          <img class="add_icon" src="@/assets/images/add_blue_icon@2x.png" />
        </div>
        <div class="name font-size-16 font-weight-400 text-align-center">
          新建空白演示
        </div>
      </div> -->

      <!-- list -->
      <div class="card" v-for="(item, index) in list" :key="index">
        <!-- 空白模板 -->
        <div
          v-if="index === 0"
          class="img_box cursor-pointer first flex-column-center"
          :style="{
            background: 'url(' + item.cover + ') no-repeat center center',
            'background-size': 'cover',
          }"
          @click="clickUseHandle(item)"
        ></div>
        <!-- 非空白模板 -->
        <div
          v-else
          class="img_box cursor-pointer"
          @click="clickItemHandle(item)"
        >
          <img class="cover" :src="item.cover" />
          <div class="mask">
            <span
              class="cursor-pointer font-size-16 font-weight-400 btn"
              @click="clickUseHandle(item)"
              >立即使用</span
            >
          </div>
        </div>
        <div class="name font-size-16 font-weight-400 text-align-center">
          {{ item.name }}
        </div>
      </div>
    </div>

    <!-- 图片预览弹框 -->
    <DialogCpn
      :show.sync="previewDialog"
      :showClose="false"
      :showFooter="false"
      :showTitle="false"
      class="dialog_box"
      width="72%"
      :center="true"
      top="10px"
    >
      <div class="con flex-row-center">
        <img
          class="icon cursor-pointer"
          src="@/assets/images/albjq/left@2x.png"
          @click="prevHandle"
        />

        <div class="img">
          <!-- <img
          src="https://e3f49eaa46b57.cdn.sohucs.com/2021/8/31/15/14/MTAwMTIyXzE2MzAzOTQwODEzMjA=.jpg"
          alt=""
        /> -->
          <el-carousel
            :interval="5000"
            arrow="always"
            indicator-position="none"
            :autoplay="false"
            :initial-index="currIndex"
            ref="carousel"
            :loop="false"
          >
            <el-carousel-item v-for="item in carouselImages" :key="item">
              <img class="carousel_img" :src="item" />
            </el-carousel-item>
          </el-carousel>

          <div class="btn_box flex-row-center">
            <span
              class="font-size-16 font-weight-400 cursor-pointer"
              @click="cancelHandle"
              >取消</span
            >
            <span
              class="font-size-16 font-weight-400 cursor-pointer"
              @click="useHandle"
              >立即使用</span
            >
          </div>
        </div>

        <img
          class="icon cursor-pointer"
          src="@/assets/images/albjq/right@2x.png"
          @click="nextHandle"
        />
      </div>
    </DialogCpn>
  </div>
</template>

<script>
import { getTemplateList, createFileApi, getWpsTokenApi } from "@/api/api.js";
import DialogCpn from "@/components/common/dialog-cpn.vue";
export default {
  name: "addTemplate",
  components: { DialogCpn },
  data() {
    return {
      firstList: [
        {
          text: "以【白色】为背景色新建空白演示",
        },
        {
          text: "以【灰色渐变】为背景色新建空白演示",
        },
        {
          text: "以【黑色】为背景色新建空白演示",
        },
      ],
      list: [],
      previewDialog: false,
      currIndex: 0, // 当前轮播图显示的索引
      carouselImages: [], // 预览图片数组
      nowObj:{}, // 当前预览对象
    };
  },
  computed: {},
  created() {
    this.getInfo();
  },
  mounted() {},
  methods: {
    /*
     * @Description: 确定使用
     * @MethodAuthor:  liulian
     * @Date: 2022-07-05 18:04:14
     * @param: ''
     */
    useHandle() {
      this.clickUseHandle(this.nowObj);
    },
    cancelHandle() {
      this.previewDialog = false;
    },
    /*
     * @Description: 切换为上一张图片
     * @MethodAuthor:  liulian
     * @Date: 2022-07-05 17:46:44
     * @param: ''
     */
    prevHandle() {
      this.$refs.carousel.prev();
    },
    /*
     * @Description: 切换下一张图片
     * @MethodAuthor:  liulian
     * @Date: 2022-07-05 17:47:15
     * @param: ''
     */
    nextHandle() {
      this.$refs.carousel.next();
    },
    mouseenterHandle(i) {},
    /*
     * @Description: 点击卡片
     * @MethodAuthor:  liulian
     * @Date: 2022-07-05 14:51:35
     * @param: ''
     */
    clickItemHandle(item) {
      this.nowObj = item
      this.previewDialog = true;
      this.carouselImages = item.imgs && item.imgs.split(",");
    },
    /*
     * @Description: 点击立即使用
     * @MethodAuthor:  liulian
     * @Date: 2022-07-05 14:52:27
     * @param: ''
     */
    clickUseHandle(item) {
      event.stopPropagation();
        // this.$router.push(`/alWps?id=${item.id}`)
      // this.getWpsToken();
      createFileApi({ template_id: item.id }).then((res) => {
        if (res.code === 10000) {
          // let obj = {
          //   url: res.data.redirect_url,
          //   token: this.$store.state.wpsToken,
          // };
          // sessionStorage.setItem("wspInfo", JSON.stringify(obj));
          // this.$router.push("/wps");
          this.$router.push(`/alWps?id=${res.data.file_id}`)
        }
      });
    },
    getWpsToken() {
      getWpsTokenApi().then((res) => {
        if (res.code === 10000) {
          this.$store.commit("SET_WPSTOKEN", res.data);
        }
      });
    },
    getInfo() {
      getTemplateList().then((res) => {
        if (res.code === 10000) {
          this.list = res.data;
        }
      });
    },
 
  },
};
</script>

<style scoped lang="scss">
.add_template {
  .con {
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 89.125rem;
    margin: 2.5rem auto;
    .card {
      margin-bottom: 2rem;
      margin-right: 1rem;
      margin-left: 1rem;
      .img_box {
        height: 15.625rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.625rem;
        border: 0.0625rem solid #a0a3bd;
        width: 15.625rem;
        img {
          height: 8.9375rem;
          border-radius: 0.625rem;
          width: 11.75rem;
          height: 8.9375rem;
        }
        .add_icon {
          width: 3rem;
          height: 3rem;
        }
        .mask {
          width: 100%;
          height: 100%;
          background: rgba(17, 16, 41, 0.5);
          border-radius: 0.625rem;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          display: none;
        }
        .btn {
          width: 7rem;
          height: 3.625rem;
          background: #ffffff;
          border-radius: 3.25rem;
          opacity: 1;
          position: absolute;
          top: 6rem;
          left: 4.3125rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #111029;
        }
        .item_list {
          margin-top: 2.9375rem;
          span {
            display: block;
            width: 3rem;
            height: 3rem;
            background: #ffffff;
            border-radius: 0.3125rem;
            opacity: 1;
            border: 0.0625rem solid #a0a3bd;
            margin-left: 0.75rem;
            margin-right: 0.75rem;
          }
          .white_box_0 {
            background: #ffffff;
          }
          .white_box_1 {
            background: linear-gradient(180deg, #ffffff 0%, #dadada 92%);
          }
          .white_box_2 {
            background: #000000;
          }
        }
      }
      .first {
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(0, 0, 0, 0.15) 100%
        );
      }
      .img_box:hover {
        .mask {
          display: block;
        }
      }
      .name {
        margin-top: 1rem;
        color: #000000;
      }
    }
    // .auto_box {
    //   flex: 5;
    // }
  }
  ::v-deep .el-dialog {
    padding-bottom: 0;
    background: transparent;
    box-shadow: 0rem 0rem 0rem 0rem #ffffff;
  }
  .dialog_box {
    ::v-deep .el-dialog__header {
      display: none;
    }
    ::v-deep .el-carousel__arrow {
      display: none;
    }
    ::v-deep .el-carousel__container {
      height: 51.5rem;

    }

    .img {
      width: 73%;
      background: #ffffff;
      border-radius: 0.625rem;
      opacity: 1;
      margin-bottom: 2rem;
      padding-top: 2rem;
      // padding-left: 2rem;
      // padding-right: 2rem;
      padding-bottom: 2rem;
      margin: auto;
      text-align: center;
      img {
        width: 96%;
        height: 98%;
        margin: auto;
      border: 2px solid #dcddeb

        
      }
      .carousel_img{
      object-fit: cover;
      }
    }
    .btn_box {
      width: 100%;
      margin: auto;
      background: #ffffff;
      margin-top: 2rem;
      border-radius: 0.625rem;
      span {
        height: 3rem;
        border-radius: 3.125rem;
        opacity: 1;
        border: 0rem solid #8a9699;
        border: 1px solid #2f2c42;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.5rem;
        margin-left: 2rem;
        margin-right: 2rem;
      }
      span:nth-child(2) {
        background: #2f2c42;
        border: none;
        width: 6.5rem;
        color: #ffffff;
      }
    }
    .con {
      align-items: center;
      width: 100%;

      .icon {
        width: 2.375rem;
        height: 2.375rem;
      }
    }
  }
}
</style>
