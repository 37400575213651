<!--
* @description 找优秀营销师更多页面
* @fileName zbkal.vue
* @author liulian
* @date 2022/06/09 16:53:13
-->
<template>
  <div class="bkal_gd">
    <div class="filter_box flex-row-between">
      <div class="left">
        <filterSelect :filters="filters" @vals="valsHandle"></filterSelect>
      </div>
      <div class="right flex">
        <!-- <div class="sort flex-row-center flex">
          <span class=" font-size-20 font-weight-400">按时间排序</span>
          <img class=" cursor-pointer" v-if="timeSort===1" src="@/assets/images/home/sort_icon@2x.png" alt="排序图标" @click="sortTimeHandle(1)">
          <img class=" cursor-pointer" v-else src="@/assets/images/home/sort_icon1@2x.png" alt="排序图标" @click="sortTimeHandle(0)">
        </div> -->
        <template v-if="queryInfo.sort === 'score_asc'">
          <div class="sort flex-row-center flex cursor-pointer"  @click="sortCoreHandle('score_asc')">
            <span class="font-size-20 font-weight-400">按评分排序</span>
            <div class="icons flex-column-center">
              <!-- 上面图标 -->
              <img class="cursor-pointer" src="@/assets/images/top_blue@2x.png" />
              <img class="bottom_icon cursor-pointer" src="@/assets/images/bottom_gray@2x.png" />
            </div>
          </div>
        </template>

        <!-- 下面图标 -->
        <template v-if="queryInfo.sort === 'score_desc'">
          <div class="sort flex-row-center flex cursor-pointer" @click="sortCoreHandle('score_desc')">
            <span class="font-size-20 font-weight-400">按评分排序</span>
            <div class="icons flex-column-center">
              <!-- 上面图标 -->
              <img class="cursor-pointer" src="@/assets/images/top_gray@2x.png" />
              <img class="bottom_icon cursor-pointer" src="@/assets/images/bottom_blue@2x.png" />
            </div>
          </div>
        </template>
      </div>
    </div>

    <loading-more :completed="completed" @changeData="loadMore" :loadingIcon="loadingIcon" v-if="!showPage">
      <div class="cardlist flex">
        <avatarCard class="item" v-for="(item, index) in lists" :key="index" :item="item"></avatarCard>
      </div>
    </loading-more>
    <empty-page v-else></empty-page>
  </div>
</template>

<script>
import filterSelect from "@/components/common/filter-select.vue"
import avatarCard from "@/components/common/avatar-card.vue"
import { marketerListApi } from "@/api/api.js"
import emptyPage from "@/components/common/empty-page.vue"
import loadingMore from "@/components/common/loadingMore.vue"
export default {
  name: "zbkal",
  components: { filterSelect, avatarCard, loadingMore, emptyPage },
  data() {
    return {
      filters: [
        {
          icon: require("@/assets/images/sjwl@2x.png"),
          name: "社交网络",
          isOpen: false,
          slot: "select1",
        },
        {
          icon: require("@/assets/images/schy@2x.png"),
          isOpen: false,
          name: "擅长行业",
          slot: "select2",
        },
        {
          icon: require("@/assets/images/scly@2x.png"),
          isOpen: false,
          name: "擅长领域",
          slot: "select3",
        },
      ],
      coreSort: null, // 评分排序
      coreSort1: null, // 评分排序
      timeSort: 0, // 时间排序
      emptyPage: false, // false
      lists: [],
      queryInfo: {
        page: 1,
        limit: 10,
        sort: "score_desc", // 默认倒叙 time_desc,time_asc,score_desc,score_asc
        keyword: "", //关键词
        tag_arr: [], // 标签数组
      },
      completed: false, // 加载
      loadingIcon: true, // 加载
      total: null, // 加载
      showPage: false,
    }
  },
  computed: {
    getSearchKey() {
      return this.$store.state.headerInput
    },
  },
  watch: {
    getSearchKey: {
      handler(val) {
        this.queryInfo.keyword = val
        this.getList()
      },
    },
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    loadMore(e) {
      if (this.total > 0) {
        let check = e > Math.ceil(this.total / this.queryInfo.limit)
        if (check) {
          this.completed = true
          this.loadingIcon = false
          return
        }
      }
      setTimeout((res) => {
        this.queryInfo.page = e
        this.getLoadList() // 重新请求数据
      }, 2000)
    },
    async getLoadList() {
      let data = await this.listApi()
      this.lists = this.lists.concat(data)
    },

    /*
     * @Description: 筛选组件传入的值
     * @MethodAuthor:  liulian
     * @Date: 2022-07-11 16:09:47
     * @param: ''
     */
    valsHandle(val) {
      this.queryInfo.tag_arr = []
      val.map((item) => {
        if (item.name == "社交网络" || item.name === "擅长行业") {
          item.selects.length &&
            item.selects.map((it) => {
              this.queryInfo.tag_arr.push(it)
            })
        }
        if (item.name === "擅长领域") {
          item.selects.length &&
            item.selects.map((it) => {
              it.value.length > 0 &&
                it.value.map((i) => {
                  this.queryInfo.tag_arr.push(i)
                })
            })
        }
      })
      this.getList()
    },
    sortCoreHandle(key) {
      this.queryInfo.sort = key === "score_desc" ? "score_asc" : "score_desc"
      this.getList()
    },
    async getList() {
      // 从首页传过来的参数
      if (this.$store.state.headerInput) {
        this.queryInfo.keyword = this.$store.state.headerInput
      }
      this.queryInfo.page=1
      this.lists = await this.listApi()
    },

    /*
     * @Description: 请求数据
     * @MethodAuthor:  liulian
     * @Date: 2022-07-11 17:10:21
     * @param: ''
     */
    listApi() {
      return new Promise((resolve, rejects) => {
        marketerListApi(this.queryInfo).then((res) => {
          if (res.code === 10000) {
            this.showPage = res.data.length === 0
            this.total = res.count
            res.data.length > 0 &&
              res.data.map((item) => {
                let tags = {
                  area: [], //擅长领域
                  industry: [], // 擅长行业
                  social: [], // 社交网络
                }
                // 标签分类
                let keys = Object.keys(tags)
                keys.map((key) => {
                  item.tag.length > 0 &&
                    item.tag.map((i) => {
                      if (key === i.top_parent_bind) {
                        tags[key].push(i)
                      }
                    })
                })
                this.$set(item, "newTag", tags)
              })
            resolve(res.data)
          }
        })
      })
    },

    /*
     * @Description: 时间排序
     * @MethodAuthor:  liulian
     * @Date: 2022-06-09 17:23:13
     * @param: ''
     */
    sortTimeHandle(key) {
      this.timeSort = key === 0 ? 1 : 0
    },
  },
}
</script>

<style scoped lang="scss">
.bkal_gd {
  .filter_box {
    width: 99%;
    margin: auto;
    position: relative;
  }
  .right {
    padding-right: 20px;
    position: absolute;
    right: 0;
    .sort {
      width: 10rem;
      height: 3.25rem;
      background: #ffffff;
      border-radius: 1rem;
      opacity: 1;
      border: 1px solid #eff0f6;
      .icons {
        margin-left: 1.0625rem;
        height: 100%;
        img {
          width: 0.625rem;
          height: 0.3906rem;
        }
        .bottom_icon {
          margin-top: 0.125rem;
        }
      }
    }
    .sort:nth-child(1) {
      margin-right: 1rem;
    }

    img {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
  .cardlist {
    margin: 2rem auto;
    flex-wrap: wrap;
    .item {
      margin-right: 21px;
    }
  }
}
</style>
