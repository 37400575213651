<!--
* @description 找优秀营销师
* @fileName index.vue
* @author liulian
* @date 2022/06/08 09:38:59
-->
<template>
  <div class="cont">
    <div class="yxyxs">
      <div class="search_box">
        <el-input placeholder="搜  视频/种草/直播 等关键词寻找擅长此方向的机构/顾问" v-model="inputValue" @keyup.enter.native="searchHandle"> </el-input>
        <div class="append" @click="searchHandle"><img class="cursor-pointer" src="@/assets/images/home/home_search@2x.png" alt="" /></div>
      </div>
      <!-- 轮播 -->
      <div class="swiper">
        <div class="top_title text-align-center">好评社媒机构/顾问推荐</div>
        <mySwiper :listsData="swiperList" :key="new Date().getTime()"></mySwiper>
      </div>

      <!-- 筛选 -->
      <div class="filter flex flex-row-between screenWidth">
        <div>
          <tipItem></tipItem>
          <filterSelect :filters="filters" @vals="valsHandle"></filterSelect>
        </div>
        <template v-if="queryInfo.sort === 'score_asc'">
          <div class="sort flex flex-row-center cursor-pointer" @click="sortCoreHandle('score_asc')">
            <span class="font-size-20 font-weight-400">按评分排序</span>
            <div class="icons flex-column-center">
              <!-- 上面图标 -->
              <img class="cursor-pointer" src="@/assets/images/top_blue@2x.png" />
              <img class="bottom_icon cursor-pointer" src="@/assets/images/bottom_gray@2x.png" />
            </div>
          </div>
        </template>

        <!-- 下面图标 -->
        <template v-if="queryInfo.sort === 'score_desc'">
          <div class="sort flex flex-row-center cursor-pointer" @click="sortCoreHandle('score_desc')">
            <span class="font-size-20 font-weight-400">按评分排序</span>
            <div class="icons flex-column-center">
              <!-- 上面图标 -->
              <img class="cursor-pointer" src="@/assets/images/top_gray@2x.png" />
              <img class="bottom_icon cursor-pointer" src="@/assets/images/bottom_blue@2x.png" />
            </div>
          </div>
        </template>
      </div>
      <!-- 列表 -->
      <loading-more v-if="!(lists.length === 0)" :completed="completed" :pageInit="queryInfo.page" @changeData="loadMore" :loadingIcon="loadingIcon">
        <div class="list screenWidth">
        <template  v-for="(item, index) in lists" >
            <div v-if="item.marketer_role !== 2" class="list_item cursor-pointer" @click="goDetail(item)">
            <div class="item_left">
              <div class="avatar">
                <img class="avatar-img" :src="item.avatar" alt="头像" />
                <!-- 1机构，2品牌 -->
                <img class="flag" v-if="item.official_type === 2" src="@/assets/images/home/yellow_icon@2x.png" alt="icon" />
                <img class="flag" v-if="item.official_type === 1" src="@/assets/images/home/blue_icon@2x.png" alt="icon" />
              </div>
              <template v-if="item.showBtn">
                <div v-if="item.is_follow" class="gz_btn font-size-16 font-weight-400" @click="cancelfollowHandle(item)">已关注</div>
                <div v-else class="gz_btn font-size-16 font-weight-400" @click="followHandle(item)">关注</div>
              </template>
              <div class="name text-align-center font-size-16 font-weight-500" :class="!item.showBtn ? 'name2' : ''">{{ item.username }}</div>
              <div class="tag1">
                <tag-btn v-if="item.level_context" class="degnji" :config="{ type: 'red', text: item.level_context }"></tag-btn>
                <tag-btn v-if="item.marketer_role_context" :config="{ type: 'green', text: item.marketer_role_context }"></tag-btn>
              </div>
              <div class="bar flex">
                <div class="div flex shoucang">
                  <img src="@/assets/images/home/str_yellow@2x.png" alt="" />
                  <span class="font-color-yellow font-size-16 font-weight-500">{{ item.score }}</span>
                </div>
                <span class="line"></span>
                <div class="div flex fensi">
                  <span class="font-size-14 font-weight-400">粉丝</span>
                  <span class="font-size-16 font-weight-500 num">{{ item.fans_num ? item.fans_num : 0 }}</span>
                </div>
                <span class="line"></span>
                <div class="div flex jingyan">
                  <span class="font-size-14 font-weight-400">执行经验</span>
                  <span class="font-size-16 font-weight-500 num">{{ item.trade_num }}</span>
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div class="item_tight">
              <div class="top_box" v-if="item.newTag.social.length !== 0 ||item.newTag.area.length !== 0 || item.newTag.industry.length!==0 ">
                <span>擅长项</span>
                <div class="con_box">
                  <div class="types flex" v-if="item.newTag.social.length > 0">
                    <span class="type font-size-14 font-weight-400">社交网络</span>
                    <div class="right_tags flex text-align-center">
                      <span class="font-size-14 font-weight-400" v-for="(it, index1) in item.newTag.social" :key="index1">{{ it.name }}</span>
                    </div>
                  </div>
                  <div class="types flex" v-if="item.newTag.area.length > 0">
                    <span class="type font-size-14 font-weight-400">传播内容</span>
                    <div class="right_tags flex text-align-center">
                      <span class="font-size-14 font-weight-400" v-for="(it, index1) in item.newTag.area" :key="index1">{{ it.name }}</span>
                    </div>
                  </div>
                  <div class="types flex" v-if="item.newTag.industry.length > 0">
                    <span class="type font-size-14 font-weight-400">行业领域</span>
                    <div class="right_tags flex text-align-center">
                      <span class="font-size-14 font-weight-400" v-for="(it, index1) in item.newTag.industry" :key="index1">{{ it.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <p class="ms font-size-14 font-weight-400">
                {{ item.introduction }}
              </p>
            </div>
          </div>
        </template>
        </div>
      </loading-more>

      <!-- 缺省 -->
      <div class="empty screenWidth" v-else>
        <emptyPage></emptyPage>
      </div>

      <up-top></up-top>
    </div>
  </div>
</template>

<script>
import mySwiper from "@/views/zyxyxs/children/swiper.vue"
import filterSelect from "@/components/common/filter-select.vue"
import emptyPage from "@/components/common/empty-page.vue"
import { marketerListApi, followApi, cancelfollowApi } from "@/api/api"
import loadingMore from "@/components/common/loadingMore.vue"
import tipItem from "@/components/common/tip-item.vue"
export default {
  name: "index",
  components: { mySwiper, filterSelect, emptyPage, loadingMore, tipItem },
  data() {
    return {
      inputValue: "",
      filters: [
        {
          icon: require("@/assets/images/sjwl@2x.png"),
          name: "社交网络",
          isOpen: false,
          slot: "select1",
        },
        {
          icon: require("@/assets/images/schy@2x.png"),
          isOpen: false,
          name: "擅长行业",
          slot: "select2",
        },
        {
          icon: require("@/assets/images/scly@2x.png"),
          isOpen: false,
          name: "擅长领域",
          slot: "select3",
        },
      ],
      lists: [],
      // 右侧类型数据
      types: [
        {
          type: "社交网络",
          key: "social",
          lists: [
            {
              name: "小红书",
            },
            {
              name: "微博",
            },
            {
              name: "哔哩哔哩",
            },

            {
              name: "哔哩哔哩",
            },

            {
              name: "微博",
            },
            {
              name: "哔哩哔哩",
            },
          ],
        },
        {
          type: "擅长领域",
          key: "area",
          lists: [
            {
              name: "小红书",
            },
            {
              name: "微博",
            },
            {
              name: "哔哩哔哩",
            },
          ],
        },
        {
          type: "擅长行业",
          key: "industry",
          lists: [
            {
              name: "小红书",
            },
            {
              name: "微博",
            },
            {
              name: "哔哩哔哩",
            },
          ],
        },
      ],
      queryInfo: {
        page: 1,
        limit: 10,
        sort: "score_desc", // 默认倒叙 time_desc,time_asc,score_desc,score_asc
        keyword: "", //关键词
        tag_arr: [], // 标签数组
      },

      completed: false, // 加载
      loadingIcon: true, // 加载
      total: null, // 加载
      showPage: false, // 加载
      swiperList: [], // 轮播数据
    }
  },
  computed: {},
  created() {
    this.getList()
    this.getSwiperList()
  },
  mounted() {},
  methods: {
    /*
     * @Description: 取消关注
     * @MethodAuthor:  liulian
     * @Date: 2022-07-22 09:42:15
     * @param: ''
     */
    cancelfollowHandle(item) {
      event.stopPropagation()
      cancelfollowApi(item.id).then((res) => {
        if (res.code === 10000) {
          this.$message.success("已取消关注")
          this.$set(item, "is_follow", false)
        }
      })
    },
    followHandle(item) {
      event.stopPropagation()
      followApi({ follow_uid: item.id }).then((res) => {
        if (res.code === 10000) {
          this.$message.success("关注成功！")
          this.$set(item, "is_follow", true)
        }
      })
    },
    /*
     * @Description: 获取轮播图前十个，评分倒叙
     * @MethodAuthor:  liulian
     * @Date: 2022-07-14 15:00:51
     * @param: ''
     */
    getSwiperList() {
      let query = {
        page: 1,
        limit: 10,
        sort: "score_desc", // 默认倒叙 time_desc,time_asc,score_desc,score_asc
        keyword: "", //关键词
        tag_arr: [], // 标签数组
      }
      marketerListApi(query).then((res) => {
        if (res.code === 10000) {
          res.data.length > 0 &&
            res.data.map((item) => {
              let tags = {
                area: [], //擅长领域
                industry: [], // 擅长行业
                social: [], // 社交网络
              }
              // 标签分类
              let keys = Object.keys(tags)
              keys.map((key) => {
                item.tag.length > 0 &&
                  item.tag.map((i) => {
                    if (key === i.top_parent_bind) {
                      tags[key].push(i)
                    }
                  })
              })
              this.$set(item, "newTag", tags)
            })
          this.swiperList = res.data
        }
      })
    },
    loadMore(e) {
      if (this.total > 0) {
        let check = e > Math.ceil(this.total / this.queryInfo.limit)
        if (check) {
          this.completed = true
          this.loadingIcon = false
          return
        }
      }
      setTimeout((res) => {
        this.queryInfo.page = e
        this.getLoadList() // 重新请求数据
      }, 2000)
    },
    async getLoadList() {
      this.completed = false
      this.loadingIcon = true
      let data = await this.listApi()
      this.lists = this.lists.concat(data)
    },
    /*
     * @Description: 搜索事件
     * @MethodAuthor:  liulian
     * @Date: 2022-07-11 16:09:59
     * @param: ''
     */
    searchHandle() {
      this.queryInfo.keyword = this.inputValue
      this.getList()
    },
    /*
     * @Description: 筛选组件传入的值
     * @MethodAuthor:  liulian
     * @Date: 2022-07-11 16:09:47
     * @param: ''
     */
    valsHandle(val) {
      this.queryInfo.tag_arr = []
      val.map((item) => {
        if (item.name == "社交网络" || item.name === "擅长行业") {
          item.selects.length &&
            item.selects.map((it) => {
              this.queryInfo.tag_arr.push(it)
            })
        }
        if (item.name === "擅长领域") {
          item.selects.length &&
            item.selects.map((it) => {
              it.value.length > 0 &&
                it.value.map((i) => {
                  this.queryInfo.tag_arr.push(i)
                })
            })
        }
      })
      this.getList()
    },
    /*
     * @Description: 跳转个人或者是机构页面
     * @MethodAuthor:  liulian
     * @Date: 2022-06-15 10:58:23
     * @param: ''
     */
    goDetail(item) {
      this.$router.push(`/yxyxsJg?id=${item.id}`)
    },
    async getList() {
      this.queryInfo.page = 1
      this.completed = false
      this.loadingIcon = true
      this.lists = await this.listApi()
    },
    /*
     * @Description: 请求数据
     * @MethodAuthor:  liulian
     * @Date: 2022-07-11 17:10:21
     * @param: ''
     */
    listApi() {
      return new Promise((resolve, rejects) => {
        marketerListApi(this.queryInfo).then((res) => {
          if (res.code === 10000) {
            this.total = res.count
            if (!(res.count > this.queryInfo.limit)) {
              this.completed = true
              this.loadingIcon = false
            }
            // this.showPage = res.data.length === 0
            res.data.length > 0 &&
              res.data.map((item) => {
                // 判断是否是当前用户
                if (this.$store.state.userInfo) {
                  if (item.id === this.$store.state.userInfo.id) {
                    this.$set(item, "showBtn", false)
                  } else {
                    this.$set(item, "showBtn", true)
                  }
                } else {
                  this.$set(item, "showBtn", true)
                }
                let tags = {
                  area: [], //擅长领域
                  industry: [], // 擅长行业
                  social: [], // 社交网络
                }
                // 标签分类
                let keys = Object.keys(tags)
                keys.map((key) => {
                  item.tag.length > 0 &&
                    item.tag.map((i) => {
                      if (key === i.top_parent_bind) {
                        tags[key].push(i)
                      }
                    })
                })
                this.$set(item, "newTag", tags)
              })
            resolve(res.data)
          }
        })
      })
    },
    sortCoreHandle(key) {
      this.queryInfo.sort = key === "score_desc" ? "score_asc" : "score_desc"
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.cont {
  background-image: url("../../assets/images/bkal/bk-bg@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 1rem;
  background-attachment: fixed;
}
.yxyxs {
  margin: auto;
  padding-bottom: 5.25rem;

  .search_box {
    width: 43.75rem;
    margin: 3.25rem auto;
    position: relative;
    .append {
      width: 5.25rem;
      height: 3.375rem;
      background: #111029;
      border-radius: 2.5rem;
      opacity: 1;
      position: absolute;
      top: 0.375rem;
      right: 0.375rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    ::v-deep .el-input__inner {
      height: 4.125rem;
      background: #ffffff;
      border-radius: 2.5rem;
      width: 43.75rem;
      opacity: 1;
      border: 1px solid #111029;
      position: relative;
    }
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
    ::v-deep.el-input__inner::-webkit-input-placeholder {
      color: #a0a3bd;

      font-size: 1.25rem;
      font-weight: 400;
    } /*Chrome/Opera/Safari*/
    ::v-deep .el-input__inner::-moz-placeholder {
      color: #a0a3bd;

      font-size: 1.25rem;
      font-weight: 400;
    } /*Firefox 19*/
    ::v-deep.el-input__inner:-ms-input-placeholder {
      color: #a0a3bd;

      font-size: 1.25rem;
      font-weight: 400;
    } /*id10+*/
    ::v-deep .el-input__inner:-moz-placeholder {
      color: #a0a3bd;
      font-size: 1.25rem;
      font-weight: 400;
    } /*Firefox 18-*/
  }
  .swiper {
    margin: auto;
    // width: 72%;
    width: 86.125rem;
    background-color: red;
    padding-top: 1rem;
    padding-bottom: 1.9375rem;
    background: #ffffff;
    box-shadow: 0px 10px 28px 0px rgba(58, 114, 255, 0.08);
    border-radius: 16px;
    border: 1px solid #dcddeb;
    .top_title {
      margin-bottom: 2rem;
      font-size: 1.25rem;
      font-weight: 400;
      color: #111029;
    }
    ::v-deep .swiper-slide {
      margin-left: 0rem;
      margin-right: 0rem;
      width: 17.58rem !important;
    }
  }
  .filter {
    margin: auto;
    margin-top: 3.9375rem;
    align-items: flex-end;
    .sort {
      width: 10rem;
      height: 3.25rem;
      background: #ffffff;
      border-radius: 1rem;
      opacity: 1;
      border: 1px solid #eff0f6;
      .icons {
        margin-left: 1.0625rem;
        height: 100%;
        img {
          width: 0.625rem;
          height: 0.3906rem;
        }
        .bottom_icon {
          margin-top: 0.125rem;
        }
      }
      span {
        color: #111029;
      }
      img {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  }
  .list {
    margin: auto;
    .list_item {
      width: 100%;
      height: 17.3125rem;
      background: #ffffff;
      border-radius: 1.75rem;
      opacity: 1;
      border: 1px solid #eff0f6;
      margin-top: 2rem;
      display: flex;
      align-items: center;
      .item_left {
        // border-right: 1px solid #f4f4f4;
        width: 16.25rem;
        .avatar {
          margin-left: 5.25rem;
          width: 5rem;
          height: 5rem;
          display: flex;
          align-items: flex-end;
          img {
            width: 5rem;
            height: 5rem;
            border-radius: 100%;
          }
          .flag {
            width: 1.75rem;
            height: 1.75rem;
            margin-left: -26px;
          }
        }
        .gz_btn {
          width: 4.75rem;
          height: 3.125rem;
          background: #ffffff;
          border-radius: 3.25rem;
          opacity: 1;
          border: 1px solid #111029;
          display: block;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: -0.8125rem 5.375rem;
          background-color: #fff;
          position: absolute;
        }
        .name {
          color: #240f45;
          margin-top: 3.4rem;
        }
        .name2 {
          margin-top: 1.4rem;
        }
        .tag1 {
          display: flex;
          margin-top: 0.5rem;
          .degnji {
            margin-right: 0.375rem;
          }
          margin: 0.5rem 4.9375rem;
        }
        .bar {
          margin-left: 2.0625rem;
          margin-top: 0.5625rem;
          .line {
            height: 0.625rem;
            width: 0px;
            opacity: 1;
            border: 1px solid #dcddeb;
            margin-left: 0.25rem;
            margin-right: 0.25rem;
          }
          .shoucang {
            img {
              width: 1.0625rem;
              height: 1.0163rem;
              margin-right: 0.375rem;
            }
          }
          .fensi {
            color: #2f2c42;
            .num {
              margin-left: 0.375rem;
            }
          }
          .jingyan {
            color: #2f2c42;
            .num {
              margin-left: 0.375rem;
            }
          }
        }
      }
      .line {
        height: 11.6875rem;
        width: 0rem;
        opacity: 1;
        border: 0.0625rem solid #f4f4f4;
      }
      .item_tight {
        width: 100%;
        .types {
          margin-left: 2rem;
          // margin-bottom: 1.375rem;
          margin-bottom: 10px;
          .type {
            margin-right: 1rem;
            color: #111029;
            white-space: nowrap;
          }
          .right_tags {
            flex-wrap: wrap;
            span {
              height: 1.75rem;
              line-height: 1.75rem;
              border-radius: 1.625rem;
              opacity: 1;
              border: 1px solid #eff0f6;
              margin-right: 0.5rem;
              display: block;
              color: #57596c;
              padding-left: 0.5rem;
              padding-right: 0.5rem;
            }
          }
        }
        .top_box {
          margin-left: 2rem;
          width: 100%;
          .con_box {
            border-radius: 12px 12px 12px 12px;
            opacity: 1;
            border: 1px solid #dcddeb;
            width: 90%;
            margin-top: 10px;
            margin-bottom: 10px;
            padding-top: 10px;
          }
        }
      }
      .ms {
        margin-left: 2rem;
        color: #57596c;
        line-height: 19px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        word-break: break-all;
        overflow: hidden;
        margin-right: 0.625rem;
      }
    }
  }
  .empty {
    margin: 15.75rem auto;
  }
}
</style>
