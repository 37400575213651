<!--
* @description 需求大厅页面更多页面
* @fileName zbkal.vue
* @author liulian
* @date 2022/06/09 16:53:13
-->
<template>
  <div class="xqdt_gd">
    <div class="filter_box flex-row-between">
      <div class="left">
        <filterSelect :filters="filters" @vals="valsHandle"></filterSelect>
      </div>
      <div class="right flex">
        <!-- <div class="sort flex-row-center flex">
          <span class=" font-size-20 font-weight-400">按时间排序</span>
          <img class=" cursor-pointer" v-if="timeSort===1" src="@/assets/images/home/sort_icon@2x.png" alt="排序图标" @click="sortTimeHandle(1)">
          <img class=" cursor-pointer" v-else src="@/assets/images/home/sort_icon1@2x.png" alt="排序图标" @click="sortTimeHandle(0)">
        </div> -->
        <template v-if="queryInfo.sort === 'time_asc'">
          <div class="sort flex-row-center flex cursor-pointer" @click="sortCoreHandle('time_asc')">
            <span class="font-size-20 font-weight-400">按时间排序</span>
            <div class="icons flex-column-center">
              <!-- 上面图标 -->
              <img class="cursor-pointer" src="@/assets/images/top_blue@2x.png" />
              <img class="bottom_icon cursor-pointer" src="@/assets/images/bottom_gray@2x.png" />
            </div>
          </div>
        </template>

        <!-- 下面图标 -->
        <template v-if="queryInfo.sort === 'time_desc'">
          <div class="sort flex-row-center flex cursor-pointer" @click="sortCoreHandle('time_desc')">
            <span class="font-size-20 font-weight-400">按时间排序</span>
            <div class="icons flex-column-center">
              <!-- 上面图标 -->
              <img class="cursor-pointer" src="@/assets/images/top_gray@2x.png" />
              <img class="bottom_icon cursor-pointer" src="@/assets/images/bottom_blue@2x.png" />
            </div>
          </div>
        </template>
      </div>
    </div>
    <loading-more :completed="completed" @changeData="loadMore" :loadingIcon="loadingIcon" v-if="!showPage">
      <div class="cardlist">
        <leftImageCard v-for="(item, index) in lists" :key="index" :item="item"></leftImageCard>
      </div>
    </loading-more>
    <emptyPage v-else></emptyPage>
  </div>
</template>

<script>
import filterSelect from "@/components/common/filter-select.vue"
import leftImageCard from "@/components/common/left-image-card.vue"
import { demandListApi } from "@/api/api"
import loadingMore from "@/components/common/loadingMore.vue"
import emptyPage from "@/components/common/empty-page.vue"
export default {
  name: "zbkal",
  components: { filterSelect, leftImageCard, loadingMore, emptyPage },
  data() {
    return {
      filters: [
        {
          icon: require("@/assets/images/sjwl@2x.png"),
          name: "社交网络",
          isOpen: false,
          slot: "select1",
        },
        {
          icon: require("@/assets/images/schy@2x.png"),
          isOpen: false,
          name: "擅长行业",
          slot: "select2",
        },
        {
          icon: require("@/assets/images/scly@2x.png"),
          isOpen: false,
          name: "擅长领域",
          slot: "select3",
        },
      ],
      timeSort: null, // 时间排序
      lists: [],
      queryInfo: {
        page: 1,
        limit: 10,
        sort: "time_desc", // 默认倒叙 time_desc,time_asc,time_desc,time_asc
        keyword: "", //关键词
        tag_arr: [], // 标签数组
      },
      total: null,
      completed: false, // 加载
      loadingIcon: true, // 加载
      showPage: false, // 空页面
    }
  },
  computed: {
    getSearchKey() {
      return this.$store.state.headerInput
    },
  },
  watch: {
    getSearchKey: {
      handler(val) {
        this.queryInfo.keyword = val
        this.getList()
      },
    },
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    loadMore(e) {
      if (this.total > 0) {
        let check = e > Math.ceil(this.total / this.queryInfo.limit)
        if (check) {
          this.completed = true
          this.loadingIcon = false
          return
        }
      }
      setTimeout((res) => {
        this.queryInfo.page = e
        this.getLoadList() // 重新请求数据
      }, 2000)
    },
    async getLoadList() {
      let data = await this.listApi()
      this.lists = this.lists.concat(data)
    },

    async getList() {
      // 从首页传过来的参数
      if (this.$store.state.headerInput) {
        this.queryInfo.keyword = this.$store.state.headerInput
      }
      this.queryInfo.page = 1
      this.lists = await this.listApi()
    },
    /*
     * @Description: 筛选框选择
     * @MethodAuthor:  liulian
     * @Date: 2022-07-13 11:27:22
     * @param: ''
     */
    valsHandle(val) {
      this.queryInfo.tag_arr = []
      this.queryInfo.page = 1

      val.map((item) => {
        if (item.name === "社交网络" || item.name === "擅长行业") {
          if (item.selects.length > 0) {
            item.selects.map((i) => {
              this.queryInfo.tag_arr.push(i)
            })
          }
        }
        if (item.name === "擅长领域") {
          if (item.selects.length > 0) {
            item.selects.map((it) => {
              if (it.value.length > 0) {
                it.value.map((i) => {
                  this.queryInfo.tag_arr.push(i)
                })
              }
            })
          }
        }
      })
      this.getList()
    },

    listApi() {
      let _this = this
      return new Promise((resolve, rejects) => {
        demandListApi(this.queryInfo).then((res) => {
          if (res.code === 10000) {
            this.showPage = res.data.length === 0
            this.total = res.count
            resolve(res.data)
          }
        })
      })
    },
    sortCoreHandle(key) {
      this.queryInfo.sort = key === "time_desc" ? "time_asc" : "time_desc"
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.xqdt_gd {
  .filter_box {
    position: relative;
  }
  .right {
    position: absolute;
    right: 0;
    .sort {
      width: 10rem;
      height: 3.25rem;
      background: #ffffff;
      border-radius: 1rem;
      opacity: 1;
      border: 1px solid #eff0f6;
      display: flex;
      align-items: center;
      .icons {
        margin-left: 1.0625rem;
        height: 100%;
        img {
          width: 0.625rem;
          height: 0.3906rem;
        }
        .bottom_icon {
          margin-top: 0.125rem;
        }
      }
    }
    img {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
  .cardlist {
    margin: 2rem auto;
    flex-wrap: wrap;
    .card {
      width: 100%;
      height: 13.75rem;
      border-radius: 1.5rem;
      border: 1px solid #eff0f6;
      margin-bottom: 2rem;
      .left {
        display: flex;
        flex-direction: column;
        margin-left: 3.25rem;
        align-items: center;
        .avatar {
          width: 7.5rem;
          height: 7.5rem;
          border-radius: 199%;
        }
        span {
          margin-top: 0.875rem;
          color: #111029;
        }
      }
      .line {
        margin-left: 2rem;
        height: 6.75rem;
        width: 0px;
        opacity: 1;
        border: 1px solid #f4f4f4;
      }
      .center {
        margin-left: 2rem;
        .title {
          color: #111029;
          width: 36rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          word-break: break-all;
          overflow: hidden;
        }
        .tip {
          margin-top: 1rem;
          color: #57596c;
          img {
            width: 1.375rem;
            height: 1.375rem;
          }
          .rili {
            margin-left: 0.5rem;
          }
          .tip_line {
            color: #a0a3bd;
          }
          .pinpai {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }
          .number {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }
        }
        .baoming {
          margin-top: 1rem;
        }
        .time {
          margin-top: 1rem;
          color: #a0a3bd;
        }
      }
      .right-price {
        position: relative;
        width: auto;
        flex: 1;
        span {
          position: absolute;
          right: 2rem;
        }
      }
    }
  }
}
</style>
