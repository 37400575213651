<!--
* @description 爆款案例推荐页面
* @fileName bkaltj.vue
* @author liulian
* @date 2022/06/08 18:31:53
-->
<template>
  <div class="cover_card">
    <div class="con">
      <div class="card cursor-pointer" @click="clickHandle(item)">
        <div class="cover_box"><img class="cover_img" :src="item.case_cover" alt="封面图" /></div>
        <div class="center">
          <div class="center_top flex">
            <div class="aratar_box" v-if="item.avatar">
              <img class="avatar_img" :src="item.avatar" alt="" />
              <img class="flag_icon" v-if="item.official_type === 1" src="@/assets/images/home/blue_icon@2x.png" alt="" />
              <img class="flag_icon" v-if="item.official_type === 2" src="@/assets/images/home/yellow_icon@2x.png" alt="" />
            </div>
            <div class="right">
              <span class="name font-size-16 font-weight-500">{{ item.username }}</span>
              <div class="card_con-center flex-row-center">
                <!-- <div class="item1 text-align-center font-size-12 font-weight-400 flex">
                  <img src="@/assets/images/home/V@2x.png" alt="">
                  <span> {{item.dengji}}</span>
                </div>
                <span class="item2 text-align-center font-size-12 font-weight-400">{{item.tag}}</span> -->
                <tag-btn v-if="item.level_context" class="degnji" :config="{ type: 'red', text: item.level_context }"></tag-btn>
                <tag-btn v-if="item.marketer_role_context" :config="{ type: 'green', text: item.marketer_role_context }"></tag-btn>
              </div>
            </div>
          </div>
          <div class="center_bottom font-size-12 font-weight-400">
            {{ item.case_name }}
          </div>
        </div>
        <div class="card_bottom flex-row-between">
          <div class="card_bottom_left font-size-14 font-weight-400 flex">
            <img class="icon" v-if="item.flag === 0" src="@/assets/images/home/aixin_yellow_icon@2x.png" alt="" />
            <img class="icon" v-else src="@/assets/images/home/aixin@2x.png" alt="" />
            <span class="font-size-14 font-weight-400" :class="item.flag === 0 ? ' font-color-yellow' : ''"> {{ item.collect_num }}</span>
          </div>
          <div class="card_bottom_right font-size-14 font-weight-500">项目案值≈{{ item.price ? $util.formatNumber(item.price) : 0 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bkaltj",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  computed: {},
  created() {
    this.getInfo()
  },
  mounted() {},
  methods: {
    /*
     * @Description: 点击进入详情页面
     * @MethodAuthor:  liulian
     * @Date: 2022-06-13 09:24:58
     * @param: ''
     */
    clickHandle(item) {
      this.$router.push(`/zbkalDetail?id=${item.case_id}`)
    },
    getInfo() {},
  },
}
</script>

<style scoped lang="scss">
.cover_card {
  .con {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    .card {
      // min-height: 24rem;
      width: 15.625rem;
      background: #ffffff;
      box-shadow: 0rem 0.125rem 0.75rem 0.0625rem rgba(20, 20, 43, 0.08);
      border-radius: 2rem;
      opacity: 1;
      border: 0.0625rem solid #eff0f6;
      margin-bottom: 1.75rem;
      padding-bottom: 0.1875rem;
      .cover_box {
        // border: 1px solid #eee;
        width: 90%;
        // height: 13.75rem;
        margin: auto;
        border-radius: 0;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        overflow: hidden;
        text-align: center;
      }
      .cover_img {
        // width: 100%;
        // height: 13.75rem;
        width: 95%;
    height: 7.75rem;
        // border-top-left-radius: 2rem;
        // border-top-right-radius: 2rem;
        // object-fit:calc(1)
        margin-top: 20px;
          border: 1px solid #eee;
        
    object-fit: contain;

      }
      .center {
        width: 13.625rem;
        margin: 0.75rem auto;
        .aratar_box {
          margin-right: 0.625rem;
          display: flex;
          align-items: flex-end;
          .avatar_img {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 100%;
          }
          .flag_icon {
            width: 0.875rem;
            height: 0.875rem;
            margin-left: -0.625rem;
          }
        }
        .right {
          .name {
            font-family: PingFang SC-Medium, PingFang SC;
            color: #240f45;
            height: 20px;
            /* width: 20px; */
            display: block;
          }
          .card_con-center {
            margin-top: 0.5rem;
            .degnji {
              margin-right: 0.375rem;
            }
          }
        }
        .center_bottom {
          color: #5c6466;
          margin-top: 0.875rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          word-break: break-all;
          // height: 2rem;
          max-height: 2rem;
          overflow: hidden;
        }
      }
      .card_bottom {
        width: 13.625rem;
        margin: 1rem auto;
        border-top: 0.0625rem solid #f4f4f4;
        display: flex;
        align-items: center;
        .card_bottom_left {
          margin-top: 0.75rem;
          span {
            color: #a0a3bd;
          }
          .icon {
            width: 1.1875rem;
            height: 1.1875rem;
            margin-right: 0.375rem;
          }
        }
        .card_bottom_right {
          margin-top: 0.75rem;
          color: #2f2c42;
        }
      }
    }
  }
}
</style>
