<template>
    <!-- 关注动态 -->
    <div class="dynamic">
        <tabs :tabs="tabslist" @handleClick="handleClick"></tabs>
        <div class="tabsone" v-if="activeindex == 1">
            <data-list :isshow="false" :dataList="dataList"></data-list>
        </div>
        <div class="tabstwo" v-if="activeindex == 2">
            <follow :follow="followList" @upload="upload"></follow>
        </div>
        <div class="tabsthree" v-if="activeindex == 3">
            <fans :fans="fansList" @upload="uploadList"></fans>
        </div>
        <!-- 空状态 -->
        <div class="empty" v-show="emptyShow">
            <img src="@/assets/images/empty.png" alt="">
            <p>哎呀，这里什么也没有~快试试其他搜索词吧！</p>
        </div>
    </div>
</template>
<script>
import { followList, wechart } from "@/api/api"
// tabs 标签
import tabs from "../../../components/tabs/tabs.vue"
// 关注动态
import dataList from "./list/list.vue"
// 关注
import follow from "./follow/follow.vue"
import fans from "./fans/fans.vue"
import { json } from "body-parser"
export default {
    components: {
        tabs,
        dataList,
        follow,
        fans
    },
    data() {
        return {
            emptyShow: false,
            uid: "",
            activeindex: 1,
            tabslist: [
                {
                    id: "1",
                    name: "关注动态",
                },
                {
                    id: "2",
                    name: "关注",
                },
                {
                    id: "3",
                    name: "粉丝 ",
                }
            ],
            query: {
                page: 1,
                limit: 10,
                type: "follow",
                uid: "",
            },
            total: "",
            followList: [],// 关注列表
            fansList: [], //粉丝列表
            dataList: [], //关注动态列表
        }
    },
    mounted() {
        this.getList()
        var that_ = this
        that_.$nextTick(() => {
            that_.scroll.sctollBot("", data => {
                if (data == true) {
                    if (that_.activeindex == 1 && that_.dataList.length < this.total) {
                        that_.query.page += 1
                        let data = {
                            page: that_.query.page,
                            limit: that_.query.limit,
                            type: 0
                        }
                        wechart(data)
                            .then(res => {
                                if (res.code == 10000) {
                                    res.data.map(e => {
                                        that_.dataList.push(e)
                                    })
                                    that_.total = res.count
                                }
                            })
                    } else if (that_.activeindex == 2 || that_.activeindex == 3) {
                        that_.query.page += 1
                        that_.query.uid = JSON.parse(localStorage.getItem("userinfor")).id
                        followList(that_.query)
                            .then(res => {
                                if (res.code == 10000) {
                                    if (that_.activeindex == 1 || that_.activeindex == 2) {
                                        if (res.count > 0) {
                                            that_.tabslist[1].name = "关注" + "(" + res.count + ")"
                                            res.data.map(e => {
                                                that_.followList.push(e)
                                            })
                                        }
                                    } else if (that_.activeindex == 3) {
                                        if (res.count > 0) {
                                            that_.tabslist[2].name = "粉丝" + "(" + res.count + ")"
                                            res.data.map(e => {
                                                that_.fansList.push(e)
                                            })
                                        }
                                    }
                                    this.total = res.count
                                }
                            })
                    }
                }
            })

        })
    },
    methods: {
        upload() {
            this.query.page = 1
            this.geFollowList()
        },
        uploadList() {
            this.geFollowList()
        },
        // 获取我的动态列表
        getList() {
            let data = {
                page: this.query.page,
                limit: this.query.limit,
                type: 0
            }
            wechart(data)
                .then(res => {
                    if (res.code == 10000) {
                        var arr = []
                        for (let key in res.data) {
                            arr.push(res.data[key]);
                        }
                        console.log(arr);
                        if (arr.length > 0) {
                            this.emptyShow = false
                        } else {
                            this.emptyShow = true
                        }
                        this.dataList = arr
                        console.log(this.dataList)
                        this.total = res.count
                    }
                })
        },
        // 获取关注列表
        geFollowList() {
            this.query.uid = JSON.parse(localStorage.getItem("userinfor")).id
            followList(this.query)
                .then(res => {
                    if (res.code == 10000) {
                        if (res.data.length > 0) {
                            this.emptyShow = false
                        } else {
                            this.emptyShow = true
                        }
                        if (this.activeindex == 1 || this.activeindex == 2) {
                            if (res.count > 0) {
                                this.tabslist[1].name = "关注" + "(" + res.count + ")"
                            } else {
                                this.tabslist[1].name = "关注"
                            }
                            this.followList = res.data
                        } else if (this.activeindex == 3) {
                            if (res.count > 0) {
                                this.tabslist[2].name = "粉丝" + "(" + res.count + ")"
                            } else {
                                this.tabslist[2].name = "粉丝"
                            }
                            this.fansList = res.data
                        }
                    }
                })
        },
        handleClick(e) {
            this.activeindex = e
            this.query.page = 1
            if (e == 2) {
                this.query.type = "follow"
                this.geFollowList()
            } else if (e == 3) {
                this.query.type = "fans"
                this.geFollowList()
            } else {
                this.getList()
            }

        }
    }
}
</script>
<style  lang="scss" scoped>
.dynamic {
    padding: 1.5625rem 2rem;

    .empty {
        margin-top: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            width: 18.75rem;
            height: 12.5rem;
        }

        p {
            margin-top: 2rem;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #A0A3BD;
            line-height: 1.1875rem;
        }
    }

}
</style>