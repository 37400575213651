<!--
* @description 444
* @fileName swiper.vue
* @author liulian
* @date 2022/06/10 18:22:29
-->
<template>
  <div class="swiper-container">
    <div class="swiper-wrapper" ref="swiper">
      <div class="swiper-slide swiper-slide1 flex-column" :class="index === lists.length - 1 ? 'swiper-no-swiping' : ''" v-for="(item, index) in lists" :key="index">
        <div class="border_box">
          <div class="swiper_img">
            <img class="cursor-pointer" :src="item.img" alt="轮播图片" @click="goDetail(item)" />
          </div>
          <span class="font-size-16 font-weight-400 case_title cursor-pointer" @click="goDetail(item)">{{ item.case_name }}</span>
        </div>
        <!-- <span class="font-size-16 font-weight-400 case_title cursor-pointer"  @click="goDetail(item)">{{ $util.substr(item.case_name, 8) }}</span> -->
      </div>
      <div class="swiper-slide swiper-slide1"></div>
      <div class="swiper-slide swiper-slide1"></div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev" @click="prev"></div>
  </div>
</template>

<script>
import Swiper from "swiper" // 注意引入的是Swiper
import "swiper/css/swiper.min.css" // 注意这里的引入
import { recommendedListApi } from "@/api/api.js"
export default {
  name: "CarouselShow",
  mounted() {
    let _this = this
    new Swiper(".swiper-container", {
      slidesPerView: 8,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      calculateHeight: true,
      cssWidthAndHeight: true,
    })
  },
  data() {
    return {
      lists: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    goDetail(item) {
      this.$router.push(`/zbkalDetail?id=${item.case_id}`)
    },
    prev() {
      event.stopPropagation()
    },
    getList() {
      recommendedListApi().then((res) => {
        if (res.code === 10000) {
          res.data.length > 0 &&
            res.data.map((i) => {
              this.lists.push({ img: i.case_cover, case_id: i.case_id, case_name: i.case_name })
            })
        }

        console.log("this.lists ", this.lists)
      })
      // for (let i = 0; i < 8; i++) {
      //   this.lists.push({ img: "https://s3.bmp.ovh/imgs/2022/06/11/81807d73ca4d9fc3.jpg" })
      // }
    },
  },
}
</script>

<style scoped lang="scss">
.swiper-container {
  margin: auto;
  ::v-deep .swiper-wrapper {
    height: 230px;
  }
  ::v-deep .swiper-slide {
    height: 194px;
    // margin-right: 0.8rem;
    // margin-left: 1rem;
    border-radius: 0.625rem;
    width: 151.9px !important ;
    text-align: center;
    // padding-bottom: 0.125rem;
    padding-left: 1.1875rem;
    padding-right: 1.1875rem;
    align-items: center;
    img {
      width: 10.25rem;
      // border-radius: 0.625rem;
      height: 194px;
      object-fit: cover;
    }
    .border_box {
      width: 10.25rem;
      height: 194px;
      border: 1px solid #eee;
      border-radius: 0.625rem;

      .swiper_img {
        width: 90%;
        height: 85px;
        margin: 20px auto;
        border: 1px solid #eee;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .case_title {
      margin-top: 10px;
      height: 44px;
      display: -webkit-box; /*重点，不能用block等其他，将对象作为弹性伸缩盒子模型显示*/
      /*从上到下垂直排列子元素*/
      -webkit-box-orient: vertical;
      /*行数，超出三行隐藏,用省略号表示...*/
      -webkit-line-clamp: 2;
      line-clamp: 2;
      word-break: break-all;
      overflow: hidden;
      max-width: 90%;
      text-align: left;
      margin: auto;
    }
  }
  ::v-deep .swiper-button-prev {
    background-image: url("../../../assets/images/bkal/left@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 2.375rem !important;
    height: 2.375rem !important;
    left: 0rem;
  }
  ::v-deep .swiper-button-prev::after {
    display: none;
    width: 2.375rem;
    height: 2.375rem;
  }
  ::v-deep .swiper-button-next {
    background-image: url("../../../assets/images/bkal/right@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 2.375rem !important;
    height: 2.375rem !important;
    right: 0rem;
  }
  ::v-deep .swiper-button-next::after {
    display: none;
    width: 2.375rem;
    height: 2.375rem;
  }

  .swiper_btn {
    width: 20px;
    height: 20px;
    background-size: contain;
  }
  ::v-deep .swiper-button-prev.swiper-button-disabled {
    pointer-events: unset !important;
    opacity: 1;
  }
}
</style>
