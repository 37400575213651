<template>
    <div class="follow">
        <div class="followlist" v-for="item in fanslist" :key="item.id">
            <div class="followtle">
                <div class="img-box">
                    <img :src="item.creatorInfo.avatar" class="avatar" alt="" @click.stop="onUserinfor(item)">
                    <img src="@/assets/images/b.png" alt="" class="icon" v-if="item.creatorInfo.official_type == 1">
                    <img src="@/assets/images/y.png" alt="" class="icon" v-if="item.creatorInfo.official_type == 2">
                </div>
                <img src="" alt="">
                <div class="user">
                    <div class="user-top">
                        <p>{{ item.creatorInfo.username }}</p>
                        <div class="lei">
                            <div class="lei_one">
                                <img src="@/assets/images/userprofile/V.png" alt="" class="leiimg">
                                <span>{{ item.creatorInfo.level_context }}</span>
                            </div>
                            <div class="lei_two">
                                {{ item.creatorInfo.marketer_role_context }}
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        {{ item.creatorInfo.introduction }}
                    </div>
                </div>
            </div>
            <div class="operation">
                <!-- <el-button class="calcle font">取消关注</el-button> -->
                <!-- <el-button class="calcle font" >{{item.flag==true?'回关':'互相关注'}}</el-button> -->
                <el-button class="calcle font" @click.stop="follow(item)">{{ item.follow_status == 0 ? '回关' : '互相关注' }}
                </el-button>
                <el-button class="letter font">私信</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { followApi, cancleFollow ,cancelfollowApi} from "@/api/api"
export default {
    props: {
        fans: {
            type: Array / String,
            default: []
        }
    },
    data() {
        return {
            fanslist: [
                {
                    id: 1,
                    name: "姓名",
                    flag: false
                },
                {
                    id: 2,
                    name: "姓名",
                    flag: true
                }
            ]
        }
    },
    watch: {
        fans: {
            handler(data) {
                if (data) {
                    this.fanslist = data
                }
            }
        }
    },
    methods: {
        // 个人详情页
        onUserinfor(item) {
            this.$router.push({ path: "/yxyxsJg", query: { id: item.creatorInfo.id } })
        },
        follow(item) {
            let data = {
                follow_uid: item.creatorInfo.id
            }
           
            if (item.follow_status == 1) {
                // cancelfollowApi
                console.log("取消关注")
                // 取消关注
                cancleFollow(item.id)
                    .then(res => {
                        if (res.code == 10000) {
                            this.$message.success("取消关注！")
                            this.$emit("upload")
                        }
                    })
            } else {
                let data = {
                    follow_uid: item.creatorInfo.id
                }
                followApi(data)
                    .then(res => {
                        if (res.code == 10000) {
                            this.$message.success("关注成功！")
                            this.$emit("upload")
                        }
                    })
            }

        }
    }
}
</script>
<style lang="scss" scoped>
.follow {
    padding: 0 1rem;

    .followlist {
        padding: 1rem 0 2rem;
        margin-top: .8125rem;
        border-top: 1px solid #EFF0F6;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .followtle {
            display: flex;

            .img-box {
                position: relative;

                .avatar {
                    cursor: pointer;
                    border-radius: 50%;
                    display: block;
                    width: 5rem;
                    height: 5rem;
                }

                .icon {
                    display: block;
                    width: 1.3125rem;
                    height: 1.3125rem;
                    position: absolute;
                    bottom: 0px;
                    right: -0.625rem;
                }
            }

            .user {
                margin-left: 2.0625rem;

                .user-top {
                    display: flex;
                    align-items: center;

                    p {
                        font-size: 1.125rem;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #111029;
                        line-height: 1.3125rem;
                        margin-right: .5rem;
                    }

                    .lei {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: .5rem 0;

                        .lei_one {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 2.625rem;
                            height: 1.0625rem;
                            background: #FFEFF0;
                            border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                            opacity: 1;

                            .leiimg {
                                display: block;
                                width: .5rem !important;
                                height: .5625rem !important;
                            }

                            span {
                                font-size: .75rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #DC2B2B;
                                line-height: .875rem;
                            }
                        }

                        .lei_two {
                            display: flex;
                            align-items: center;
                            width: 2.625rem;
                            margin-left: .5625rem;
                            width: auto;
                            padding: 0 .3125rem;
                            height: 1.0625rem;
                            background: #DEF2E6;
                            border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                            opacity: 1;
                            font-size: .75rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                           color: #11845b;
                            line-height: .875rem;
                        }

                    }

                }

                .content {
                    width: 87%;
                    font-size: .875rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #57596C;
                    line-height: 1rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box; //使用自适应布局
                    -webkit-line-clamp: 2; //设置超出行数，要设置超出几行显示省略号就把这里改成几
                    -webkit-box-orient: vertical;
                }
            }
        }

    }

    .operation {
        display: flex;
        align-items: center;

        .calcle {
            width: auto;
            height: 3rem;
            background: #FFFFFF;
            border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
            color: #2F2C42;
            border: 1px solid #2F2C42;
        }

        .font {
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 100;
            line-height: 1.1875rem;
        }

        .letter {
            width: 4.5rem;
            height: 3rem;
            margin-left: 1rem;
            background: #2F2C42;
            border-radius: 3.125rem 3.125rem 3.125rem 3.125rem;
            color: #ffffff;
            border: 0px solid #8A9699;
        }
    }
}
</style>