<!--
* @description 分页组件
* @fileName pagination.vue
* @author isL
* @date 2021/02/01 15:49:06
-->
<template>
  <div class="pagination_box" :style="`text-align:${align}`">
    <el-pagination :background="background" :current-page.sync="currentPage" :page-size.sync="pageSize" :layout="layout" :page-sizes="pageSizes" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
  </div>
</template>

<script>
export default {
  name: "Pagination",
  components: {},
  // 接收父组件传过来的值
  props: {
    total: {
      required: true,
      type: Number,
    },
    align: {
      type: String,
      default: () => {
        return "right"
      },
    },
    page: {
      type: Number,
      default: 1, // 默认第一页
    },
    limit: {
      type: Number,
      default: 20, // 默认每页20条
    },
    pageSizes: {
      type: Array,
      // default: [10, 20, 30, 50]
      default: function () {
        return [1, 2, 3, 5] // 默认显示可选的每页多少条数据
      },
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
    background: {
      type: Boolean,
      default: true,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    // 当前页多少条数据并且赋值给父组件
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit("update:page", val)
      },
    },
    // 改变当前页几条数据得值赋值给父组件
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit("update:limit", val)
      },
    },
  },
  created() {
  },
  mounted() {},
  methods: {
//   每页显示的条数
    handleSizeChange(val) {
      this.$emit("pagination", { pageIndex: 1, pageSize: val })
    },
    // 切换每页
    handleCurrentChange(val) {
      this.$emit("pagination", { pageIndex: val, pageSize: this.pageSize })
    },
  },
}
</script>

<style scoped>
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-pager li {
 color: #7b7c85;
}
::v-deep .el-pager .active {
 color: #2F2C42 ;
}
</style>
