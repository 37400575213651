<template>
    <div id="creator">
        <div class="datalist">
            <ul>
                <li v-for="item in demandList" :key="item.id" @click="ondetail()">
                    <div class="tle_price">
                        <p>
                           需求标题需求标题需求标题需求标题需求标题需求标题需求标题...
                        </p>
                        <p>￥3000</p>
                    </div>
                    <div class="project">
                        <p>需求方：李芳 </p>
                        <p>项目编号：2654848484</p>
                    </div>
                    <div class="copy-icon">
                        <div class="icon iconone">
                            <img src="@/assets/images/userprofile/daiqueren.png" alt="">
                           <span>待确认</span>
                        </div>
                        <div class="icon icontwo" v-show="false">
                            <img src="@/assets/images/userprofile/zhixingzhong.png" alt="">
                            <span>执行中</span>
                        </div>
                        <div class="icon iconthree" v-show="false">
                            <img src="@/assets/images/userprofile/end.png" alt="">
                            <span>已结束</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        demand:{
            type:Array/String,
            default:[]
        }
    },
    data() {
        return {
            pathactive: 1,
            demandList:[],
            tablist: [
                {
                    id: 1,
                    name: "全部"
                }, {
                    id: 2,
                    name: "待执行"
                }, {
                    id: 3,
                    name: "执行中"
                }, {
                    id: 4,
                    name: "已结束"
                }
            ]

        }
    },
    watch:{
        demand:{
            handler(data){
                if(data){
                    this.demandList=data
                }
            }
        }
    },
    methods: {
        onTablist(e) {
            this.pathactive = e.id
        },
  
    // 进入详情
    ondetail(){
         this.$router.push("/grzx/demangdetail")
    }
    }
}
</script>
<style lang="scss" scoped>
#creator {
    
    .datalist {
        margin-top: 1rem;
        ul {
            li {
                cursor: pointer;
                padding: 2rem 0;
                border-top: 1px solid #EFF0F6;

                .tle_price {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: .5rem;

                    :nth-child(1) {
                        font-size: 1rem;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #111029;
                        line-height: 1.1875rem;
                    }

                    :nth-child(2) {
                        font-size: 1.25rem;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 800;
                        color: #FF6868 !important;
                        line-height: 1.4375rem;
                    }

                }

                .project {
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 300;
                    color: #57596C;
                    line-height: 1.1875rem;
                    margin-bottom: 2.75rem;

                    p {
                        margin: .25rem 0;
                    }
                }

                .copy-icon {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .copy {
                        cursor: pointer;
                        font-size: .875rem;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 100;
                        color: #3A71FF;
                        line-height: 1rem;
                    }

                    ::v-deep .icon {
                        width: auto;
                        margin-left: auto;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background: #EAF4FF;
                        border-radius: 2.1875rem 2.1875rem 2.1875rem 2.1875rem;
                        padding: .625rem;
                        font-size: 1rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        line-height: 1.1875rem;

                        span {
                            display: block;
                            margin-left: .3125rem;
                        }
                    }
                    .iconone {
                       color: #086CD9;
                       background: #EAF4FF;

                    }
                    .icontwo {
                        background: #DEF2E6;
                        color: #11845B;
                    }
                    .iconthree{
                        background: #F7F7FC;
                        color: #A0A3BD;
                    }
                }
            }
        }
    }
}
</style>