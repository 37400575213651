<!--
* @description
* @fileName index.vue
* @author *恋*
* @date 2022/01/12 18:26:45
-->
<template>
  <div class="maskDialog-container">
    <el-dialog :title="showTitle?title:''" :visible.sync="dialogVisible" :width="width" :center="center" :fullscreen="fullscreen" :before-close="cancel" :show-close="showClose" :top="top" :class="headerBorder?'header-border':''" ref="dialog">
      <!-- 弹框内容区域插槽 -->
      <template>
        <div :style="{'height':heightStyle}">
          <slot></slot>
        </div>
      </template>
      <!-- 弹窗的按钮区域插槽。可自定义， -->
      <span slot="footer" class="dialog-footer" v-if="showFooter">
        <slot name="footer-slot"> 
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="ok">确 定</el-button>
        </slot>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'MaskDialog',
  components: {},
  props: {
    // 是否显示标题
    showTitle: {
      type: Boolean,
      default: true
    },
    // dialog的标题
    title: {
      type: String,
      default: '提示'
    },
    // 弹窗的宽度
    width: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: null
    },
    // 弹框的高
    // 是否充满整个屏幕
    fullscreen: {
      type: Boolean,
      default: false
    },
    // 是否给弹框设置居中对齐
    center: {
      type: Boolean,
      default: false
    },
    // 是否显示关闭按钮
    showClose: {
      type: Boolean,
      default: true
    },
    // 头部下面是否显示边框
    headerBorder: {
      type: Boolean,
      default: false
    },
    // 弹框的显示
    show: {
      type: Boolean,
      default: false
    },
    // 弹框距离顶部的位置,如120px
    top: {
      type: String,
      default: '15vh'
    },
    // 是否现实底部按钮
    showFooter: {
      type: Boolean,
      default: true
    }

  },
  watch: {
    // 对父组件传入的show值进行监听并传给子组件的dialogVisible
    show: function (val) {
      this.dialogVisible = val
      console.log(val)
    },
    // 监听子组件的值并传给父组件对父组件，并更新dom
    dialogVisible: function (val) {
      this.$emit('update:show', val)
    },

  },
  data() {
    return {
      dialogVisible: false,
      heightStyle: this.height - 124 + 'px'
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      //  改变弹框的高度

    },
    ok() {
      this.dialogVisible = false
      this.$emit('isOk')
    },
    cancel() {
      this.dialogVisible = false
      this.$emit('cancel')
    }
  }
}
</script>

<style  lang="scss">
.maskDialog-container {
  .el-dialog__wrapper {
    background: rgba(36, 15, 69, 0.5);
    backdrop-filter: blur(8px);
  }
  .el-dialog__title {
    font-size: 1.25rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #111029;
  }
  .el-dialog {
    border-radius: 6px;
    padding-bottom: .75rem;
  }
  .header-border {
    .el-dialog__header {
      border-bottom: 1px solid #eee;
    }
  }
  .dialog-footer {
    .el-button {
      width: 150px;
    }
    .el-button:nth-child(2) {
      width: 150px;
      margin-left: 50px;
    }
  }
}
</style>