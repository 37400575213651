<template>
    <div style="margin-top: 4rem;">
        <div class="datalist" v-for="(item, index) in datalist" :key="index" @click="onDetail(item)">
            <div class="userinfo">
                <div class="userinfo-left">
                    <div class="img-box">
                        <img class="avatar" :src="item.creatorInfo.avatar" alt="">
                        <img src="@/assets/images/b.png" alt="" class="icon" v-if="item.creatorInfo.official_type == 1">
                        <img src="@/assets/images/y.png" alt="" class="icon" v-if="item.creatorInfo.official_type == 2">
                    </div>

                    <div class="lei">
                        <p>{{ item.creatorInfo.username }}</p>
                        <div class="lei_one">
                            <img src="@/assets/images/userprofile/V.png" alt="" class="leiimg">
                            <span>{{ item.creatorInfo.level_context }}</span>
                        </div>
                        <div class="lei_two">
                            {{ item.creatorInfo.marketer_role_context }}
                        </div>
                    </div>
                </div>
                <div class="userinfo-right">
                    <div class="edit" @click.stop="edits(index)">
                        <img src="@/assets/images/userprofile/edit.png" alt="">
                        <div class="edit-box" v-if="editshow == true && editIndex == index">
                            <p @click="onEdit(item)">编辑</p>
                            <p @click="onDelete(item)">删除</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="content-main">
                    <div class="content-tle">
                        <p>{{ item.title }}</p>
                    </div>
                </div>
                <div class="content-bot">
                    <p>{{ item.created_at }}</p>
                    <div class="forward">
                        <div class="comment">
                            <!-- <img src="@/assets/images/userprofile/forward.png" alt=""> -->
                            <share @onMove="onMoves(item)" :QRCodeMsg="qrurl"></share>
                        </div>
                        <div class="comment">
                             <img src="@/assets/images/userprofile/xx@2x.png" alt="">
                            <span>{{item.commentTotal}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 删除确认弹窗 -->
        <dialogs :show.sync="showDialog" width="690px" :center="true" title="删除">
            <p>是否删除这篇文章？</p>
            <template slot="footer-slot">
                <el-button class="cancle" @click="cancle">
                    取消
                </el-button>
                <el-button class="determine" @click="determine">
                    确认
                </el-button>
            </template>
        </dialogs>
    </div>
</template>
<script>
import { delArticle } from "@/api/api.js"
import share from "@/components/share/share.vue"
import dialogs from "@/components/common/dialog-cpn.vue"
export default {
    components: {
        share,
        dialogs
    },
    props: {
        article: {
            type: Array / String,
            default: ""
        }
    },
    data() {
        return {
            deleteId: "",
            showDialog: false,//删除弹窗
            editshow: false,
            qrurl: "",
            editlist: [
                {
                    name: "编辑",
                    value: 1
                },
                {
                    name: "删除",
                    value: 2
                }
            ],
            datalist: []
        }
    },
    watch: {
        article: {
            handler(data) {
                if (data) {
                    this.datalist = data
                }
            },
        }
    },
    mounted(){
         document.addEventListener("click", this.handleClose);

    },

    methods: {
        handleClose(){
            if(this.editshow){
                this.editshow=false
            }
            
        },
        onDetail(item) {
            this.$router.push({ path: '/wdwz/articledetail', query: { id: item.id } })
        },
        onMoves(item) {
            console.log(item)
            this.qrurl = item.codeurl
        },
        edits(index) {
            console.log(index)
            this.editIndex = index
            if (this.editshow == false) {
                this.editshow = true
            } else {
                this.editshow = false
            }
        },
        // 编辑
        onEdit(item) {
            this.$emit("editArticle", item)
        },
        // 删除
        onDelete(item) {
            this.deleteId = item.id
            this.showDialog = true
        },
        // 取消删除
        cancle() {
            this.showDialog = false
        },
        // 确认删除
        determine() {
            delArticle(this.deleteId)
                .then(res => {
                    if (res.code == 10000) {
                        this.$message.success("删除成功！")
                        this.showDialog = false
                        this.$emit("UploadList")
                    }
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.el-dialog {
    .el-dialog__body {
        p {
            text-align: center;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 300;
            color: #5C6466;
            line-height: 1.1875rem;
        }

    }

    .el-dialog__footer {
        .dialog-footer {

            .cancle {
                width: 4.25rem;
                height: 2.375rem;
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #111029;
                line-height: 1rem;
                background: #FFFFFF;
                border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                border: 1px solid #111029;
            }

            .determine {
                margin-left: 11.375rem;
                width: 4.25rem;
                height: 2.375rem;
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 1rem;
                background: #111029;
                border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                border: 1px solid #111029;
            }
        }
    }
}

.datalist {
    margin-top: 1rem;
    padding: 2rem 0;
    border-top: 1px solid #EFF0F6;
    cursor: pointer;

    .userinfo {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .userinfo-left {
            display: flex;
            align-items: center;

            .img-box {
                position: relative;

                .avatar {
                    border-radius: 50%;
                    display: block;
                    width: 2.5rem;
                    height: 2.5rem;
                    opacity: 1;
                }
                .icon {
                    display: block;
                    width: .8125rem;
                    height:.8125rem;
                    position: absolute;
                    bottom: 0px;
                    right: -0.3125rem;
                }
            }


            .lei {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                margin: .5rem 0;

                p {
                    font-size: 1.125rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1.3125rem;
                    margin: 0 .5rem;
                }

                .lei_one {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 2.625rem;
                    height: 1.0625rem;
                    background: #FFEFF0;
                    border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                    opacity: 1;

                    .leiimg {
                        display: block;
                        width: .5rem !important;
                        height: .5625rem !important;
                    }

                    span {
                        font-size: .75rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #DC2B2B;
                        line-height: .875rem;
                    }
                }

                .lei_two {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: .5625rem;
                    width: auto;
                    padding: 0 .3125rem;
                    height: 1.0625rem;
                    background: #DEF2E6;
                    border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                    opacity: 1;
                    font-size: .75rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #11845B;
                    line-height: .875rem;

                }
            }
        }


        .userinfo-right {
            display: flex;

            align-items: center;

            .edit {
                position: relative;
                margin-left: 2rem;
                cursor: pointer;

                img {
                    display: block;
                    width: 1.25rem;
                    height: 1.25rem;
                }

                .edit-box {

                    position: absolute;
                    padding: .625rem 1rem;
                    left: -3.125rem;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 6px 1px rgba(20, 20, 43, 0.0600);
                    border-radius: .625rem;
                    opacity: 1;
                    border: 1px solid #EFF0F6;

                    p {
                        padding: .375rem 0;
                        font-size: .875rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #57596C;
                        line-height: 1rem;
                    }
                }
            }


        }

    }

    .content {
        padding-left: 3.125rem;

        .content-main {
            display: flex;
            align-items: center;

            .content-tle {
                p {
                    font-size: 1rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #111029;
                    line-height: 1.1875rem;
                    margin: 1rem 0;
                }



            }


        }

        .content-bot {
            margin-top: .875rem;
            margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-size: .875rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #A0A3BD;
                line-height: 1rem;
                word-break: break-all;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .forward {
                display: flex;
                align-items: center;

                .comment {
                    display: flex;
                    align-content: center;
                    img {
                        display: block;
                        width: 1.25rem;
                        height: 1.25rem;
                    }
                    span {
                        display: inline-block;
                        margin-left: .3125rem;
                        font-size: .875rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #A0A3BD;
                        line-height: 1rem;
                    }

                    &:nth-child(1) {
                        margin-right: 4.5625rem;
                    }

                    ::v-deep.share-box {
                        right: 0;
                    }
                }
            }
        }
    }
}

.el-dropdown-menu {

    padding: 1rem 0;
    background: #FFFFFF;
    box-shadow: 0px .125rem .375rem .0625rem rgba(20, 20, 43, 0.06);
    border-radius: .625rem .625rem .625rem .625rem;

    .el-dropdown-menu__item {
        font-size: .875rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 100;
        color: #57596C;
        line-height: 1rem;
        margin: .375rem 0;
    }
}
</style>