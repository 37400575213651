<template>
    <div class="setUp">
        <tabs :tabs="tabslist" @handleClick="handleClick"></tabs>
        <!-- 基本设置 -->
        <settings v-if="tabindex == 1" :userinfor="userinfor" @upload="upload"></settings>
        <!-- 安全设置 -->
        <security v-if="tabindex == 2" :security="userinfor.user_data" @upload="upsecurity"></security>
        <!-- 钱包 -->
        <apply v-if="tabindex == 3" :balance="balance" @tabs="tabs" :infor="userinfor" :wallectList="wallectList"></apply>
    </div>
</template>
<script>
import { setUp, wallet, walletDetails, userHomePage } from "@/api/api"
import tabs from "@/components/tabs/tabs.vue"
import settings from "./basicsettings/settings.vue"
// 安全设置/
import security from "./security/security.vue"
// 钱包
import apply from "./apply/apply.vue"
export default {
    components: {
        tabs,
        settings,
        security,
        apply
    },
    data() {
        return {
            balance: "",
            userinfor: {},//个人基本信息
            tabindex: 1,
            tabslist: [
                {
                    id: "1",
                    name: "基本设置"
                }, {
                    id: '2',
                    name: "安全设置"
                },
                {
                    id: '3',
                    name: "我的钱包"
                }
            ],
            wallectList: [],//钱包明细列表
            total:"",
            // 账单分页
            query: {
                page: 1,
                limit: 10,
            },
            type: 1,//1支出,2收入
        }
    },
   
    mounted() {
        this.getSetUp()
        this.$nextTick(()=>{
              this.scroll.sctollBot("", (data) => {
                if(data==true){
                    if(this.wallectList.length<this.total){
                        this.query.page +=1
                         walletDetails(this.type, this.query).then(res=>{
                            if(res.code==10000){
                                res.data.map(e=>{
                                     this.wallectList.push(e)
                                })
                                this.total=res.count
                            }
                         })

                    }
                }
              })
        })
    },
    methods: {
        upload() {
            this.getSetUp()
            this.getHomePage(this.userinfor.user_data.id)
            
        },
        upsecurity(){
            this.getSetUp()
        },
        tabs(id) {
            this.type = id
            this.WallectDetail()

        },
        getSetUp() {
            setUp()
                .then(res => {
                    if (res.code == 10000) {
                        this.userinfor = res.data
                    }
                })
        },
        // 获取用户主页信息
        getHomePage(id) {
            console.log(id)
            userHomePage(id)
                .then(res => {
                    if (res.code == 10000) {
                        var userinfor = {}
                        userinfor.infor = res.data.user_info
                        var area = []
                        var social = []
                        var industry = []
                        res.data.tag.map(e => {
                            console.log(e)
                            if (e.top_parent_bind == 'area') {
                                area.push(e)
                            } else if (e.top_parent_bind == "social") {
                                social.push(e)
                            } else {
                                industry.push(e)
                            }
                        })
                        userinfor.area = area
                        userinfor.social = social
                        userinfor.industry = industry
                        this.userinfor=userinfor
                        this.$store.commit('getHomePage', userinfor)
                        // this.$store.state.userInfo.avatar=userinfor.infor.avatar
                        this.$store.commit('SET_USERINFO',userinfor.infor)
                    }
                })
        },
        // 我的钱包
        getWallect() {
            wallet()
                .then(res => {
                    if (res.code == 10000) {
                        this.balance = res.data.balance
                    }
                })
        },
        // 账单明细
        WallectDetail() {
            walletDetails(this.type, this.query)
                .then(res => {
                    if (res.code == 10000) {
                        this.wallectList = res.data
                        this.total=res.count
                    }
                })
        },
        handleClick(e) {
            this.tabindex = e
            if (e == 3) {
                this.getWallect()
                this.WallectDetail()
            }
            this.getSetUp()

        }
    }
}
</script>
<style  lang="scss" scoped>
.setUp {
    padding: 1.5625rem 2rem;
}
</style>