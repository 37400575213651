<template>
    <div class="Collection">
        <tabs :tabs="tabslist" @handleClick="handleClick"></tabs>
        <!-- 列表数据 -->
        <cases :tabindex="tabindex" :datalist="datalist" @upadte="upadte"></cases>
        <!-- 空状态 -->
        <div class="empty" v-show="emptyShow">
            <img src="@/assets/images/empty.png" alt="">
            <p>哎呀，这里什么也没有~快试试其他搜索词吧！</p>
        </div>
    </div>
</template>
<script>
import { collectionList } from "@/api/api"
import tabs from "../../../components/tabs/tabs.vue"
import cases from "./anli/anli.vue"
export default {
    components: {
        tabs,
        cases
    },
    data() {
        return {
            tabindex: 1,
            emptyShow: false,//空状态
            tabslist: [
                {
                    id: "1",
                    name: "案例"
                },
                {
                    id: "2",
                    name: "文章"
                },
            ],
            datalist: [],
            query: {
                module: "case",
                page: 1,
                limit: 10
            },
            total: "",
        }
    },
    mounted() {
        var that_ = this
        this.getCollection()
        // 判断是否触底
        this.$nextTick(() => {
            that_.scroll.sctollBot('', e => {
                if (e == true) {
                    if (that_.datalist.length < that_.total) {
                        this.query.page += 1
                        collectionList(that_.query)
                            .then(res => {
                                if (res.code == 10000) {
                                    if (res.data.length > 0) {
                                        res.data.map(e => {
                                            that_.datalist.push(e)
                                            that_.total = res.count
                                        })
                                    }

                                }
                            })
                    }
                }
            })
        })
    },
    methods: {
        upadte() {
            this.query.page = 1
            this.getCollection()
        },
        // 收藏列表
        getCollection() {
            collectionList(this.query)
                .then(res => {
                    if (res.code == 10000) {
                        if (res.data.length > 0) {
                            this.emptyShow = false
                        } else {
                            this.emptyShow = true
                        }
                        this.total = res.count
                        this.datalist = res.data
                    }
                })
        },
        handleClick(e) {
            this.query.page=1
            this.tabindex = e
            if (e == 1) {
                this.query.module = 'case'
            } else {
                this.query.module = "article"
            }
            this.getCollection()
        }
    }
}
</script>
<style  lang="scss" scoped>
.Collection {
    padding: 1.5625rem 2rem;

    .empty {
        margin-top: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            width: 18.75rem;
            height: 12.5rem;
        }

        p {
            margin-top: 2rem;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #A0A3BD;
            line-height: 1.1875rem;
        }
    }


}
</style>