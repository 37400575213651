<!--
* @description 登录页面
* @fileName login.vue
* @author liulian
* @date 2022/06/10 09:53:33
!-->
<template>
  <div class="login">
    <div class="login_box" :class="tabValue === 1 ? '' : 'login_box2'">
      <div class="con_box">
        <!-- tab切换个人注册，官方注册 -->
        <div class="menu flex flex-row-center font-size-20 font-weight-400">
          <span class="cursor-pointer" :class="tabValue === 1 ? 'isAceive' : ''" @click="changeTabHandle(1)">账密登录</span>
          <span class="l_line"></span>
          <span class="cursor-pointer" :class="tabValue === 2 ? 'isAceive' : ''" @click="changeTabHandle(2)">免密登录</span>
        </div>
        <!-- 个人注册表单 -->
        <el-form v-if="tabValue === 1" class="form_box" :model="form1" status-icon :rules="rules1" ref="form1">
          <el-form-item>
            <el-input placeholder="手机号/邮箱" v-model="phoneOrMail1" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="输入6-10位密码" type="password" v-model="form1.password" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <div class="tips flex-row-between">
              <div class="link font-size-16 font-weight-400">
                还没有账号？<span class="font-color-blue cursor-pointer" @click="registerHandle">去注册</span>
              </div>
              <div class="right font-size-16 font-weight-400 font-color-blue cursor-pointer"
                @click="forgotPasswordHandle">
                忘记密码
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="submitForm1('form1')">登录</el-button>
          </el-form-item>
        </el-form>

        <!-- 官方注册 -->
        <el-form v-if="tabValue === 2" class="form_box form_box2" :model="form2" status-icon :rules="rules2"
          ref="form2">
          <el-form-item>
            <el-input placeholder="手机号/邮箱" v-model="phoneOrMail2" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <div class="code_box flex">
              <el-input placeholder="输入4位验证码" v-model="form2.code" clearable></el-input>
              <span class="code_btn text-align-center font-size-16 font-weight-400"
                :class="codeText !== '获取验证码' ? 'gray-color' : ''" @click="codeText === '获取验证码' ? sendCode() : null">{{
                    codeText
                }}</span>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="tips flex-row-between">
              <div class="link font-size-16 font-weight-400">
                还没有账号？<span class="font-color-blue cursor-pointer" @click="registerHandle">去注册</span>
              </div>
              <div class="right font-size-16 font-weight-400 font-color-blue cursor-pointer"
                @click="forgotPasswordHandle">
                忘记密码
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="submitForm2('form1')">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 信息 -->
    <p class="bottom-tip font-size-12 font-weight-400F text-align-center">
      ©1999-2022北京创新乐知网络技术有限公司
    </p>
  </div>
</template>

<script>
import {
  mailCode,
  loginPwdApi,
  loginCodeApi,
  getUserApi,
  getUig,
  updataIm
} from "@/api/api.js";
import { isPhone, isEmail, isPassword } from "@/utils/validate.js";
import { smsLoginCodeApi, mailLoginCodeApi } from "@/api/javaApi.js";
export default {
  name: "login",
  components: {},
  data() {
    return {
      tabValue: 1,
      form1: {
        mobile: null,
        mail: null,
        password: "",//liulian552034
      }, // 个人注册表单
      form2: {
        mobile: "",
        code: "",
      }, // 官方注册
      rules1: {}, // 个人注册表单
      rules2: {}, // 官方注册
      phoneOrMail1: "",//2313673906@qq.com
      phoneOrMail2: "",
      options: [
        {
          label: "机构",
          value: "机构",
        },
        {
          label: "品牌",
          value: "品牌",
        },
      ],
      codeText: "获取验证码",
    };
  },
  computed: {},
  created() { },
  mounted() { },
  methods: {
    submitForm1() {
      let c = this.checkData();
      if (!c) {
        loginPwdApi(this.form1).then((res) => {
          if (res.code === 10000) {
            this.$store.commit("SET_TOKEN", res.data.token);
            setTimeout(() => {
              this.getUserInfo();
              this.getuisg()
            }, 1000);
          }
        });
      }
    },
    // 同步个人信息
    updataIm(){
      updataIm().then(res=>{
        if(res.code==10000){
          console.log(res)
        }
      })
    },
    // 获取用户isg
    getuisg() {
      getUig()
        .then(res => {
          if (res.code == 10000) {
            let promise = this.$tim.login({ userID: res.data.user_id.toString(), userSig: res.data.user_sig });
            promise.then(function (imResponse) {
              console.log(imResponse)
              console.log(imResponse.data); // 登录成功
              if (imResponse.data.repeatLogin === true) {
                // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
                console.log(imResponse.data.errorInfo);
                // 同步个人信息
                this.updataIm()
              }
            }).catch(function (imError) {
              console.warn('login error:', imError); // 登录失败的相关信息
            });
          }
        })
    },
    submitForm2() {
      let c = this.checkData2();
      if (!c) {
        loginCodeApi(this.form2).then((res) => {
          if (res.code === 10000) {
            this.$store.commit("SET_TOKEN", res.data.token);
            setTimeout(() => {
              this.getUserInfo();
               this.getuisg()
            }, 1000);
          }
        });
      }
    },


    /*
     * @Description: 获取用户信息
     * @MethodAuthor:  liulian
     * @Date: 2022-06-28 11:12:10
     * @param: ''
     */
    getUserInfo() {
      getUserApi().then((res) => {
        if (res.code === 10000) {
          this.$store.commit("SET_USERINFO", res.data);

          this.$router.push("/");
        }
      });
    },
    /*
     * @Description: 格式校验
     * @MethodAuthor:  liulian
     * @Date: 2022-06-28 10:27:37
     * @param: ''
     */
    checkData() {
      if (!this.phoneOrMail1) {
        this.$message.error("手机或邮箱不能为空");
        return true;
      }
      if (this.phoneOrMail1) {
        this.form1.mobile = null;
        this.form1.mail = null;
        let phone = isPhone(this.phoneOrMail1);
        let mail = isEmail(this.phoneOrMail1);
        if (phone) {
          this.form1.mobile = this.phoneOrMail1;
        } else if (mail) {
          this.form1.mail = this.phoneOrMail1;
        } else {
          this.$message.error("手机或邮箱格式不正确");
          return true;
        }
      }
      if (!this.form1.password) {
        this.$message.error("密码不能为空");
        return true;
      }
      if (this.form1.password) {
        let check = isPassword(this.form1.password);
        // if (!check) {
        //   this.$message.error("密码大于6个字符，包含字母加数字");
        //   return true;
        // }
      }
    },
    checkData2() {
      if (!this.phoneOrMail2) {
        this.$message.error("手机或邮箱不能为空");
        return true;
      }
      if (this.phoneOrMail2) {
        this.form2.mobile = null;
        this.form2.mail = null;
        let phone = isPhone(this.phoneOrMail2);
        let mail = isEmail(this.phoneOrMail2);
        if (phone) {
          this.form2.mobile = this.phoneOrMail2;
        } else if (mail) {
          this.form2.mail = this.phoneOrMail2;
        } else {
          this.$message.error("手机或邮箱格式不正确");
          return true;
        }
      }
      if (!this.form2.code) {
        this.$message.error("验证码不能为空");
        return true;
      }
    },
    sendCode() {
      if (!this.phoneOrMail2) {
        this.$message.error("手机号或邮箱不能为空");
        return;
      } else {
        // 校验是手机号，还是验证码
        let c = this.checkPhoneOrEmail(this.phoneOrMail2, 2);
        if (c) {
          this.codeText = "验证码已发送";
          let num = 60;
          let timer = setInterval((res) => {
            num = num - 1;
            this.codeText = `${num}s后重新获取`;
            if (num === 0) {
              clearInterval(timer);
              this.codeText = "获取验证码";
            }
          }, 1000);
        }
        if (this.form2.mobile) {
          smsLoginCodeApi({ mobile: this.form2.mobile }).then((res) => {
            if (!(res.code === 10000)) {
              this.$message.error(res.msg);
            }
          });
        } else if (this.form2.mail) {
          mailLoginCodeApi({ mail: this.form2.mail }).then((res) => {
            if (!(res.code === 10000)) {
              this.$message.error(res.msg);
            }
          });
        }
      }
    },
    checkPhoneOrEmail(str, key) {
      this.form1.mobile = null;
      this.form1.mail = null;

      let phone = isPhone(str);
      let mail = isEmail(str);
      if (key === 1) {
        if (phone) {
          this.form1.mobile = str;
        }
        if (mail) {
          this.form1.mail = str;
        }
      } else {
        if (phone) {
          this.form2.mobile = str;
        }
        if (mail) {
          this.form2.mail = str;
        }
      }
      if (phone || mail) {
        return true;
      } else {
        this.$message.error("手机或邮箱格式不正确");
      }
    },
    forgotPasswordHandle() {
      this.$router.push("/forgotPassword");
    },
    registerHandle() {
      this.$router.push("/register");
    },
    changeTabHandle(key) {
      this.tabValue = key;
      if (this.tabValue === 1) {
        this.form2 = {};
        this.phoneOrMail2 = "";
      } else {
        this.form1 = {};
        this.phoneOrMail1 = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  width: 100%;
  background-image: url("../../assets/images/login/login_bg@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 70vh;

  .login_box {
    margin-top: 4.6rem;
    margin-right: 6.25rem;
    width: 31.75rem;
    height: 23.25rem;
    float: right;
    background-color: #fff;
    box-shadow: 0px 0px 30px 1px rgba(237, 235, 245, 0.7);
    border-radius: 0.625rem;
    opacity: 1;
    border: 1px solid #f4f4f4;
    margin-bottom: 1rem;

    ::v-deep .el-select {
      width: 100%;
    }

    .con_box {
      width: 23.75rem;
      margin: auto;

      .menu {
        margin-top: 1.5rem;
        margin-bottom: 2.375rem;

        span {
          color: #57596c;
        }

        .l_line {
          height: 10px;
          width: 0px;
          background: #57596c;
          opacity: 1;
          border: 1px solid #240f45;
          margin-left: 2rem;
          margin-right: 2rem;
        }

        .isAceive {
          font-weight: 500;
          color: #111029;
        }
      }

      .form_box {
        ::v-deep .el-input__inner {
          box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
          border-radius: 10px 10px 10px 10px;
          height: 42px;
        }

        .code_box {
          .code_btn {
            height: 2.625rem;
            background: #ffffff;
            box-shadow: 0rem 0.25rem 0.625rem 0.0625rem rgba(58, 114, 255, 0.06);
            border-radius: 0.625rem;
            opacity: 1;
            border: 1px solid #eff0f6;
            white-space: nowrap;
            padding-left: 1rem;
            padding-right: 1rem;
            cursor: pointer;
          }

          .gray-color {
            color: #a0a3bd;
          }

          ::v-deep .el-input__inner {
            width: 252px;
            height: 42px;
            background: #ffffff;
            box-shadow: 0px 4px 10px 1px rgba(58, 114, 255, 0.06);
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            border: 1px solid #eff0f6;
          }

          ::v-deep span {
            right: 1.25rem;
          }
        }

        .link {
          height: 1.375rem;
          color: #240f45;
          margin-top: 0.375rem;
        }

        ::v-deep.el-form-item {
          margin-top: -0.375rem;

          height: 2.625rem;
        }

        ::v-deep .el-button--primary {
          width: 100%;
          border-radius: 0.625rem;
          font-size: 0.875rem;
          font-weight: 500;
          height: 2.375rem;
          margin-top: 1.5625rem;
        }
      }
    }
  }

  .bottom-tip {
    color: #969696;
    clear: both;
    position: absolute;
    bottom: 9.3125rem;
    left: 40%;
  }

  .form_box2 {
    .upload_item {
      margin-bottom: 3rem;
    }

    .link {
      height: 1.375rem;
      color: #240f45;
    }
  }
}
</style>
