<template>
    <div id="apply">
        <div class="apply-price">
            <div class="price">
                <span v-cloak>￥{{ balance }}</span>
                <p @click="onApply">申请提现</p>
            </div>
            <span>提示：平台将收取3%服务费用</span>
        </div>
        <div class="appay-table">
            <ul>
                <li v-for="item in tabs" :key="item.id" @click="ontabs(item)"
                    :class="pathAtive == item.id ? 'isActive' : ''">
                    {{ item.name }}
                </li>
            </ul>
            <el-table :data="tableData" style="width: 100%" v-loading="loading">
                <el-table-column prop="trade_time" label="时间" width="auto">
                </el-table-column>
                <el-table-column prop="cate_context" label="名称" width="auto">
                </el-table-column>
                <el-table-column :label="pathAtive == 1 ? '支出金额' : '到账金额'">
                    <template slot-scope="scope">
                        <div :class="pathAtive == 1 ? 'iscolor' : ''">{{ scope.row.trade_money }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="service_charge" label="平台服务费" v-if="pathAtive == 2">
                </el-table-column>
            </el-table>
        </div>
        <applydialog :show.sync="dialogVisible" width="690px" :center="true" title="申请提现" :showFooter="false">
            <el-form :model="ruleForm2" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                v-loading="loadings" element-loading-text="正在提现中" class="demo-ruleForm2">
                <el-form-item prop="old">
                    <el-input v-model="ruleForm2.old" placeholder="请输入提现金额"></el-input>
                </el-form-item>
                <p>提示：确认提现支付宝账号为{{ alipay_account }}。</p>
                <el-form-item id="editbtn">

                    <el-button @click="resetForm('ruleForm')">取消</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>

                </el-form-item>
            </el-form>
        </applydialog>
    </div>
</template>
<script>
import { withdrawal } from "@/api/api"
import applydialog from "../../../../components/common/dialog-cpn.vue"
export default {
    components: {
        applydialog
    },
    props: {
        balance: {
            type: String,
            default: ""
        },
        wallectList: {
            type: Array,
            default: []
        },
        infor: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            loadings: false,
            loading: false,
            pathAtive: 1,
            tabs: [
                {
                    id: 1,
                    name: "支出明细"
                },
                {
                    id: 2,
                    name: "到账明细"
                }
            ],
            ruleForm2: {},
            rules: {},
            codeText1: "获取验证码",
            dialogVisible: false,
            tableData: [],
            alipay_account: "",//支付宝账号
        }
    },
    watch: {
        wallectList: {
            handler(data) {
                if (data) {
                    this.tableData = data
                    this.loading = false
                }
            },
            deep: true,
            immediate: true
        },
        infor: {
            handler(data) {
                if (data) {
                    this.alipay_account = data.user_data.alipay_account
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        this.loading = true
    },
    methods: {
        ontabs(e) {
            this.loading = true
            this.pathAtive = e.id
            setTimeout(() => {
                this.$emit("tabs", e.id)
            }, 500)
        },
        // 申请提现
        onApply(e) {
            if (this.balance == '0.00') return
            if (this.infor.user_data.alipay_account == '') {
                this.$message.warning("请先添加支付宝账号！");
                return
            }
            this.dialogVisible = true
        },
        submitForm(formName) {
            this.loadings = true
            let obj = { money: this.ruleForm2.old }
            withdrawal(obj).then(res => {
                if (res.code == 10000) {
                    this.loadings = false
                    this.dialogVisible = false
                } else {
                    this.loadings = false
                }
            })
        },
        resetForm(formName) {
            this.dialogVisible = false
            this.$refs[formName].resetFields();
        },
    }
}
</script>
<style lang="scss"  >
#apply {
    padding: 1.5625rem 0rem;

    .apply-price {
        .price {
            display: flex;
            align-items: flex-end;
            font-size: 1rem;
            font-family: PingFang SC-Medium, PingFang SC;
            line-height: 1.1875rem;
            margin-bottom: 1.1875rem;

            span {
                font-weight: 500;
                color: #111029;
            }

            p {
                cursor: pointer;
                margin-left: 2rem;
                font-weight: 100;
                color: #3A71FF;

            }
        }

        span {
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 300;
            color: #A0A3BD;
            line-height: 1.1875rem;
        }
    }

    .appay-table {
        margin-top: 2rem;

        ul {
            display: flex;
            align-items: center;
            padding: .9375rem 0;
            border-top: 1px solid #EFF0F6;
            border-bottom: 1px solid #EFF0F6;

            li {
                margin: 0 1.875rem;
                cursor: pointer;
                font-size: 1.125rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #111029;
                line-height: 1.3125rem;

                &:first-child {
                    margin-left: 0;
                }
            }

            .isActive {
                border-bottom: 0.25rem solid #2F2C42;
            }

        }

        .el-table__header-wrapper {
            .cell {
                text-align: center;
                font-size: 1.125rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 100;
                color: #2F2C42;
                line-height: 1.3125rem;
            }
        }

        .el-table__body-wrapper {
            .cell {
                text-align: center;
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #57596C;
                line-height: 1.1875rem;

            }

            .el-table__cell {
                border: none;
            }

            .iscolor {
                color: #FF6868;
            }
        }

    }

    .el-dialog {
        width: 34.875rem;
        background: #FFFFFF;
        border-radius: .625rem .625rem .625rem .625rem;

        .el-dialog__close {
            font-size: 1.875rem;
            font-weight: bold;
        }

        .el-form-item__content {
            margin-left: 0 !important;

            .el-input {
                width: 23.75rem;
                height: 2.625rem;
                background: #FFFFFF;
                box-shadow: 0px .25rem .625rem .0625rem rgba(58, 114, 255, 0.06);
                border-radius: .625rem .625rem .625rem .625rem;

                .el-input__inner {
                    height: 100%;
                    font-size: .875rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 100;
                    color: #606266;
                    line-height: 1rem;

                    &::placeholder {
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 100;
                        color: #A0A3BD
                    }

                    &::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #A0A3BD
                    }

                    &:-moz-placeholder {
                        /* Mozilla Firefox 4 to 18 适配火狐 */
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #A0A3BD;
                        /* 设置opacity  解决firefox不生效问题 */
                        opacity: 1;
                    }

                    &::-moz-placeholder {
                        /* Mozilla Firefox 19+ 适配火狐 */
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #A0A3BD;
                        opacity: 1;
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10+  适配ie*/
                        font-size: 14px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #A0A3BD
                    }
                }
            }
        }

        #editbtn {

            .el-form-item__content {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .el-button {
                    width: 4.25rem;
                    height: 2.375rem;
                    background: #FFFFFF;
                    border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                    opacity: 1;
                    border: 1px solid #111029;

                    &:nth-child(2) {
                        background: #111029;
                    }
                }
            }
        }

        .demo-ruleForm2 {
            text-align: center;

            .verification {
                .el-input {
                    width: 15.75rem;
                    height: 2.625rem;
                    background: #FFFFFF;
                    box-shadow: 0px .25rem .625remx .0625rem rgba(58, 114, 255, 0.06);
                    border-radius: .625rem .625rem .625rem .625rem;
                }

                .verification-span {
                    cursor: pointer;
                    margin-left: 1rem;
                    display: inline-block;
                    width: 7rem;
                    height: 2.625rem;
                    background: #FFFFFF;
                    box-shadow: 0px .25rem .625rem .0625rem rgba(58, 114, 255, 0.06);
                    border-radius: .625rem .625rem .625rem .625rem;
                    border: 1px solid #EFF0F6;
                }
            }

            #editbtn {
                margin-top: 2rem;
            }
        }

        .el-dialog__footer {
            .dialog-footer {
                display: flex;
                align-items: center;
                justify-content: center;

                .el-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 4.25rem;
                    height: 2.375rem;
                    background: #FFFFFF;
                    border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
                    opacity: 1;
                    border: 1px solid #111029;

                    &:nth-child(2) {
                        background: #111029;
                    }
                }
            }
        }

    }


}

el-table__row>td {
    border: none;
}

.el-table::before {
    height: 0px;
}

// 只去除表格的最最底部的横线
::v-deep .el-table--border::after,
::v-deep .el-table--group::after,
::v-deep .el-table::before {
    background-color: transparent;
}
</style>