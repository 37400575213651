<template>
  <div class="message">
    <div class="container">
      <el-aside width="200px">
        <div class="userinfo">
          <div class="img">
            <img :src="userInfo && userInfo.avatar ? userInfo.avatar : img" class="avatar" alt="" />
            <img v-if="userInfo && userInfo.official_type === 1" src="../../assets/images/userprofile/isvip.png" class="isvip" alt="" />
            <img v-if="userInfo && userInfo.official_type === 2" src="@/assets/images/home/yellow_icon@2x.png" class="isvip" alt="" />
          </div>
          <p>{{ userInfo && userInfo.username }}</p>
          <div class="lei">
            <div class="lei_one" v-if="userInfo && userInfo.level_context">
              <img src="@/assets/images/userprofile/V.png" alt="" class="leiimg" />
              <span>{{ userInfo && userInfo.level_context }}</span>
            </div>
            <div class="lei_two" v-if="userInfo && userInfo.marketer_role_context">{{ userInfo && userInfo.marketer_role_context }}</div>
          </div>
        </div>
        <div class="sidebar">
          <el-menu default-active="2" class="el-menu-vertical-demo">
            <el-badge :value="bagvalone" class="item badeg1" :max="99" :hidden="bagvalone == 0 ? true : false"> </el-badge>
            <el-badge :value="bagvaltwo" class="item badeg2" :max="99" :hidden="bagvaltwo == 0 ? true : false"> </el-badge>
            <el-badge :value="bagvalthree" class="item badeg3" :max="99" :hidden="bagvalthree == 0 ? true : false"></el-badge>
            <el-badge :value="bagvalfour" class="item badeg4" :max="99" :hidden="bagvalfour == 0 ? true : false"> </el-badge>
            <el-badge :value="bagvalfive" class="item badeg5" :max="99" :hidden="bagvalfive == 0 ? true : false"> </el-badge>
            <el-badge :value="bagvalSix" class="item badeg6" :max="99" :hidden="bagvalSix == 0 ? true : false"> </el-badge>
            <el-menu-item v-for="item in sidelist" :key="item.id" @click="onSide(item)">
              <span :class="sideactive == item.id ? 'isactive' : ''"></span>
              <p>{{ item.name }}</p>
            </el-menu-item>
          </el-menu>
        </div>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </div>
  </div>
</template>
<script>
import { METHODS } from "http"
import { countMessageApi } from "@/api/api.js"
const img = require("@/assets/images/header/avatar.png")

export default {
  data() {
    return {
      bagvalone: 0,
      bagvaltwo: 0,
      bagvalthree: 0,
      bagvalfour: 0,
      bagvalfive: 0,
      bagvalSix: 0,
      sideactive: 0,
      sidelist: [
        {
          id: 1,
          name: "评论/回复",
          path: "/message/comment",
        },
        {
          id: 2,
          name: "新增关注",
          path: "/message/follow",
        },
        {
          id: 3,
          name: "私信",
          path: "/message/chart",
        },
        {
          id: 4,
          name: "需求通知",
          path: "/message/partake",
        },
        {
          id: 5,
          name: "项目通知",
          path: "/message/notify",
        },
        {
          id: 6,
          name: "其他通知",
          path: "/message/other",
        },
      ],
      userInfo: {},
      img: img,
      queryInfo: {
        type: "comment",
        page: 1,
        limit: 1,
      },
    }
  },
  mounted() {
    switch (this.$route.path) {
      case "/message/comment":
        this.sideactive = 1
        break
      case "/message/follow":
        this.sideactive = 2
        break
      case "/message/chart":
        this.sideactive = 3
        break
      case "/message/partake":
        this.sideactive = 4
        break
      case "/message/notify":
        this.sideactive = 5
        break
      case "/message/other":
        this.sideactive = 6
        break
    }
  },
  created() {
    this.getData()
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        switch (val.fullPath) {
          case "/message/comment":
            this.sideactive = 1
            break
          case "/message/follow":
            this.sideactive = 2
            break
          case "/message/chart":
            this.sideactive = 3
            break
          case "/message/partake":
            this.sideactive = 4
            break
          case "/message/notify":
            this.sideactive = 5
            break
        }
      },
      // 深度观察监听
      deep: true,
    },
    getUserInfo: {
      handler(val) {
        this.userInfo = val
      },
      immediate: true,
    },

    // 消息统计监听
    getUpdateMsg: {
      handler(val, oldVal) {
        this.getListApi()
      },
    },
  },
  computed: {
    getUserInfo() {
      return this.$store.state.userInfo
    },
    getUpdateMsg() {
      return this.$store.state.updateMsgCount
    },
  },
  methods: {
    getData() {
      let route = this.$route.path
      this.getListApi()
    },
    getListApi() {
      countMessageApi().then((res) => {
        if (res.code === 10000) {
          this.bagvalone = Number(res.data.comment) + Number(res.data.reply)
          this.bagvaltwo = Number(res.data.follow)
          this.bagvalfour = Number(res.data.demand) +  Number(res.data.demand_refuse) + Number(res.data.demand_pass)  
          this.bagvalfive = Number(res.data.project)
          this.bagvalSix = Number(res.data.register) + Number(res.data.registerPass) + Number(res.data.demand_refuse)
        }
      })
    },
    onSide(e) {
      this.$router.push(e.path)
      this.sideactive = e.id
    },
  },
}
</script>
<style lang="scss" scoped>
.message {
  width: 100%;
  // height: 100vh;
  overflow: hidden;
  background: url("../../assets/images/userprofile/userinfobg.png") center no-repeat;
  background-size: 100% 100%;

  .container {
    display: flex;
    max-width: 86.25rem;
    margin: 0 auto;
    height: 100%;

    .el-aside {
      margin-top: 2.5rem;
      width: 17.5rem;
      height: auto;

      .userinfo {
        padding: 2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #ffffff;
        align-items: center;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;

        .img {
          width: 7.5rem;
          height: 7.5rem;
          border-radius: 50%;
          position: relative;

          .avatar {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }

          .isvip {
            position: absolute;
            width: 2.625rem;
            height: 2.625rem;
            bottom: 0.625rem;
            right: 0px;
          }
        }

        p {
          padding: 1rem 0;
          font-size: 1rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: bold;
          color: #240f45;
          line-height: 1.1875rem;
        }

        .lei {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0.5rem 0;

          .lei_one {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 2.625rem;
            height: 1.0625rem;
            background: #ffeff0;
            border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
            opacity: 1;

            .leiimg {
              display: block;
              width: 0.5rem !important;
              height: 0.5625rem !important;
            }

            span {
              font-size: 0.75rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 100;
              color: #dc2b2b;
              line-height: 0.875rem;
            }
          }

          .lei_two {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 0.5625rem;
            min-width: 2.625rem;
            height: 1.0625rem;
            background: #def2e6;
            border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
            opacity: 1;
            font-size: 0.75rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 100;
            color: #11845b;
            line-height: 0.875rem;
          }
        }
      }

      .sidebar {
        margin-top: 0.5rem;
        padding: 0rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #ffffff;
        align-items: center;
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;

        .el-menu {
          background: #ffffff;
          border-right: none;
          width: 100%;
          border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
          position: relative;

          .badeg1 {
            position: absolute;
            right: 3.125rem;
            top: 2rem;
          }

          .el-badge {
            z-index: 10;
            ::v-deep .el-badge__content {
              background: #dc2b2b;
            }
          }

          .badeg2 {
            position: absolute;
            right: 3.125rem;
            top: 6.5625rem;
          }

          .badeg3 {
            position: absolute;
            right: 5.5625rem;
            bottom: 10.6875rem;
          }

          .badeg4 {
            position: absolute;
            right: 3.125rem;
            bottom: 6.25rem;
          }

          .badeg5 {
            position: absolute;
            right: 3.125rem;
            bottom: 1.875rem;
          }
          .badeg6 {
            position: absolute;
            right: 3.125rem;
            bottom: 1.875rem;
          }


          .el-menu-item {
            padding: 0;
            padding-left: 0px !important;

            .isactive {
              background: #2f2c42;
            }
          }

          .el-menu-item:hover {
            background: #ffffff;
          }

          .item:hover {
            background: #ffffff;
          }
        }

        ul {
          li {
            margin: 0.9375rem 0;

            cursor: pointer;
            display: flex;
            align-items: center;

            span {
              display: inline-block;
              width: 10px;
              height: 32px;
              background: #ffffff;
              border-radius: 10px 10px 10px 10px;
              opacity: 1;
            }

            p {
              padding-left: 2.25rem;
              font-size: 1rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 100;
              color: #111029;
              line-height: 1.875rem;
            }
          }
        }
      }
    }

    .el-main {
      margin-top: 2.5rem;
      margin-left: 1rem;
      width: 67.75rem;
      // height: 100%;
      // height: 58.375rem;
      background: #ffffff;
      border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
      overflow: unset;
    }
  }
}
</style>
