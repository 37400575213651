<template>
    <div class="detail">
        <div class="container">
            <div class="detail-box">
                <p class="tle">
                    文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文章标题文
                </p>
                <div class="userprofile">
                    <div class="basic">
                        <img src="" alt="">

                        <div class="lei">
                            <p>名字名字名字名字</p>
                            <div class="lei_one">
                                <img src="@/assets/images/userprofile/V.png" alt="" class="leiimg">
                                <span>初级</span>
                            </div>
                            <div class="lei_two">
                                点子王
                            </div>
                            <div class="fans">
                                <span>/</span>
                                <p>粉丝 13</p>
                                <span>/</span>
                                <p>2022.05.20 10:20</p>
                            </div>
                        </div>
                    </div>
                    <el-button class="follow">关注</el-button>
                </div>
            </div>
            <div class="content">
                <p>本文主要讲什么是适老化设计以及如何做适老化设计，建议阅读时长30分钟，希望能对你有帮助。</p>
                <div class="swiper">
                    <el-carousel height="47.0625rem" :autoplay="false">
                        <el-carousel-item v-for="item in 4" :key="item">
                            <img src="" alt="">
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <span>本文主要讲什么是适老化设计以及如何做适老化设计，建议阅读时长30分钟，希望能对你有帮助。本文主要讲什么是适老化设计以及如何做适老化设计，建议阅读时长30分钟，希望能对你有帮助。本文主要讲什么是适老化设计以及如何做适老化设计，建议阅读时长30分钟，希望能对你有帮助。本文主要讲什么是适老化设计以及如何做适老化设计，建议阅读时长30分钟，希望能对你有帮助。本文主要讲什么是适老化设计以及如何做适老化设计，建议阅读时长30分钟，希望能对你有帮助。本文主要讲什么是适老化设计以及如何做适老化设计，建议阅读时长30分钟，希望能对你有帮助。本文主要讲什么是适老化设计以及如何做适老化设计，建议阅读时长30分钟，希望能对你有帮助。本文主要讲什么是适老化设计以及如何做适老化设计，建议阅读时长30分钟，希望能对你有帮助。本文主要讲什么是适老化设计以及如何做适老化设计，建议阅读时长30分钟，希望能对你有帮助。本文主要讲什么是适老化设计以及如何做适老化设计，建议阅读时长30分钟，希望能对你有帮助。本文主要讲什么是适老化设计以及如何做适老化设计，建议阅读时长30分钟，希望能对你有帮助。本文主要讲什么是适老化设计以及如何做适老化设计，建议阅读时长30分钟，希望能对你有帮助。</span>
                <div class="operation">
                    <div class="follow">
                        <img src="@/assets/images/userprofile/fabulous2.png" alt="">
                        <span>12</span>
                    </div>
                    <div class="follow">
                        <img src="@/assets/images/userprofile/forward.png" alt="">
                        <span>2</span>
                    </div>
                    <div class="follow">
                        <img src="@/assets/images/userprofile/comment.png" alt="">
                        <span>20</span>
                    </div>
                </div>
            </div>
            <div class="fixed" v-show="false">
                <div class="fixed_box">
                    <img src="@/assets/images/userprofile/fabulous copy.png" alt="">
                </div>
                <div class="fixed_box">
                    <img src="@/assets/images/userprofile/forward.png" alt="">
                </div>
                <div class="fixed_box">
                    <img src="@/assets/images/userprofile/comment.png" alt="">
                </div>
            </div>
            <!-- 评论回复 -->
           <comment-reply></comment-reply>
        </div>

    </div>
</template>
<script>
import commentReply from "../../components/commentreply/index.vue"
export default {
    components:{
        commentReply
    },
    data() {
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
.detail {
    height: auto;
    padding-top: 2.5rem;
    position: relative;

    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: #EFF0F6;
        position: absolute;
        top: 13.625rem;
    }

    .container {
        width: 86.25rem;
        margin: 0 auto;
        height: 100%;
        padding: 0 3.125rem;

        .detail-box {
            .tle {
                font-size: 1.25rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #240F45;
                line-height: 1.4375rem;
            }

            .userprofile {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 2.625rem;

                .basic {
                    display: flex;
                    align-items: center;

                    img {
                        display: block;
                        width: 3rem;
                        height: 3rem;
                        border-radius: 50%;
                    }



                    .lei {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        margin: .5rem 0 0 1.625rem;

                        p {
                            font-size: 1.125rem;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #111029;
                            line-height: 1.3125rem;
                            margin: 0 .5rem;
                        }

                        .lei_one {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 2.625rem;
                            height: 1.0625rem;
                            background: #FFEFF0;
                            border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                            opacity: 1;

                            .leiimg {
                                display: block;
                                width: .5rem !important;
                                height: .5625rem !important;
                            }

                            span {
                                font-size: .75rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #DC2B2B;
                                line-height: .875rem;
                            }
                        }

                        .lei_two {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: .5625rem;
                            width: 2.625rem;
                            height: 1.0625rem;
                            background: #DEF2E6;
                            border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                            opacity: 1;
                            font-size: .75rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #11845B;
                            line-height: .875rem;

                        }

                        .fans {
                            display: flex;
                            align-items: flex-end;

                            p {
                                font-size: .875rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #57596C;
                                line-height: 1rem;
                            }

                            span {
                                padding: 0 2rem;
                                color: #57596C;
                            }
                        }
                    }
                }

                .follow {
                    width: 5rem;
                    height: 3.625rem;
                    background: #FFFFFF;
                    font-size: 1rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #111029;
                    line-height: 1.1875rem;
                    border-radius: 3.25rem 3.25rem 3.25rem 3.25rem;
                    opacity: 1;
                    border: 1px solid #111029;
                }
            }
        }

        .content {
            margin-top: 4rem;

            p {
                text-align: center;
                font-size: 1.125rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #57596C;
                line-height: 1.3125rem;
                padding-bottom: 1.8125rem;
            }

            .swiper {
                margin-bottom: 2rem;

                img {
                    display: block;
                    width: 100%;
                }
            }

            span {
                font-size: 1rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #57596C;
                line-height: 1.1875rem;
            }

            .operation {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .follow {
                    display: flex;
                    align-items: center;
                    margin: 2rem;

                    span {
                        display: inline-block;
                        margin-left: .375rem;
                        font-size: .875rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #A0A3BD;
                        line-height: 1rem;
                    }

                    &:nth-child(3) {
                        margin-right: 0px;
                    }
                }
            }
        }

        .fixed {
            position: fixed;
            top: 60%;
            right: 12.5rem;
            .fixed_box {
                margin: .5rem 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3rem;
                height: 3rem;
                background: #FFFFFF;
                box-shadow: 0px .125rem .375rem .0625rem rgba(20, 20, 43, 0.06);
                border-radius: .625rem .625rem .625rem  .625rem ;
                opacity: 1;
                border: .0625rem solid #EFF0F6;
            }
        }
    }
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
</style>