<template>
  <div class="detail">
    <div class="container">
      <div class="detail-box">
        <p class="tle">
          {{ detail.title }}
        </p>
        <div class="userprofile">
          <div class="basic">
            <img :src="detail.creatorInfo && detail.creatorInfo.avatar" alt="" />
            <div class="lei">
              <p>{{detail.creatorInfo && detail.creatorInfo.username }}</p>
              <div class="lei_one">
                <img src="@/assets/images/userprofile/V.png" alt="" class="leiimg" />
                <span>{{detail.creatorInfo && detail.creatorInfo.level_context }}</span>
              </div>
              <div class="lei_two">
                {{detail.creatorInfo && detail.creatorInfo.marketer_role_context }}
              </div>
              <div class="fans">
                <span>/</span>
                <p>粉丝 {{detail.creatorInfo && detail.creatorInfo.fans_num }}</p>
                <span>/</span>
                <p>{{ detail.updated_at }}</p>
              </div>
            </div>
          </div>
          <div class="btn" v-if="status == ''">
            <p class="edit" @click="edit(detail)">编辑</p>
            <p class="del" @click="onDelete(detail)">删除</p>
          </div>
          <!-- <el-button class="follow">关注</el-button> -->
        </div>
      </div>
      <div class="content">
        <!-- <p>本文主要讲什么是适老化设计以及如何做适老化设计，建议阅读时长30分钟，希望能对你有帮助。</p> -->
        <div class="swiper" style="white-space: pre-wrap" v-html="detail.content"></div>
        <div class="operation">
          <div class="follow" @click="collect(detail)">
            <img v-if="detail.isMark == false" src="@/assets/images/userprofile/fabulous2.png" alt="" />
            <img v-if="detail.isMark == true" src="@/assets/images/userprofile/fabulous.png" alt="" style="width: 20px; height: 20px" />
            <span>{{ detail.collectAmount }}</span>
          </div>
          <div class="follow" @mouseenter="showQrEvent">
            <img src="@/assets/images/userprofile/forward.png" />
            <div class="qr flex-column-center" v-if="showQr" @mouseleave="closeQrEvent">
              <span class="font-size-14 font-weight-400">扫一扫分享内容</span>
              <div class="qrcode_box">
                <qrcode_cpn :url="qrcodeUrl"></qrcode_cpn>
              </div>
              <div class="url cursor-pointer font-color-blue font-size-16 font-weight-400" v-clipboard:copy="this.qrcodeUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">点击复制连接</div>
            </div>
          </div>
          <div class="follow">
            <img src="@/assets/images/userprofile/comment.png" alt="" />
            <span>{{ msgCount }}</span>
          </div>
        </div>
      </div>
      <div class="fixed" v-show="false">
        <div class="fixed_box">
          <img src="@/assets/images/userprofile/fabulous copy.png" alt="" />
        </div>
        <div class="fixed_box">
          <img src="@/assets/images/userprofile/forward.png" alt="" />
        </div>
        <div class="fixed_box">
          <img src="@/assets/images/userprofile/comment.png" alt="" />
        </div>
      </div>
      <tabs :isadd="false" ref="tabs" @UploadList="UploadList"></tabs>
      <!-- 评论回复 -->
      <!-- <comment-reply></comment-reply> -->

      <div class="pl">
        <!--评论内容  -->
        <el-input class="text_box" v-model="inputValue" type="textarea" placeholder="说点什么吧" @keyup.enter.native="addComment"></el-input>
        <div class="pl_btn">
          <span class="font-size-16 font-weight-400 cursor-pointer" :class="inputValue.length > 0 ? 'isBlack' : ''" @click="addComment">评论</span>
        </div>
      </div>
      <!-- 消息列表 -->
      <div class="msg" v-id="msgs.length > 0">
        <div class="msg_title text-align-left font-size-20 font-weight-500">全部评论</div>
        <div class="msg_item" :class="item.children.length > 0 ? '' : 'border'" v-for="(item, index) in msgs.slice(0, expendNum)" :key="index">
          <div class="item_left">
            <img v-if="item.avatar" :src="item.avatar" />
          </div>
          <div class="item_center">
            <div class="title flex">
              <span class="name font-size-16 font-weight-500">{{ item.username }}</span>
              <tag-btn v-if="item.level_context" class="redtag" :config="{ text: item.level_context, type: 'red' }"> </tag-btn>
              <tag-btn v-if="item.marketer_role_context" :config="{ text: item.marketer_role_context }"> </tag-btn>
            </div>
            <div class="ms">{{ item.content }}</div>
            <div class="times_bar flex flex-row-between">
              <span class="times font-size-14 font-weight-400">{{ item.created_at }}</span>
              <div class="bar_tight cursor-pointer">
                <i v-if="item.creatorInfo.id ===($store.state.userInfo && $store.state.userInfo.id)" class="el-icon-delete delete_icon" @click="delHandle(item, 0)"></i>
                <img src="@/assets/images/bkal/xx@2x.png" @click="showInputHandle(item, msgs)" />
                <span class="font-size-14 font-weight-400">{{ item.children.length }}</span>
              </div>
            </div>
            <!-- 输入框盒子 -->
            <div class="input_box" v-if="item.showInput">
              <el-input v-model="item.inputValue" :placeholder="`回复${item.username}`" :maxlength="item.maxlength" @keyup.enter.native="childPlHandle(item)"></el-input>
              <div class="pl_btn">
                <span class="font-size-16 font-weight-400 cursor-pointer" :class="item.inputValue && item.inputValue.length > 0 ? 'isBlack' : ''" @click="childPlHandle(item)">评论</span>
              </div>
            </div>
            <!-- 子消息列表 -->
            <div class="msg_children bgc" v-if="item.children.length > 0">
              <div class="msg_item" :class="index === item.children.length - 1 ? '' : 'border '" v-for="(it, index1) in item.children.slice(0, item.expendNum)" :key="index1">
                <div class="item_left">
                  <img :src="it.avatar" alt="" />
                </div>
                <div class="item_center">
                  <div class="title flex">
                    <span class="name font-size-16 font-weight-500">{{ it.username }}</span>
                    <tag-btn v-if="item.level_context" class="redtag" :config="{ text: item.level_context, type: 'red' }"></tag-btn>
                    <tag-btn v-if="item.marketer_role_context" :config="{ text: item.marketer_role_context }"></tag-btn>
                  </div>
                  <div class="ms font-size-16 font-weight-400">
                    回复<span class="names font-size-16 font-weight-500">{{ it.toUserName }}：</span>{{ it.content }}
                  </div>
                  <div class="times_bar flex flex-row-between">
                    <span class="times font-size-14 font-weight-400">{{ it.created_at }}</span>
                    <div class="bar_tight cursor-pointer">
                      <i v-if="it.fromUserInfo.id ===($store.state.userInfo && $store.state.userInfo.id)" class="el-icon-delete delete_icon" @click="delHandle(it, 1)"></i>
                      <img src="@/assets/images/bkal/xx@2x.png" @click="showInputHandle(it, item.children)" />
                      <!-- <span class="font-size-14 font-weight-400">{{ it }}</span> -->
                    </div>
                  </div>
                  <!-- 输入框盒子 -->
                  <div class="input_box" v-if="it.showInput">
                    <el-input v-model="it.inputValue" :placeholder="`回复${item.username}`" :maxlength="it.maxlength" @keyup.enter.native="childPlHandle2(it, item)"> </el-input>
                    <div class="pl_btn">
                      <span class="font-size-16 font-weight-400 cursor-pointer" :class="it.inputValue && it.inputValue.length > 0 ? 'isBlack' : ''" @click="childPlHandle2(it, item)">评论</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 子消息展开更多 -->
            <div class="more" v-if="item.children.length > item.expendNum">
              <div class="item cursor-pointer" @click="expendChildHandle(item, 1)">
                <span class="font-size-14 font-weight-400">展开更多</span>
                <img class="" src="@/assets/images/xqdt/zk@2x.png" alt="icon" />
              </div>
            </div>
            <div class="more" v-if="item.children.length === item.expendNum && item.children.length !== 3">
              <div class="item cursor-pointer" @click="expendChildHandle(item, 2)">
                <span class="font-size-14 font-weight-400">收起</span>
                <img class="shou" src="@/assets/images/xqdt/zk@2x.png" alt="icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 展开更多 -->
      <div class="more">
        <div v-if="msgs.length > expendNum" class="item cursor-pointer" @click="expendHandle(1)">
          <span class="font-size-14 font-weight-400">展开更多</span>
          <img class="" src="@/assets/images/xqdt/zk@2x.png" alt="icon" />
        </div>
        <div v-if="msgs.length === expendNum && msgs.length !== 3" class="item cursor-pointer" @click="expendHandle(2)">
          <span class="font-size-14 font-weight-400">收起</span>
          <img class="shou" src="@/assets/images/xqdt/zk@2x.png" alt="icon" />
        </div>
      </div>
      <!-- 删除确认弹窗 -->
      <dialogs :show.sync="showDialog" width="690px" :center="true" title="删除">
        <p>是否删除这篇文章？</p>
        <template slot="footer-slot">
          <el-button class="cancle" @click="cancle"> 取消 </el-button>
          <el-button class="determine" @click="determine"> 确认 </el-button>
        </template>
      </dialogs>
    </div>
    <el-empty description="快来发表你的评论吧" v-if="msgs.length == 0"></el-empty>
  </div>
</template>
<script>
import tabs from "../../../../components/tabs/tabs.vue"
import dialogs from "@/components/common/dialog-cpn.vue"
import qrcode_cpn from "@/components/common/qrcode_cpn.vue"
import { articleDetail, delArticle, caseDetailApi, commentPageApi, commentApi, delCommontAPi, delReply, replyApi, collectApi, iscollect } from "@/api/api"
import commentReply from "@/components/commentreply/index.vue"
export default {
  components: {
    commentReply,
    tabs,
    dialogs,
    qrcode_cpn,
  },
  data() {
    return {
      status: 0,
      inputValue: "",
      msgCount: "",
      msgs: [],
      expendNum: 3, // 默认显示3条
      deleteId: "",
      showDialog: false,
      detailId: "",
      detail: {},
      commentQuery: {
        module: "",
        module_id: null,
        page: 1,
        limit: 999,
      },
      showQr: false,
      qrcodeUrl: "", //二维码链接
    }
  },
  created() {
    this.getInfo()
    this.getUrl()
  },
  mounted() {},
  methods: {
    async getInfo() {
      if (this.$route.query.id) {
        this.detailId = this.$route.query.id
        this.getDetail(this.detailId)
        this.getCommentList()
        if (this.$route.query.status) {
          this.status = this.$route.query.status
        } else {
          this.status = ""
        }
      }
    },
    /*
     * @Description: 品论删除
     * @MethodAuthor:  liulian
     * @Date: 2022-09-08 09:38:04
     * @param: key 0,父级，1子级
     */
    delHandle(item, key) {
      this.$messageBox("确认删除该条内容么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          switch (key) {
            case 0:
              this.delCommonApi(item)
              break
            case 1:
              this.delReplyApi(item)
              break
          }
        })
        .catch(() => {})
    },
    /*
     * @Description: 回复删除
     * @MethodAuthor:  liulian
     * @Date: 2022-09-08 10:02:32
     * @param: ''
     */
    delReplyApi(item) {
      delReply(item.id).then((res) => {
        if (res.code === 10000) {
          this.$message.success("删除成功！")
          this.getCommentList()
          this.expendNum = 3
        }
      })
    },
    /*
     * @Description: 删除品论
     * @MethodAuthor:  liulian
     * @Date: 2022-09-08 09:45:07
     * @param: ''
     */
    delCommonApi(item) {
      console.log("item ", item)
      delCommontAPi(item.id).then((res) => {
        if (res.code === 10000) {
          this.$message.success("删除成功！")
          this.getCommentList()
          this.expendNum = 3
        }
      })
    },
    // 获取当前url
    getUrl() {
      this.qrcodeUrl = location.href
    },
    /*
     * @Description: 点击复制链接
     * @MethodAuthor:  liulian
     * @Date: 2022-07-13 17:13:52
     * @param: ''
     */
    onCopy() {
      this.$message.info("已复制到剪切板")
    },

    onError() {},
    /*
     * @Description: 是否展示二维码弹框
     * @MethodAuthor:  liulian
     * @Date: 2022-08-04 17:02:18
     * @param: ''
     */
    showQrEvent() {
      this.showQr = true
    },
    // 关闭弹框
    closeQrEvent() {
      this.showQr = false
    },
    // 跳转到个人主页
    goPersonal() {
      if (this.detail.creatorInfo.id) {
        this.$router.push(`/yxyxsJg?id=${this.detail.creatorInfo.id}`)
      }
    },
    // 点赞
    collect(item) {
      console.log(item)
      let data = {
        module: "article",
        module_id: item.id,
      }
      if (item.isMark == false) {
        // 收藏
        collectApi(data).then((res) => {
          if (res.code == 10000) {
            this.$message.success("收藏成功！")
            this.getDetail(this.detailId)
          }
        })
      } else {
        // 取消收藏
        iscollect(data).then((res) => {
          if (res.code == 10000) {
            this.$message.success("取消收藏!")
            this.getDetail(this.detailId)
          }
        })
      }
    },
    /*
     * @Description: 子消息评论
     * @MethodAuthor:  liulian
     * @Date: 2022-07-15 15:45:27
     * @param: ''
     */
    childPlHandle(item) {
      if (!item.inputValue) {
        this.$message.info("回复内容不能为空")
        return
      }
      let obj = {
        content: item.inputValue,
        to_uid: item.creatorInfo.id, // 评论人id
        from_uid: this.$store.state.userInfo.id, // 当前登录人id
        comment_id: item.id, // 评论id
      }
      replyApi(obj).then((res) => {
        if (res.code === 10000) {
          this.$message.success("回复成功！")
          this.getCommentList()
        }
      })
    },
    /*
     * @Description: 展开更多
     * @MethodAuthor:  liulian
     * @Date: 2022-06-14 11:52:31
     * @param: ''
     */
    expendHandle(key) {
      key === 1 && (this.expendNum = this.msgs.length)
      key === 2 && (this.expendNum = 3)
    },
    /*
     * @Description: 最后一级评论
     * @MethodAuthor:  liulian
     * @Date: 2022-07-15 16:52:48
     * @param: ''
     */
    childPlHandle2(item, parentObj) {
      if (!item.inputValue) {
        this.$message.info("回复内容不能为空")
        return
      }
      let obj = {
        content: item.inputValue,
        to_uid: item.fromUserInfo.id, // 评论人id
        from_uid: this.$store.state.userInfo.id, // 当前登录人id
        comment_id: parentObj.id, // 评论id
      }
      replyApi(obj).then((res) => {
        if (res.code === 10000) {
          this.$message.success("回复成功！")
          this.getCommentList()
        }
      })
    },
    // 新增评论内容
    addComment() {
      if (!this.inputValue) {
        this.$message.info("评论内容不能为空")
        return
      }
      let obj = {
        module: "article",
        module_id: this.detail.id,
        content: this.inputValue,
      }
      commentApi(obj).then((res) => {
        if (res.code === 10000) {
          this.$message.success("评论成功！")
          this.inputValue = ""
          this.getCommentList()
        }
      })
    },
    // 获取评论列表
    getCommentList() {
      this.msgs = []
      this.commentQuery.module = "article"
      this.commentQuery.module_id = this.detailId
      commentPageApi(this.commentQuery).then((res) => {
        if (res.code === 10000) {
          console.log(res.data)
          this.msgCount = res.count
          if (res.data.length > 0) {
            this.msgs = res.data
            this.initStyle()
          }
        }
      })
    },
    /*
     * @Description: 列表参数化
     * @MethodAuthor:  liulian
     * @Date: 2022-07-15 15:38:36
     * @param: ''
     */
    initStyle() {
      this.msgs.map((i) => {
        this.$set(i, "children", i.replyList)
        this.$set(i, "avatar", i.creatorInfo.avatar)
        this.$set(i, "username", i.creatorInfo.username)
        this.$set(i, "level_context", i.creatorInfo.level_context)
        this.$set(i, "marketer_role_context", i.creatorInfo.marketer_role_context)
        this.$set(i, "showInput", false)
        this.$set(i, "input", null)
        this.$set(i, "maxlength", 2000)
        this.$set(i, "expendNum", 3) // 最多展示几条

        if (i.children.length > 0) {
          i.children.map((j) => {
            this.$set(j, "avatar", j.fromUserInfo.avatar)
            this.$set(j, "username", j.fromUserInfo.username)
            this.$set(j, "level_context", j.fromUserInfo.level_context)
            this.$set(j, "marketer_role_context", j.fromUserInfo.marketer_role_context)
            this.$set(j, "toUserName", j.toUserInfo.username)
          })
        }
      })
    },
    /*
     * @Description: 展开更多
     * @MethodAuthor:  liulian
     * @Date: 2022-07-15 17:16:01
     * @param: '' key = 1展开，2收起
     */
    expendChildHandle(item, key) {
      key === 1 && this.$set(item, "expendNum", item.children.length)
      key === 2 && this.$set(item, "expendNum", 3)
    },
    /*
     * @Description:
     * @MethodAuthor:  liulian
     * @Date: 2022-07-13 10:19:04
     * @param: ''
     */
    mouseenterHandle() {
      this.showQr = true
    },
    mouseleaveHandle() {
      this.showQr = false
    },
    /*
     * @Description: 展开评论输入框
     * @MethodAuthor:  liulian
     * @Date: 2022-07-15 15:37:48
     * @param: ''
     */
    showInputHandle(item, arr) {
      this.$set(item, "showInput", true)
    },
    async getList() {
      let data = await this.getDetail()
      console.log("%c [ data ]-349", "font-size:13px; background:pink; color:#bf2c9f;", data)
      if (data.case_info.id) {
        let msgs = await this.getCommentList()
      }
    },
    UploadList() {
      this.getDetail(this.detailId)
    },
    getDetail(id) {
      articleDetail(id).then((res) => {
        if (res.code == 10000) {
          this.detail = res.data
        }
      })
    },
    // 编辑
    edit(e) {
      console.log(this.$refs.tabs)
      this.$refs.tabs.onTabs(e)
    },
    // 删除
    onDelete(item) {
      this.deleteId = item.id
      this.showDialog = true
    },
    // 取消删除
    cancle() {
      this.showDialog = false
    },
    // 确认删除
    determine() {
      delArticle(this.deleteId).then((res) => {
        if (res.code == 10000) {
          this.$message.success("删除成功！")
          this.showDialog = false
          this.$router.go(-1)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.detail {
  height: auto;
  padding-top: 2.5rem;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #eff0f6;
    position: absolute;
    top: 13.625rem;
  }

  .container {
    width: 86.25rem;
    margin: 0 auto;
    height: 100%;
    padding: 0 3.125rem;

    .detail-box {
      .tle {
        font-size: 1.25rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #240f45;
        line-height: 1.4375rem;
      }

      .userprofile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.625rem;

        .basic {
          display: flex;
          align-items: center;

          img {
            display: block;
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
          }

          .lei {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin: 0.5rem 0 0 1.625rem;

            p {
              font-size: 1.125rem;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #111029;
              line-height: 1.3125rem;
              margin: 0 0.5rem;
            }

            .lei_one {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 2.625rem;
              height: 1.0625rem;
              background: #ffeff0;
              border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
              opacity: 1;

              .leiimg {
                display: block;
                width: 0.5rem !important;
                height: 0.5625rem !important;
              }

              span {
                font-size: 0.75rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #dc2b2b;
                line-height: 0.875rem;
              }
            }

            .lei_two {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 0.5625rem;
              width: auto;
              padding: 0 0.3125rem;
              height: 1.0625rem;
              background: #def2e6;
              border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
              opacity: 1;
              font-size: 0.75rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 100;
              color: #11845b;
              line-height: 0.875rem;
            }

            .fans {
              display: flex;
              align-items: flex-end;

              p {
                font-size: 0.875rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 100;
                color: #57596c;
                line-height: 1rem;
              }

              span {
                padding: 0 2rem;
                color: #57596c;
              }
            }
          }
        }

        .btn {
          display: flex;

          p {
            cursor: pointer;
            font-size: 1rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #57596c;
            line-height: 1.1875rem;
          }

          .del {
            margin-left: 2rem;
          }
        }
      }
    }

    .content {
      margin-top: 4rem;

      p {
        padding-top: 4rem;
        text-align: center;
        font-size: 1.125rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 100;
        color: #57596c;
        line-height: 1.3125rem;
        padding-bottom: 1.8125rem;
      }

      .swiper {
        padding-top: 50px;
        margin-bottom: 2rem;

        img {
          display: block;
          width: 100%;
        }
      }

      span {
        font-size: 1rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 100;
        color: #57596c;
        line-height: 1.1875rem;
      }

      .operation {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .follow {
          display: flex;
          align-items: center;
          margin: 2rem;
          position: relative;

          span {
            display: inline-block;
            margin-left: 0.375rem;
            font-size: 0.875rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 100;
            color: #a0a3bd;
            line-height: 1rem;
          }

          &:nth-child(1),
          &:nth-child(2) {
            cursor: pointer;
          }

          &:nth-child(3) {
            margin-right: 0px;
          }

          .qr {
            width: 12.75rem;
            height: 15.125rem;
            background: #ffffff;
            box-shadow: 0rem 0.125rem 0.375rem 0.0625rem rgba(20, 20, 43, 0.06);
            border-radius: 0.625rem;
            opacity: 1;
            border: 0.0625rem solid #eff0f6;
            position: absolute;
            top: 1.625rem;
            left: -4.25rem;
            z-index: 33;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            img {
              width: 8.75rem;
              height: 8.75rem;
              margin-top: 0.375rem;
              margin-bottom: 1rem;
            }

            .qrcode_box {
              width: 8.75rem;
              height: 8.75rem;
              margin-top: 0.25rem;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }

    .fixed {
      position: fixed;
      top: 60%;
      right: 12.5rem;

      .fixed_box {
        margin: 0.5rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        background: #ffffff;
        box-shadow: 0px 0.125rem 0.375rem 0.0625rem rgba(20, 20, 43, 0.06);
        border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
        opacity: 1;
        border: 0.0625rem solid #eff0f6;
      }
    }
  }

  .el-dialog {
    .el-dialog__body {
      p {
        text-align: center;
        font-size: 1rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 300;
        color: #5c6466;
        line-height: 1.1875rem;
      }
    }

    .el-dialog__footer {
      .dialog-footer {
        .cancle {
          width: 4.25rem;
          height: 2.375rem;
          font-size: 0.875rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #111029;
          line-height: 1rem;
          background: #ffffff;
          border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
          border: 1px solid #111029;
        }

        .determine {
          margin-left: 11.375rem;
          width: 4.25rem;
          height: 2.375rem;
          font-size: 0.875rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 1rem;
          background: #111029;
          border-radius: 2.9375rem 2.9375rem 2.9375rem 2.9375rem;
          border: 1px solid #111029;
        }
      }
    }
  }

  .pl {
    margin-top: 3.5rem;

    ::v-deep .el-textarea__inner {
      height: 6.25rem;
      box-shadow: 0rem 0.25rem 0.625rem 0.0625rem rgba(58, 114, 255, 0.06);
      border-radius: 0.5rem;
      opacity: 1;
    }
  }

  .pl_btn {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;

    span {
      width: 4.75rem;
      height: 3.125rem;
      // background: #f7f7fc;
      background: #eff0f6;
      border-radius: 3.25rem;
      opacity: 1;
      color: #111029;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .isBlack {
      background: #111029;
      color: #ffffff;
    }
  }

  .msg {
    margin-top: 0.875rem;

    .msg_item {
      margin-top: 2rem;
      position: relative;
      display: flex;

      .item_left {
        position: relative;
        width: 3rem;
        height: 3rem;
        border-radius: 100%;
        background-color: #eee;

        img {
          width: 3rem;
          height: 3rem;
          border-radius: 100%;
        }
      }

      .item_center {
        margin-left: 1rem;
        flex: 1;

        .title {
          .name {
            color: #111029;
          }

          .redtag {
            margin-left: 0.5rem;
            margin-right: 0.375rem;
          }
        }

        .ms {
          color: #2f2c42;
          margin-top: 1rem;
          font-size: 1rem;
          font-weight: 400;

          .names {
            color: #2f2c42;
            margin-left: 0.625rem;
          }
        }

        .times_bar {
          .delete_icon {
            color: #a0a3bd;
            margin-right: 20px;
          }
          .times {
            color: #a0a3bd;
            margin-top: 1rem;
            display: block;
            margin-bottom: 2rem;
          }

          .bar_tight {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;

            img {
              width: 0.9875rem;
              height: 0.8962rem;
              margin-right: 0.5rem;
            }

            span {
              color: #a0a3bd;
            }
          }
        }

        .input_box {
          margin-bottom: 2rem;

          ::v-deep .el-input__inner {
            height: 3rem;
          }
        }

        // 子消息列表
        .msg_children {
          border-radius: 1rem;

          .msg_item {
            margin-top: 0;

            .item_left {
              margin-top: 1rem;
              margin-left: 1rem;
            }

            .item_center {
              margin-top: 1rem;

              .bar_tight {
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }

    .border {
      border-bottom: 1px solid #eff0f6;
    }

    .bgc {
      background: #f7f7fc;
    }
  }

  .more {
    .item {
      display: inline-block;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
    }

    img {
      width: 1.25rem;
      height: 1.25rem;
      margin-left: 0.5rem;
    }

    span {
      color: #a0a3bd;
    }
  }

  ::v-deep .el-carousel__arrow {
    background-color: #bebdbf;
    display: block !important;

    i {
      color: #111029;
      font-weight: bold;
      font-size: 20px;
    }
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
