<template>
    <div class="userprofile">
        <!-- 个人介绍 -->
        <div class="profile-top" v-cloak>
            <div class="user-contailer">
                <div class="user-header">
                    <div class="header-left">
                        <div class="avatar" @click.stop="onuser">
                              <img :src="$store.state.userHomPage.infor.avatar" alt="" class="img">
                              <img src="../../assets/images/b.png" alt="" class="icon" v-if="$store.state.userHomPage.infor.official_type==1">
                              <img src="../../assets/images/y.png" alt="" class="icon" v-if="$store.state.userHomPage.infor.official_type==2">
                        </div>
                        <p>{{ $store.state.userHomPage.infor.username }}</p>
                        <div class="lei">
                            <div class="lei_one">
                                <img src="@/assets/images/userprofile/V.png" alt="" class="leiimg">
                                <span>{{ $store.state.userHomPage.infor.level_context }}</span>
                            </div>
                            <div class="lei_two" v-if="$store.state.userHomPage.infor.marketer_role_context != ''">
                                {{ $store.state.userHomPage.infor.marketer_role_context }}
                            </div>
                        </div>
                        <div class="fans">粉丝 <span>{{$store.state.userHomPage.infor.fans_num}}</span></div>
                    </div>
                    <div class="header-right" >
                        <div class="right-box" v-if="tag.length > 0">
                            <p class="introduce">{{userInfor.infor.is_official_admin==1 && userInfor.infor.official_type==1 ?"机构介绍" :'' || userInfor.infor.is_official_admin==1 && userInfor.infor.official_type==2 ?"品牌介绍" :'' || userInfor.infor.is_official_admin==0 && userInfor.infor.official_type==1 ?"自我介绍" :''  }}</p>
                            <span style="white-space: pre-wrap;">{{ $store.state.userHomPage.infor.introduction }}</span>
                            <div class="tablist">
                                <div class="list">
                                    <p>社交网络</p>
                                    <ul>
                                        <li v-for="item in $store.state.userHomPage.social" :key="item.value">{{ item.name }}</li>
                                    </ul>
                                </div>
                                <div class="list">
                                    <p>传播内容</p>
                                    <ul>
                                        <li v-for="item in $store.state.userHomPage.area" :key="item.value">{{ item.name }}</li>
                                    </ul>
                                </div>
                                <div class="list">
                                    <p>行业领域</p>
                                    <ul>
                                        <li v-for="item in $store.state.userHomPage.industry" :key="item.value">{{ item.name }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                         <div class="right-box" v-else-if=" $store.state.userHomPage.infor.introduction.length > 0" :style="styleobject">
                            <span class="mo"> </span>
                            <p class="introduce">{{userInfor.infor.is_official_admin==1 && userInfor.infor.official_type==1 ?"机构介绍" :'' || userInfor.infor.is_official_admin==1 && userInfor.infor.official_type==2 ?"品牌介绍" :'' || userInfor.infor.is_official_admin==0 && userInfor.infor.official_type==1 ?"自我介绍" :''  }}</p>
                            <span style="white-space: pre-wrap; margin-left: 1rem;">{{ $store.state.userHomPage.infor.introduction }}</span>
                        </div>
                        <div class="right-box boxnone" v-else>
                            <p>自我介绍</p>
                            <span>快去介绍自己吧~</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 侧边导航栏 -->
        <div class="navigationbar">
            <div class="nav-container">
                <el-container>
                    <el-aside width="280px">
                        <el-menu default-active="2" class="el-menu-vertical-demo">
                            <el-menu-item class="slideritem" v-for="item in sidelist" :key="item.id"
                                @click="onSide(item)">
                                <span :class="sideactive == item.id ? 'isactive' : ''"></span>
                                <p>{{ item.name }}</p>
                            </el-menu-item>
                        </el-menu>
                    </el-aside>
                    <!-- 中间内容 -->
                    <el-main>
                        <router-view></router-view>
                    </el-main>
                </el-container>
            </div>
        </div>
    </div>
</template>
<script>
import { getUserApi, userHomePage } from "../../api/api"
export default {
    data() {
        return {
            styleobject:{
                marginLeft:'0rem',
                display:"flex",
                alignItems:"center"
            },
            flag:true,
            userid: "",
            userinfor: this.$store.state.userHomPage,
            radio: "",
            username: "张三",//用户名，
            tag: [],
            tagList: {},
            list: [
                {
                    name: "小红薯",
                    value: 1
                },
                {
                    name: "小红薯",
                    value: 2
                },
                {
                    name: "小红薯",
                    value: 3
                }
            ],
            // 导航栏选中
            sideactive: 1,
            sidelist: [
                {
                    id: 1,
                    name: "关注动态",
                    path: "/grzx/gzdt"
                },
                {
                    id: 2,
                    name: "机构创作者",
                    path: "/grzx/jgcz"
                },
                {
                    id: 3,
                    name: "我的案例",
                    path: "/grzx/wdal"
                },
                {
                    id: 4,
                    name: "我的需求",
                    path: "/grzx/wdxq"
                }, {
                    id: 5,
                    name: "我的文章",
                    path: "/grzx/wdwz"
                },
                {
                    id: 6,
                    name: "我的收藏",
                    path: "/grzx/wdsc"
                },
                {
                    id: 7,
                    name: "好友圈",
                    path: "/grzx/hyq"
                },
                {
                    id: 8,
                    name: "项目中心",
                    path: "/grzx/xmzx"
                },
                {
                    id: 9,
                    name: "案例订单",
                    path: "/grzx/aldd"
                },
                {
                    id: 10,
                    name: "设置",
                    path: "/grzx/sz"
                },
            ]

        }
    },
    updated() {
    },
    computed:{
		userInfor(){
			return this.$store.state.userHomPage
		}
	},
    watch:{
        userInfor:{
            handler(data){
                console.log(data)
            },
            deep:true,
            immediate:true
        }
    },
     beforDestroy() {
        Bus.$off("isLeft")
    },
    mounted() {
        console.log(this.$store.state.userHomPage)
        this.getuserinfor()
        console.log(this.$route.path)
        switch (this.$route.path) {
            case '/grzx/gzdt':
                this.sideactive = 1
                break
            case "/grzx/jgcz":
                this.sideactive = 2
                break
            case "/grzx/wdal":
                this.sideactive = 3
                break
            case "/grzx/wdxq":
                this.sideactive = 4
                break
            case "/grzx/wdwz":
                this.sideactive = 5
                break
            case "/grzx/wdsc":
                this.sideactive = 6
                break
            case "/grzx/hyq":
                this.sideactive = 7
                break
            case "/grzx/xmzx":
                this.sideactive = 8
                break
            case "/grzx/aldd":
                this.sideactive = 9
                break
            case "/grzx/sz":
                this.sideactive = 10
                break
        }
    },
    watch: {
        $route: {
            handler: function (val, oldVal) {
                switch (val.fullPath) {
                    case '/grzx/gzdt':
                        this.sideactive = 1
                        break
                    case "/grzx/jgcz":
                        this.sideactive = 2
                        break
                    case "/grzx/wdal":
                        this.sideactive = 3
                        break
                    case "/grzx/wdxq":
                        this.sideactive = 4
                        break
                    case "/grzx/wdwz":
                        this.sideactive = 5
                        break
                    case "/grzx/wdsc":
                        this.sideactive = 6
                        break
                    case "/grzx/hyq":
                        this.sideactive = 7
                        break
                    case "/grzx/xmzx":
                        this.sideactive = 8
                        break
                    case "/grzx/aldd":
                        this.sideactive = 9
                        break
                    case "/grzx/sz":
                        this.sideactive = 10
                        break
                }
            },
            // 深度观察监听
            deep: true
        }
    },
    updated() {
        if (this.$route.path == '/grzx/sz') {
            console.log("222")
        }
    },
    methods: {
        // 获取用户主页信息
        getHomePage(id) {
            userHomePage(id)
                .then(res => {
                    if (res.code == 10000) {
                        if(this.flag){
                              if (res.data.user_info.is_official_admin != 1 || res.data.user_info.official_type != 1) {
                            this.sidelist.splice(1, 1)
                        }
                        }
                        var userinfor = {}
                        userinfor.infor = res.data.user_info
                        this.tag = res.data.tag
                        var area = []
                        var social = []
                        var industry = []
                        res.data.tag.map(e => {
                            console.log(e)
                            if (e.top_parent_bind == 'area') {
                                area.push(e)
                            } else if (e.top_parent_bind == "social") {
                                social.push(e)
                            } else {
                                industry.push(e)
                            }
                        })
                        userinfor.area = area
                        userinfor.social = social
                        userinfor.industry = industry
                        this.$store.commit('getHomePage', userinfor)
                        this.$store.commit('SET_USERINFO',userinfor.infor)
                        this.userinfor = this.$store.state.userHomPage
                        console.log( this.userinfor )
                        //    console.log(this.$store.state.userHomPage)
                    }
                })
        },
        // 获取用户信息
        getuserinfor() {
            getUserApi()
                .then((res) => {
                    if (res.code == 10000) {
                        this.userid = res.data.id
                        this.getHomePage(res.data.id)
                        localStorage.setItem('userinfor', JSON.stringify(res.data))
                    }
                })
        },
        // 点击导航栏
        onSide(e) {
            this.$router.push(e.path)
            this.sideactive = e.id
            // this.getHomePage(this.userid)
            this.flag=false
        },
        // 进入作者个人主页
        onuser(){
            this.$router.push({path:"/yxyxsJg",query:{id:this.userinfor.infor.id}})
        }
    }
}
</script>
<style lang="scss" scoped>
[v-cloak] {
    display: none;
}

.userprofile {
    width: 100%;
    height: auto;

    .user-contailer {
        max-width: 86.25rem;
        height: 100%;
        margin: 0 auto;
        padding: 2.5rem 3.125rem 2.25rem;

        .user-header {
            display: flex;
            align-items: center;

            .header-left {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                margin-left: 4.3125rem;
                .avatar {
                    position: relative;
                    cursor: pointer;
               .img {
                    display: block;
                    width: 7.5rem;
                    height: 7.5rem;
                    border-radius: 50%;
                }
                .icon {
                    display: block;
                    width: 2.625rem;
                    height: 2.625rem;
                    position: absolute;
                    right: 0;
                    bottom: .125rem;
                }
                }
                

                p {
                    margin-top: 1.0625rem;
                    font-size: 1rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #240F45;
                    line-height: 1.1875rem;
                }

                .lei {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: .5rem 0;

                    .lei_one {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 2.625rem;
                        height: 1.0625rem;
                        background: #FFEFF0;
                        border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                        opacity: 1;

                        .leiimg {
                            display: block;
                            width: .5rem !important;
                            height: .5625rem !important;
                        }

                        span {
                            font-size: .75rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 100;
                            color: #DC2B2B;
                            line-height: .875rem;
                        }
                    }

                    .lei_two {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: .5625rem;
                        width: auto;
                        height: 1.0625rem;
                        padding: 0 .1875rem;
                        background: #DEF2E6;
                        border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
                        opacity: 1;
                        font-size: .75rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #11845B;
                        line-height: .875rem;
                    }

                }

                .fans {
                    font-size: 0.875rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 100;
                    color: #2F2C42;
                    line-height: 1rem;

                    span {
                        display: inline-block;
                        font-weight: 500;
                    }
                }
            }

            .header-right {
                margin-left: 4.1875rem;
                position: relative;

                &::after {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 11.6875rem;
                    background: #F4F4F4;
                    position: absolute;
                    left: 0;
                    top: 10%;
                    // margin-top: -50%;
                }

                .right-box {
                    margin-left: 2.0625rem;
                   
                    .mo {
                        display: block;
                        width: 1px;
                    height: 11.6875rem;
                        background: #F4F4F4;
                    }
                    .introduce {
                        margin-bottom: .5rem;
                        font-size: .875rem;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #111029;
                        line-height: 1rem;
                    }

                    span {

                        font-size: .875rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 100;
                        color: #57596C;
                        line-height: 1rem;
                    }

                    .tablist {
                        margin-top: 1.25rem;
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;

                        .list {
                            display: flex;
                            align-items: center;
                            margin: .4375rem 0;

                            p {
                                font-size: .875rem;
                                font-family: PingFang SC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #111029;
                                line-height: 1rem;
                            }

                            ul {
                                display: flex;
                                align-items: center;
                                margin: 0 .75rem;

                                li {
                                    margin: 0 .25rem;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: auto;
                                    padding: .25rem .5rem;
                                    height: 1.75rem;
                                    border-radius: 1.625rem 1.625rem 1.625rem 1.625rem;
                                    opacity: 1;
                                    border: .0625rem solid #EFF0F6;
                                    font-size: .875rem;
                                    font-family: PingFang SC-Regular, PingFang SC;
                                    font-weight: 100;
                                    color: #57596C;
                                    line-height: 1rem;
                                }
                            }
                        }
                    }
                }
                

                .boxnone {
                    height: 12.8125rem;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    justify-content: center;

                    p {
                        padding-bottom: .5rem;
                    }
                }
            }
        }
    }

    .navigationbar {
        width: 100%;
        background: url('../../assets/images/userprofile/userinfobg.png') center no-repeat;
        background-size: 100% 100%;
        height: 100%;

        .nav-container {
            max-width: 86.25rem;
            height: 100%;
            margin: 0 auto;
            padding: .75rem .125rem 2.25rem;

            .el-container {
                .el-aside {
                    background: #ffffff;
                    border-radius: .625rem;
                    opacity: 1;
                    height: 100%;

                    .el-menu {
                        background: #ffffff;
                        border-right: none;
                        height: 100%;

                        .el-menu-item {
                            height: auto;
                            padding: .9375rem 0;
                            padding-left: 0px !important;
                            margin: 0;

                            .isactive {
                                transition: all 2s ease;
                                background: #2F2C42;
                            }
                        }

                        .el-menu-item:hover {
                            background: #ffffff;
                        }

                        .item:hover {
                            background: #ffffff;
                        }
                    }

                    ul {
                        li {

                            margin: .9375rem 0;

                            cursor: pointer;
                            display: flex;
                            align-items: center;

                            span {
                                display: inline-block;
                                width: 10px;
                                height: 32px;
                                background: #ffffff;
                                border-radius: 10px 10px 10px 10px;
                                opacity: 1;
                            }



                            p {
                                padding-left: 2.25rem;
                                font-size: 1rem;
                                font-family: PingFang SC-Regular, PingFang SC;
                                font-weight: 100;
                                color: #111029;
                                line-height: 1.875rem;
                            }
                        }


                    }
                }

                .el-main {
                    min-height: calc(100vh - 400px);
                    padding: 0;
                    margin-left: 1rem;
                    overflow-y: hidden;
                    background: #FFFFFF;
                    border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
                }
            }
        }
    }
}
</style>